// AGI.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const AGI = () => {
	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Godlike intelligence."
				content={[
					"At Del Complex, we're committed to developing ethical, responsible, and self-improving artificial general intelligence (AGI) systems. Our aim is to accelerate humanity and find solutions to the world's most pressing challenges.",
				]}
				titleLevel={1}
			/>

			<Article
				title="p(Doom) Defense Unit"
				content={[
					"We recognize the critical importance of AI alignment in the development of artificial general intelligence. Ensuring that AGI systems act in accordance with human values and intentions is a top priority.",
					"Our dedicated teams of Alignment Agents are developing advanced techniques and ethical frameworks to guide the alignment of AGI with human objectives, while proactively addressing potential risks. By working to reduce p(Doom) and prioritizing AI alignment, we aim to create responsible, safe, and beneficial AGI systems that help us become more of who we are destined to be, without compromising the well-being of future generations.",

					"One of our pioneering approaches is The Twin-Based Neurofeedback Reinforcement Learning (TBNRL) method. By leveraging the genetic and physiological similarities between twin siblings we're able to align our AI systems with unprecedented speed, objectivity, and accuracy. When twins interact with the AI, their synchronized neural responses are captured by PsyNet, our state-of-the-art brain-computer interface. This rich dataset creates a nuanced, human-centric reward signal. Our AI system then performs a 'symmetry-breaking' operation, discerning and adapting to even the slightest differences in reactions, fine-tuning its behavior to closely align with human values and expectations.",
				]}
				images={["/media/employees_agi.jpg"]}
				imageIndex={2}
			/>
			<Article
				title="Feature Space Unit"
				content={[
					"Del Complex's Feature Space Unit aims to expand the set of possible thoughts accessible to humans through AGI.",
					"By leveraging advanced feature engineering and semantic mapping techniques, our team fine tunes the multidimensional feature spaces that our AGI operates within. This allows the AGI to process and interpret complex data in novel ways, illuminating new insights and concepts that enrich and broaden the scope of human cognition.",

					"With a strong focus on improving AGI-human interactions, the Feature Space Unit at Del Complex is paving the way towards responsible, safe, and efficient AGI systems that make our lives richer and our challenges more manageable.",
				]}
				images={["/media/datacenter.jpg"]}
				imageIndex={2}
			/>
			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default AGI;
