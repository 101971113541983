// PsynetUpdate.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const PsynetUpdate = () => {
	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Psynet Update"
				content={[
					"We are pleased to update the public on the progress of our Psynet project. The system is now fully operational and ready to accept applications from qualified candidates. We are excited to begin the next phase of our research and development, and we look forward to welcoming new participants into the program.",

					<>
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/Ew8PeOcdf_0?si=uotUzNutdbIgK1SW"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowfullscreen
						></iframe>
					</>,
				]}
				titleLevel={1}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default PsynetUpdate;
