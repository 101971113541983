// PurpleSmokeRequestAccess.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PurpleSmokeNavigation from "./PurpleSmokeNavigation";
import PurpleSmokeFooter from "./PurpleSmokeFooter";
import usePurpleSmokeSecurity from "./usePurpleSmokeSecurity";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const PurpleSmokeRequestAccess = () => {
	usePurpleSmokeSecurity();
	const [formData, setFormData] = useState({
		contractorCode: "",
		internalCode: "",
		partnerCode: "",
	});
	const [serverMessage, setServerMessage] = useState("");
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		event.preventDefault();
		setServerMessage("");

		try {
			const response = await fetch(`${API_URL}/validate-input-purpleSmoke`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userInput: formData }),
			});

			const data = await response.json();

			if (data.valid) {
				localStorage.setItem("bluesea_access_granted", "true");
				navigate(data.redirectUrl);
			} else {
				setServerMessage(data.message || "Invalid input");
			}
		} catch (err) {
			setServerMessage("An error occurred");
			console.log("An error occurred:", err);
		}
	};

	return (
		<div className="purpleSmoke">
			<PurpleSmokeNavigation />

			<form onSubmit={handleSubmit} className="inputForm">
				<label htmlFor="contractorCode">Contractor Code</label>
				<input
					type="text"
					id="contractorCode"
					name="contractorCode"
					value={formData.contractorCode}
					onChange={(e) =>
						setFormData({ ...formData, contractorCode: e.target.value })
					}
				/>
				<label htmlFor="internalCode">Internal Code</label>
				<input
					type="text"
					id="internalCode"
					name="internalCode"
					value={formData.internalCode}
					onChange={(e) =>
						setFormData({ ...formData, internalCode: e.target.value })
					}
				/>
				<label htmlFor="partnerCode">Partner Code</label>
				<input
					type="text"
					id="partnerCode"
					name="partnerCode"
					value={formData.partnerCode}
					onChange={(e) =>
						setFormData({ ...formData, partnerCode: e.target.value })
					}
				/>
				<button type="submit" className="submit-button">
					Submit
				</button>
				<div className="serverMessage">{serverMessage}</div>
			</form>
			<PurpleSmokeFooter />
		</div>
	);
};

export default PurpleSmokeRequestAccess;
