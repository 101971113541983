// vongoomoperatorRegistration.js
import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const vongoomoperatorRegistration = () => {
	const [employeeData, setEmployeeData] = useState({
		address: "",
		champion: "",
	});
	const [serverMessage, setServerMessage] = useState("");

	const sanitizeEthereumAddress = (address) => {
		// Remove leading and trailing spaces
		address = address.trim();

		// Convert to lowercase
		address = address.toLowerCase();

		// Remove "0x" prefix if present
		if (address.startsWith("0x")) {
			address = address.slice(2);
		}

		// Remove non-hex characters
		address = address.replace(/[^a-f0-9]/g, "");

		// Ensure the address is exactly 40 characters long
		if (address.length !== 40) {
			// Handle validation error, if necessary
			// For example: throw new Error("Invalid Ethereum address");
		}

		return "0x" + address; // Add back the "0x" prefix
	};

	const handleSubmit = async (event, formId, formData) => {
		event.preventDefault();

		// Clear out the previous server message
		setServerMessage("");

		try {
			const response = await fetch(`${API_URL}/store`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userInput: formData, rC: formId }),
			});

			const data = await response.json();
			console.log("Data sent to server:", data);

			// Wait for a brief moment before updating the server message
			setTimeout(() => {
				if (response.status === 400) {
					setServerMessage(data.message || "Bad Request");
				} else if (response.status === 200) {
					setServerMessage(data.message || "Success");
				}
			}, 300);
		} catch (err) {
			setTimeout(() => {
				setServerMessage("An error occurred");
			}, 300);

			console.log("An error occurred:", err);
		}
	};

	return (
		<div className="main-content">
			<div className="content-container">
				<div className="spacerShort"></div>
				<div className="article-content">
					<div style={{ width: "85vw" }}>
						<div
							style={{ marginLeft: "auto", marginRight: "auto", width: "50%" }}
						>
							<h3>VG OP REG</h3>
							<p>Gambit execution.</p>
							<div>{serverMessage === "" ? "\u00A0" : serverMessage}</div>
						</div>

						<form
							onSubmit={(e) => handleSubmit(e, "r6vg", employeeData)}
							className="inputForm"
						>
							<label htmlFor="operatorAddress">Operator Address</label>
							<br />
							<input
								type="text"
								id="operatorAddress"
								name="operatorAddress"
								placeholder="0x..."
								value={employeeData.address}
								onChange={(e) =>
									setEmployeeData({
										...employeeData,
										address: sanitizeEthereumAddress(e.target.value),
									})
								}
							/>
							<br />
							<label htmlFor="vongoomoperatorA">Champion</label>
							<input
								type="text"
								id="vongoomoperatorA"
								name="vongoomoperatorA"
								placeholder=""
								value={employeeData.champion}
								onChange={(e) =>
									setEmployeeData({ ...employeeData, champion: e.target.value })
								}
							/>
							<button type="submit" className="auto-margin">
								Register
							</button>
							<br />
							<p>
								You are accessing a restricted endpoint. If you believe you have
								reached this page in error, please contact the Del Complex
								Security Operations Center.
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default vongoomoperatorRegistration;
