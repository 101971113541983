import React from "react";
import Article from "./Article";

const content = [
	"October 9th, 2023",
	"",
	"Public Disclosure",
	"",
	"In compliance with the regulations governing the receipt of government funds, Del Complex is required to disclose the establishment of a strategic partnership with Xio Sky, resulting in the creation of 'Flipper'. This initiative is designed to enhance organizational resilience and security, aligning with the objectives outlined in our funding parameters.",
	"",
	"This joint initiative is designed to meet the growing need for strengthened organizational resilience and enhanced security measures amid escalating global challenges.",
	"",
	"We maintain strict adherence to compliance, quality, and integrity standards, consistent with the oversight and regulations associated with the allocation of government resources. 'Flipper' is aligned with these standards, ensuring the responsible utilization of granted funds.",
	"",
	"Detailed information regarding 'Flipper', including its operational framework, expected outcomes, and compliance with statutory mandates, will be available for public review. We are committed to providing ongoing disclosures to keep the public and our stakeholders informed of developments.",
	"",
	"End of Disclosure",
];

export const title = "Del Complex and Xio Sky Announce Strategic Partnership";
const PortalNewsFlipper = () => {
	return <Article title={title} content={content} titleLevel={2} />;
};

export default PortalNewsFlipper;
