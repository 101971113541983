// BrainWormDoctorRequestAccess.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BrainWormDoctorNavigation from "./BrainWormDoctorNavigation";
import BrainWormDoctorFooter from "./BrainWormDoctorFooter";
import useBrainWormDoctorSecurity from "./useBrainWormDoctorSecurity";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const BrainWormDoctorRequestAccess = () => {
	useBrainWormDoctorSecurity();
	const [employeeData, setEmployeeData] = useState({
		address: "",
		code: "",
	});
	const [serverMessage, setServerMessage] = useState("");

	const sanitizeEthereumAddress = (address) => {
		// Remove leading and trailing spaces
		address = address.trim();

		// Convert to lowercase
		address = address.toLowerCase();

		// Remove "0x" prefix if present
		if (address.startsWith("0x")) {
			address = address.slice(2);
		}

		// Remove non-hex characters
		address = address.replace(/[^a-f0-9]/g, "");

		// Ensure the address is exactly 40 characters long
		if (address.length !== 40) {
			// Handle validation error, if necessary
		}

		return "0x" + address; // Add back the "0x" prefix
	};

	const handleSubmit = async (event, formId, formData) => {
		event.preventDefault();

		// Clear out the previous server message
		setServerMessage("");

		try {
			const response = await fetch(`${API_URL}/store`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userInput: formData, rC: formId }),
			});

			const data = await response.json();
			console.log("Data sent to server:", data);

			// Wait for a brief moment before updating the server message
			setTimeout(() => {
				if (response.status === 400) {
					setServerMessage(data.message || "Bad Request");
				} else if (response.status === 200) {
					setServerMessage(data.message || "Success");
				}
			}, 300);
		} catch (err) {
			setTimeout(() => {
				setServerMessage("An error occurred");
			}, 300);

			console.log("An error occurred:", err);
		}
	};

	return (
		<div className="BrainWormDoctor">
			<BrainWormDoctorNavigation />

			<form
				onSubmit={(e) => handleSubmit(e, "rHsMed", employeeData)}
				className="inputForm"
			>
				<label htmlFor="contractorAddress">Address</label>
				<br />
				<input
					type="text"
					id="contractorAddress"
					name="contractorAddress"
					placeholder="0x..."
					value={employeeData.address}
					onChange={(e) =>
						setEmployeeData({
							...employeeData,
							address: sanitizeEthereumAddress(e.target.value),
						})
					}
				/>
				<br />
				<label htmlFor="AccessCode">Project Codename</label>
				<input
					type="text"
					id="AccessCode"
					name="AccessCode"
					placeholder=""
					value={employeeData.code}
					onChange={(e) =>
						setEmployeeData({ ...employeeData, code: e.target.value })
					}
				/>
				<br />

				<button type="submit" className="auto-margin">
					Request
				</button>
				<p>
					{" "}
					<div>{serverMessage === "" ? "\u00A0" : serverMessage}</div>
				</p>
			</form>
			<BrainWormDoctorFooter />
		</div>
	);
};

export default BrainWormDoctorRequestAccess;
