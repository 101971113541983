// ResearchOverview.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const ResearchOverview = () => {
	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Embodied intelligent agents."
				content={[
					"At Del Complex our robotics research is designed to serve as the interface between the digital minds we're developing and the tangible world, strengthening their sphere of influence.",
					"To achieve this, we have focused on several key areas, namely autonomous systems, swarm robotics, human-robot interaction, and power efficiency.",
				]}
				titleLevel={1}
			/>

			<Article
				title="Autonomous Systems."
				content={[
					"Our Autonomous Systems team is at the forefront of developing sophisticated perception systems, including a groundbreaking technology we call OmniSciNet. Inspired by the human ability to absorb and process multi-modal sensory information, OmniSciNet is an AI model capable of integrating and interpreting data from multiple sensor modalities simultaneously. It uses a large language model (LLM) and data from  LiDAR, radar, computer vision, and even thermal and inertial sensors to create a holistic understanding of the environment, similar to human sensory perception.",
					"",
				]}
				images={["/media/robotics2.jpg"]}
				imageIndex={1}
			/>

			<Article
				title="Swarm Robotics."
				content={[
					"Our swarm robotics systems are designed to function effectively in large groups. We facilitate robust and rapid communication within the swarm by implementing next-generation communication protocols such as terahertz and vortex millimeter waves. This allows for the transmission of terabytes of data over several miles in less than a second. We are also developing a new class of scalable, collective decision-making algorithms that draw inspiration from biological systems to handle the intricacies of swarm dynamics.",
					"",
				]}
				images={["/media/robotics.jpg"]}
				imageIndex={1}
			/>

			<Article
				title="Human-Robot Interaction."
				content={[
					"Our Human-Robot Interaction team is devoted to creating robots that are safe, intuitive, and user-friendly. We are advancing research in affective computing, using multimodal data including vocal intonation, facial expressions, and physiological signals. Integrated with our cutting-edge natural language processing techniques and the neural data gathered using our brain-computer interface technology, PsyNet, our robots are capable of interpreting and adapting to human behavior with unparalleled impact.",
				]}
			/>

			<Article
				title="Power Efficiency."
				content={[
					"Our robots are engineered to operate efficiently. This involves the use of low-power neuromorphic hardware that approaches the energy efficiency of the human brain and advanced energy management systems.",
					"Particularly in swarm robotics, we've introduced the Dynamic Reinforcement Energy-Aware Management for Swarms (DREAMS). This system uses a combination of Machine Learning (ML) and Reinforcement Learning (RL) to dynamically manage energy distribution among the swarm robots, enhancing their operational time and energy efficiency significantly. Furthermore, we're exploring innovative energy harvesting technologies such as advanced photovoltaic cells, piezoelectric materials, and thermoelectric generators for our robots.",
				]}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default ResearchOverview;
