// VideoContext.js
import { createContext, useState, useEffect } from 'react';

export const VideoContext = createContext();

export const VideoProvider = ({ children }) => {
  const [videoTime, setVideoTime] = useState(() => {
    const localData = localStorage.getItem('videoTime');
    return localData ? JSON.parse(localData) : 0;
  });

  useEffect(() => {
    localStorage.setItem('videoTime', JSON.stringify(videoTime));
  }, [videoTime]);

  return (
    <VideoContext.Provider value={{ videoTime, setVideoTime }}>
      {children}
    </VideoContext.Provider>
  );
};
