// Facilities.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const Facilities = () => {
	const textCardsData = [
		{
			heading: "Learn more about our research.",
			caption: "[Research overview]",
			link: "/research-overview/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Facilities"
				content={[
					"Our world class facilities are the sketal structure of our distributed body of research and development. They are the physical manifestation of our mission and our home away from home, where for many of us, work is life and life is work. Here, we are all family and our blood is the blood of our mission.",
				]}
				titleLevel={1}
			/>
			<Article
				title="
                Sunnyvale Offices"
				content={[
					"Situated in Silicon Valley, our Sunnyvale offices serves as the central location for Del Complex's Unit 5 and  Unit 6 Labs.",
					"At Unit 6 Labs researchers are focused on the ethical development and deployment of AGI. They follow a dual approach: the p(Doom) Defense Unit mitigates the risks associated with unaligned AGI systems, while the Feature Space Unit refines how AGI systems encapsulate knowledge. These labs underscore our unyielding dedication to harnessing AGI for the greater good of humanity.",
					"Contact: Sunnyvale Unit 6 Labs +1 (669) 600-6898",
				]}
				images={["/media/sunnyvale.jpg"]}
				imageIndex={1}
			/>

			<Article
				title="
                Redding Research"
				content={[
					"Our Redding Research site is dedicated to pushing the boundaries of human cognition.",
					"It’s home to a team of skilled engineers and neuroscientists who are doing research and development on PsyNet, our first product aiming to seamlessly merge advanced AGI with the human mind.",

					"Contact: Redding Research +1 (530) 338-0209",
				]}
				images={["/media/redding.jpg"]}
				imageIndex={1}
			/>

			<Article
				title="Mt. Augusta Facility"
				content={[
					"The center of Del Complex operations is an extensive subterranean facility for advanced research and development located beneath Mt. Augusta in Nevada.",
					"This facility provides a secure and controlled environment for our largest and most demanding scientific research, such as our work on resilient systems and clean energy.",

					"Project Resonance and Aether are installed near the peak of Mt. Augusta, and are paving the way to fully autonomous resilient communication systems.",

					"And beneath the surface, our work on clean energy is synchronized with the current developments in theoretical and applied physics.",

					"Contact: Mt. Augusta Facility +1 (844) 917-2675",
					<>
						Visit: <a href="/visitor-pass">Schedule your visit</a>.
					</>,
				]}
				images={["/media/augusta.jpg"]}
				imageIndex={1}
			/>

			<Article
				title="Alpine Security Site"
				content={[
					"High-security site located in Alpine, Texas.",

					"Contact: Alpine Security Site +1 (432) 356-3236",
				]}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default Facilities;
