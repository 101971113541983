// Updates.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";
import GridCard from "./GridCard";

import { updatesData } from "./updatesData";

const Updates = () => {
	const textCardsData = [
		{
			heading: "Learn more about our research.",
			caption: "[Research overview]",
			link: "/research-overview/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article title="Blog" content={["Latest Updates"]} titleLevel={1} />

			<div className="grid-container">
				{updatesData.map((data, index) => (
					<GridCard
						key={index}
						heading={data.heading}
						imageSrc={data.imageSrc}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>

			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default Updates;
