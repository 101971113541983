// DeveloperConference.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const DeveloperConference = () => {
	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Annual Developer Conference"
				content={[
					"Join us for Del Complex's annual developer conference on December 14th at our Mt. Augusta Facility in Nevada.",

					"Attendees wishing to participate either remotely or in person can register in the upcoming weeks; please note that a thorough background examination and security review will be conducted beforehand.",

					"At this event, global developers can team up with Del Complex's experts, explore the latest tools, and meet other industry leaders. We'll offer workshops and breakout rooms, but spaces are limited and given to early arrivals.",

					"From the moment we introduced PsyNet, our neural prosthetic technology, we've rapidly enhanced its capabilities, creating a path for developers to fuse advanced AGIs with human cognition. Today, a select group of neuroscientists utilize PsyNet in their labs, integrating it into various applications, ranging from enhancing cognitive functionalities to crafting entirely new experiences previously thought unimaginable.",
					"Contact us at :",
					<>
						<a href="https://twitter.com/DelComplex">Del Complex on Twitter.</a>
					</>,
					"Mt. Augusta Facility (844) 917-2675",
				]}
				titleLevel={1}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default DeveloperConference;
