// PurpleSmokeViolation.js
import React, { useState } from "react";
import PurpleSmokeNavigation from "./PurpleSmokeNavigation";
import PurpleSmokeFooter from "./PurpleSmokeFooter";
import usePurpleSmokeSecurity from "./usePurpleSmokeSecurity";
const PurpleSmokeViolation = () => {
	usePurpleSmokeSecurity();
	const [submitted, setSubmitted] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		// Here, you might want to handle the form submission, e.g., sending the report to an API
		setSubmitted(true);
	};

	return (
		<div className="purpleSmoke">
			<PurpleSmokeNavigation />
			<div className="report-container">
				{!submitted ? (
					<form onSubmit={handleSubmit} className="violationForm">
						<h2>Report Violation</h2>
						<textarea
							required
							placeholder="Describe the violation..."
							className="violationTextarea"
						></textarea>
						<button type="submit" className="submit-button">
							Submit
						</button>
					</form>
				) : (
					<div className="success-message">
						<h2>Submission Received</h2>
						<p>
							Your report has been logged into our system. It is currently
							pending review.
						</p>
					</div>
				)}
			</div>
			<PurpleSmokeFooter />
		</div>
	);
};

export default PurpleSmokeViolation;
