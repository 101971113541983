import React, { useEffect, useState, useCallback } from "react";
import {
	useWaitForTransactionReceipt,
	useWriteContract,
	useAccount,
	useChainId,
	useSwitchChain,
} from "wagmi";
import { parseEther, encodeFunctionData } from "viem";
import { mainnet, base } from "wagmi/chains";
import ManifoldABIJson from "../ABI/ManifoldABI.json";
const ManifoldABI = ManifoldABIJson.abi;

const mintContractAddress = "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";
const claimContractAddress = "0xDb8d79C775452a3929b86ac5DEaB3e9d38e1c006";
const creatorContractAddress = "0x882Dd757E02a3420F003Dc2550FEBf58374797e5";

export function MintNFT({
	isMint = false,
	claimId,
	requiredChainId,
	mintButtonText = "Mint 0.01E",
	claimButtonText = "Claim Now - Free",
	priceOverride,
}: {
	claimId: string | number;
	requiredChainId?: number;
	isMint?: boolean;
	mintButtonText?: string;
	claimButtonText?: string;
	priceOverride?: string;
}) {
	const { data: hash, error, isPending, writeContract } = useWriteContract();
	const { address } = useAccount();
	const chainId = useChainId();
	const { switchChain } = useSwitchChain();

	const targetChainId = requiredChainId || mainnet.id;

	const [isSwitching, setIsSwitching] = useState(false);

	const checkNetwork = useCallback(async () => {
		const provider = (window as any).ethereum;
		if (provider && provider.request) {
			try {
				const currentChainId = await provider.request({
					method: "eth_chainId",
				});
				console.log("Current chainId:", currentChainId);
				return parseInt(currentChainId, 16) === targetChainId;
			} catch (error) {
				console.log("Error checking network:", error);
			}
		}
		return chainId === targetChainId;
	}, [targetChainId, chainId]);

	const handleSwitchNetwork = async () => {
		setIsSwitching(true);
		try {
			await switchChain({ chainId: targetChainId });
		} catch (error: any) {
			console.error("Failed to switch network:", error);
			if (
				error.code === 4001 ||
				(error.message && error.message.includes("User rejected"))
			) {
				console.log("User cancelled the network switch");
			}
		} finally {
			setIsSwitching(false);
		}
	};

	const handleTransaction = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const provider = (window as any).ethereum;
		console.log("MetaMask Provider Check:", {
			exists: !!provider,
			selectedAddress: provider?.selectedAddress,
			isConnected: provider?.isConnected?.(),
			chainId: provider?.chainId,
			hasPermissions: !!provider?._metamask?.isUnlocked,
		});

		if (!address) {
			console.error("No wallet connected");
			return;
		}

		// Verify wallet permissions
		try {
			const accounts = await provider?.request({
				method: "eth_accounts",
			});
			console.log("Connected accounts:", accounts);

			if (!accounts || accounts.length === 0) {
				console.error("No accounts available. Requesting permissions...");
				await provider?.request({
					method: "eth_requestAccounts",
				});
			}
		} catch (error) {
			console.error("Error checking/requesting account permissions:", error);
			return;
		}

		const isCorrect = await checkNetwork();
		if (!isCorrect) {
			await handleSwitchNetwork();
			return;
		}

		console.log("Starting transaction...");
		try {
			const value = parseEther(
				priceOverride ? priceOverride : isMint ? "0.0105" : "0.0005"
			);

			// Try direct MetaMask transaction request
			const transactionParameters = {
				to: isMint ? mintContractAddress : claimContractAddress,
				from: address,
				value: `0x${value.toString(16)}`,
				data: encodeFunctionData({
					abi: ManifoldABI,
					functionName: "mint",
					args: [creatorContractAddress, BigInt(claimId), 0, [], address],
				}),
			};

			console.log("Sending transaction parameters:", transactionParameters);

			let txHash;
			try {
				txHash = await provider.request({
					method: "eth_sendTransaction",
					params: [transactionParameters],
				});
				console.log("Transaction sent:", txHash);
			} catch (err: any) {
				console.error("Direct MetaMask transaction failed:", {
					error: err,
					code: err.code,
					message: err.message,
				});

				// Check for user rejection (MetaMask error code 4001)
				if (
					err.code === 4001 ||
					(err.message && err.message.includes("User rejected"))
				) {
					console.log("User cancelled the transaction");
					return; // Exit early, don't try fallback
				}
			}

			// Fallback to wagmi if direct method fails (but not if user rejected)
			if (!txHash) {
				console.log("Falling back to wagmi transaction...");
				try {
					const result = await writeContract({
						address: isMint ? mintContractAddress : claimContractAddress,
						abi: ManifoldABI,
						functionName: "mint",
						args: [creatorContractAddress, BigInt(claimId), 0, [], address],
						value,
					});
					console.log("Wagmi transaction result:", result);
				} catch (wagmiError: any) {
					// Check for user rejection in wagmi error
					if (
						wagmiError.code === 4001 ||
						(wagmiError.message && wagmiError.message.includes("User rejected"))
					) {
						console.log("User cancelled the wagmi transaction");
						return;
					}
					throw wagmiError; // Re-throw other errors
				}
			}
		} catch (error: any) {
			console.error("Transaction failed with error:", {
				message: error.message,
				code: error.code,
				details: error.details,
				data: error.data,
				shortMessage: error.shortMessage,
			});
		}
	};

	const { isLoading: isConfirming, isSuccess: isConfirmed } =
		useWaitForTransactionReceipt({
			hash,
		});

	const getButtonText = () => {
		if (isPending || isConfirming) return "Confirming...";
		if (isSwitching) return "Switching...";
		return isMint ? mintButtonText : claimButtonText;
	};

	return (
		<form onSubmit={handleTransaction}>
			<button
				disabled={isPending || isConfirming || !address || isSwitching}
				type="submit"
				style={{
					borderRadius: "0.5rem",
				}}
			>
				{getButtonText()}
			</button>
		</form>
	);
}

export default MintNFT;
