// CareersTransparencyReport.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const CareersTransparencyReport = () => {
	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Careers Transparency Report"
				content={[
					<>
						The{" "}
						<a href="media/archival_media/Del_Complex_Careers_Transparency_Report_September_2023.pdf">
							Del Complex 2023 Careers Transparency Report
						</a>{" "}
						provides a detailed insight into our hiring process and emphasizes
						our commitment to cultural alignment, fairness, equity, and the
						advancement of diversity throughout the recruitment process.
					</>,

					"Additionally, we present data offering an aggregated view of the 426 responses we received for the position of 'Researcher: Artificial General Intelligence and Brain-Computer Interfaces'. These charts are grounded in our pre-interview screening questions, which aim to measure the ethical, moral, and practical considerations that researchers must face when navigating this emerging field.",

					"This feedback captures the mindset of those pursuing AI research. A significant proportion are prepared to explore the profound, ethically challenging dimensions of research. Their willingness to work in confidential settings, undertake projects with potential undesirable outcomes, and be ready for public scrutiny signifies a deep-seated commitment. This dedication goes beyond mere technological progress; it reflects a nuanced understanding that as benefits grow, risks and challenges can also intensify.",

					"The report is available either for download, or as a digital archival record below.",
					<>
						<a href="media/archival_media/Del_Complex_Careers_Transparency_Report_September_2023.pdf">
							Download the Full Report.
						</a>
					</>,
					<>
						<a href="/archival-media/">Request an Archival Record.</a>
					</>,
				]}
				titleLevel={1}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default CareersTransparencyReport;
