import React, { useState, useEffect } from "react";
import Article from "./Article";
import captchaImages from "./captchas";
import SmartImg from "./SmartImg";
import WalletConnect from "./WalletConnect";
import ClaimToken from "./ClaimToken";
import SmartURL from "./SmartURL";

const JrEmployeeOnboarding = () => {
	const [currentStep, setCurrentStep] = useState("intro");

	const [showIntro, setShowIntro] = useState(true);
	const [showCommitment, setShowCommitment] = useState(false);
	const [showCaptcha, setShowCaptcha] = useState(false);
	const [showPhoneNumber, setShowPhoneNumber] = useState(false);

	const [captchaImage, setCaptchaImage] = useState("");
	const [userInput, setUserInput] = useState("");
	const [userInputAccessCode, setUserInputAccessCode] = useState("");

	const [showDeptClaim1, setShowDeptClaim1] = useState(false);
	const [showDeptClaim2, setShowDeptClaim2] = useState(false);
	const [showDeptClaim3, setShowDeptClaim3] = useState(false);
	const [deptName, setDeptName] = useState("");

	const [commitmentInput, setCommitmentInput] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [showError, setShowError] = useState(false);

	const getRandomCaptcha = () => {
		const randomIndex = Math.floor(Math.random() * captchaImages.length);
		return captchaImages[randomIndex];
	};

	useEffect(() => {
		const savedStep = localStorage.getItem("onboardingStep");
		const savedDeptName = localStorage.getItem("deptName");

		if (savedStep) {
			switch (savedStep) {
				case "commitment":
					setShowIntro(false);
					setShowCommitment(true);
					break;
				case "captcha":
					setShowIntro(false);
					setShowCommitment(false);
					setShowCaptcha(true);
					break;
				case "phoneNumber":
					setShowIntro(false);
					setShowCommitment(false);
					setShowCaptcha(false);
					setShowPhoneNumber(true);
					break;
				case "deptClaim1":
					setShowIntro(false);
					setShowCommitment(false);
					setShowCaptcha(false);
					setShowPhoneNumber(false);
					setShowDeptClaim1(true);
					break;
				case "deptClaim2":
					setShowIntro(false);
					setShowCommitment(false);
					setShowCaptcha(false);
					setShowPhoneNumber(false);
					setShowDeptClaim2(true);
					break;
				case "deptClaim3":
					setShowIntro(false);
					setShowCommitment(false);
					setShowCaptcha(false);
					setShowPhoneNumber(false);
					setShowDeptClaim3(true);
					break;
				default:
					break;
			}
			setCurrentStep(savedStep);
		}
		if (savedDeptName) {
			setDeptName(savedDeptName);
		}
	}, []);

	const startOver = () => {
		setShowIntro(true);
		setShowCommitment(false);
		setShowCaptcha(false);
		setShowPhoneNumber(false);
		setShowDeptClaim1(false);
		setShowDeptClaim2(false);
		setShowDeptClaim3(false);

		setCaptchaImage(getRandomCaptcha());
		setUserInput("");
		setUserInputAccessCode("");
		setCommitmentInput("");
		setErrorMessage("");
		setShowError(false);
		setDeptName("");

		setCurrentStep("intro");
		localStorage.removeItem("onboardingStep");
		localStorage.removeItem("deptName"); // Add this line
	};

	useEffect(() => {
		setCaptchaImage(getRandomCaptcha());
	}, []);

	const handleIntroSubmit = () => {
		setShowIntro(false);
		setShowCaptcha(true);
		setCurrentStep("captcha");
		localStorage.setItem("onboardingStep", "captcha");
		window.scrollTo(0, 0);
	};

	const handleCommitmentSubmit = () => {
		if (commitmentInput.toLowerCase() === "yes") {
			setShowCommitment(false);
			setShowPhoneNumber(true);
			setCurrentStep("phoneNumber");
			localStorage.setItem("onboardingStep", "phoneNumber");
			window.scrollTo(0, 0);
		} else {
			setErrorMessage("You must type 'yes' to continue.");
			setShowError(true);
			setCommitmentInput("");
		}
	};

	const handleAccessCodeChange = (e) => {
		setUserInputAccessCode(e.target.value);
	};

	const deptWelcomeMessages = [
		"Welcome to Del Complex. We are excited to have you onboard.",
		"As a junior employee, you will be granted access to our facilities, resources, and intranet.",
		"Please claim the department token below to finalize your onboarding process.",
		<span>
			Additionally, please download the{" "}
			<a
				href={SmartURL("/media/documents/Del_Complex_New_Hire_Orientation.pdf")}
				target="_blank"
				rel="noopener noreferrer"
			>
				New Hire Onboarding and First Day Checklist
			</a>{" "}
			for successful integration into our organization.
		</span>,
	];

	const deptDisclaimerMessage = [
		"Please note, as a junior employee you are subject to an indefinite probationary period. Your performance will be closely monitored and evaluated. During this time you are ineligible for compensation, benefits, or paid time off. Your status as a junior employee may be revoked at any time and does not guarantee future employment.",
		"Remember, Del Complex is more than a job, it's who you are.",
	];
	const handleSubmitAccessCode = () => {
		setShowDeptClaim1(false);
		setShowDeptClaim2(false);
		setShowDeptClaim3(false);
		if (userInputAccessCode === "11579") {
			setCurrentStep("deptClaim1");
			localStorage.setItem("onboardingStep", "deptClaim1");
			localStorage.setItem("deptName", "Mechanical Engineering");

			setShowDeptClaim1(true);
			setShowPhoneNumber(false);
			setDeptName("Mechanical Engineering");
			window.scrollTo(0, 0);
		} else if (userInputAccessCode === "64221") {
			setCurrentStep("deptClaim2");
			localStorage.setItem("onboardingStep", "deptClaim2");
			localStorage.setItem("deptName", "Electrical Engineering");

			setShowDeptClaim2(true);
			setShowPhoneNumber(false);
			setDeptName("Electrical Engineering");
			window.scrollTo(0, 0);
		} else if (userInputAccessCode === "32114") {
			setCurrentStep("deptClaim3");
			localStorage.setItem("onboardingStep", "deptClaim3");
			localStorage.setItem("deptName", "Software Engineering");

			setShowDeptClaim3(true);
			setShowPhoneNumber(false);
			setDeptName("Software Engineering");
			window.scrollTo(0, 0);
		} else {
			setErrorMessage("Invalid access code. Call our hotline for assistance.");
			setShowError(true);
			setUserInputAccessCode("");
			setDeptName("");
		}
	};

	const handleInputChange = (e) => {
		setUserInput(e.target.value.toLowerCase());
	};

	const handleRefreshCaptcha = () => {
		setUserInput("");
		setCaptchaImage(getRandomCaptcha());
	};

	const handleSubmitCaptcha = () => {
		const captchaSolution = captchaImage
			.split("/")
			.pop()
			.split(".")[0]
			.toLowerCase()
			.replace(/_/g, " ");

		if (userInput === captchaSolution) {
			setShowCaptcha(false);
			setShowCommitment(true);
			setCurrentStep("commitment"); // Added this line
			localStorage.setItem("onboardingStep", "commitment"); // Added this line
			window.scrollTo(0, 0);
		} else {
			setErrorMessage("Incorrect solution.");
			setShowError(true);
			setUserInput("");
			setCaptchaImage(getRandomCaptcha());
		}
	};

	useEffect(() => {
		if (showError) {
			const timer = setTimeout(() => {
				setShowError(false);
			}, 1000);

			return () => clearTimeout(timer);
		}
	}, [showError]);

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<div className="onboarding">
				{showIntro ? (
					<>
						<Article
							title="Join us."
							content={[
								"Our automated hiring system, a reflection of our commitment to efficiency and innovation, is designed to facilitate a seamless onboarding experience, preparing you for an exciting journey ahead.",
								"We invite you to begin your onboarding process and look forward to the valuable contributions we believe you’ll make at Del Complex.",
							]}
							titleLevel={1}
						/>
						<button onClick={handleIntroSubmit}>
							Begin Onboarding Process
						</button>
					</>
				) : showCommitment ? (
					<>
						<Article
							title="Your commitment."
							content={[
								"Your integration into our organization is conditioned upon your acceptance of the following commitments:",

								"I commit myself to the corporate culture. My team is my family. This is more than a job, it's who I am.",

								"I commit myself to silence. I will not speak a word to the public, or to any employee who is not disclosed.",

								"I commit myself to efficiency. I will track my time and follow the Jira sprint schedule. The SCRUM Master is my master.",

								"I commit myself to vigilance. I will report suspicious activity and watch my team members for signs of disloyalty.",

								"By accepting these commitments, I affirm my allegiance to Del Complex, its standards, protocols, and objectives.",
								" ",
								"Do you commit yourself?",
							]}
							titleLevel={1}
						/>
						<input
							type="text"
							onChange={(e) => setCommitmentInput(e.target.value)}
							value={commitmentInput}
						/>
						<button onClick={handleCommitmentSubmit}>I Commit</button>
						{showError ? (
							<div className="error-message fade-in">{errorMessage}</div>
						) : (
							<div className="error-message fade-out">{errorMessage}</div>
						)}
					</>
				) : showCaptcha ? (
					<>
						<Article
							title="Proof of cognition."
							content={[
								"Solve the captcha below to verify you are a cognitive entity.",
							]}
							titleLevel={1}
						/>

						<div className="captchaContainer">
							<SmartImg
								className="captchaImg"
								src={captchaImage}
								alt="CAPTCHA"
							/>
							<input
								type="text"
								onChange={handleInputChange}
								value={userInput}
							/>
							<button onClick={handleRefreshCaptcha}>Refresh</button>
							<button onClick={handleSubmitCaptcha}>Submit</button>
							{showError ? (
								<div className="error-message fade-in">{errorMessage}</div>
							) : (
								<div className="error-message fade-out">{errorMessage}</div>
							)}
						</div>
					</>
				) : showPhoneNumber ? (
					<>
						<Article
							title="Department placement."
							content={[
								"To ensure you are paired with the appropriate department, please call our psychological and team culture assessment hotline at:",
								"+1 (669) 600-0217.",
								"Afterward, you will be provided with a unique access code to finalize your onboarding process.",
								<br />,
								<>
									If you are unable to call, you may also register as a{" "}
									<a href="/register-contractor">contractor</a>, or a{" "}
									<a href="/visitor-pass">visitor</a>.
								</>,
							]}
							titleLevel={1}
						/>
						<input
							type="text"
							onChange={handleAccessCodeChange}
							value={userInputAccessCode}
						/>
						<button onClick={handleSubmitAccessCode}>Submit Access Code</button>
						{showError ? (
							<div className="error-message fade-in">{errorMessage}</div>
						) : (
							<div className="error-message fade-out">{errorMessage}</div>
						)}
					</>
				) : showDeptClaim1 || showDeptClaim2 || showDeptClaim3 ? (
					<>
						<Article
							title={deptName}
							content={deptWelcomeMessages}
							titleLevel={1}
						/>
						<WalletConnect />
						{/* <div className="spacerShort"></div> */}
						{showDeptClaim1 ? (
							<>
								<div className="token-container-single">
									<ClaimToken
										claimId="82710768"
										imageSrc="/media/archival_media/MechanicalEngineeringBackpack.jpg"
										fileSrc="/media/archival_media/MechanicalEngineeringBackpack.jpg"
										alt="Mechanical Engineering"
									/>
								</div>
							</>
						) : showDeptClaim2 ? (
							<>
								{" "}
								<div className="token-container-single">
									<ClaimToken
										claimId="82716912"
										imageSrc="/media/archival_media/ID_Badge_Universal_Schematic.jpg"
										fileSrc="/media/archival_media/ID_Badge_Universal_Schematic.jpg"
										alt="Electrical Engineering"
									/>
								</div>
							</>
						) : showDeptClaim3 ? (
							<>
								{" "}
								<div className="token-container-single">
									<ClaimToken
										claimId="82714864"
										imageSrc="/media/archival_media/WelcomeLetter.jpg"
										fileSrc="/media/archival_media/WelcomeLetter.jpg"
										alt="Software Engineering"
									/>
								</div>
							</>
						) : (
							<></>
						)}

						<Article
							title=""
							content={[
								<>
									You may now access our corporate{" "}
									<a href="/intranet">intranet</a> and other resources. Please
									be advised, these resources are for internal use only.
									Unauthorized use is strictly prohibited.
								</>,
							]}
							titleLevel={1}
						/>

						<Article title="" content={deptDisclaimerMessage} titleLevel={1} />
					</>
				) : (
					<></>
				)}
				{currentStep !== "intro" && (
					<button onClick={startOver}>Start Over</button>
				)}

				<div className="spacerShort"></div>
			</div>
		</div>
	);
};

export default JrEmployeeOnboarding;
