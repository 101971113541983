// BrainWorms.js
import React, { useEffect, useRef, useState } from "react";
import Article from "./Article";
import TextCard from "./TextCard";
import GridCard from "./GridCard";
import SmartURL from "./SmartURL";

const BrainWorms = () => {
	const [totalSupply, setTotalSupply] = useState(null);
	const [circSupply, setcircSupply] = useState(null);

	useEffect(() => {
		const fetchSupplyData = async () => {
			try {
				const totalSupplyResponse = await fetch(
					"https://api.delcomplex.com/totalsupply"
				);
				let totalSupplyData = await totalSupplyResponse.json();
				// convert to number and remove the decimals
				totalSupplyData = Math.floor(Number(totalSupplyData));
				setTotalSupply(totalSupplyData);

				const circSupplyResponse = await fetch(
					"https://api.delcomplex.com/circulatingsupply"
				);
				let circSupplyData = await circSupplyResponse.json();
				// convert to number and remove the decimals
				circSupplyData = Math.floor(Number(circSupplyData));
				setcircSupply(circSupplyData);
			} catch (error) {
				console.error("Error fetching supply data:", error);
			}
		};

		fetchSupplyData();
	}, []);

	// Ref for the iframe container
	const iframeContainerRef = useRef(null);

	useEffect(() => {
		const setIframeHeight = () => {
			if (iframeContainerRef.current) {
				const width = iframeContainerRef.current.offsetWidth;
				const height = width * 1.25;
				iframeContainerRef.current.style.height = `${height}px`;
			}
		};

		// Set initial height
		setIframeHeight();

		// Adjust height on window resize
		window.addEventListener("resize", setIframeHeight);

		// Cleanup listener on component unmount
		return () => window.removeEventListener("resize", setIframeHeight);
	}, []);

	const links = [
		{
			name: "Uniswap",
			url: "https://app.uniswap.org/swap?chain=base&outputCurrency=0x506BEb7965FC7053059006c7ab4C62c02C2D989F",
		},
		{
			name: "Solana - DexScreener",
			url: "https://dexscreener.com/base/0x506BEb7965FC7053059006c7ab4C62c02C2D989F",
		},
		{
			name: "Base - DexScreener",
			url: "https://dexscreener.com/solana/9dkqqmjttzdqnyyjmb3w8zzfpwjxdrmcu64onxs8zk1e",
		},
		{
			name: "NFTs on OpenSea",
			url: "https://opensea.io/assets/base/0x506BEb7965FC7053059006c7ab4C62c02C2D989F",
		},
		{ name: "X/Twitter", url: "https://x.com/delcomplex" },
		{ name: "Instagram", url: "https://www.instagram.com/delcomplex/" },
		{ name: "YouTube", url: "https://www.youtube.com/@DelComplex" },
		{ name: "Discord", url: "https://discord.gg/YbxdyrnfRD" },
		{ name: "Telegram", url: "https://t.me/delcomplex" },
		{
			name: "BaseScan",
			url: "https://basescan.org/address/0x506BEb7965FC7053059006c7ab4C62c02C2D989F",
		},
		{
			name: "Warpcast",
			url: "https://warpcast.com/delcomplex",
		},
		{
			name: "DexTools",
			url: "https://www.dextools.io/app/en/base/pair-explorer/0x506BEb7965FC7053059006c7ab4C62c02C2D989F",
		},
	];

	const textCardsData = [
		{
			heading: "Learn more about us.",
			caption: "[About us]",
			link: "/about/",
		},
		{
			heading: "Discord",
			caption: "Join our community",
			link: "https://discord.gg/YbxdyrnfRD",
		},
		{
			heading: "Twitter/X",
			caption: "Connect with us",
			link: "https://twitter.com/DelComplex",
		},
	];

	const wormCardData1 = [
		{
			imageSrc: "/media/brainworms/brainworm_2.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/brainworm_2.jpg"),
		},
	];

	const wormCardData2 = [
		{
			imageSrc: "/media/brainworms/wormsinmybrain.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/wormsinmybrain.jpg"),
		},
	];

	const wormCardData3 = [
		{
			imageSrc: "/media/brainworms/brainworm_2.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/brainworm_2.jpg"),
		},
		{
			imageSrc: "/media/brainworms/wormsinmybrain.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/wormsinmybrain.jpg"),
		},
		{
			imageSrc: "/media/brainworms/brainworm_3.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/brainworm_3.jpg"),
		},

		{
			imageSrc: "/media/brainworms/brainworm_1.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/brainworm_1.jpg"),
		},
		{
			imageSrc: "/media/brainworms/doyouhavebrainworms.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/doyouhavebrainworms.jpg"),
		},
		{
			imageSrc: "/media/brainworms/brainworm_4.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/brainworm_4.jpg"),
		},
		{
			imageSrc: "/media/brainworms/brainworm_5.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/brainworm_5.jpg"),
		},
		{
			imageSrc: "/media/brainworms/proudofbrainworms.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/proudofbrainworms.jpg"),
		},
		{
			imageSrc: "/media/brainworms/brainworm_6.jpg",
			heading: "",
			caption: "",
			link: SmartURL("/media/brainworms/brainworm_6.jpg"),
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Brain Worms"
				content={[
					<>
						Del Complex is proud to present, Brain Worms, a brain parasite
						system on the Base network, with Player-vs-Player combat, resource
						harvesting, and more.
						<br />
						<br />
						Now also available on Solana via Wormhole bridge.
						<br />
						<br />
						<>
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/A7oFfu3gLI8?si=bBghHAQ5BC6a1fU4"
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowfullscreen
								style={{
									display: "flex",
									justifyContent: "center",
									width: "100%",
								}}
							></iframe>
						</>
						<br />
						<>
							<button
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "3em",
									width: "15em",
									// border: "1px solid white",
									// borderRadius: "5px",
									// cursor: "pointer",
									marginLeft: "auto",
									marginRight: "auto",
								}}
								onClick={() => window.open("login-brainworms-simulator")}
							>
								Play Now
							</button>
						</>
						<br />
					</>,

					<>
						<table style={{ width: "100%", borderCollapse: "collapse" }}>
							<tbody>
								{links
									.reduce((rows, link, index) => {
										if (index % 3 === 0) rows.push([]);
										rows[rows.length - 1].push(link);
										return rows;
									}, [])
									.map((row, rowIndex) => (
										<tr key={rowIndex}>
											{row.map((link, cellIndex) => (
												<td
													key={cellIndex}
													style={{
														width: "33.33%",
														padding: "10px",
														border: "1px solid #ddd",
														textAlign: "center",
													}}
												>
													<a
														href={link.url}
														target="_blank"
														rel="noopener noreferrer"
													>
														{link.name}
													</a>
												</td>
											))}
											{row.length < 3 &&
												[...Array(3 - row.length)].map((_, i) => (
													<td
														key={`empty-${i}`}
														style={{
															width: "33.33%",
															padding: "10px",
															border: "1px solid #ddd",
														}}
													></td>
												))}
										</tr>
									))}
							</tbody>
						</table>
					</>,

					<>
						<br />
						<h4>Bridge:</h4>
						Between Base and Solana using{" "}
						<a href=" https://portalbridge.com/advanced-tools/#/transfer">
							Portal Bridge
						</a>
						<br />
					</>,
					<>
						<br />
						<h4>Supply:</h4>
						{totalSupply !== null && circSupply !== null ? (
							<p>
								Circulating supply: {circSupply}
								<br />
								Total supply: {totalSupply}
								<br />
								<br />
								The supply will slowly reach the max as early depositors finish
								claiming their tokens.
							</p>
						) : (
							<p>
								Circulating supply: roughly 2948
								<br />
								Total supply: 3289
								<br />
								<br />
								<br />
								The supply will slowly reach the max as early depositors finish
								claiming their tokens.
							</p>
						)}
						<br />
					</>,
					<>
						<br />
						<h4>Contracts:</h4>
						Base CA: 0x506BEb7965FC7053059006c7ab4C62c02C2D989F
						<br />
						<br />
						Solana CA: 5fvuVSTycsgqaB4s1weFxuLxBy29sXEGqD38JUxaKtvk
						<br />
					</>,
					<>
						{" "}
						<br />
						<h4>More:</h4>
						See our{" "}
						<a href="https://x.com/DelComplex/status/1811134190295077091">
							announcement on X/Twitter
						</a>{" "}
						for further opportunities to connect.
						<br />
					</>,

					// <>
					// 	<div id="responsive-iframe" ref={iframeContainerRef}>
					// 		<iframe
					// 			width="560"
					// 			height="800"
					// 			src="data:text/html;charset=utf-8,
					// 			<!DOCTYPE html>
					// 			<html lang='en'>
					// 			<head>
					// 			<meta charset='UTF-8'>
					// 			<meta name='viewport' content='width=device-width, initial-scale=1.0'>
					// 			<title>Twitter Embed</title>
					// 			</head>
					// 			<body>
					// 			<blockquote class='twitter-tweet'>
					// 			<p lang='en' dir='ltr'>
					// 			Brain Worms is live on <a href='https://twitter.com/base?ref_src=twsrc%5Etfw'>@base</a> !
					// 			<br><br>0x506BEb7965FC7053059006c7ab4C62c02C2D989F
					// 			<br><br>A fully onchain generative brain parasite system, with PvP, resource harvesting, and more! See the link below.
					// 			<br><br>For additional token distribution opportunities, call
					// 			<br>+1 (775) 365-WORM
					// 			<br><br>Built on ERC-404… <a href='https://t.co/YZSahREjnU'>pic.twitter.com/YZSahREjnU</a>
					// 			</p>
					// 			— Del Complex (@DelComplex) <a href='https://twitter.com/DelComplex/status/1811134190295077091?ref_src=twsrc%5Etfw'>July 10, 2024</a>
					// 			</blockquote>
					// 			<script async src='https://platform.twitter.com/widgets.js' charset='utf-8'></script>
					// 			</body>
					// 			</html>"
					// 			frameborder="0"
					// 			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					// 			allowfullscreen
					// 		></iframe>
					// 	</div>
					// </>,
				]}
				titleLevel={1}
			/>

			<Article
				title="Features"
				content={[
					<>
						<b>Stats and Resources</b>
					</>,
					"- Each Brain Worm has it's own strengths and weaknesses, and can be adapted by harvesting resources from various areas of the synthetic brain they live in.",
					<>
						<b>Combat System</b>
					</>,
					"- Because resources in the brain are limited, Brain Worms can be pitted against each other in PVP battles to determine the strongest. PVP titles are updated via an Elo rating system, with bonuses for the top worms.",
					<>
						<b>Evolution</b>
					</>,
					"- Powered by ERC-404, Brain Worms can be reborn with new traits and abilities as they are broken apart and recombined.",
					<>
						<b>3D Organisms</b>
					</>,
					"- Each Brain Worm is a unique, fully 3D synthetic organism generated onchain. They can be viewed in the browser, or in augmented reality on an iPhone or Vision Pro.",
				]}
				titleLevel={2}
			/>

			<div className="grid-container-wider">
				{wormCardData3.map((data, index) => (
					<GridCard
						key={index}
						heading={data.heading}
						imageSrc={data.imageSrc}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>

			<Article
				title="Research"
				content={[
					"Our long term goal is to genetically engineer parasitic worms to secrete cognitive enhancing biomolecules. Thus increasing the intelligence of the host human. With Brain Worms, we will accelerate the human species in order to compete with AGI.",
					"The Brain Worm implantation process begins at the nose. We inject them via intranasal delivery to bypass the blood-brain barrier and enable direct access to the brain. Once inside, they compete for limited resources found in the interstitial fluid of the brain such as glucose, amino acids, and micronutrients. Thus, the Brain Worm Simulation is a critical step in understanding the behavior of the worms in a hostile environment.",
					"As users interact with the Brain Worm Simulation, engage with PVP combat, and gather resources, our scientists will learn from the data and improve our biological brainworms.",
				]}
				titleLevel={2}
			/>

			<Article
				title="Frequently Asked Questions"
				content={[
					<>
						<b>What is Del Complex?</b>
					</>,
					"- Del Complex is an alternate reality corporation.",
					<>
						<br />
					</>,
					<>
						<b>
							I'm ready to put a real Brain Worm in my brain. How do I do that?
						</b>
					</>,
					<>
						-{" "}
						<a href="humansubject">
							Applications to become a human host are now open.
						</a>
					</>,
					"",
				]}
				titleLevel={2}
				imageIndex={8}
				images={["/media/brainworm_wide.jpg"]}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default BrainWorms;
