import React from "react";
import Article from "./Article";

const PortalHelp = () => {
	return (
		<div>
			<Article title="Need help?" titleLevel={1} />

			<div className="article help">
				<p>
					If you have an urgent matter that requires immediate attention, please
					contact your direct manager. They are your primary point of contact
					for pressing issues and can provide guidance on how to proceed.
				</p>

				<p>
					Important Notice: As of the current policy update, all requests for
					paid time off (PTO) are suspended until further notice. All employees
					will be issued a one time bonus of $500 to compensate for the
					inconvenience.
				</p>
			</div>
		</div>
	);
};

export default PortalHelp;
