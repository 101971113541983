import React from "react";
import Article from "./Article";
import { useEffect, useState } from "react";
import WalletConnect from "./WalletConnect";
import MintToken from "./MintToken";
import { useLocation } from "react-router-dom";
import MetaTags from "./MetaTags";
import useStaffSecurity from "./useStaffSecurity";

const XioSkyETR = () => {
	useStaffSecurity();
	const location = useLocation();

	return (
		<div className="article-container">
			<MetaTags
				titleSuffix="Authorized Access Only"
				description="Authorized Access Only"
				path={location.pathname}
			/>
			<div className="spacerShort"></div>
			<Article
				title=""
				content={[
					"Del Complex Staff - INTERNAL ARCHIVE ENDPOINT",
					"Please be reminded that access to this repository is restricted to authorized personnel only. If you are viewing this notification in error, or without explicit authorization, report to your supervisor immediately.",
				]}
				titleLevel={1}
			/>
			<WalletConnect />
			<div className="spacerShort"></div>

			<h2 className="archiveQuarter"> Q4 2024 Internal Archive: </h2>

			<div className="token-container-single">
				<MintToken
					claimId="3790141680"
					priceOverride="0.0005"
					mintButtonText="Claim Staff Mission Patch"
					imageSrc="/media/archival_media/MissionPatch_SwordGift.jpg"
					fileSrc="/media/archival_media/MissionPatch_SwordGift.jpg"
					alt="Del Complex Staff Patch"
				/>
			</div>
			<div className="spacerShort"></div>
		</div>
	);
};

export default XioSkyETR;
