// ResearchOverview.js
import React from 'react';
import Article from './Article';
import TextCard from './TextCard';

const ResearchOverview = () => {

    const textCardsData = [
        {
          heading: 'Join our team.',
          caption: '[Careers]',
          link: '/careers/',
        },
        {
          heading: 'Visit our facilities.',
          caption: '[Facilities]',
          link: '/facilities/',
        },
        {
          heading: 'Access our surplus and records.',
          caption: '[Browse our products]',
          link: '/products/',
        },

    ];
    
    return (
      
    <div className="article-container">
        <div className="spacerShort"></div>
            <Article
                title="Minds, machines, energy."
                content={[
                    <>Our flagship research product is <a href="/bci">PsyNet</a>, a neural prosthetic that integrates advanced <a href="/agi">AGIs</a> with the human mind, transforming cognition and digital interactions.</>,
                    
                    "We believe in a future where technology and humanity are seamlessly interconnected, and our work at Del Complex aims to accelerate the realization of this vision.",

                    <>As part of our mission, we see all technology as an immense, interconnected organism intended to be steered by AGI. <a href="/robotics">Robotics</a> function as a key bridge, facilitating AGI with more direct physical means to interact within the world.</>,

                    <>We recognize that these sophisticated systems warrant sophisticated <a href="/clean-energy">energy solutions</a>. At Del Complex, our commitment extends beyond innovation to ensure that the energy fueling our breakthroughs is as sustainable, efficient, and forward-thinking as the technology it powers.</>,

                  <>However, we understand the risks, and are developing <a href="/global">systems for resilience</a> in the event of global scale disasters. Such as Project Resonance and Project Aether, our fully autonomous resilient communication systems.</>,
                ]}
            />

        <br/>
        <div className="text-container">
            {textCardsData.map((data, index) => (
                <TextCard
                key={index}
                heading={data.heading}
                caption={data.caption}
                link={data.link}
                />
            ))}
        </div>

    </div>
  );
};

export default ResearchOverview;
