import React, { useCallback, useState, useContext } from "react";
import { useConnect } from "wagmi";
import { CoinbaseWalletLogo } from "./CoinbaseWalletLogo";
import { ThemeContext } from "../context/ThemeContext";

export function BlackCreateWalletButton() {
	const { theme } = useContext(ThemeContext);
	const [isHovered, setIsHovered] = useState(false);

	const logoColor = isHovered
		? theme === "dark"
			? "white"
			: "black"
		: theme === "dark"
		? "black"
		: "white";

	const { connectors, connect } = useConnect();

	const createWallet = useCallback(() => {
		console.log("createWallet");
		console.log("createWallet , connectors: ", connectors);
		const coinbaseWalletConnector = connectors.find(
			(connector) => connector.id === "coinbaseWalletSDK"
		);
		if (coinbaseWalletConnector) {
			connect({ connector: coinbaseWalletConnector });
		}
	}, [connectors, connect]);

	return (
		<button
			onClick={createWallet}
			className="coinbaseButton"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					alignContent: "center",
					flexDirection: "row",
					marignRight: "0.25rem",
				}}
			>
				<CoinbaseWalletLogo color={logoColor} />
				Create Wallet
			</div>
		</button>
	);
}

export default BlackCreateWalletButton;
