// RegistrationForm.js
import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import WalletConnect from "./WalletConnect";
import ClaimToken from "./ClaimToken";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const RegistrationFormContractor = () => {
	const { theme, setTheme } = useContext(ThemeContext);
	const isDark = theme === "dark";

	const logoSrc = isDark
		? "https://xiosky.com/delmedia/media/logoblack.png"
		: "https://xiosky.com/delmedia/media/logowhite.png";

	return (
		<div className="main-content">
			<div className="content-container">
				<div className="spacerShort"></div>
				<div className="article-content">
					<div style={{ width: "85vw" }}>
						<div className="centeredMargin50">
							<img src={logoSrc} alt="Del Complex" className="logo-image" />
							<p>Contractor Registration.</p>
							<div className="spacerShort"></div>
						</div>
						<WalletConnect />

						<div className="token-container-single">
							<ClaimToken
								claimId="52390128"
								imageSrc="/media/archival_media/contractor_entrance.jpg"
								fileSrc="/media/archival_media/contractor_entrance.jpg"
								alt="Contractor Entrance"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegistrationFormContractor;
