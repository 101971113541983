import React from "react";
import SmartURL from "./SmartURL";

const ResourceRow = ({ resource }) => {
	return (
		<div className="resource-row">
			<a
				href={SmartURL(resource.link)}
				target="_blank"
				rel="noreferrer"
				style={{ textDecoration: "none", color: "inherit" }}
			>
				<div className="resource-basic">
					<span>{resource.name}</span>
					<span>{resource.createdDate}</span>
					<span>{resource.type}</span>
				</div>
				<div className="resource-extra">
					<p>{resource.description}</p>
					{/* You can add other data fields here */}
				</div>
			</a>
		</div>
	);
};

export default ResourceRow;
