// SiteMap.js
import React from "react";
import { navLinks } from "./navData";

const SiteMap = () => {
	return (
		<div className="sitemap">
			{navLinks.map((dropdown, idx) => (
				<div key={idx}>
					<h2>{dropdown.title}</h2>
					<ul>
						{dropdown.items.map((item, idx) => (
							<li key={idx}>
								<a href={item.url}>{item.name}</a>
							</li>
						))}
					</ul>
				</div>
			))}
			<div>
				<h2>Intranet</h2>
				<ul>
					<li>
						<a href="/intranet/">Log In</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default SiteMap;
