import React from "react";
import { useAccount, useChainId, useSwitchChain } from "wagmi";
import { base } from "wagmi/chains";
import { ConnectKitButton } from "connectkit";

import WalletConnect from "./WalletConnect";
const BrainWormsLogin = React.memo(({ style, onStartButtonClick }) => {
	const { address, isConnected } = useAccount();
	const chainId = useChainId();
	const { switchChain } = useSwitchChain();

	const itemContainerStyle = {
		overflowY: "scroll",
		height: "100%",
		marginLeft: "4%",
		paddingRight: "4%",
	};

	const handleSwitchToBase = async () => {
		try {
			await switchChain({ chainId: base.id });
		} catch (error) {
			console.error("Failed to switch to Base network:", error);
		}
	};

	return (
		<div>
			<div style={style}>
				<div style={itemContainerStyle} className="scrollbar-container">
					<p>
						Brain Worms Simulation System
						<br /> <br />
						Part of the{" "}
						<a href="https://delcomplex.com/brainworms">
							Brain Worms Research Program
						</a>
					</p>
					<div>
						<WalletConnect />
						{isConnected && address && chainId !== base.id && (
							<div className="flexCenter">
								<button
									onClick={handleSwitchToBase}
									className="brainWormsAlphaLogin"
								>
									Switch to Base Network
								</button>
							</div>
						)}
						{isConnected && address && chainId == base.id && (
							<div className="flexCenter">
								<button
									onClick={onStartButtonClick}
									className="brainWormsAlphaLogin"
								>
									Start
								</button>
							</div>
						)}
						<div className="brainWormsAlphaLogin">
							<br />
							Learn more{" "}
							<a
								href="https://delcomplex.com/about"
								target="_blank"
								rel="noopener noreferrer"
							>
								about Del Complex
							</a>
							, connect with us on{" "}
							<a
								href="https://twitter.com/DelComplex"
								target="_blank"
								rel="noopener noreferrer"
							>
								X/Twitter
							</a>
							,{" "}
							<a
								href="https://discord.gg/YbxdyrnfRD"
								target="_blank"
								rel="noopener noreferrer"
							>
								Discord
							</a>
							,{" "}
							<a
								href="https://www.instagram.com/delcomplex/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Instagram
							</a>
							,{" "}
							<a
								href="https://t.me/delcomplex"
								target="_blank"
								rel="noopener noreferrer"
							>
								Telegram
							</a>
							, and consider our exciting{" "}
							<a
								href="https://delcomplex.com/onboarding/"
								target="_blank"
								rel="noopener noreferrer"
							>
								career opportunities.
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default BrainWormsLogin;
