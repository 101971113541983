import React from "react";
import { NavLink } from "react-router-dom";
import SmartImg from "./SmartImg";
import MetaTags from "./MetaTags";

const PurpleSmokeNavigation = ({ user }) => {
	// Adjust this to get the user info as needed
	return (
		<div className="purpleSmoke-nav">
			<MetaTags
				titleSuffix="ACCESS DENIED"
				description="ACCESS DENIED"
				path={location.pathname}
			/>
			<SmartImg
				src="/media/purplesmoke.png"
				alt="Purple Smoke"
				className="logo"
			/>
			<nav>
				<ul>
					<li>
						<NavLink to="/purplesmoke" exact activeClassName="active">
							Dashboard
						</NavLink>
					</li>
					<li>
						<NavLink to="/purplesmoke/request" activeClassName="active">
							Request
						</NavLink>
					</li>
					<li>
						<NavLink to="/purplesmoke/reportviolation" activeClassName="active">
							Report
						</NavLink>
					</li>
				</ul>
			</nav>
			<div className="user-info">
				<span>Welcome, Error Fetching Username.</span>
			</div>
		</div>
	);
};

export default PurpleSmokeNavigation;
