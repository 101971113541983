// HiringEvent.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const HiringEvent = () => {
	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Del Complex Hiring Event"
				content={[
					<>
						<b>Date:</b> September 24th, 2023
					</>,
					<>
						<b>Time:</b> 9:00 AM - 4:00 PM
					</>,
					<>
						<b>Location:</b> Mt. Augusta Facility - Entry Hall, Tunnel K
						Entrance, NV 89406
					</>,
					<>
						<b>Call to Register:</b> (530) 338-0209
					</>,
					<>
						<br />
					</>,
					<>
						<b>Career Opportunity:</b>
					</>,
					"Del Complex is at the forefront of integrating advanced AGIs with human cognition. We invite qualified candidates to explore career opportunities at our hiring event. Diverse backgrounds welcome.",
					<>
						<br />
					</>,
					<>
						<b>Research and Development:</b>
					</>,
					"Influence and contribute to the next wave of technological advancements that will shape future human-tech interactions.",
					<>
						<br />
					</>,
					<>
						<b>Cross-functional Collaboration:</b>
					</>,
					"Operate within a framework designed for efficient interdisciplinary interactions. Engage with top-tier experts to tackle and overcome industry-wide challenges.",
					<>
						<br />
					</>,
					<>
						<b>Structured Career Pathways:</b>
					</>,
					"Del Complex offers clear paths for professional growth. Employees benefit from systematic programs designed for skill development, leadership opportunities and vertical mobility in a fast-paced work environment.",
					<>
						<br />
					</>,
					<>
						<br />
					</>,
					<>
						<b>For Prospective Candidates :</b>
					</>,
					<>
						<a href="careers/">Learn more about working at Del Complex.</a>
					</>,
					<>
						<br />
					</>,
					<>
						<b>For Preferred Candidates :</b>
					</>,
					<>
						<a href="candidates/">Register your identity.</a>
					</>,
				]}
				titleLevel={1}
				images={["/media/hiring_event.jpg"]}
				imageIndex={11}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default HiringEvent;
