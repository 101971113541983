import React, { useState, useEffect, useCallback, useRef } from "react";
import ForceGraph2D from "react-force-graph-2d";

const ForceGraph = () => {
	const [graphData, setGraphData] = useState({ nodes: [], links: [] });
	const [phase, setPhase] = useState("remove"); // 'remove' or 'add'
	const fgRef = useRef();
	const containerRef = useRef();
	const [dimensions, setDimensions] = useState({ width: 0, height: 200 });
	const totalNodes = 150;
	const maxConnectionsPerNode = 3;
	const minTotalConnections = totalNodes - 1;
	const maxTotalConnections = totalNodes * 1.5;

	const initializeGraph = useCallback(() => {
		const numNodes = totalNodes;
		const nodes = Array.from({ length: numNodes }, (_, i) => ({
			id: i,
			name: `Brain Worm ${i}`,
		}));

		const links = [];
		const visited = new Set();
		visited.add(0);

		for (let i = 1; i < numNodes; i++) {
			const possibleTargets = Array.from(visited);
			const target =
				possibleTargets[Math.floor(Math.random() * possibleTargets.length)];
			links.push({ source: i, target });
			visited.add(i);
		}

		return { nodes, links };
	}, []);

	const getNodeConnections = (node, links) => {
		return links.filter((link) => link.source === node || link.target === node)
			.length;
	};

	const updateLinks = useCallback(
		(currentLinks) => {
			const numNodes = totalNodes;
			let newLinks = [...currentLinks];

			if (phase === "remove" && newLinks.length > minTotalConnections) {
				// Remove a random link
				const removableLinkIndices = newLinks.reduce((acc, link, index) => {
					if (
						getNodeConnections(link.source, newLinks) > 1 &&
						getNodeConnections(link.target, newLinks) > 1
					) {
						acc.push(index);
					}
					return acc;
				}, []);

				if (removableLinkIndices.length > 0) {
					const indexToRemove =
						removableLinkIndices[
							Math.floor(Math.random() * removableLinkIndices.length)
						];
					newLinks.splice(indexToRemove, 1);
				}
			} else if (phase === "add" && newLinks.length < maxTotalConnections) {
				// Add a new random link
				let attempts = 0;
				while (attempts < 100) {
					const source = Math.floor(Math.random() * numNodes);
					let target = Math.floor(Math.random() * numNodes);
					if (
						source !== target &&
						!newLinks.some(
							(link) =>
								(link.source === source && link.target === target) ||
								(link.source === target && link.target === source)
						) &&
						getNodeConnections(source, newLinks) < maxConnectionsPerNode &&
						getNodeConnections(target, newLinks) < maxConnectionsPerNode
					) {
						newLinks.push({ source, target });
						break;
					}
					attempts++;
				}
			}

			// Switch phase if limits are reached
			if (newLinks.length <= minTotalConnections) {
				setPhase("add");
			} else if (newLinks.length >= maxTotalConnections) {
				setPhase("remove");
			}

			return newLinks;
		},
		[phase]
	);

	useEffect(() => {
		setGraphData(initializeGraph());

		const interval = setInterval(() => {
			setGraphData((current) => ({
				...current,
				links: updateLinks(current.links),
			}));
		}, 100); // Update every 100ms for continuous change

		return () => clearInterval(interval);
	}, [initializeGraph, updateLinks]);

	const handleEngineStop = useCallback(() => {
		if (fgRef.current) {
			const fg = fgRef.current;
			fg.zoomToFit(400);

			const simulation = fg.d3Force();
			if (simulation) {
				simulation.force("charge").strength(-300);
				simulation.force("center").strength(0.5);
				simulation.force("link").distance(50);
				simulation.alpha(1).restart();
			}
		}
	}, []);

	useEffect(() => {
		const updateDimensions = () => {
			if (containerRef.current) {
				setDimensions({
					width: containerRef.current.offsetWidth,
					height: 200,
				});
			}
		};

		updateDimensions();
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const handleWheel = useCallback((event) => {
		event.preventDefault();
	}, []);

	return (
		<div
			ref={containerRef}
			style={{
				width: "100%",
				height: "200px",
				border: "1px solid red",
				marginTop: "20px",
				marginBottom: "20px",
			}}
			onWheel={handleWheel}
		>
			<ForceGraph2D
				ref={fgRef}
				graphData={graphData}
				nodeLabel="name"
				nodeColor={() => "#ff0000"}
				linkColor={() => "#999999"}
				width={dimensions.width}
				height={dimensions.height}
				cooldownTicks={50}
				onEngineStop={handleEngineStop}
				enableZooming={false}
				enablePanning={false}
				enableNodeDrag={false}
			/>
		</div>
	);
};

export default ForceGraph;
