import React, { useState, useEffect, useRef } from "react";

import SmartURL from "./SmartURL";
const BrainWormsResourceDisplay = ({
	isGuestMode,
	style,
	fontColorHighlight,
	elementsResourcePage,
	elementsResourcePageHarvest,
	onResourceButtonClick,
	onHarvestButtonClick,
	userLoadedNFTProperties,
	currentBlockNumber,
	setToolTipMessage,
}) => {
	////console.log("BrainWormsResourceDisplay loaded ");
	// if (userLoadedNFTProperties) {
	////console.log(
	// 	"BrainWormsResourceDisplay loaded , rank : " +
	// 		userLoadedNFTProperties.rank
	// );
	// }

	const [hoverElement, setHoverElement] = useState(null);
	const [activeElement, setActiveElement] = useState(null);

	const handleElementInteraction = (elementIndex) => {
		onResourceButtonClick(elementIndex);
	};

	const resourceMainImage = SmartURL(
		"/media/brainworms/simulator/resourcesBackgroundWidescreen.jpg"
	);

	let currentResourceName = "";
	let heightValue = parseFloat(style?.height || "100%");
	let widthValue = parseFloat(style?.width || "100%");
	let widthScale = widthValue / 1110;
	let heightScale = heightValue / 890;
	let buttonHeight = 90;
	let buttonWidth = 239;

	// -----------------------------------
	// extract width and height from style
	const width = style?.width || "100%";
	const height = style?.height || "100%";

	const widthUnit = width.replace(/[0-9.]/g, "");
	const heightUnit = height.replace(/[0-9.]/g, "");

	// Extract fontSize from style
	const fontSize = style?.fontSize || "1em"; // Default to '1em' if fontSize isn't provided
	const fontSizeValue = parseFloat(fontSize); // Convert fontSize string to a number
	const fontSizeUnit = fontSize.replace(/[0-9.]/g, ""); // Extract the unit (em, px, etc.)

	const propertiesFontScale = 0.6;
	const propertiesFontSize = `${
		fontSizeValue * propertiesFontScale
	}${fontSizeUnit}`;

	const calculatedMarginLeftValue = fontSizeValue * 0.5;
	const calculatedMarginLeft = `${calculatedMarginLeftValue}${fontSizeUnit}`;

	const calculatedTitleMarginTop = `${fontSizeValue * 2}${fontSizeUnit}`;

	const calculatedPropertiesMarginTop1Value = fontSizeValue * 1.6;
	const calculatedPropertiesMarginTop1 = `${calculatedPropertiesMarginTop1Value}${fontSizeUnit}`;

	const calcImgLeft = (index) => {
		return (
			buttonWidth * widthScale * index +
			(widthValue - buttonWidth * 3 * widthScale) * 0.25 * (index + 1) +
			buttonWidth * widthScale * 0.2 -
			index * buttonWidth * widthScale * 0.2
			//buttonWidth * widthScale * 0.33 +
			// buttonWidth * index * widthScale
		);
	};

	const backgroundImageContainerStyle = {
		backgroundImage: `url(${resourceMainImage})`,
		// backgroundSize: `${widthScale * 252 * 2}px ${heightScale * 315 * 2}px`,
		// backgroundPosition: `${widthValue * 0.25}${heightUnit} ${
		// 	heightValue * 0.3
		// }${widthUnit}`,
		backgroundSize: "100%",
		backgroundRepeat: "no-repeat",
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		// pointerEvents: "none",
	};

	const calcImgTop = (index) => {
		return buttonHeight * 2.2 * heightScale;
	};

	//console.log(
	// 	"BrainWormsResourceDisplay userLoadedNFTProperties.resourceTypeHarvested currently harvested " +
	// 		userLoadedNFTProperties.resourceTypeHarvested
	// );

	//console.log(
	// 	"BrainWormsResourceDisplay currentBlockNumber " + currentBlockNumber,
	// 	"userLoadedNFTProperties.lastHarvestBlock " +
	// 		userLoadedNFTProperties.lastHarvestBlock
	// );

	//console.log(userLoadedNFTProperties);

	// log data type of resoureType
	//console.log(typeof userLoadedNFTProperties.resourceTypeHarvested);
	return userLoadedNFTProperties ? (
		<div style={style}>
			<div style={backgroundImageContainerStyle}>
				<div>
					{elementsResourcePage.map((element, index) => (
						<div>
							<img
								className="clickable"
								key={index}
								ref={element.ref}
								src={
									isGuestMode
										? element.srcDarkened
										: userLoadedNFTProperties.lastHarvestBlock !== 0 &&
										  index === userLoadedNFTProperties.resourceTypeHarvested
										? element.srcHarvest
										: activeElement === index
										? element.srcActive
										: hoverElement === index
										? element.srcHover
										: element.src
								}
								style={{
									position: "absolute",
									left: `${calcImgLeft(index)}px`,
									top: `${calcImgTop(index)}px`,
									width: `${buttonWidth * widthScale}px`,
									height: `${buttonHeight * heightScale}px`,
								}}
								onMouseEnter={() => {
									if (isGuestMode) {
										setToolTipMessage(
											element.title +
												"  boosts " +
												element.harvestText +
												". However, Guest Mode does not allow harvesting."
										);
										return;
									}
									// if its the index resource type, dont respond
									// if its the index resource type, and the harvest block is nonzero
									// then don't respond
									// otherwise respond
									if (
										userLoadedNFTProperties.lastHarvestBlock !== 0 &&
										index === userLoadedNFTProperties.resourceTypeHarvested
									) {
										setToolTipMessage(
											"Currently harvesting " +
												element.title +
												". Your rank is relative to other worms balances, and determines your bonus."
										);
										return;
									}
									// otherwise respond
									setHoverElement(index);
									setToolTipMessage(element.toolTip);
								}}
								onMouseLeave={() => {
									setHoverElement(null);
									setActiveElement(null);
									if (isGuestMode) {
										setToolTipMessage(
											"Guest Mode worms cannot harvest resources. Obtain a Brain Worm to harvest resources."
										);
									} else {
										setToolTipMessage("");
									}
								}}
								onMouseDown={() => {
									if (isGuestMode) {
										return;
									}
									// if its the index resource type, and the harvest block is nonzero
									// then don't respond
									// otherwise respond
									if (
										userLoadedNFTProperties.lastHarvestBlock !== 0 &&
										index === userLoadedNFTProperties.resourceTypeHarvested
									) {
										return;
									}
									// otherwise respond
									setActiveElement(index);
									handleElementInteraction(index);
									////console.log("resourceDisplay page clicked on index:", index);
									////console.log("resourceDisplay page clicked on ", element.name);
									////console.log(
									// 	"resourceDisplay page , currently harvest type is userLoadedNFTProperties.resourceTypeHarvested:",
									// 	userLoadedNFTProperties.resourceTypeHarvested
									// );
								}}
								onMouseUp={() => setActiveElement(null)}
							/>
							<p
								style={{
									position: "absolute",
									left: `${calcImgLeft(index)}px`,
									top: `${calcImgTop(index) + buttonHeight * heightScale}px`,
									width: `${buttonWidth * widthScale}px`,
									height: `${buttonHeight * heightScale}px`,
									fontSize: propertiesFontSize,
									zIndex: 5,
									pointerEvents: "none",
									textAlign: "center",
									color:
										!isGuestMode &&
										userLoadedNFTProperties.lastHarvestBlock !== 0 &&
										index === userLoadedNFTProperties.resourceTypeHarvested
											? fontColorHighlight
											: "inherit",
								}}
							>
								{!isGuestMode &&
								userLoadedNFTProperties.lastHarvestBlock !== 0 &&
								index === userLoadedNFTProperties.resourceTypeHarvested ? (
									index === 0 ? (
										<>
											{`Bal: ${userLoadedNFTProperties?.resource1}`}
											<br />
											{`Rank: ${userLoadedNFTProperties.rank}/99`}
											<br />
											{`+${Math.floor(userLoadedNFTProperties.rank / 20)} ${
												element.harvestText
											}`}
										</>
									) : index === 1 ? (
										<>
											{`Bal: ${userLoadedNFTProperties?.resource2}`}
											<br />
											{`Rank: ${userLoadedNFTProperties.rank}/99`}
											<br />{" "}
											{`+${Math.floor(userLoadedNFTProperties.rank / 20)} ${
												element.harvestText
											}`}
										</>
									) : (
										<>
											{`Bal: ${userLoadedNFTProperties?.resource3}`}
											<br />
											{`Rank: ${userLoadedNFTProperties.rank}/99`}
											<br />{" "}
											{`+${Math.floor(userLoadedNFTProperties.rank / 20)} ${
												element.harvestText
											}`}
										</>
									)
								) : index === 0 ? (
									<> {`Bal: ${userLoadedNFTProperties?.resource1}`}</>
								) : index === 1 ? (
									<> {`Bal: ${userLoadedNFTProperties?.resource2}`}</>
								) : (
									<> {`Bal: ${userLoadedNFTProperties?.resource3}`}</>
								)}
							</p>
						</div>
					))}

					{elementsResourcePageHarvest.map((element, index) => (
						<div>
							<img
								className="clickable"
								key={index}
								ref={element.ref}
								src={
									isGuestMode
										? element.srcDarkened
										: userLoadedNFTProperties.lastHarvestBlock === 0 ||
										  currentBlockNumber -
												userLoadedNFTProperties.lastHarvestBlock <
												200
										? element.srcDarkened
										: activeElement === `harvest-${index}`
										? element.srcActive
										: hoverElement === `harvest-${index}`
										? element.srcHover
										: element.src
								}
								style={{
									position: "absolute",
									left: `${calcImgLeft(1)}px`,
									top: `${calcImgTop(1) + buttonHeight * 3.4 * heightScale}px`,
									width: `${buttonWidth * widthScale}px`,
									height: `${buttonHeight * heightScale}px`,
								}}
								onMouseEnter={() => {
									if (isGuestMode) {
										return;
									}
									let blocksPassed =
										currentBlockNumber -
										userLoadedNFTProperties.lastHarvestBlock;
									if (
										userLoadedNFTProperties.lastHarvestBlock !== 0 &&
										blocksPassed >= 200
									) {
										setHoverElement(`harvest-${index}`);
										setToolTipMessage(element.toolTip);
									} else if (userLoadedNFTProperties.lastHarvestBlock === 0) {
										setToolTipMessage("Select a resource first");
									} else if (blocksPassed < 200) {
										return;
									}
								}}
								onMouseLeave={() => {
									setHoverElement(null);
									setActiveElement(null);
									if (isGuestMode) {
										setToolTipMessage(
											"Guest Mode worms cannot harvest resources. Obtain a Brain Worm to harvest resources."
										);
									} else {
										setToolTipMessage("");
									}
								}}
								onMouseDown={() => {
									if (isGuestMode) {
										return;
									}
									let blocksPassed =
										currentBlockNumber -
										userLoadedNFTProperties.lastHarvestBlock;
									if (blocksPassed < 200) {
										return;
									}
									if (userLoadedNFTProperties.lastHarvestBlock !== 0) {
										setActiveElement(`harvest-${index}`);
										onHarvestButtonClick();
									}
								}}
								onMouseUp={() => setActiveElement(null)}
							/>
							<p
								style={{
									position: "absolute",
									left: `0px`,
									top: `${calcImgTop(1) + buttonHeight * 4.4 * heightScale}px`,
									width: `100%`,
									height: `${buttonHeight * heightScale}px`,
									fontSize: propertiesFontSize,
									zIndex: 5,
									pointerEvents: "none",
									textAlign: "center",
									color: fontColorHighlight,
								}}
							>
								{isGuestMode ? (
									"Unavailable in Guest Mode."
								) : userLoadedNFTProperties.lastHarvestBlock === 0 ? (
									"Select a resource first"
								) : currentBlockNumber -
										userLoadedNFTProperties.lastHarvestBlock <
								  200 ? (
									<>
										<span>
											Resources found:{" "}
											{currentBlockNumber -
												userLoadedNFTProperties.lastHarvestBlock}
										</span>
										<br />
										<span>harvest available after 200</span>
									</>
								) : (
									`Resources found: ${
										currentBlockNumber -
										userLoadedNFTProperties.lastHarvestBlock
									}`
								)}
							</p>
						</div>
					))}
				</div>
			</div>
		</div>
	) : (
		<div style={style}>
			<div style={backgroundImageContainerStyle}></div>
		</div>
	);
};

export default BrainWormsResourceDisplay;
