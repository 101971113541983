import React from "react";
import Article from "./Article";
import SmartURL from "./SmartURL";
const content = [
	"January 29th, 2024",
	"",
	"To All Employees,",

	<span>
		We are pleased to announce the release of the{" "}
		<a
			href={SmartURL("/media/documents/Del_Complex_New_Hire_Orientation.pdf")}
			target="_blank"
			rel="noopener noreferrer"
		>
			New Hire Onboarding and First Day Checklist
		</a>
		{". "}
		This document is now available for all new employees joining Del Complex.
	</span>,

	"This checklist outlines the essential tasks and security protocols that new hires must follow on their first day. It is crucial for integrating new team members into our corporate structure and ensuring adherence to our stringent security standards.",
	"All hiring managers are required to ensure that new hires review and understand this checklist. Compliance with the outlined procedures is mandatory.",
	"Sincerely,",
	"Internal Corporate Communications",
	"Del Complex",
];

export const title = "New Hire Onboarding.";
const PortalNewsOnboardingChecklist = () => {
	return <Article title={title} content={content} titleLevel={2} />;
};

export default PortalNewsOnboardingChecklist;
