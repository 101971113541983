const captchaImages = [
	"/media/captchas/Odyssey_Silence_Nose.png",
	"/media/captchas/Sphinx_Star_Child.png",
	"/media/captchas/Ragnarok_Cosmos_Ankle.png",
	"/media/captchas/Sorcerer_Pulse_Head.png",
	"/media/captchas/Kraken_Rift_Hair.png",
	"/media/captchas/Elysium_Moon_Heart.png",
	"/media/captchas/Prometheus_Horizon_Nerve.png",
	"/media/captchas/Aegis_Abyss_Mouth.png",
	"/media/captchas/Rune_Asteroid_Stomach.png",
	"/media/captchas/Wizard_Abyss_Muscle.png",
	"/media/captchas/Damocles_Planet_Tooth.png",
	"/media/captchas/Thanatos_Silence_Foot.png",
	"/media/captchas/Morpheus_Comet_Leg.png",
	"/media/captchas/Titan_Horizon_Arm.png",
	"/media/captchas/Elysium_Void_Knee.png",
	"/media/captchas/Atlantis_Paradox_Tooth.png",
	"/media/captchas/Thanatos_Cosmos_Head.png",
	"/media/captchas/Wizard_Planet_Head.png",
	"/media/captchas/Phoenix_Galaxy_Ankle.png",
	"/media/captchas/Witch_Quantum_Nerve.png",
	"/media/captchas/Hydra_Phase_Shoulder.png",
	"/media/captchas/Phoenix_Galaxy_Ankle.png",
	"/media/captchas/Wizard_Planet_Head.png",
	"/media/captchas/Sphinx_Quantum_Bone.png",
	"/media/captchas/Argonaut_Horizon_Finger.png",
	"/media/captchas/Athena_Aurora_Head.png",
	"/media/captchas/Warlock_Abyss_Thumb.png",
	"/media/captchas/Aegis_Moon_Mind.png",
	"/media/captchas/Aegis_Star_Bone.png",
	"/media/captchas/Wizard_Abyss_Muscle.png",
	"/media/captchas/Damocles_Planet_Tooth.png",
	"/media/captchas/Valkyrie_Meteor_Brain.png",
	"/media/captchas/Ares_Void_Nose.png",
	"/media/captchas/Witch_Sun_Head.png",
	"/media/captchas/Zeus_Aurora_Soul.png",
	"/media/captchas/Wizard_Abyss_Muscle.png",
	"/media/captchas/Minotaur_Galaxy_Foot.png",
	"/media/captchas/Wizard_Planet_Head.png",
	"/media/captchas/Sorcerer_Pulse_Head.png",
	"/media/captchas/Mystic_Ether_Hair.png",
	"/media/captchas/Warlock_Abyss_Thumb.png",
	"/media/captchas/Kraken_Rift_Hair.png",
	"/media/captchas/Nemesis_Cosmos_Eye.png",
	"/media/captchas/Nyx_Vector_Vein.png",
	"/media/captchas/Gorgon_Echo_Heart.png",
	"/media/captchas/Witch_Gravity_Child.png",
	"/media/captchas/Warlock_Abyss_Thumb.png",
	"/media/captchas/Styx_Cosmos_Hand.png",
	"/media/captchas/Sphinx_Star_Child.png",
	"/media/captchas/Elysium_Nova_Elbow.png",
	"/media/captchas/Zeus_Cosmos_Arm.png",
	"/media/captchas/Valkyrie_Supernova_Nail.png",
	"/media/captchas/Argonaut_Gravity_Fist.png",
	"/media/captchas/Rune_Supernova_Liver.png",
	"/media/captchas/Witch_Gravity_Child.png",
	"/media/captchas/Titan_Ion_Face.png",
	"/media/captchas/Uranus_Horizon_Neck.png",
	"/media/captchas/Arcane_Moon_Shoulder.png",
	"/media/captchas/Odyssey_Silence_Nose.png",
	"/media/captchas/Hydra_Cosmos_Toe.png",
	"/media/captchas/Valkyrie_Vortex_Child.png",
	"/media/captchas/Thanatos_Cosmos_Head.png",
	"/media/captchas/Sphinx_Quantum_Bone.png",
	"/media/captchas/Pandora_Quantum_Brain.png",
	"/media/captchas/Thanatos_Cosmos_Head.png",
	"/media/captchas/Prometheus_Horizon_Nerve.png",
	"/media/captchas/Nyx_Spectre_Liver.png",
	"/media/captchas/Wizard_Silence_Neck.png",
	"/media/captchas/Hydra_Cosmos_Toe.png",
	"/media/captchas/Kraken_Pulse_Spirit.png",
	"/media/captchas/Argonaut_Gravity_Fist.png",
	"/media/captchas/Minotaur_Anomaly_Mind.png",
	"/media/captchas/Sphinx_Quantum_Bone.png",
	"/media/captchas/Witch_Quantum_Nerve.png",
	"/media/captchas/Wizard_Sun_Soul.png",
	"/media/captchas/Gorgon_Supernova_Mouth.png",
	"/media/captchas/Arcane_Moon_Shoulder.png",
	"/media/captchas/Nemesis_Cosmos_Eye.png",
	"/media/captchas/Rune_Cipher_Arm.png",
	"/media/captchas/Zeus_Aurora_Soul.png",
	"/media/captchas/Zeus_Phase_Soul.png",
	"/media/captchas/Thanatos_Silence_Foot.png",
	"/media/captchas/Kraken_Galaxy_Eye.png",
	"/media/captchas/Thanatos_Cosmos_Head.png",
	"/media/captchas/Elysium_Void_Knee.png",
	"/media/captchas/Witch_Sun_Head.png",
	"/media/captchas/Wizard_Abyss_Muscle.png",
	"/media/captchas/Mystic_Asteroid_Liver.png",
	"/media/captchas/Valkyrie_Supernova_Nail.png",
	"/media/captchas/Oracle_Paradox_Arm.png",
	"/media/captchas/Oracle_Nova_Face.png",
	"/media/captchas/Hades_Quantum_Vein.png",
	"/media/captchas/Witch_Sun_Head.png",
	"/media/captchas/Damocles_Rift_Soul.png",
	"/media/captchas/Ares_Void_Nose.png",
	"/media/captchas/Siren_Comet_Kidney.png",
	"/media/captchas/Mystic_Ether_Hair.png",
	"/media/captchas/Athena_Cipher_Skin.png",
	"/media/captchas/Elysium_Spectre_Shoulder.png",
	"/media/captchas/Oracle_Paradox_Arm.png",
];

export default captchaImages;
