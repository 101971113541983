import React, { useState, useEffect } from "react";
import SmartURL from "./SmartURL";

const initialErrors = [
	"System ID: 0xRedding Faucet p1",
	"0xRedding 00032: caught SIGSEGV, dumping core",
	"core.c(3510): 0xRedding 00558: generating backtrace for pid Faucet p1",
	"backtrace.c(102): Backtrace:",
	"backtrace.c(115): [0x00007f9d8c312c10] /usr/lib/apache2/modules/mod_example.so(+0x12c10)",
	"backtrace.c(115): [0x00007f9d8c315fe0] /lib/x86_64-linux-gnu/libc.so.6(+0x35fe0)",
	"backtrace.c(115): [0x00007f9d8c319210] /usr/lib/apache2/modules/mod_php7.so(+0x4210)",
	"backtrace.c(135): end of backtrace",
	"0xRedding 00169: caught SIGTERM, shutting down",
	"CALLBACK REQUEST INVALID TOKEN",
	"332857219929374, TELIOS, APEX",
];
const usernameMessages = [
	"CALL BACK REQUEST",
	"Rebooting process initiated...",
	"System reboot in progress...",
	"Reboot completed successfully.",
];

const passwordMessages = ["Username accepted.", "Verifying credentials..."];

const successMessages = [
	"Credentials verified.",
	"Access granted.",
	"Welcome!",
];
const allStagesMessages = {
	reboot: initialErrors,
	username: usernameMessages,
	password: passwordMessages,
	success: successMessages,
};

const formatDate = () => {
	const date = new Date();
	const options = {
		year: "numeric",
		month: "short",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		timeZoneName: "short",
	};
	return date.toLocaleDateString("en-US", options);
};

const ContactXioSky = () => {
	const [inputValue, setInputValue] = useState("");
	const [inputFocused, setInputFocused] = useState(false);
	const [currentDate, setCurrentDate] = useState(formatDate());

	const [authStage, setAuthStage] = useState("reboot"); // 'reboot', 'username', 'password', 'success'

	const [displayedMessages, setDisplayedMessages] = useState([]);

	useEffect(() => {
		if (
			authStage !== "success" &&
			displayedMessages.length < allStagesMessages[authStage].length
		) {
			const timer = setTimeout(() => {
				setDisplayedMessages(
					allStagesMessages[authStage].slice(0, displayedMessages.length + 1)
				);
			}, 500); // Adjust this delay time as needed
			return () => clearTimeout(timer);
		} else if (
			authStage === "success" &&
			displayedMessages.length < successMessages.length
		) {
			const timer = setTimeout(() => {
				setDisplayedMessages(
					successMessages.slice(0, displayedMessages.length + 1)
				);
			}, 500); // Adjust this delay time as needed
			return () => clearTimeout(timer);
		}
	}, [displayedMessages, authStage]);

	const handleInputChange = (e) => setInputValue(e.target.value);

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			const enteredUsername = btoa(inputValue.toLowerCase()); // encode the entered username
			const enteredPassword = btoa(inputValue); // encode the entered password

			switch (authStage) {
				case "reboot":
					if (
						inputValue.toLowerCase() === "y" ||
						inputValue.toLowerCase() === "yes"
					) {
						setCurrentDate(formatDate());
						setInputValue("");
						setDisplayedMessages([]); // Reset displayed messages
						setAuthStage("username");
					} else {
						setInputValue(""); // Clear the input field for 'no' or any other input
					}
					break;
				case "username":
					if (enteredUsername === "emVwaHly") {
						setCurrentDate(formatDate());
						setInputValue("");
						setDisplayedMessages([]); // Reset displayed messages
						setAuthStage("password");
					} else {
						setInputValue(""); // Clear the input field for incorrect username
					}
					break;
				case "password":
					if (enteredPassword === "ZGVscmlu") {
						setCurrentDate(formatDate());
						setInputValue("");
						setDisplayedMessages([]); // Reset displayed messages
						setAuthStage("success");
						localStorage.setItem("zephyr_access_granted", "true");
					} else {
						setInputValue(""); // Clear the input field for incorrect password
					}
					break;
				default:
					break;
			}
		}
	};

	return (
		<div className="contactXioSkyPage">
			{authStage !== "success" ||
			displayedMessages.length < successMessages.length ? (
				<div>
					{displayedMessages.map((message, index) => (
						<p key={index} className="contactXioSkyPage-error-text">
							[{currentDate}] [core:notice] [pid 12345] [client
							192.168.1.2:56789] {message}
						</p>
					))}

					{(authStage !== "reboot" ||
						displayedMessages.length === initialErrors.length) &&
						(authStage !== "username" ||
							displayedMessages.length === usernameMessages.length) &&
						(authStage !== "password" ||
							displayedMessages.length === passwordMessages.length) && (
							<p className="contactXioSkyPage-error-text">
								[{currentDate}] [core:notice] [pid 1111] 0xRedding 00171:
								{getPromptText(authStage)}
							</p>
						)}

					{(authStage !== "reboot" ||
						displayedMessages.length === initialErrors.length) &&
						(authStage !== "username" ||
							displayedMessages.length === usernameMessages.length) &&
						(authStage !== "password" ||
							displayedMessages.length === passwordMessages.length) && (
							<div className="input-container">
								<input
									type="text"
									value={inputValue}
									onChange={handleInputChange}
									onKeyPress={handleKeyPress}
									onFocus={() => setInputFocused(true)}
									onBlur={() => setInputFocused(false)}
									className="contactXioSkyPage-input transparent-input"
								/>
								{!inputFocused && <div className="blinking-cursor"></div>}
							</div>
						)}
				</div>
			) : (
				<p>
					Access granted to{" "}
					<a href="/internal-delimages-archive">internal-delimages-archive</a>
				</p>
			)}
		</div>
	);
};

const getPromptText = (stage) => {
	switch (stage) {
		case "reboot":
			return "Reboot: y, n?";
		case "username":
			return "Enter username:";
		case "password":
			return "Enter password:";
		default:
			return "";
	}
};

export default ContactXioSky;
