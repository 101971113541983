import React from "react";
import Article from "./Article";
import SmartURL from "./SmartURL";

const content = [
	"January 2024",
	"",
	"Mt. Augusta Facility - Lost and Found Notice",
	"",
	"To All Visitors and Employees,",
	"Due to an excessive accumulation of lost and misplaced items at the Mt. Augusta Facility, we urgently remind you to contact the Facility Management Office if you have left any personal belongings on our premises.",
	<>
		Our <a href="/lost-and-found">current Lost and Found inventory</a> has
		reached its maximum capacity. As a result, we must inform you that unclaimed
		items will be subject to incineration or disposal if not retrieved within
		the next 30 days.
	</>,
	"We urge all visitors and employees to ensure the security of their belongings during their time at the facility. Should you find any items, please promptly report them to Facility Management.",
	"This measure is critical to managing the overwhelming volume of lost items and maintaining the operational efficiency of the Mt. Augusta Facility.",
	"We appreciate your immediate attention to this matter and your cooperation in adhering to our updated Lost and Found protocol.",
	"Sincerely,",
	"Del Complex Facility Infrastructure Group",
];
export const title = "Lost and Found Notice.";
const PortalNewsAgusutaLostFound = () => {
	return <Article title={title} content={content} titleLevel={2} />;
};

export default PortalNewsAgusutaLostFound;
