import React from "react";
import Article from "./Article";

const content = [
	"We regret to inform all Del Complex employees and contractors that there has been an unauthorized release of documents classified under FOUO//TLP: RED from our internal archive. This breach was orchestrated by external entities with the unintended assistance of some new employees.",
	"",
	"Immediate actions are being taken to address this situation:",
	"",
	"1. Investigation: A comprehensive internal investigation has been launched to determine the extent of the breach and to identify all parties involved. External agencies may also be involved to assist in this process.",
	"2. Employee Training: All new employees will undergo a rigorous retraining on our security and confidentiality protocols. It is crucial to ensure that every member of our organization understands and adheres to our strict security measures.",
	"3. Strengthened Security Measures: We are implementing enhanced security protocols and measures to prevent such breaches in the future. This includes a thorough review of our current systems and protocols.",
	"4. Communication: We urge all employees and contractors to refrain from discussing, sharing, or disseminating any information related to this breach outside of the company. Doing so can further compromise our security and may have legal implications.",
	"",
	"Please be reminded of the following:",
	"",

	"- The unauthorized release of these documents is a grave violation of our security protocols. Any employee found to have willingly participated or aided in this breach will face serious consequences.",
	"- Employees and contractors are reminded of the importance of adhering to the confidentiality notice and the guidelines set by the Traffic Light Protocol. Protecting our company's information remains a paramount responsibility for all.",
	"- Report any suspicious activity or information related to this breach to your Department Lead immediately.",
	"",
	"We understand the gravity of this situation and assure you that we are doing everything in our power to rectify it. We sincerely apologize for any concerns this may have caused and appreciate your continued trust and cooperation.",
	"Internal Corporate Communications",
	"Del Complex",
];

export const title = "URGENT NOTICE";
const PortalNewsETRLeak = () => {
	return <Article title={title} content={content} titleLevel={2} />;
};

export default PortalNewsETRLeak;
