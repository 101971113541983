// BrainWormDoctorContactAdmin.js
import React, { useState } from "react";
import BrainWormDoctorNavigation from "./BrainWormDoctorNavigation";
import BrainWormDoctorFooter from "./BrainWormDoctorFooter";
import useBrainWormDoctorSecurity from "./useBrainWormDoctorSecurity";
const BrainWormDoctorContactAdmin = () => {
	useBrainWormDoctorSecurity();
	const [submitted, setSubmitted] = useState(false);

	const handleSubmit = (event) => {
		event.preventDefault();
		setSubmitted(true);
	};

	return (
		<div className="BrainWormDoctor">
			<BrainWormDoctorNavigation />
			<div className="report-container">
				{!submitted ? (
					<form onSubmit={handleSubmit} className="ContactAdminForm">
						<h4>Contact Admin</h4>
						<textarea
							required
							placeholder="Enter your message here..."
							className="ContactAdminTextarea"
						></textarea>
						<button type="submit" className="submit-button">
							Submit
						</button>
					</form>
				) : (
					<div className="success-message">
						<h4>Submission Received</h4>
						<p>
							Your report has been logged into our system. It is currently
							pending review.
						</p>
					</div>
				)}
			</div>
			<BrainWormDoctorFooter />
		</div>
	);
};

export default BrainWormDoctorContactAdmin;
