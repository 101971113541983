import React, { useState, useEffect } from "react";
import SmartURL from "./SmartURL";
// for reference:
// battleResults = {
// 	battlePending: ... true or false,
//  battleReady: ... true or false,
// 	battleId: battleId,
// 	tokenIdRegularPlayer1:
// 	tokenIdRegularPlayer2:
// tokenIdRegularPlayer1:
// tokenIdRegularPlayer2:
// 	didPlayer1RollInitiative: decodedData[0],
// 	didPlayer1CriticalHit: decodedData[1],
// 	didPlayer2CriticalHit: decodedData[2],
// 	didPlayer1Miss: decodedData[3],
// 	didPlayer2Miss: decodedData[4],
// 	staminaRemainingPlayer1: decodedData[5],
// 	staminaRemainingPlayer2: decodedData[6],
// didPlayer1Win: decodedData[7],
// staminaStartingPlayer1: userLoadedNFTProperties.stamina,
// staminaStartingPlayer2: enemyLoadedNFTProperties.stamina,
// };

const BrainWormsBattleStatsDisplay = React.memo(
	({
		isGuestMode,
		style,
		battleResults,
		fontColorHighlight,
		sharedDefaultFontColor,
		fontColorHighlightEnemy,
		sharedDefaultEnemyFontColor,
		isBattleSoundEnabled,
		pvpPointTiers,
		pvpRankTitles,
		onBattleDisplayCompletion,
	}) => {
		const [battleResultsTitle, setBattleResultsTitle] = useState("");
		const [battleResultsLines, setBattleResultsLines] = useState([]);
		const [displayedLines, setDisplayedLines] = useState([]);
		const [showDetails, setShowDetails] = useState(false);

		const [audioUrls, setAudioUrls] = useState([]); // Store audio URLs
		const defaultTimeout = 1300;
		const [timeoutLength, setTimeoutLength] = useState(defaultTimeout);

		const soundsAttack = [
			"/media/brainworms/simulator/attack_1.mp3",
			"/media/brainworms/simulator/attack_2.mp3",
			"/media/brainworms/simulator/attack_3.mp3",
			"/media/brainworms/simulator/attack_4.mp3",
			"/media/brainworms/simulator/attack_5.mp3",
			"/media/brainworms/simulator/attack_6.mp3",
		];
		const soundsCriticalHit = [
			"/media/brainworms/simulator/crit_1.mp3",
			"/media/brainworms/simulator/crit_2.mp3",
			"/media/brainworms/simulator/crit_3.mp3",
		];
		const soundsMiss = [
			"/media/brainworms/simulator/miss_1.mp3",
			"/media/brainworms/simulator/miss_2.mp3",
			"/media/brainworms/simulator/miss_3.mp3",
			"/media/brainworms/simulator/miss_4.mp3",
			"/media/brainworms/simulator/miss_5.mp3",
		];
		const soundsLoss = [
			"/media/brainworms/simulator/loss_1.mp3",
			"/media/brainworms/simulator/loss_2.mp3",
			"/media/brainworms/simulator/loss_3.mp3",
			"/media/brainworms/simulator/loss_4.mp3",
		];
		const soundsWin = [
			"/media/brainworms/simulator/win_1.mp3",
			"/media/brainworms/simulator/win_2.mp3",
			"/media/brainworms/simulator/win_3.mp3",
			"/media/brainworms/simulator/win_4.mp3",
			"/media/brainworms/simulator/win_5.mp3",
		];
		const soundsP1Initiative = ["/media/brainworms/simulator/p1_init.mp3"];
		const soundsP2Initiative = ["/media/brainworms/simulator/p2_init.mp3"];

		function getRandomSound(sounds) {
			const randomIndex = Math.floor(Math.random() * sounds.length);
			return sounds[randomIndex];
		}

		const calculateInitiative = (dex, stam, dexBeatsStam) => {
			// init roll in solidity is defined as
			// // Roll for initiative
			// {
			// 	// add attackers full dex to the roll
			// 	uint8 p1Roll = uint8(prng.next() % 20) + p1Attributes[2];
			// 	// subtract half the defenders stamina from their roll,
			// 	// if their roll is greater than half the defenders stamina
			// 	uint8 p2Roll = uint8(prng.next() % 20);
			// 	p2Roll = p2Roll > p2Attributes[1] / 2
			// 		? p2Roll - p2Attributes[1] / 2
			// 		: 0;
			// 	combatStates[ATTACKER_GOES_FIRST] = p1Roll > p2Roll;
			// }
			// we don't know what they rolled
			// but we do know the outcome of the init roll
			// and their attributes
			// so we can values of the rolls that would have resulted in the outcome

			// battleResults.staminaStartingPlayer1
			// battleResults.dexterityStartingPlayer1
			// battleResults.staminaStartingPlayer2
			// battleResults.dexterityStartingPlayer2
			let p1RawRoll = 0;
			let p2RawRoll = 0;

			//console.log("Rolling initiative, dexBeatsStam: ", dexBeatsStam);
			if (dexBeatsStam) {
				do {
					// Generate hitChance and dodgeChance randomly between 1 and 20 (inclusive)
					p1RawRoll = Math.floor(Math.random() * 20) + 1;
					p2RawRoll = Math.floor(Math.random() * 20) + 1;
				} while (p1RawRoll + dex <= p2RawRoll - Math.floor(stam / 2));
			} else {
				do {
					// Generate hitChance and dodgeChance randomly between 1 and 20 (inclusive)
					p1RawRoll = Math.floor(Math.random() * 20) + 1;
					p2RawRoll = Math.floor(Math.random() * 20) + 1;
				} while (p1RawRoll + dex >= p2RawRoll - Math.floor(stam / 2));
			}

			//console.log("Initiative rolls: ", p1RawRoll, p2RawRoll);

			return [p1RawRoll, p2RawRoll];
		};

		const calculateMissRoll = (attackerStrength, defenderDexterity) => {
			let hitChance = 0;
			let dodgeChance = 0;

			do {
				// Generate hitChance and dodgeChance randomly between 1 and 20 (inclusive)
				hitChance = Math.floor(Math.random() * 20) + 1;
				dodgeChance = Math.floor(Math.random() * 20) + 1;
			} while (hitChance + attackerStrength >= dodgeChance + defenderDexterity);

			return [hitChance, dodgeChance];
		};

		const calculateCriticalHit = (attackerDexterity) => {
			let hitChance = 0;

			do {
				// Generate hitChance randomly between 1 and 20 (inclusive)
				hitChance = Math.floor(Math.random() * 20) + 1;
			} while (hitChance + attackerDexterity / 2 < 22);

			return hitChance;
		};

		/*
		function performAttack(
			uint8 attackerStrength,
			uint8 attackerDexterity,
			uint8 defenderStamina,
			uint8 defenderDexterity,
			LibPRNG.PRNG memory prng
		) internal pure returns (uint8, bool, bool) {
			uint8 hitChance = uint8(prng.next() % 20);
			uint8 dodgeChance = uint8(prng.next() % 20);
			bool criticalHit = false;
			bool miss = false;

			// Critical hit, direct knock-out
			if (hitChance + attackerDexterity / 2 >= 22) {
				criticalHit = true;
				// rekt
				defenderStamina = 0;
			} else if (
				hitChance + attackerStrength > dodgeChance + defenderDexterity
			) {
				// attack landed
				uint8 damage = uint8(prng.next() % 10) + attackerStrength / 2;
				defenderStamina = (damage >= defenderStamina)
					? 0
					: (defenderStamina - damage);
			} else {
				// Attack missed, no damage dealt
				miss = true;
			}
			return (defenderStamina, criticalHit, miss);
		}
		*/
		const calculateAttackDamageRoll = (attackerStrength, damageDone) => {
			// Calculate the raw roll value
			const rawRoll = damageDone - Math.floor(attackerStrength / 2);
			return rawRoll;
		};

		useEffect(() => {
			const updateResults = () => {
				if (!battleResults) {
					return;
				}
				const lines = [];
				const sounds = [];
				if (battleResults.battlePending || battleResults.battleReady) {
					setBattleResultsTitle(`Ready for Battle!`);
					// lines.push({
					// 	text: `BW-${battleResults.tokenIdRegularPlayer1} vs. BW-${battleResults.tokenIdRegularPlayer2}`,
					// 	player: "info",
					// });
					// sounds.push(""); // No sound for this line
					if (battleResults.battlePending) {
						lines.push({
							text: `Waiting for results`,
							player: "info",
						});
						sounds.push(""); // No sound for this line
						lines.push({
							text: `.`,
							player: "info",
						});
						sounds.push(""); // No sound for this line
						lines.push({
							text: `..`,
							player: "info",
						});
						sounds.push(""); // No sound for this line
						lines.push({
							text: `...`,
							player: "info",
						});
						sounds.push(""); // No sound for this line
					}
				} else {
					if (battleResults.battleId > 0) {
						setBattleResultsTitle(`Battle ${battleResults.battleId}!`);
					} else {
						setBattleResultsLines("Guest Mode Battle!");
					}
					lines.push(...determineBattleLines(battleResults, sounds));
					setTimeoutLength(1500); // Reset timeout length
				}
				setBattleResultsLines(lines);
				setAudioUrls(sounds);
				setDisplayedLines([]);
			};

			updateResults();
		}, [battleResults]);

		// Determine the battle lines to display
		const determineBattleLines = (battleResults, sounds) => {
			if (battleResults.tokenIdRegularPlayer1 == 69420) {
				battleResults.tokenIdRegularPlayer1 = "Guest-Mode";
			}
			const lines = [];
			let p1FullStamina =
				battleResults.staminaStartingPlayer1 + battleResults.p1StaminaBonus;
			let p1FullStrength =
				battleResults.strengthStartingPlayer1 + battleResults.p1StrengthBonus;
			let p1FullDexterity =
				battleResults.dexterityStartingPlayer1 + battleResults.p1DexterityBonus;
			let p2FullStamina =
				battleResults.staminaStartingPlayer2 + battleResults.p2StaminaBonus;
			let p2FullStrength =
				battleResults.strengthStartingPlayer2 + battleResults.p2StrengthBonus;
			let p2FullDexterity =
				battleResults.dexterityStartingPlayer2 + battleResults.p2DexterityBonus;

			if (battleResults.didPlayer1RollInitiative) {
				//console.log("Player 1 rolled initiative");
				let initRoll = calculateInitiative(
					battleResults.dexterityStartingPlayer1,
					battleResults.staminaStartingPlayer2,
					true
				);
				let rollMod1 = battleResults.dexterityStartingPlayer1;
				let rollMod2 = Math.floor(battleResults.staminaStartingPlayer2 / 2);

				lines.push({
					text: `BW-${battleResults.tokenIdRegularPlayer1} rolled initiative!`,
					player: "player1",
					details: `Init: ${initRoll[0]} (+${rollMod1} base dex)`,
					detailsInfo: "vs.",
					detailsp2: `Init: ${initRoll[1]} (-${rollMod2} half base stam.)`,
				});
				sounds.push(getRandomSound(soundsP1Initiative));
			} else {
				//console.log("Player 2 rolled initiative");
				let initRoll = calculateInitiative(
					battleResults.dexterityStartingPlayer1,
					battleResults.staminaStartingPlayer2,
					false
				);

				let rollMod1 = battleResults.dexterityStartingPlayer1;
				let rollMod2 = Math.floor(battleResults.staminaStartingPlayer2 / 2);

				lines.push({
					text: `BW-${battleResults.tokenIdRegularPlayer2} rolled initiative!`,
					player: "player2",
					details: `Init: ${initRoll[0]} (+${rollMod1} base dex)`,
					detailsInfo: "vs.",
					detailsp2: `Init: ${initRoll[1]} (-${rollMod2} half base stam.)`,
				});
				sounds.push(getRandomSound(soundsP2Initiative));
			}

			const damageDoneToPlayer1 =
				battleResults.staminaStartingPlayer1 +
				battleResults.p1StaminaBonus -
				battleResults.staminaRemainingPlayer1;

			const damageDoneToPlayer2 =
				battleResults.staminaStartingPlayer2 +
				battleResults.p2StaminaBonus -
				battleResults.staminaRemainingPlayer2;

			if (battleResults.didPlayer1RollInitiative) {
				// Player 1 actions
				if (battleResults.didPlayer1CriticalHit) {
					let critRoll = calculateCriticalHit(p1FullDexterity);
					lines.push({
						text: `BW-${battleResults.tokenIdRegularPlayer1} Critical hit!`,
						player: "player1",
						details: `Crit: ${critRoll} + ${p1FullDexterity} full dex. >= 22`,
						detailsInfo: ``,
						detailsp2: ``,
					});
					sounds.push(getRandomSound(soundsCriticalHit));
				} else if (battleResults.didPlayer1Miss) {
					let missRoll = calculateMissRoll(p1FullStrength, p2FullDexterity);
					lines.push({
						text: `BW-${battleResults.tokenIdRegularPlayer1} missed!`,
						player: "player1",
						details: `Hit: ${missRoll[0]} + ${p1FullStrength} full str.`,
						detailsInfo: "vs.",
						detailsp2: `Dodge: ${missRoll[1]} + ${p2FullDexterity} full dex.`,
					});
					sounds.push(getRandomSound(soundsMiss));
				} else if (damageDoneToPlayer2 > 0) {
					let damageRoll = calculateAttackDamageRoll(
						p1FullStrength,
						damageDoneToPlayer2
					);
					lines.push({
						text: `BW-${battleResults.tokenIdRegularPlayer1} attacked! ${damageDoneToPlayer2} dmg!`,
						player: "player1",
						details: `Dmg: ${damageRoll} + ${Math.floor(
							p1FullStrength / 2
						)} half str.`,
					});
					sounds.push(getRandomSound(soundsAttack));
				}

				// Player 2 actions (if still alive)
				if (battleResults.staminaRemainingPlayer2 > 0) {
					if (battleResults.didPlayer2CriticalHit) {
						let critRoll = calculateCriticalHit(p2FullDexterity);
						lines.push({
							text: `BW-${battleResults.tokenIdRegularPlayer2} Critical hit!`,
							player: "player2",
							details: ``,
							detailsInfo: ``,
							detailsp2: `Crit: ${critRoll} + ${p2FullDexterity} full dex. >= 22`,
						});
						sounds.push(getRandomSound(soundsCriticalHit));
					} else if (battleResults.didPlayer2Miss) {
						let missRoll = calculateMissRoll(p2FullStrength, p1FullDexterity);
						lines.push({
							text: `BW-${battleResults.tokenIdRegularPlayer2} missed!`,
							player: "player2",
							details: `Dodge: ${missRoll[1]} + ${p1FullDexterity} full dex`,
							detailsp2: `Hit: ${missRoll[0]} + ${p2FullStrength} full str.`,
							detailsInfo: "vs.",
						});
						sounds.push(getRandomSound(soundsMiss));
					} else if (damageDoneToPlayer1 > 0) {
						let damageRoll = calculateAttackDamageRoll(
							p2FullStrength,
							damageDoneToPlayer1
						);
						lines.push({
							text: `BW-${battleResults.tokenIdRegularPlayer2} countered! ${damageDoneToPlayer1} dmg!`,
							player: "player2",
							details: ``,
							detailsInfo: ``,
							detailsp2: `Dmg: ${damageRoll} + ${Math.floor(
								p2FullStrength / 2
							)} half str.`,
						});
						sounds.push(getRandomSound(soundsAttack));
					}
				}
			} else {
				// Player 2 actions
				if (battleResults.didPlayer2CriticalHit) {
					let critRoll = calculateCriticalHit(p2FullDexterity);
					lines.push({
						text: `BW-${battleResults.tokenIdRegularPlayer2} Critical hit!`,
						player: "player2",
						details: ``,
						detailsInfo: ``,
						detailsp2: `Crit: ${critRoll} + ${p2FullDexterity} full dex. >= 22`,
					});
					sounds.push(getRandomSound(soundsCriticalHit));
				} else if (battleResults.didPlayer2Miss) {
					let missRoll = calculateMissRoll(p2FullStrength, p1FullDexterity);
					lines.push({
						text: `BW-${battleResults.tokenIdRegularPlayer2} missed!`,
						player: "player2",
						detailsp2: `Hit: ${missRoll[0]} + ${p2FullStrength} full str.`,
						detailsInfo: "vs.",
						details: `Dodge: ${missRoll[1]} + ${p1FullDexterity} full dex`,
					});
					sounds.push(getRandomSound(soundsMiss));
				} else if (damageDoneToPlayer1 > 0) {
					let damageRoll = calculateAttackDamageRoll(
						p2FullStrength,
						damageDoneToPlayer1
					);
					lines.push({
						text: `BW-${battleResults.tokenIdRegularPlayer2} attacked! ${damageDoneToPlayer1} dmg!`,
						player: "player2",
						details: ``,
						detailsInfo: ``,
						detailsp2: `Dmg: ${damageRoll} + ${Math.floor(
							p2FullStrength / 2
						)} half str.`,
					});
					sounds.push(getRandomSound(soundsAttack));
				}

				// Player 1 actions (if still alive)
				if (battleResults.staminaRemainingPlayer1 > 0) {
					if (battleResults.didPlayer1CriticalHit) {
						let critRoll = calculateCriticalHit(p1FullDexterity);
						lines.push({
							text: `BW-${battleResults.tokenIdRegularPlayer1} Critical hit!`,
							player: "player1",
							details: `Crit: ${critRoll} + ${p1FullDexterity} full dex. >= 22`,
							detailsInfo: ``,
							detailsp2: ``,
						});
						sounds.push(getRandomSound(soundsCriticalHit));
					} else if (battleResults.didPlayer1Miss) {
						let missRoll = calculateMissRoll(p1FullStrength, p2FullDexterity);
						lines.push({
							text: `BW-${battleResults.tokenIdRegularPlayer1} missed!`,
							player: "player1",
							details: `Hit: ${missRoll[0]} + ${p1FullStrength} full str.`,
							detailsInfo: ``,
							detailsp2: `Dodge: ${missRoll[1]} + ${p2FullDexterity} full dex.`,
						});
						sounds.push(getRandomSound(soundsMiss));
					} else if (damageDoneToPlayer2 > 0) {
						let damageRoll = calculateAttackDamageRoll(
							p1FullStrength,
							damageDoneToPlayer2
						);

						lines.push({
							text: `BW-${battleResults.tokenIdRegularPlayer1} countered! ${damageDoneToPlayer2} dmg!`,
							player: "player1",
							details: `Dmg: ${damageRoll} + ${Math.floor(
								p1FullStrength / 2
							)} half str.`,
							detailsInfo: ``,
							detailsp2: ``,
						});
						sounds.push(getRandomSound(soundsAttack));
					}
				}
			}

			// Results
			if (battleResults.didPlayer1Miss && battleResults.didPlayer2Miss) {
				lines.push({
					text: `Both Brain Worms missed! You lose by default!`,
					player: "results",
					details: ``,
				});
				sounds.push(getRandomSound(soundsLoss));
				// lines.push({
				// 	text: `You lose by default!`,
				// 	player: "results",
				// 	details: ``,
				// });
				// sounds.push(getRandomSound(soundsLoss));
			} else {
				if (battleResults.staminaRemainingPlayer1 <= 0) {
					let detailText = "";
					if (battleResults.didPlayer2CriticalHit) {
						detailText = "Death by critical hit!";
					} else {
						detailText = "Death by severe damage!";
					}

					lines.push({
						text: `BW-${battleResults.tokenIdRegularPlayer1} is fatally wounded!`,
						player: "player1",
						details: detailText,
						detailsInfo: ``,
						detailsp2: ``,
					});
					sounds.push(""); // No sound for this line
				} else if (battleResults.staminaRemainingPlayer2 <= 0) {
					let detailText = "";

					if (battleResults.didPlayer1CriticalHit) {
						detailText = "Death by critical hit!";
					} else {
						detailText = "Death by severe damage!";
					}
					lines.push({
						text: `BW-${battleResults.tokenIdRegularPlayer2} is fatally wounded!`,
						player: "player2",
						detailsp2: detailText,
						detailsInfo: ``,
						details: ``,
					});
					sounds.push(""); // No sound for this line
				} else {
					let winnertext = battleResults.didPlayer1Win
						? "You win!"
						: "You lose!";
					lines.push({
						text: `Stam result: ${battleResults.staminaRemainingPlayer1} vs. ${battleResults.staminaRemainingPlayer2} ${winnertext} `,
						player: "results",
						details: ``,
						detailsInfo: ``,
						detailsp2: ``,
					});
					sounds.push(""); // No sound for this line
				}

				// if (battleResults.didPlayer1Win) {
				// 	lines.push({
				// 		text: `You win!`,
				// 		player: "info",
				// 		details: ``,
				// 	});
				// 	sounds.push(getRandomSound(soundsWin));
				// } else {
				// 	lines.push({
				// 		text: `You lost!`,
				// 		player: "info",
				// 		details: ``,
				// 	});
				// 	sounds.push(getRandomSound(soundsLoss));
				// }
			}

			if (battleResults.resourceAmountTransferred > 0) {
				let resourceStrings = ["Glucose", "Amino Acids", "Lipids"];
				if (battleResults.didPlayer1Win) {
					let whichResource = battleResults.resourceHarvestingByPlayer1;
					let resourceString = resourceStrings[whichResource];
					lines.push({
						text: `Drained! ${battleResults.resourceAmountTransferred} ${resourceString} sent to BW-${battleResults.tokenIdRegularPlayer1}`,
						player: "results",
						details: ``,
						detailsp2: ``,
						detailsInfo: ``,
					});

					sounds.push(""); // No sound for this line
				} else {
					let whichResource = battleResults.resourceHarvestingByPlayer2;
					let resourceString = resourceStrings[whichResource];

					lines.push({
						text: `Resources Drained! ${battleResults.resourceAmountTransferred} ${resourceString} sent to BW-${battleResults.tokenIdRegularPlayer2}`,
						player: "results",
						details: ``,
						detailsp2: ``,
						detailsInfo: ``,
					});
					sounds.push(""); // No sound for this line
				}
			}

			// display PVP points transfer and points until next level
			let userGainedPvpPoints = 0;
			let enemyGainedPvpPoints = 0;
			//console.log("PVP Points change: ", battleResults.pvpPointsChange);
			//console.log(typeof battleResults.pvpPointsChange);
			//console.log(battleResults.pvpPointsChange === 0);
			//console.log(battleResults.pvpPointsChange !== 0);
			//console.log(battleResults.pvpPointsChange == 0);
			if (battleResults.pvpPointsChange !== 0) {
				//console.log(
				// 	"PVP Points change non zero: ",
				// 	battleResults.pvpPointsChange
				// );
				if (battleResults.didPlayer1Win) {
					userGainedPvpPoints = battleResults.pvpPointsChange;
					enemyGainedPvpPoints = -battleResults.pvpPointsChange;
				} else {
					userGainedPvpPoints = -battleResults.pvpPointsChange;
					enemyGainedPvpPoints = battleResults.pvpPointsChange;
				}
			}
			//console.log("User gained PVP points: ", userGainedPvpPoints);
			//console.log("Enemy gained PVP points: ", enemyGainedPvpPoints);
			let pointDiff = Math.abs(
				battleResults.p1PvpPoints - battleResults.p2PvpPoints
			);

			if (userGainedPvpPoints == 0 && pointDiff >= 200) {
				if (battleResults.p1PvpPoints > battleResults.p2PvpPoints) {
					lines.push({
						text: `Enemy's PVP Title too low to earn points!`,
						player: "results",
						details: ``,
						detailsInfo: ``,
						detailsp2: ``,
					});
					sounds.push(""); // No sound for this line
				} else {
					lines.push({
						text: `Enemy's PVP Title too high to earn points!`,
						player: "results",
						details: ``,
						detailsInfo: ``,
						detailsp2: ``,
					});
					sounds.push(""); // No sound for this line
				}
			} else {
				//console.log("PVP Points string building");
				let userDiffString = userGainedPvpPoints > 0 ? "+" : "";
				userDiffString = userGainedPvpPoints === 0 ? "" : userDiffString;
				let enemyDiffString =
					enemyGainedPvpPoints > 0 ? "Enemy Gained" : "Enemy Lost";
				let userEndString = userGainedPvpPoints != 0 ? "PVP Points!" : "";
				let enemyEndString = userGainedPvpPoints == 0 ? "PVP Points!" : "";
				userGainedPvpPoints =
					userGainedPvpPoints === 0 ? "" : userGainedPvpPoints;
				enemyDiffString = enemyGainedPvpPoints === 0 ? "" : enemyDiffString;
				enemyGainedPvpPoints =
					enemyGainedPvpPoints === 0 ? "" : enemyGainedPvpPoints;

				let pvpRankData = getPvpRankData(
					battleResults.p1PvpPoints,
					pvpPointTiers
				);

				let pointsStringFull =
					pvpRankData[0] === "MAX"
						? `You are the Apex!`
						: `${pvpRankData[0]} to ${pvpRankData[2]}! `;

				if (isGuestMode) {
					enemyDiffString = "";
					enemyGainedPvpPoints = "";
					enemyEndString = "";
				}
				lines.push({
					text: `${userDiffString} ${userGainedPvpPoints} ${userEndString}  ${enemyDiffString} ${enemyGainedPvpPoints} ${enemyEndString}`,
					player: "results",
					details: ``,
				});
				sounds.push(""); // No sound for this line
				lines.push({
					text: `You are ${pvpRankData[1]}, ${pointsStringFull}`,
					player: "results",
					details: ``,
					detailsInfo: ``,
					detailsp2: ``,
				});
				sounds.push(""); // No sound for this line
			}
			//console.log("Battle lines length: ", lines.length);
			//console.log("Battle lines ", lines);
			return lines;
		};

		const getPvpRankData = (pvpPoints, pvpPointTiers) => {
			let pvpTitle = "";
			let pvpPointsRemaining = 0;
			let nextPvpTitle = "";

			if (pvpPoints >= pvpPointTiers[10]) {
				pvpPointsRemaining = "MAX";
			} else if (pvpPoints >= pvpPointTiers[9]) {
				pvpPointsRemaining = pvpPointTiers[10] - pvpPoints;
				pvpTitle = pvpRankTitles[9];
				nextPvpTitle = pvpRankTitles[10];
			} else if (pvpPoints >= pvpPointTiers[8]) {
				pvpPointsRemaining = pvpPointTiers[9] - pvpPoints;
				pvpTitle = pvpRankTitles[8];
				nextPvpTitle = pvpRankTitles[9];
			} else if (pvpPoints >= pvpPointTiers[7]) {
				pvpPointsRemaining = pvpPointTiers[8] - pvpPoints;
				pvpTitle = pvpRankTitles[7];
				nextPvpTitle = pvpRankTitles[8];
			} else if (pvpPoints >= pvpPointTiers[6]) {
				pvpPointsRemaining = pvpPointTiers[7] - pvpPoints;
				pvpTitle = pvpRankTitles[6];
				nextPvpTitle = pvpRankTitles[7];
			} else if (pvpPoints >= pvpPointTiers[5]) {
				pvpPointsRemaining = pvpPointTiers[6] - pvpPoints;
				pvpTitle = pvpRankTitles[5];
				nextPvpTitle = pvpRankTitles[6];
			} else if (pvpPoints >= pvpPointTiers[4]) {
				pvpPointsRemaining = pvpPointTiers[5] - pvpPoints;
				pvpTitle = pvpRankTitles[4];
				nextPvpTitle = pvpRankTitles[5];
			} else if (pvpPoints >= pvpPointTiers[3]) {
				pvpPointsRemaining = pvpPointTiers[4] - pvpPoints;
				pvpTitle = pvpRankTitles[3];
				nextPvpTitle = pvpRankTitles[4];
			} else if (pvpPoints >= pvpPointTiers[2]) {
				pvpPointsRemaining = pvpPointTiers[3] - pvpPoints;
				pvpTitle = pvpRankTitles[2];
				nextPvpTitle = pvpRankTitles[3];
			} else if (pvpPoints >= pvpPointTiers[1]) {
				pvpPointsRemaining = pvpPointTiers[2] - pvpPoints;
				pvpTitle = pvpRankTitles[1];
				nextPvpTitle = pvpRankTitles[2];
			} else if (pvpPoints >= pvpPointTiers[0]) {
				pvpPointsRemaining = pvpPointTiers[1] - pvpPoints;
				pvpTitle = pvpRankTitles[0];
				nextPvpTitle = pvpRankTitles[1];
			}
			return [pvpPointsRemaining, pvpTitle, nextPvpTitle];
		};

		useEffect(() => {
			if (
				battleResultsLines.length > 0 &&
				displayedLines.length < battleResultsLines.length
			) {
				const timer = setTimeout(() => {
					const nextLineIndex = displayedLines.length;
					setDisplayedLines(battleResultsLines.slice(0, nextLineIndex + 1));
					playSound(audioUrls[nextLineIndex]); // Play sound for the next line

					if (battleResults.battlePending) {
						// Increase timeout length by 1 second for each line
						setTimeoutLength(
							(prevTimeoutLength) => prevTimeoutLength + defaultTimeout
						);
					}
				}, timeoutLength);

				if (
					displayedLines.length === battleResultsLines.length - 1 &&
					!battleResults.battlePending
				) {
					// this means that the last line has been displayed
					// and the battle is not pending so its over
					onBattleDisplayCompletion();
				}

				return () => clearTimeout(timer);
			}
		}, [displayedLines, battleResultsLines, audioUrls, timeoutLength]);

		// Function to play sound
		const playSound = (audioUrl) => {
			if (isBattleSoundEnabled && audioUrl) {
				const audio = new Audio(SmartURL(audioUrl));
				audio.play();
			}
		};

		// -----------------------------------
		// extract width and height from style
		const width = style?.width || "100%";
		const height = style?.height || "100%";

		const widthValue = parseFloat(width);
		const widthUnit = width.replace(/[0-9.]/g, "");
		const heightValue = parseFloat(height);
		const heightUnit = height.replace(/[0-9.]/g, "");

		// Extract fontSize from style
		const fontSize = style?.fontSize || "1em"; // Default to '1em' if fontSize isn't provided
		const fontSizeValue = parseFloat(fontSize); // Convert fontSize string to a number
		const fontSizeUnit = fontSize.replace(/[0-9.]/g, ""); // Extract the unit (em, px, etc.)

		const propertiesFontScale = 0.6;
		const propertiesFontSize = `${
			fontSizeValue * propertiesFontScale
		}${fontSizeUnit}`;

		const calculatedMarginLeftValue = fontSizeValue * 1.5;
		const calculatedMarginLeft = `${calculatedMarginLeftValue}${fontSizeUnit}`;

		const calculatedTitleMarginTop = `${
			fontSizeValue * 0.5 * 0.98
		}${fontSizeUnit}`;

		const calculatedPropertiesMarginTop1Value = fontSizeValue * 1.6;
		const calculatedPropertiesMarginTop1 = `${calculatedPropertiesMarginTop1Value}${fontSizeUnit}`;

		const titleStyle = {
			...style, // Spread the existing style props
			marginTop: calculatedTitleMarginTop,
			marginLeft: calculatedMarginLeft,
			marginRight: calculatedMarginLeft,
			height: fontSize,
			zIndex: 5,
			// pointerEvents: "none",
			overflow: "visible",
			textAlign: "center",
			// set width to width minus margin left , and use the same unit
			width: `${widthValue - calculatedMarginLeftValue}${widthUnit}`,
			color: fontColorHighlight,
		};

		const propertiesStyle1 = {
			...style, // Spread the existing style props
			marginTop: calculatedPropertiesMarginTop1,
			marginLeft: calculatedMarginLeft,
			marginRight: calculatedMarginLeft,
			fontSize: propertiesFontSize,
			zIndex: 5,
			// pointerEvents: "none",
			overflow: "visible",
			// set width to width minus margin left , and use the same unit
			width: `${widthValue - calculatedMarginLeftValue}${widthUnit}`,
			// set the height to the height minus the first margin top, and use the same unit
			height: `${
				heightValue - calculatedPropertiesMarginTop1Value
			}${heightUnit}`,
		};

		const paragraphStyle = {
			player1: {
				textAlign: "left",
				// marginTop: "0.15rem",
				marginBottom: "0",
				color: fontColorHighlight,
			},
			player2: {
				textAlign: "right",
				// marginTop: "0.15rem",
				marginBottom: "0",
				marginRight: calculatedMarginLeft,
				color: fontColorHighlightEnemy,
			},
			info: {
				textAlign: "center",
				marginTop: "0",
				marginBottom: "0",
				color: fontColorHighlight,
			},
			results: {
				textAlign: "center",
				marginTop: "0",
				marginBottom: "0.35rem",
				color: fontColorHighlight,
			},
		};

		const detailsStyle = {
			color: sharedDefaultFontColor,
			// fontSize: `${fontSizeValue * propertiesFontScale * 0.8}${fontSizeUnit}`,
			fontStyle: "italic",
		};

		return (
			<div id="brainWormsBattleStatsDisplay">
				<h2 style={titleStyle}>{battleResultsTitle}</h2>
				<div
					style={propertiesStyle1}
					onMouseEnter={() => setShowDetails(true)}
					onMouseLeave={() => setShowDetails(false)}
				>
					{displayedLines.map((line, index) => (
						<div key={index}>
							<p style={paragraphStyle[line.player]}>{line.text}</p>
							<p
								style={{ ...paragraphStyle[line.player], marginTop: "-0.5em" }}
							>
								{(line.details || line.detailsp2) && (
									<span
										style={{
											...detailsStyle,
											opacity: showDetails ? "1" : "0",
										}}
									>
										<>
											<span style={{ color: sharedDefaultFontColor }}>
												{line.details}
											</span>
											{line.detailsInfo && (
												<span style={{ color: fontColorHighlight }}>
													{" "}
													{line.detailsInfo}
												</span>
											)}{" "}
											<span style={{ color: sharedDefaultEnemyFontColor }}>
												{line.detailsp2}
											</span>
										</>
									</span>
								)}
							</p>
						</div>
					))}
				</div>
			</div>
		);
	}
);

export default BrainWormsBattleStatsDisplay;
