// GridCard.js
import React from "react";
import SmartImg from "./SmartImg";

const GridCard = ({ imageSrc, heading, caption, link }) => {
	return (
		<div className="grid-card">
			<a href={link}>
				<SmartImg src={imageSrc} alt={heading} />
				{heading && <h2>{heading}</h2>}
				<p className="grid-card-caption">{caption}</p>
			</a>
		</div>
	);
};

export default GridCard;
