import React, { useState, useEffect } from "react";
import Article from "./Article";
import captchaImages from "./captchas";
import SmartImg from "./SmartImg";
import SmartURL from "./SmartURL";

import WalletConnect from "./WalletConnect";
import ClaimToken from "./ClaimToken";

const PsynetRegister = () => {
	const [currentStep, setCurrentStep] = useState("intro");

	const [fullNameInput, setFullNameInput] = useState("");
	const [dateOfBirthInput, setDateOfBirthInput] = useState("");
	const [contactEmailInput, setContactEmailInput] = useState("");
	const [contactPhoneInput, setContactPhoneInput] = useState("");
	const [addressInput, setAddressInput] = useState("");
	const [cityInput, setCityInput] = useState("");
	const [stateInput, setStateInput] = useState("");
	const [zipCodeInput, setZipCodeInput] = useState("");
	const [countryInput, setCountryInput] = useState("");
	const [ethnicityInput, setEthnicityInput] = useState("");
	const [bloodTypeInput, setBloodTypeInput] = useState("");
	const [genderIdentityInput, setGenderIdentityInput] = useState("");
	const [sexualPreferenceInput, setSexualPreferenceInput] = useState("");
	const [iqInput, setIQInput] = useState("");
	const [educationInput, setEducationInput] = useState("");
	const [organDonorInput, setOrganDonorInput] = useState("");
	const [emergencyContactNameInput, setEmergencyContactNameInput] =
		useState("");
	const [
		emergencyContactRelationshipInput,
		setEmergencyContactRelationshipInput,
	] = useState("");
	const [emergencyContactPhoneInput, setEmergencyContactPhoneInput] =
		useState("");

	const [userInput, setUserInput] = useState("");
	const [showIntro, setShowIntro] = useState(true);
	const [showCommitment, setShowCommitment] = useState(false);
	const [showCaptcha, setShowCaptcha] = useState(false);
	const [captchaImage, setCaptchaImage] = useState("");
	const [showFinal, setShowFinal] = useState(false);
	const [showArchival, setShowArchival] = useState(false);

	const [showlegalText, setShowlegalText] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [showError, setShowError] = useState(false);

	const getRandomCaptcha = () => {
		const randomIndex = Math.floor(Math.random() * captchaImages.length);
		return captchaImages[randomIndex];
	};

	useEffect(() => {
		const savedStep = localStorage.getItem("onboardingStep");

		if (savedStep) {
			switch (savedStep) {
				case "commitment":
					setShowIntro(false);
					setShowCommitment(true);
					break;
				case "captcha":
					setShowIntro(false);
					setShowCommitment(false);
					setShowCaptcha(true);
					break;
				case "legalText":
					setShowIntro(false);
					setShowCommitment(false);
					setShowCaptcha(false);
					setShowlegalText(true);
					break;
				default:
					break;
			}
			setCurrentStep(savedStep);
		}
	}, []);

	const startOver = () => {
		setShowIntro(true);
		setShowCommitment(false);
		setShowCaptcha(false);
		setShowlegalText(false);

		setCaptchaImage(getRandomCaptcha());
		setUserInput("");
		setUserInputAccessCode("");
		setCommitmentInput("");
		setErrorMessage("");
		setShowError(false);

		setCurrentStep("intro");
		localStorage.removeItem("onboardingStep");
	};

	useEffect(() => {
		setCaptchaImage(getRandomCaptcha());
	}, []);

	const handleIntroSubmit = () => {
		setShowIntro(false);
		setShowCaptcha(true);
		setCurrentStep("captcha");
		localStorage.setItem("onboardingStep", "captcha");
		window.scrollTo(0, 0);
	};

	const handleCommitmentSubmit = () => {
		setShowCommitment(false);
		setShowlegalText(true);
		setCurrentStep("legalText");
		localStorage.setItem("onboardingStep", "legalText");
		window.scrollTo(0, 0);
	};

	const handleLegalSubmit = () => {
		setShowlegalText(false);
		setShowFinal(true);
		setCurrentStep("final");
		localStorage.setItem("onboardingStep", "final");
		window.scrollTo(0, 0);
	};
	const handleFinalSubmit = () => {
		setShowFinal(false);
		setShowArchival(true);
		setCurrentStep("archival");
		localStorage.setItem("onboardingStep", "archival");
		window.scrollTo(0, 0);
	};

	const handleInputChange = (e) => {
		setUserInput(e.target.value.toLowerCase());
	};

	const handleRefreshCaptcha = () => {
		setUserInput("");
		setCaptchaImage(getRandomCaptcha());
	};

	const handleSubmitCaptcha = () => {
		const captchaSolution = captchaImage
			.split("/")
			.pop()
			.split(".")[0]
			.toLowerCase()
			.replace(/_/g, " ");

		if (userInput === captchaSolution) {
			setShowCaptcha(false);
			setShowCommitment(true);
			setCurrentStep("commitment"); // Added this line
			localStorage.setItem("onboardingStep", "commitment"); // Added this line
			window.scrollTo(0, 0);
		} else {
			setErrorMessage("Incorrect solution.");
			setShowError(true);
			setUserInput("");
			setCaptchaImage(getRandomCaptcha());
		}
	};

	useEffect(() => {
		if (showError) {
			const timer = setTimeout(() => {
				setShowError(false);
			}, 1000);

			return () => clearTimeout(timer);
		}
	}, [showError]);

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<div className="onboarding">
				{showIntro ? (
					<>
						<Article
							title="Register for PsyNet - Shape the Future"
							content={[
								"Prospective candidates, including members of the public and family members of PsyNet program participants, are invited to apply to the PsyNet Research Program. Participants will be among the initial individuals to undergo the integration of their minds with advanced artificial intelligence. Please note, this procedure is irreversible, and involves the permanent installation of the PsyNet brain-computer interface prototype and genetic modifications to the participant's brain and other biological systems. Consequently, several terms and conditions govern participation.",
							]}
							titleLevel={1}
						/>
						<button onClick={handleIntroSubmit}>Begin Registration</button>
					</>
				) : showCommitment ? (
					<>
						<Article
							title="Registration Details"
							content={["Please provide the following required information:"]}
							titleLevel={1}
						/>
						<form className="visitor-form">
							<label htmlFor="fullName">Full Name:</label>
							<input
								type="text"
								id="fullName"
								onChange={(e) => setFullNameInput(e.target.value)}
								value={fullNameInput}
							/>

							<label htmlFor="dateOfBirth">Date of Birth:</label>
							<input
								type="text"
								id="dateOfBirth"
								onChange={(e) => setDateOfBirthInput(e.target.value)}
								value={dateOfBirthInput}
							/>

							<label htmlFor="contactEmail">Contact Email:</label>
							<input
								type="text"
								id="contactEmail"
								onChange={(e) => setContactEmailInput(e.target.value)}
								value={contactEmailInput}
							/>

							<label htmlFor="contactPhone">Contact Phone Number:</label>
							<input
								type="text"
								id="contactPhone"
								onChange={(e) => setContactPhoneInput(e.target.value)}
								value={contactPhoneInput}
							/>

							<label htmlFor="address">Address:</label>
							<input
								type="text"
								id="address"
								onChange={(e) => setAddressInput(e.target.value)}
								value={addressInput}
							/>

							<label htmlFor="city">City:</label>
							<input
								type="text"
								id="city"
								onChange={(e) => setCityInput(e.target.value)}
								value={cityInput}
							/>

							<label htmlFor="state">State/Province:</label>
							<input
								type="text"
								id="state"
								onChange={(e) => setStateInput(e.target.value)}
								value={stateInput}
							/>

							<label htmlFor="zipCode">Zip Code/Postal Code:</label>
							<input
								type="text"
								id="zipCode"
								onChange={(e) => setZipCodeInput(e.target.value)}
								value={zipCodeInput}
							/>

							<label htmlFor="country">Country:</label>
							<input
								type="text"
								id="country"
								onChange={(e) => setCountryInput(e.target.value)}
								value={countryInput}
							/>

							{/* Ethnicity */}
							<label htmlFor="ethnicity">Ethnicity:</label>
							<select
								id="ethnicity"
								onChange={(e) => setEthnicityInput(e.target.value)}
								value={ethnicityInput}
							>
								<option value="">Select</option>
								<option value="African American">African American</option>
								<option value="Albanian">Albanian</option>
								<option value="Algerian">Algerian</option>
								<option value="American Indian">American Indian</option>
								<option value="Andorran">Andorran</option>
								<option value="Angolan">Angolan</option>
								<option value="Antiguans">Antiguans</option>
								<option value="Argentinean">Argentinean</option>
								<option value="Armenian">Armenian</option>
								<option value="Australian">Australian</option>
								<option value="Austrian">Austrian</option>
								<option value="Azerbaijani">Azerbaijani</option>
								<option value="Bahamian">Bahamian</option>
								<option value="Bahraini">Bahraini</option>
								<option value="Bangladeshi">Bangladeshi</option>
								<option value="Barbadian">Barbadian</option>
								<option value="Barbudans">Barbudans</option>
								<option value="Belarusian">Belarusian</option>
								<option value="Belgian">Belgian</option>
								<option value="Belizean">Belizean</option>
								<option value="Beninese">Beninese</option>
								<option value="Bhutanese">Bhutanese</option>
								<option value="Bolivian">Bolivian</option>
								<option value="Bosnian">Bosnian</option>
								<option value="Brazilian">Brazilian</option>
								<option value="British">British</option>
								<option value="Bruneian">Bruneian</option>
								<option value="Bulgarian">Bulgarian</option>
								<option value="Burkinabe">Burkinabe</option>
								<option value="Burmese">Burmese</option>
								<option value="Burundian">Burundian</option>
								<option value="Cambodian">Cambodian</option>
								<option value="Cameroonian">Cameroonian</option>
								<option value="Canadian">Canadian</option>
								<option value="Cape Verdean">Cape Verdean</option>
								<option value="Central African">Central African</option>
								<option value="Chadian">Chadian</option>
								<option value="Chilean">Chilean</option>
								<option value="Chinese">Chinese</option>
								<option value="Colombian">Colombian</option>
								<option value="Comoran">Comoran</option>
								<option value="Congolese">Congolese</option>
								<option value="Costa Rican">Costa Rican</option>
								<option value="Croatian">Croatian</option>
								<option value="Cuban">Cuban</option>
								<option value="Cypriot">Cypriot</option>
								<option value="Czech">Czech</option>
								<option value="Danish">Danish</option>
								<option value="Djibouti">Djibouti</option>
								<option value="Dominican">Dominican</option>
								<option value="Dutch">Dutch</option>
								<option value="East Timorese">East Timorese</option>
								<option value="Ecuadorean">Ecuadorean</option>
								<option value="Egyptian">Egyptian</option>
								<option value="Emirian">Emirian</option>
								<option value="Equatorial Guinean">Equatorial Guinean</option>
								<option value="Eritrean">Eritrean</option>
								<option value="Estonian">Estonian</option>
								<option value="Ethiopian">Ethiopian</option>
								<option value="Fijian">Fijian</option>
								<option value="Filipino">Filipino</option>
								<option value="Finnish">Finnish</option>
								<option value="French">French</option>
								<option value="Gabonese">Gabonese</option>
								<option value="Gambian">Gambian</option>
								<option value="Georgian">Georgian</option>
								<option value="German">German</option>
								<option value="Ghanaian">Ghanaian</option>
								<option value="Greek">Greek</option>
								<option value="Grenadian">Grenadian</option>
								<option value="Guatemalan">Guatemalan</option>
								<option value="Guinea-Bissauan">Guinea-Bissauan</option>
								<option value="Guinean">Guinean</option>
								<option value="Guyanese">Guyanese</option>
								<option value="Haitian">Haitian</option>
								<option value="Herzegovinian">Herzegovinian</option>
								<option value="Honduran">Honduran</option>
								<option value="Hungarian">Hungarian</option>
								<option value="I-Kiribati">I-Kiribati</option>
								<option value="Icelander">Icelander</option>
								<option value="Indian">Indian</option>
								<option value="Indonesian">Indonesian</option>
								<option value="Iranian">Iranian</option>
								<option value="Iraqi">Iraqi</option>
								<option value="Irish">Irish</option>
								<option value="Israeli">Israeli</option>
								<option value="Italian">Italian</option>
								<option value="Ivorian">Ivorian</option>
								<option value="Jamaican">Jamaican</option>
								<option value="Japanese">Japanese</option>
								<option value="Jordanian">Jordanian</option>
								<option value="Kazakhstani">Kazakhstani</option>
								<option value="Kenyan">Kenyan</option>
								<option value="Kittian and Nevisian">
									Kittian and Nevisian
								</option>
								<option value="Kuwaiti">Kuwaiti</option>
								<option value="Kyrgyz">Kyrgyz</option>
								<option value="Laotian">Laotian</option>
								<option value="Latvian">Latvian</option>
								<option value="Lebanese">Lebanese</option>
								<option value="Liberian">Liberian</option>
								<option value="Libyan">Libyan</option>
								<option value="Liechtensteiner">Liechtensteiner</option>
								<option value="Lithuanian">Lithuanian</option>
								<option value="Luxembourger">Luxembourger</option>
								<option value="Macedonian">Macedonian</option>
								<option value="Malagasy">Malagasy</option>
								<option value="Malawian">Malawian</option>
								<option value="Malaysian">Malaysian</option>
								<option value="Maldivan">Maldivan</option>
								<option value="Malian">Malian</option>
								<option value="Maltese">Maltese</option>
								<option value="Marshallese">Marshallese</option>
								<option value="Mauritanian">Mauritanian</option>
								<option value="Mauritian">Mauritian</option>
								<option value="Mexican">Mexican</option>
								<option value="Micronesian">Micronesian</option>
								<option value="Moldovan">Moldovan</option>
								<option value="Monacan">Monacan</option>
								<option value="Mongolian">Mongolian</option>
								<option value="Moroccan">Moroccan</option>
								<option value="Mosotho">Mosotho</option>
								<option value="Motswana">Motswana</option>
								<option value="Mozambican">Mozambican</option>
								<option value="Namibian">Namibian</option>
								<option value="Nauruan">Nauruan</option>
								<option value="Nepalese">Nepalese</option>
								<option value="New Zealander">New Zealander</option>
								<option value="Nicaraguan">Nicaraguan</option>
								<option value="Nigerian">Nigerian</option>
								<option value="Nigerien">Nigerien</option>
								<option value="North Korean">North Korean</option>
								<option value="Northern Irish">Northern Irish</option>
								<option value="Norwegian">Norwegian</option>
								<option value="Omani">Omani</option>
								<option value="Pakistani">Pakistani</option>
								<option value="Palauan">Palauan</option>
								<option value="Panamanian">Panamanian</option>
								<option value="Papua New Guinean">Papua New Guinean</option>
								<option value="Paraguayan">Paraguayan</option>
								<option value="Peruvian">Peruvian</option>
								<option value="Polish">Polish</option>
								<option value="Portuguese">Portuguese</option>
								<option value="Qatari">Qatari</option>
								<option value="Romanian">Romanian</option>
								<option value="Russian">Russian</option>
								<option value="Rwandan">Rwandan</option>
								<option value="Saint Lucian">Saint Lucian</option>
								<option value="Salvadoran">Salvadoran</option>
								<option value="Samoan">Samoan</option>
								<option value="San Marinese">San Marinese</option>
								<option value="Sao Tomean">Sao Tomean</option>
								<option value="Saudi">Saudi</option>
								<option value="Scottish">Scottish</option>
								<option value="Senegalese">Senegalese</option>
								<option value="Serbian">Serbian</option>
								<option value="Seychellois">Seychellois</option>
								<option value="Sierra Leonean">Sierra Leonean</option>
								<option value="Singaporean">Singaporean</option>
								<option value="Slovakian">Slovakian</option>
								<option value="Slovenian">Slovenian</option>
								<option value="Solomon Islander">Solomon Islander</option>
								<option value="Somali">Somali</option>
								<option value="South African">South African</option>
								<option value="South Korean">South Korean</option>
								<option value="Spanish">Spanish</option>
								<option value="Sri Lankan">Sri Lankan</option>
								<option value="Sudanese">Sudanese</option>
								<option value="Surinamer">Surinamer</option>
								<option value="Swazi">Swazi</option>
								<option value="Swedish">Swedish</option>
								<option value="Swiss">Swiss</option>
								<option value="Syrian">Syrian</option>
								<option value="Taiwanese">Taiwanese</option>
								<option value="Tajik">Tajik</option>
								<option value="Tanzanian">Tanzanian</option>
								<option value="Thai">Thai</option>
								<option value="Togolese">Togolese</option>
								<option value="Tongan">Tongan</option>
								<option value="Trinidadian or Tobagonian">
									Trinidadian or Tobagonian
								</option>
								<option value="Tunisian">Tunisian</option>
								<option value="Turkish">Turkish</option>
								<option value="Tuvaluan">Tuvaluan</option>
								<option value="Ugandan">Ugandan</option>
								<option value="Ukrainian">Ukrainian</option>
								<option value="Uruguayan">Uruguayan</option>
								<option value="Uzbekistani">Uzbekistani</option>
								<option value="Venezuelan">Venezuelan</option>
								<option value="Vietnamese">Vietnamese</option>
								<option value="Welsh">Welsh</option>
								<option value="Yemenite">Yemenite</option>
								<option value="Zambian">Zambian</option>
								<option value="Zimbabwean">Zimbabwean</option>
							</select>

							{/* Blood Type */}
							<label htmlFor="bloodType">Blood Type:</label>
							<select
								id="bloodType"
								onChange={(e) => setBloodTypeInput(e.target.value)}
								value={bloodTypeInput}
							>
								<option value="">Select</option>
								<option value="A+">A+</option>
								<option value="A-">A-</option>
								<option value="B+">B+</option>
								<option value="B-">B-</option>
								<option value="AB+">AB+</option>
								<option value="AB-">AB-</option>
								<option value="O+">O+</option>
								<option value="O-">O-</option>
							</select>

							{/* Gender */}
							<label htmlFor="genderIdentity">Gender Identity:</label>
							<select
								id="genderIdentity"
								onChange={(e) => setGenderIdentityInput(e.target.value)}
								value={genderIdentityInput}
							>
								<option value="">Select</option>
								<option value="Male">Male</option>
								<option value="Female">Female</option>
								<option value="Transgender">Transgender</option>
								<option value="Non-binary">Non-binary</option>
								<option value="Genderqueer">Genderqueer</option>
								<option value="Genderfluid">Genderfluid</option>
								<option value="Agender">Agender</option>
								<option value="Bigender">Bigender</option>
								<option value="Two-spirit">Two-spirit</option>
								<option value="Other">Other</option>
							</select>

							{/* Sex */}
							{/* Sexual Preference */}
							<label htmlFor="sexualPreference">Sexual Preference:</label>
							<select
								id="sexualPreference"
								onChange={(e) => setSexualPreferenceInput(e.target.value)}
								value={sexualPreferenceInput}
							>
								<option value="">Select</option>
								<option value="Heterosexual">Heterosexual</option>
								<option value="Homosexual">Homosexual</option>
								<option value="Bisexual">Bisexual</option>
								<option value="Pansexual">Pansexual</option>
								<option value="Asexual">Asexual</option>
								<option value="Queer">Queer</option>
								<option value="Demisexual">Demisexual</option>
								<option value="Sapiosexual">Sapiosexual</option>
								<option value="Androsexual">Androsexual</option>
								<option value="Gynosexual">Gynosexual</option>
								<option value="Polysexual">Polysexual</option>
								<option value="Skoliosexual">Skoliosexual</option>
								<option value="Autosexual">Autosexual</option>
								<option value="Graysexual">Graysexual</option>
								<option value="Lithromantic">Lithromantic</option>
								<option value="Demiromantic">Demiromantic</option>
								<option value="Biromantic">Biromantic</option>
								<option value="Panromantic">Panromantic</option>
								<option value="Aromantic">Aromantic</option>
								<option value="Homoromantic">Homoromantic</option>
								<option value="Heteroromantic">Heteroromantic</option>
								<option value="Queerplatonic">Queerplatonic</option>
								<option value="Polyromantic">Polyromantic</option>
								<option value="Cupioromantic">Cupioromantic</option>
								<option value="Reciprosexual">Reciprosexual</option>
								<option value="Recipromantic">Recipromantic</option>
								<option value="Allosexual">Allosexual</option>
								<option value="Alloromantic">Alloromantic</option>
								<option value="Ambisexual">Ambisexual</option>
								<option value="Ambiromantic">Ambiromantic</option>
								<option value="Akoisexual">Akoisexual</option>
								<option value="Akoirromantic">Akoirromantic</option>
								<option value="Ceterosexual">Ceterosexual</option>
								<option value="Ceteroromantic">Ceteroromantic</option>
								<option value="Fraysexual">Fraysexual</option>
								<option value="Frayromantic">Frayromantic</option>
								<option value="Acespike">Acespike</option>
								<option value="Demiace">Demiace</option>
								<option value="Demispike">Demispike</option>
								<option value="Demiflux">Demiflux</option>
								<option value="Heteroflexible">Heteroflexible</option>
								<option value="Homoflexible">Homoflexible</option>
								<option value="Panflexible">Panflexible</option>
								<option value="Aflexible">Aflexible</option>
								<option value="Queerflexible">Queerflexible</option>
								<option value="Other">Other</option>
							</select>

							{/* IQ */}
							<label htmlFor="iq">IQ:</label>
							<select
								id="iq"
								onChange={(e) => setIQInput(e.target.value)}
								value={iqInput}
							>
								<option value="">Select</option>
								<option value="Below 70">Below 70</option>
								<option value="70-89">70-89</option>
								<option value="90-109">90-109</option>
								<option value="110-129">110-129</option>
								<option value="130-149">130-149</option>
								<option value="150 and above">150 and above</option>
							</select>

							{/* Highest Education */}
							<label htmlFor="education">Highest Education:</label>
							<select
								id="education"
								onChange={(e) => setEducationInput(e.target.value)}
								value={educationInput}
							>
								<option value="">Select</option>
								<option value="No Formal Education">No Formal Education</option>
								<option value="Elementary School">Elementary School</option>
								<option value="Middle School">Middle School</option>
								<option value="High School">High School</option>
								<option value="Vocational School or Certificate">
									Vocational School or Certificate
								</option>
								<option value="Associate's Degree">Associate's Degree</option>
								<option value="Bachelor's Degree">Bachelor's Degree</option>
								<option value="Master's Degree">Master's Degree</option>
								<option value="Doctorate or PhD">Doctorate or PhD</option>
								<option value="Other Postgraduate Degree">
									Other Postgraduate Degree
								</option>
								{/* Add more education options here if needed */}
							</select>

							{/* Organ Donor Status */}
							<label htmlFor="organDonor">Organ Donor Status:</label>
							<select
								id="organDonor"
								onChange={(e) => setOrganDonorInput(e.target.value)}
								value={organDonorInput}
							>
								<option value="">Select</option>
								<option value="Yes">Yes</option>
								<option value="No">No</option>
								{/* Add more organ donor options here */}
							</select>

							{/* Emergency Contact Name */}
							<label htmlFor="emergencyContactName">
								Emergency Contact Name:
							</label>
							<input
								type="text"
								id="emergencyContactName"
								onChange={(e) => setEmergencyContactNameInput(e.target.value)}
								value={emergencyContactNameInput}
							/>

							{/* Emergency Contact Relationship */}
							<label htmlFor="emergencyContactRelationship">
								Emergency Contact Relationship:
							</label>
							<input
								type="text"
								id="emergencyContactRelationship"
								onChange={(e) =>
									setEmergencyContactRelationshipInput(e.target.value)
								}
								value={emergencyContactRelationshipInput}
							/>

							{/* Emergency Contact Phone */}
							<label htmlFor="emergencyContactPhone">
								Emergency Contact Phone:
							</label>
							<input
								type="text"
								id="emergencyContactPhone"
								onChange={(e) => setEmergencyContactPhoneInput(e.target.value)}
								value={emergencyContactPhoneInput}
							/>
						</form>

						<button onClick={handleCommitmentSubmit}>Submit</button>

						{showError ? (
							<div className="error-message fade-in">{errorMessage}</div>
						) : (
							<div className="error-message fade-out">{errorMessage}</div>
						)}
					</>
				) : showCaptcha ? (
					<>
						<Article
							title="Proof of cognition."
							content={[
								"Solve the captcha below to verify you are a cognitive entity.",
							]}
							titleLevel={1}
						/>

						<div className="captchaContainer">
							<SmartImg
								className="captchaImg"
								src={captchaImage}
								alt="CAPTCHA"
							/>
							<input
								type="text"
								onChange={handleInputChange}
								value={userInput}
							/>
							<button onClick={handleRefreshCaptcha}>Refresh</button>
							<button onClick={handleSubmitCaptcha}>Submit</button>
							{showError ? (
								<div className="error-message fade-in">{errorMessage}</div>
							) : (
								<div className="error-message fade-out">{errorMessage}</div>
							)}
						</div>
					</>
				) : showlegalText ? (
					<>
						<Article
							title={<strong>PsyNet Registration Terms and Conditions</strong>}
							content={[
								<p>
									<strong>1. Definitions</strong>
								</p>,
								<p>
									1.1. <strong>PsyNet:</strong> Refers to the brain-computer
									interface program developed by Del Complex, including all
									associated technologies, procedures, and modifications.
								</p>,
								<p>
									1.2. <strong>Participant:</strong> Any individual who
									registers for the PsyNet program or undergoes the PsyNet
									procedure, including members of the public and family members
									of participants.
								</p>,
								<p>
									1.3. <strong>Genetic Modification:</strong> The process of
									altering DNA within specific brain regions using CRISPR-based
									gene editing techniques to imbue neurons with optogenetic
									qualities.
								</p>,
								<p>
									1.4. <strong>Del Complex:</strong> The organization
									responsible for the development and administration of the
									PsyNet program.
								</p>,
								<p>
									<strong>2. Acknowledgment of Risks</strong>
								</p>,
								<p>
									2.1. By registering for the PsyNet program, you acknowledge
									that participation involves a radical departure from
									conventional medical procedures. The core component of PsyNet
									is the 'Localized Genetic Modification' system, which
									fundamentally alters the genetic and biological stability of
									your brain. This includes the precise modification of DNA
									within specific brain regions, making neurons sensitive to
									light, and permitting the manipulation of brain circuits.
								</p>,
								<p>
									2.2. You understand and accept that these procedures carry
									inherent risks, including but not limited to:
								</p>,
								<ul>
									<li>Neurological changes</li>
									<li>Physical discomfort</li>
									<li>
										Risk of infection due to invasive surgery by the automated
										robotic surgical system.
									</li>
									<li>
										Risk of the body's immune system rejecting the implanted
										electrodes.
									</li>
									<li>
										Risk of loss of life or severe disability due to unforeseen
										consequences.
									</li>
								</ul>,
								<p>
									2.3. Del Complex provides no guarantee of specific outcomes or
									results, and you voluntarily assume all risks associated with
									participation in the PsyNet program.
								</p>,
								<p>
									<strong>3. Waiver of Liability</strong>
								</p>,
								<p>
									3.1. By participating in the PsyNet program, you waive any
									ability to sue Del Complex or its affiliates for any adverse
									effects or outcomes resulting from your involvement. This
									includes but is not limited to any physical, neurological, or
									psychological changes or harm that may occur.
								</p>,
								<p>
									<strong>4. Consent to Genetic Modification</strong>
								</p>,
								<p>
									4.1. You provide informed consent for the 'Localized Genetic
									Modification' process, which involves the precise alteration
									of DNA within your brain. This process is irreversible and may
									have long-term effects on your genetic makeup.
								</p>,
								<p>
									<strong>5. Data Privacy and Confidentiality</strong>
								</p>,
								<p>
									5.1. Del Complex will collect and process personal data as
									part of the PsyNet program. You consent to the collection,
									storage, and use of your personal information in accordance
									with Del Complex's privacy policy.
								</p>,
								<p>
									<strong>6. Psychological and Emotional Impact</strong>
								</p>,
								<p>
									6.1. Participation in the PsyNet program may have
									psychological and emotional effects. You acknowledge that the
									procedure may lead to changes in mood, behavior, or cognitive
									functioning.
								</p>,
								<p>
									<strong>7. Long-term Effects</strong>
								</p>,
								<p>
									7.1. The long-term effects of the PsyNet procedure are not
									fully understood. You acknowledge that there may be
									unanticipated consequences that become apparent over time.
								</p>,
								<p>
									<strong>8. Follow-up and Monitoring</strong>
								</p>,
								<p>
									8.1. Del Complex may require ongoing monitoring and
									assessments of participants. You agree to participate in
									follow-up assessments as requested by Del Complex.
								</p>,
								<p>
									<strong>9. Withdrawal</strong>
								</p>,
								<p>
									9.1. You have the right to withdraw from the PsyNet program at
									any time. However, you understand that the genetic
									modifications made are irreversible.
								</p>,
								<p>
									<strong>10. Intellectual Property</strong>
								</p>,
								<p>
									10.1. Any intellectual property rights arising from the PsyNet
									program, including research findings, inventions, and
									discoveries, belong to Del Complex.
								</p>,
								<p>
									<strong>11. Indemnification</strong>
								</p>,
								<p>
									11.1. You agree to indemnify and hold Del Complex harmless
									from any claims, damages, or liabilities arising from your
									participation in the PsyNet program.
								</p>,
								<p>
									<strong>12. Ethical Considerations</strong>
								</p>,
								<p>
									12.1. Del Complex is committed to ethical research and
									practices. If you believe there are ethical concerns related
									to the PsyNet program, you have the right to report them.
								</p>,
								<p>
									<strong>13. Modification of Terms</strong>
								</p>,
								<p>
									13.1. Del Complex reserves the right to modify these terms and
									conditions at any time. You will be notified of any changes,
									and your continued participation constitutes acceptance of the
									updated terms.
								</p>,
								<p>
									<strong>14. Communication</strong>
								</p>,
								<p>
									14.1. Del Complex may communicate with you via email or other
									electronic means. It is your responsibility to ensure you
									receive and respond to communication from Del Complex.
								</p>,
								<p>
									<strong>15. Severability</strong>
								</p>,
								<p>
									15.1. If any provision of these terms and conditions is found
									to be invalid or unenforceable, the remaining provisions shall
									remain in full force and effect.
								</p>,
							]}
							titleLevel={1}
						/>
						<button onClick={handleLegalSubmit}>I Agree</button>
					</>
				) : showFinal ? (
					<>
						<Article
							title="Thank you for registering."
							content={[
								"Your registration has been received. A Del Complex representative will contact you shortly to discuss the next steps.",
								"Please download the following consent form and bring it with you to your appointment:",
								<a
									href={SmartURL("/media/documents/PsyNet_Register.pdf")}
									target="_blank"
								>
									PsyNet Consent Form
								</a>,
								"Alternatively, you may choose to receive an archival copy of the consent form below:",
								<span onClick={handleFinalSubmit} style={{ cursor: "pointer" }}>
									<u>Request Archival Copy</u>
								</span>,
							]}
							titleLevel={1}
						/>
					</>
				) : showArchival ? (
					<>
						<Article
							title="Archival Copy Request"
							content={[
								"Del Complex is required to provide an archival copy of the consent form upon request. Please connect your identity to receive a copy.",
							]}
							titleLevel={1}
						/>
						<WalletConnect />
						<div className="spacerShort"></div>
						<div className="token-container-single">
							<ClaimToken
								claimId="65380592"
								imageSrc="/media/archival_media/psynet_register.png"
								fileSrc="/media/documents/PsyNet_Register.pdf"
								alt="PsyNet Consent Form"
							/>
						</div>
					</>
				) : (
					<></>
				)}
				{currentStep !== "intro" && (
					<button onClick={startOver}>Start Over</button>
				)}
				<div className="spacerShort"></div>
			</div>
		</div>
	);
};

export default PsynetRegister;
