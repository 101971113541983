// components/Web3Provider.js
import React, { useContext, useState, useEffect } from "react";
import { WagmiProvider, createConfig, http } from "wagmi";
import { mainnet, base } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { ThemeProvider, ThemeContext } from "../context/ThemeContext";
// import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
// import { CoinbaseWalletContext } from "./CoinbaseWalletContext";

const queryClient = new QueryClient();

const ConnectKitProviderWithTheme = ({ children }) => {
	const { theme } = useContext(ThemeContext);
	let oppositeTheme = theme === "light" ? "dark" : "light";

	return (
		<ConnectKitProvider
			theme="default"
			mode={oppositeTheme}
			customTheme={{
				"--ck-connectbutton-box-shadow": "0 0 0 0.1rem rgba(0, 0, 0, 1)",
				"--ck-connectbutton-font-size": "1.105rem",
				"--ck-font-family": "Source Code Pro",
				"--ck-connectbutton-width": "230px",
			}}
		>
			{children}
		</ConnectKitProvider>
	);
};

export const Web3Provider = ({ children, connectToBase }) => {
	const createWagmiConfig = (connectToBase) => {
		const chains = connectToBase ? [base, mainnet] : [mainnet, base];

		console.log("Creating Wagmi config with:", {
			mainnetAlchemy: !!process.env.REACT_APP_MAINNET_ALCHEMY_ID,
			baseAlchemy: !!process.env.REACT_APP_BASE_ALCHEMY_ID,
			walletConnect: !!process.env.REACT_APP_WALLETCONNECT_PROJECT_ID,
			chains: chains.map((c) => c.id),
		});

		return createConfig(
			getDefaultConfig({
				chains,
				transports: {
					[mainnet.id]: http(process.env.REACT_APP_MAINNET_ALCHEMY_ID),
					[base.id]: http(process.env.REACT_APP_BASE_ALCHEMY_ID),
				},
				walletConnectProjectId:
					process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || "",
				appName: "Del Complex",
				appDescription: "Alternate Reality Corporation",
				appUrl: "https://www.delcomplex.com",
				appIcon: "https://xiosky.com/delmedia/logo200x200.png",
			})
		);
	};

	const config = createWagmiConfig(connectToBase);

	return (
		<ThemeProvider>
			<WagmiProvider config={config}>
				<QueryClientProvider client={queryClient}>
					<ConnectKitProviderWithTheme>{children}</ConnectKitProviderWithTheme>
				</QueryClientProvider>
			</WagmiProvider>
		</ThemeProvider>
	);
};

export default Web3Provider;
