import React from "react";
import Article from "./Article";

const PortalHome = ({ newsArticles }) => {
	return (
		<div className="home">
			<Article title="Stay informed." titleLevel={1} />
			<div className="news-links">
				<div className="article">
					<p>Recent updates:</p>
					{newsArticles.map((article, index) => (
						<div key={index} className="news-link-container">
							<a href={`#article-${index}`}>{article.title}</a>
						</div>
					))}
				</div>
			</div>
			<hr />

			{newsArticles.map((ArticleComponent, index) => (
				<React.Fragment key={index}>
					<div id={`article-${index}`}>
						<ArticleComponent.component />
						<div className="article">
							<a href="#top"> &uarr;</a>
						</div>
					</div>
					{index < newsArticles.length - 1 && <hr />}
				</React.Fragment>
			))}
		</div>
	);
};

export default PortalHome;
