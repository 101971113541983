import React from "react";
import Article from "./Article";

const PortalJobOpportunities = () => {
	return (
		<div>
			<Article title="Moving autonomously." titleLevel={1} />

			<div className="article">
				<p>
					We are pleased to inform you that following the culmination of our Q3
					Hiring Event, Del Complex’s enhanced automated hiring system is now
					fully operational. This development underscores our ongoing commitment
					to implementing innovative solutions that drive efficiency, precision,
					and streamlined recruitment processes.
				</p>

				<p>
					Please begin your application via the{" "}
					<a href="/onboarding">onboarding portal</a>.
				</p>
				<p>
					Your interest is acknowledged and valued. We are confident that this
					new automated hiring system will facilitate a more efficient and
					effective recruitment process for all parties involved.
				</p>
			</div>
		</div>
	);
};

export default PortalJobOpportunities;
