import React, { useState, useEffect, useCallback } from "react";
import {
	useAccount,
	useSwitchChain,
	useChainId,
	usePublicClient,
	useWalletClient,
} from "wagmi";
import { base } from "wagmi/chains";
import { BrowserProvider } from "ethers";
import BrainWormsSimulator from "./BrainWormsSimulator";
import MusicPlayer from "./MusicPlayer";

const playlist = [
	"/media/brainworms/simulator/music/Nullsleep_Hidden_Patterns_(Deco_Mix).mp3",
	"/media/brainworms/simulator/music/Nullsleep_Strobed.mp3",
	"/media/brainworms/simulator/music/Nullsleep_Tabbed_Out_(demo).mp3",
	"/media/brainworms/simulator/music/Nullsleep_Breaking_Point.mp3",
	"/media/brainworms/simulator/music/Nullsleep_Body_Hammer_92.mp3",
];

const BrainWormsSimulatorLanding = React.memo(({ authenticated }) => {
	const [isMusicPlaying, setIsMusicPlaying] = useState(false);
	const [musicTitle, setMusicTitle] = useState("");
	const [provider, setProvider] = useState(null);

	const { address, isConnected } = useAccount();
	const chainId = useChainId();
	const { switchChain } = useSwitchChain();
	const publicClient = usePublicClient();
	const { data: walletClient } = useWalletClient();

	useEffect(() => {
		if (walletClient) {
			const provider = new BrowserProvider(walletClient);
			setProvider(provider);
		}
	}, [walletClient]);

	useEffect(() => {
		if (isConnected && chainId !== base.id) {
			switchChain({ chainId: base.id });
		}
	}, [isConnected, chainId, switchChain]);

	const toggleMusicPlay = useCallback(() => {
		setIsMusicPlaying((prev) => !prev);
	}, []);

	const handleTrackChange = useCallback((title) => {
		setMusicTitle(title.replace(/_/g, " ").replace("Nullsleep", ""));
	}, []);

	return (
		<div>
			<BrainWormsSimulator
				userAddress={address}
				isMusicPlaying={isMusicPlaying}
				toggleMusicPlay={toggleMusicPlay}
				musicTitle={musicTitle}
				isLoggedIn={provider && isConnected}
				provider={provider}
			/>
			<MusicPlayer
				active={isMusicPlaying}
				playlist={playlist}
				onTrackChange={handleTrackChange}
			/>
		</div>
	);
});

export default BrainWormsSimulatorLanding;
