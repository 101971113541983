import React, { useState, useEffect } from "react";
import BrainWormDoctorNavigation from "./BrainWormDoctorNavigation";
import BrainWormDoctorFooter from "./BrainWormDoctorFooter";
import useBrainWormDoctorSecurity from "./useBrainWormDoctorSecurity";
import SmartURL from "./SmartURL";

const BrainWormDoctorDashboard = () => {
	useBrainWormDoctorSecurity();
	const [disclosures, setDisclosures] = useState([]);

	useEffect(() => {
		const fetchedDisclosures = [
			{
				name: "Executive Update Q2 2024",
				description: "Project Chimera",
				date: "2024-04-01",
				link: "/medicalprovider/Project_Chimera_Q2_2024_Executive_Update.pdf",
			},
			{
				name: "Post-Op ",
				description: "c13",
				date: "2024-03-12",
				link: "/medicalprovider/postop_c13.jpg",
			},
			{
				name: "Lab Tech: Chris",
				description: "Cleanup",
				date: "2024-02-02",
				link: "/medicalprovider/labtech_chris_feb2.jpg",
			},
			{
				name: "Lab Tech: Synthesis",
				description: "Synthesis Station",
				date: "2024-02-12",
				link: "/medicalprovider/labtech_synthesis_station.jpg",
			},
			{
				name: "XioSky Security",
				description: "BlueDragon Incident 3 Report A",
				date: "2023-12-14",
				link: "/medicalprovider/XioSky_BlueDragon_Incident_3_Report_A.pdf",
			},
		];

		setDisclosures(fetchedDisclosures);
	}, []);

	return (
		<div className="BrainWormDoctor">
			<BrainWormDoctorNavigation />
			<div className="dashboard">
				<h4>Records:</h4>
				<div className="disclosure-cards">
					{disclosures.map((disclosure, index) => (
						<a
							href={SmartURL(disclosure.link)}
							target="_blank"
							rel="noreferrer"
						>
							<div key={index} className="disclosure-card">
								<h5>{disclosure.name}</h5>
								<p>{disclosure.description}</p>
								<small>Date: {disclosure.date}</small>
							</div>
						</a>
					))}
				</div>
			</div>
			<BrainWormDoctorFooter />
		</div>
	);
};

export default BrainWormDoctorDashboard;
