// useStaffSecurity.js
import { useEffect } from "react";

const useStaffSecurity = () => {
	useEffect(() => {
		if (localStorage.getItem("staffaccess_granted") !== "true") {
			alert("Access Denied");
			window.location.href = "/";
		} else {
		}
	}, []);
};

export default useStaffSecurity;
