// XioSkyContractorRegistrationEnergy.js
import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const XioSkyContractorRegistrationEnergy = () => {
	const [employeeData, setEmployeeData] = useState({
		address: "",
		generator: "",
		connection: "",
	});
	const [serverMessage, setServerMessage] = useState("");

	const sanitizeEthereumAddress = (address) => {
		// Remove leading and trailing spaces
		address = address.trim();

		// Convert to lowercase
		address = address.toLowerCase();

		// Remove "0x" prefix if present
		if (address.startsWith("0x")) {
			address = address.slice(2);
		}

		// Remove non-hex characters
		address = address.replace(/[^a-f0-9]/g, "");

		// Ensure the address is exactly 40 characters long
		if (address.length !== 40) {
			// Handle validation error, if necessary
			// For example: throw new Error("Invalid Ethereum address");
		}

		return "0x" + address; // Add back the "0x" prefix
	};

	const handleSubmit = async (event, formId, formData) => {
		event.preventDefault();

		// Clear out the previous server message
		setServerMessage("");

		try {
			const response = await fetch(`${API_URL}/store`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userInput: formData, rC: formId }),
			});

			const data = await response.json();
			console.log("Данные отправлены на сервер:", data);

			// Wait for a brief moment before updating the server message
			setTimeout(() => {
				if (response.status === 400) {
					setServerMessage(data.message || "Неверный запрос");
				} else if (response.status === 200) {
					setServerMessage(data.message || "Успех");
				}
			}, 300);
		} catch (err) {
			setTimeout(() => {
				setServerMessage("Произошла ошибка");
			}, 300);

			console.log("Произошла ошибка:", err);
		}
	};

	return (
		<div className="main-content">
			<div className="content-container">
				<div className="spacerShort"></div>
				<div className="article-content">
					<div style={{ width: "85vw" }}>
						<div
							style={{ marginLeft: "auto", marginRight: "auto", width: "50%" }}
						>
							<h3>Xio Sky Регистрация подрядчика.</h3>
							<p>
								Пусть Зевс хранит нашу неистовую силу воли. Пусть море будет
								нашей силой.
							</p>
							<div>{serverMessage === "" ? "\u00A0" : serverMessage}</div>
						</div>

						<form
							onSubmit={(e) => handleSubmit(e, "r5", employeeData)}
							className="inputForm"
						>
							<label htmlFor="contractorAddress">Адрес подрядчика</label>
							<br />
							<input
								type="text"
								id="contractorAddress"
								name="contractorAddress"
								placeholder="0x..."
								value={employeeData.address}
								onChange={(e) =>
									setEmployeeData({
										...employeeData,
										address: sanitizeEthereumAddress(e.target.value),
									})
								}
							/>
							<br />
							<label htmlFor="xioskyContractorA">генератор</label>
							<input
								type="text"
								id="xioskyContractorA"
								name="xioskyContractorA"
								placeholder=""
								value={employeeData.generator}
								onChange={(e) =>
									setEmployeeData({
										...employeeData,
										generator: e.target.value,
									})
								}
							/>
							<br />
							<label htmlFor="xioskyContractorB">связь</label>
							<input
								type="text"
								id="xioskyContractorB"
								name="xioskyContractorB"
								placeholder=""
								value={employeeData.connection}
								onChange={(e) =>
									setEmployeeData({
										...employeeData,
										connection: e.target.value,
									})
								}
							/>
							<button type="submit" className="auto-margin">
								регистр
							</button>
							<br />
							<p>
								Вы получаете доступ к ограниченной конечной точке. Если вы
								верите, что у вас есть зашли на эту страницу по ошибке,
								пожалуйста, свяжитесь с Del Complex Оперативный центр
								безопасности.
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default XioSkyContractorRegistrationEnergy;
