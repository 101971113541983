import React from "react";
import { ConnectKitButton } from "connectkit";
import styled from "styled-components";

const ConnectKitCustomButton = ({ extraStyles }) => {
	let StyledButton = styled.button`
		cursor: pointer;
		position: relative;
		display: inline-block;
		margin-top: 1em;
		margin-bottom: 1em;
		background-color: rgba(49, 49, 49, 0.54);
		color: white;
		outline-color: white;
		border: 1px solid white;
		padding: 0.5rem;
		border-radius: 0.5rem;

		&:hover {
			background-color: white;
			color: black;
		}
		&:active {
			background-color: black;
			color: white;
		}
	`;
	// tack on extra styles
	if (extraStyles) {
		StyledButton = styled(StyledButton)`
			${extraStyles}
		`;
	}

	return (
		<ConnectKitButton.Custom>
			{({ isConnected, show, truncatedAddress, ensName }) => {
				return (
					<StyledButton onClick={show}>
						{isConnected ? ensName ?? truncatedAddress : "Connect Wallet"}
					</StyledButton>
				);
			}}
		</ConnectKitButton.Custom>
	);
};

export default ConnectKitCustomButton;
