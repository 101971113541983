// ResearchOverview.js
import React from 'react';
import Article from './Article';
import TextCard from './TextCard';

const ResearchOverview = () => {

    const textCardsData = [
        {
          heading: 'Join our team.',
          caption: '[Careers]',
          link: '/careers/',
        },
        {
          heading: 'Visit our facilities.',
          caption: '[Facilities]',
          link: '/facilities/',
        },
        {
          heading: 'Access our surplus and records.',
          caption: '[Browse our products]',
          link: '/products/',
        },

    ];
    
    return (
      
    <div className="article-container">
        <div className="spacerShort"></div>
            <Article
                title="Seeking power."
                content={[
                    "As we shape the next generation of technology, our mission is to ensure that the energy systems fueling these breakthroughs are as sustainable, efficient, and forward-thinking as the innovations they empower.",
                ]}
                titleLevel={1}
            />

            <Article
                title="High energy density battery technology."
                content={[
                    "Our groundbreaking research has resulted in the development of an integrated silicon-sulfur battery system with nanostructured silicon anodes and advanced sulfur cathodes. This innovative architecture effectively addresses the volume expansion issue during the charge-discharge cycles and improves the electrical conductivity, leading to an unprecedented energy density of 800 Wh/kg.",
                    "The system's remarkable cycling stability and its non-flammable electrolyte make it ideal for a wide range of applications from electric vehicles to grid-scale energy storage. It is a leap forward towards achieving our goal of sustainable and long-lasting energy storage solutions.",
                    ,""
                ]}
                images={["/media/battery.jpg"]}
                imageIndex={1}
            />

            
            <Article
                title="Energy aware neural networks."
                content={[
                    "With the advent of EnergyNet, our energy-efficient sparse neural network architecture, we have opened up new possibilities for low-power neuromorphic hardware. By leveraging sparse connectivity, energy-aware neuron activation, and dynamic power management, EnergyNet minimizes unnecessary computations and synaptic operations, thereby reducing power consumption. Achieving up to 60% energy savings compared to traditional neural architectures, EnergyNet holds the potential to significantly advance energy-efficient computing systems.",
                ]}
            />

            <Article
                title="Energy management."
                content={[
                    "Del Complex is leading the charge in swarm robotics with the development of the Dynamic Reinforcement Energy-Aware Management for Swarms (DREAMS), a system that combines Machine Learning (ML) and Reinforcement Learning (RL) to dynamically manage energy distribution among swarm robots.",
                    "The system's operation involves three distinct but interconnected modules. A Sensor Module continuously collects and reports vital data about each robot's energy status and environmental conditions. This information is processed by the Learning Module, a component that uses a Q-Learning-based RL model to evaluate the data and dynamically update energy management policies. The aim is to ensure an optimal balance between energy usage and task completion.",
                    "The Decision Module then carries out the actions suggested by the Learning Module, dynamically adjusting the energy distribution among the swarm robots based on the refined policies.",
                    "Through extensive testing, DREAMS has proven to significantly improve energy efficiency by up to 30% and extend swarm operational time by 25%, compared to traditional energy management methods. Moreover, it has shown its robustness and adaptability under a variety of environmental conditions, demonstrating the enormous potential of AI in advancing energy management in swarm robotics."
                ]}
            />



        <br />
        <div className="text-container">
            {textCardsData.map((data, index) => (
                <TextCard
                key={index}
                heading={data.heading}
                caption={data.caption}
                link={data.link}
                />
            ))}
        </div>

    </div>
  );
};

export default ResearchOverview;
