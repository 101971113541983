import React from "react";

const BrainWormDoctorFooter = () => {
	return (
		<div className="BrainWormDoctor-footer">
			<footer>
				<p>
					Unauthorized access is prohibited. Medical Provider Portal for
					internal use only.
				</p>
				<p>
					For security issues, please contact{" "}
					<a href="https://risk.xiosky.com/">XioSky Risk Management</a>
				</p>
			</footer>
		</div>
	);
};

export default BrainWormDoctorFooter;
