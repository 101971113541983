// useBlueSeaSecurity.js
import { useEffect } from "react";

const useBlueSeaSecurity = () => {
	useEffect(() => {
		if (localStorage.getItem("bluesea_access_granted") !== "true") {
			alert("Access Denied");
			window.location.href = "/";
		} else {
		}
	}, []);
};

export default useBlueSeaSecurity;
