// ProductCards.js
import React from "react";
import GridCard from "./GridCard";

const ProductCards = () => {
	const gridCardsData = [
		{
			imageSrc: "/media/products/promptEngineer.jpg",
			heading: "Prompt Engineer",
			caption: "Staff Unisex Windbreaker",
			link: "https://shop.delcomplex.com/products/staff-unisex-windbreaker-prompt-engineer",
		},
		{
			imageSrc: "/media/products/lot2.jpg",
			heading: "Lot 2",
			caption: "60 count NVIDIA H100 Servers",
			link: "https://shop.delcomplex.com/products/lot-1-60-count-nvidia-h100-servers",
		},
		{
			imageSrc: "/media/products/unit6badge.jpg",
			heading: "Unit 6 Labs",
			caption: "Employee ID Badge",
			link: "https://shop.delcomplex.com/products/employee-id-badge-unit-6-labs",
		},
		{
			imageSrc: "/media/products/nuroIPS.jpg",
			heading: "NeuroIPS",
			caption: "Conference Unisex T-Shirt",
			link: "https://shop.delcomplex.com/products/conference-unisex-t-shirt-neuroips",
		},

		{
			imageSrc: "/media/products/alignmentAgent.jpg",
			heading: "Alignment Agent",
			caption: "Staff Unisex Windbreaker",
			link: "https://shop.delcomplex.com/products/staff-unisex-windbreaker-alignment-agent",
		},

		{
			imageSrc: "/media/products/lot1.jpg",
			heading: "Lot 1",
			caption: "8 count pallets of misc equipment",
			link: "https://shop.delcomplex.com/products/lot-1-8-count-pallets-of-misc-equipment",
		},

		// {
		// 	imageSrc: "/media/products/lot1.jpg",
		// 	heading: "Lot 1",
		// 	caption: "8 count pallets of misc equipment",
		// 	link: "https://shop.delcomplex.com/products/lot-1-8-count-pallets-of-misc-equipment",
		// },
	];

	return (
		<div>
			<div className="title-container">
				<h2>Surplus property.</h2>
				<p>Del Complex Property Office shop.</p>
			</div>
			<div className="grid-container">
				{gridCardsData.map((data, index) => (
					<GridCard
						key={index}
						heading={data.heading}
						imageSrc={data.imageSrc}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default ProductCards;
