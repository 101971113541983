import React from "react";
import { ConnectKitButton } from "connectkit";
import BlackCreateWalletButton from "./BlackCreateWalletButton.jsx";
import styled from "styled-components";
import ConnectKitCustomButton from "./ConnectKitCustomButton.js";
const WalletConnect = () => {
	return (
		<div className="walletConnect">
			<ConnectKitCustomButton />
			<BlackCreateWalletButton />
		</div>
	);
};
export default WalletConnect;
