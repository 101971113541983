// HumanSubject.js
import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const Colony = () => {
	const [employeeData, setEmployeeData] = useState({
		address: "",
		code: "",
	});
	const [serverMessage, setServerMessage] = useState("");

	const sanitizeEthereumAddress = (address) => {
		// Remove leading and trailing spaces
		address = address.trim();

		// Convert to lowercase
		address = address.toLowerCase();

		// Remove "0x" prefix if present
		if (address.startsWith("0x")) {
			address = address.slice(2);
		}

		// Remove non-hex characters
		address = address.replace(/[^a-f0-9]/g, "");

		// Ensure the address is exactly 40 characters long
		if (address.length !== 40) {
			// Handle validation error, if necessary
		}

		return "0x" + address; // Add back the "0x" prefix
	};

	const handleSubmit = async (event, formId, formData) => {
		event.preventDefault();

		// Clear out the previous server message
		setServerMessage("");

		try {
			const response = await fetch(`${API_URL}/store`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userInput: formData, rC: formId }),
			});

			const data = await response.json();
			console.log("Data sent to server:", data);

			// Wait for a brief moment before updating the server message
			setTimeout(() => {
				if (response.status === 400) {
					setServerMessage(data.message || "Bad Request");
				} else if (response.status === 200) {
					setServerMessage(data.message || "Success");
				}
			}, 300);
		} catch (err) {
			setTimeout(() => {
				setServerMessage("An error occurred");
			}, 300);

			console.log("An error occurred:", err);
		}
	};

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<h3>Brain Worms Colony Distribution</h3>
			<br />
			<br />
			<p>Thank you for your interest in an additional token distribution.</p>
			<br />
			<br />

			<p>
				To receive an access code, call:
				<br />
				+1(775) 365-WORM
			</p>
			<form
				onSubmit={(e) => handleSubmit(e, "rCol", employeeData)}
				className="inputForm"
			>
				<label htmlFor="contractorAddress">Address</label>
				<br />
				<input
					type="text"
					id="contractorAddress"
					name="contractorAddress"
					placeholder="0x..."
					value={employeeData.address}
					onChange={(e) =>
						setEmployeeData({
							...employeeData,
							address: sanitizeEthereumAddress(e.target.value),
						})
					}
				/>
				<br />
				<label htmlFor="AccessCode">Access Code</label>
				<input
					type="text"
					id="AccessCode"
					name="AccessCode"
					placeholder=""
					value={employeeData.code}
					onChange={(e) =>
						setEmployeeData({ ...employeeData, code: e.target.value })
					}
				/>
				<br />

				<button type="submit" className="auto-margin">
					Register
				</button>
				<p>
					{" "}
					<div>{serverMessage === "" ? "\u00A0" : serverMessage}</div>
				</p>
			</form>

			<div>
				<p>
					Note: Submission of an application does not guarantee distribution,
					airdrop, or additional allocation. We will accommodate as many
					interested parties as possible. We appreciate your interest and
					commitment to the Brain Worms Research Program.
				</p>
				<br />
				<p>
					Want to learn more? Visit the
					<br />
					<a href="/brainworms/">Brain Worms Research Program</a>
				</p>
				<br />
				<p>
					Explore or exciting career opportunities.
					<br />
					Visit the <a href="/onboarding/">Employee Onboarding Portal</a>
				</p>
				<br />
				<p>
					Medical Providers, please see the
					<br />
					<a href="/medicalprovider/login">Medical Provider Login Portal</a>
				</p>
				<br />
				<p>
					Security Concerns?
					<br /> <a href="/contactxiosky/">Contact Xio Sky</a>
				</p>
			</div>
			<div className="spacerShort"></div>
		</div>
	);
};

export default Colony;
