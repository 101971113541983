// Facilities.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const Facilities = () => {
	const textCardsData = [
		{
			heading: "Learn more about our research.",
			caption: "[Research overview]",
			link: "/research-overview/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Why choose Del Complex?"
				content={[
					"If you're ready to become part of a collaborative, ambitious, and dynamic team that's pushing the limits of what's possible, we invite you to explore our exciting career opportunities.",
					"For candiates who wish to accelerate their recruitment process, our new automated hiring system is now fully operational. Please begin your application via:",

					<>
						<a href="/onboarding">Employee Onboarding Portal</a>
					</>,

					<>
						<br />
					</>,

					<>
						<b>Do Your Life's Best Work:</b>
					</>,
					"Be at the forefront of cutting - edge research and development in AGI and neural prosthetics, contributing to the creation of technologies that will redefine the human experience.",
					<>
						<br />
					</>,
					<>
						<b>Collaborative Environment:</b>
					</>,
					"Immerse yourself in a culture that fosters interdisciplinary collaboration, bringing together the brightest minds from various fields to solve complex challenges and drive meaningful progress.",
					<>
						<br />
					</>,
					<>
						<b>Professional Growth:</b>
					</>,
					"At Del Complex, we invest in the growth of our team members, providing opportunities for continuous learning, skill development, and career advancement in a rapidly evolving industry.",
					<>
						<br />
					</>,
					<>
						<b>Competitive Compensation and Benefits:</b>
					</>,
					"We recognize and reward our employees' valuable contributions with competitive salaries, comprehensive benefits packages, and a supportive work environment.",
					<>
						<br />
					</>,
					<>
						<b>Make a Global Impact:</b>
					</>,
					"Be part of a mission-driven organization that is dedicated to creating a better, more connected future for humanity through responsible innovation and global collaboration.",
				]}
				images={["/media/employees_1.jpg"]}
				imageIndex={3}
				titleLevel={1}
			/>

			<Article
				title="Current Openings"
				content={[
					"We are always on the lookout for exceptional talent in a variety of areas, including artificial intelligence, neuroscience, engineering, data science, ethics, and more.",
					"",
				]}
				images={["/media/employees_salad.jpg"]}
				imageIndex={2}
			/>

			<Article
				title="Benefits"
				content={[
					"Del Complex benefits enhance the employee experience and foster a dynamic corporate ecosystem. Our focus on work-life synergy, collaboration, and personal growth ensures individuals can navigate the demands of scientific exploration effectively.",
				]}
			/>

			<Article
				content={[
					<>
						<b>For workforce members:</b>
					</>,
				]}
				list={[
					"Competitive compensation and comprehensive benefits",
					"Opportunities for vertical and lateral career advancement",
					"Integrated and synergistic team dynamics",
					"Access to state-of-the-art technological infrastructure",
					"High-engagement assignments with potential for societal ROI",
					"Interactive webinars and weekly meetings with management",
				]}
				images={["/media/employees_2.jpg"]}
				imageIndex={1}
			/>

			<Article
				content={[""]}
				images={["/media/employees_2.jpg"]}
				imageIndex={0}
			/>
			<Article
				content={[
					<>
						<b>Life & family considerations:</b>
					</>,
				]}
				list={[
					"Work flexibility and telecommuting options",
					"Comprehensive parental leave and family-centric programs",
					"Proactive health and wellness interventions",
					"Comprehensive Employee Assistance Programs (EAPs)",
					"Opportunities for community outreach and engagement",
				]}
			/>
			<Article
				content={[
					<>
						<b>Culture & growth potential:</b>
					</>,
				]}
				list={[
					"Robust and inclusive corporate culture ethos",
					"Commitment to diversity, equity, and inclusion (DEI)",
					"Continuous Learning and Development (L&D) programs",
					"Mentorship and coaching opportunities",
					"Recognition, rewards, and token based incentive programs",
					"Vibrant and inclusive corporate culture",
				]}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default Facilities;
