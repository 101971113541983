// PreferredCandidates.js
import React, { useState, useEffect, useContext } from "react";
import Article from "./Article";
import TextCard from "./TextCard";
import WalletConnect from "./WalletConnect";
import ClaimToken from "./ClaimToken";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const PreferredCandidates = () => {
	const textCardsData = [
		{
			heading: "Learn about our research.",
			caption: "[Research Overview]",
			link: "/research-overview/",
		},
		{
			heading: "Request a record.",
			caption: "[Archival Media]",
			link: "/archival-media/",
		},
		{
			heading: "Access our surplus property.",
			caption: "[Browse our products]",
			link: "https://shop.delcomplex.com/",
		},
	];

	const [contractorAddress, setContractorAddress] = useState({
		address: "",
	});

	const [serverMessage, setServerMessage] = useState("");

	const sanitizeEthereumAddress = (address) => {
		// Remove leading and trailing spaces
		address = address.trim();

		// Convert to lowercase
		address = address.toLowerCase();

		// Remove "0x" prefix if present
		if (address.startsWith("0x")) {
			address = address.slice(2);
		}

		// Remove non-hex characters
		address = address.replace(/[^a-f0-9]/g, "");

		// Ensure the address is exactly 40 characters long
		if (address.length !== 40) {
			// Handle validation error, if necessary
			// For example: throw new Error("Invalid Ethereum address");
		}

		return "0x" + address; // Add back the "0x" prefix
	};

	const handleSubmit = async (event, formId, formData) => {
		event.preventDefault();

		// Clear out the previous server message
		setServerMessage("");

		try {
			const response = await fetch(`${API_URL}/store`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userInput: formData, rC: formId }),
			});

			const data = await response.json();
			console.log("Data sent to server:", data);

			// Wait for a brief moment before updating the server message
			setTimeout(() => {
				if (response.status === 400) {
					setServerMessage(data.message || "Bad Request");
				} else if (response.status === 200) {
					setServerMessage(data.message || "Success");
				}
			}, 300);
		} catch (err) {
			setTimeout(() => {
				setServerMessage("An error occurred");
			}, 300);

			console.log("An error occurred:", err);
		}
	};

	useEffect(() => {
		if (localStorage.getItem("access_granted") !== "true") {
			alert("Access Denied");
			// Optionally, redirect them elsewhere or display an error message
			window.location.href = "/";
		}
	}, []); // The empty array means this useEffect will run once when the component is mounted

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Preferred candidate portal."
				content={[
					"Welcome to the Del Complex preferred candidate registration portal. We are proud include you in our network of the world's most talented individuals.",

					"You may register your Ethereum address below as a preferred candidate for future opportunities.",
					<>
						In addition, an archival copy of the{" "}
						<a href="/2023-hiring-event">hiring event</a> poster is available
						for your records. Limited to one per candidate. Availability ends
						October 9th, 2023.
					</>,
				]}
				titleLevel={1}
			/>
			<WalletConnect />
			<div className="spacerShort"></div>
			<div className="token-container-single">
				<ClaimToken
					claimId="76239088"
					imageSrc="/media/archival_media/HiringEvent.jpg"
					fileSrc="/media/archival_media/HiringEvent.jpg"
					alt="Hiring Event Poster"
				/>
			</div>

			<Article
				title=""
				content={[
					<>
						Additional tokens through our information disclosure and{" "}
						<a href="/archival-media/">archival media program</a> are available
						upon request.
					</>,

					<>
						Future opportunities for preferred candidates may include temporary{" "}
						<a href="/facilities">site access</a>, honorary researcher
						identification, global benefits packages, and or regulatory
						compliance waivers.
					</>,
				]}
				titleLevel={1}
			/>

			<form
				onSubmit={(e) => handleSubmit(e, "r3PC", contractorAddress)} // Use contractorAddress.address here
				className="inputForm"
			>
				<label htmlFor="contractorAddress">Preferred Candidate Address:</label>
				<br />
				<input
					type="text"
					id="contractorAddress"
					name="contractorAddress"
					placeholder="0x..."
					value={contractorAddress.address}
					onChange={(e) =>
						setContractorAddress({
							...contractorAddress,
							address: sanitizeEthereumAddress(e.target.value),
						})
					}
				/>
				<button type="submit" className="auto-margin">
					Register
				</button>
			</form>
			<div className="text-centered">
				{serverMessage === "" ? "\u00A0" : serverMessage}
			</div>
			<Article
				title=""
				content={[
					"Please note that registration does not guarantee employment. All candidates are subject to a rigorous screening process. Future opportunities are not guaranteed and may be revoked at any time. Benefits are subject to change without notice. For more information, please contact your recruiter.",
				]}
				titleLevel={1}
			/>
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default PreferredCandidates;
