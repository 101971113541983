import React from "react";
import Article from "./Article";
import { useEffect, useState } from "react";
import WalletConnect from "./WalletConnect";
import ClaimToken from "./ClaimToken";
import MetaTags from "./MetaTags";

const MediaServiceMintPage = () => {
	return (
		<div className="article-container">
			<div className="LlostAndFound" style={{}}>
				<MetaTags
					titleSuffix="Authorized Access Only"
					description="Authorized Access Only"
					path={location.pathname}
				/>
				<div className="spacerShort"></div>
				<p>///////////////////////////////////////////////</p>

				<Article
					title=""
					content={["INTERNAL ARCHIVE ENDPOINT", "PsyNet surgical media."]}
					titleLevel={1}
				/>
				<p>///////////////////////////////////////////////</p>

				<WalletConnect />
				<div className="spacerShort"></div>
				<p>///////////////////////////////////////////////</p>
				<div>
					<ClaimToken
						claimId="33849584"
						imageSrc="/media/archival_media/psynet_day_five.jpg"
						fileSrc="/media/archival_media/psynet_day_five.jpg"
						alt="PsyNet - Day Five"
					/>
				</div>
				<p>///////////////////////////////////////////////</p>
				<p>///////////////////////////////////////////////</p>
			</div>
		</div>
	);
};

export default MediaServiceMintPage;
