// ResearchOverview.js
import React from 'react';
import Article from './Article';
import TextCard from './TextCard';

const ResearchOverview = () => {

    const textCardsData = [
        {
          heading: 'Join our team.',
          caption: '[Careers]',
          link: '/careers/',
        },
        {
          heading: 'Visit our facilities.',
          caption: '[Facilities]',
          link: '/facilities/',
        },
        {
          heading: 'Access our surplus and records.',
          caption: '[Browse our products]',
          link: '/products/',
        },

    ];
    
    return (
      
    <div className="article-container">
        <div className="spacerShort"></div>
            <Article
                title="Symbiosis realized."
                content={[
                    "PsyNet by Del Complex is an innovative brain-computer interface (BCI), combining the cutting-edge of neuroscience, artificial intelligence, and biomedical engineering. PsyNet offers unparalleled insights into the functioning of the brain, catalyzing new advancements in understanding cognition, treating neurological disorders, and developing more advanced BCIs. PsyNet is made possible by four major breakthroughs by Del Complex researchers.",
                ]}
                titleLevel={1}

            />

            <Article
                title="1. Autonomous Implantation:"
                content={[
                    "Conventional brain implantation procedures are prone to error and risk of damage due to the complexity of the brain. That’s why PsyNet is implanted with the aid of our proprietary auto-surgical system.",
                    "We leverage real-time MRI imaging for high-resolution, dynamic visualization of the brain. Our advanced machine learning algorithms have been trained on an extensive dataset of brain scans, allowing the system to intuitively understand and navigate individual neuroanatomy and fill in gaps in real-time data. It also employs a sensitive haptic and electro-chemical feedback system, ensuring minimal impact on brain tissue during implantation. Our system represents a leap forward in surgical precision and safety in the field of BCI.",
                    ""
                ]}
                images={["/media/employees_bci.jpg"]}
                imageIndex={2}
            />

            
            <Article
                title="2. Self-Organizing Electrodes:"
                content={[
                    "Achieving optimal positioning of electrodes in the intricate brain structure is a primary challenge for effective brain-computer interfaces. At the core of PsyNet are the self-organizing electrodes, capable of arranging themselves into an optimal configuration once inside the brain.",
                    "Responding to the unique environment of each brain, these smart electrodes adjust their positioning according to a complex set of factors, including neuronal electrical activity and chemical gradients. Their ability to sense and react to their immediate environment enables unprecedented interfacing fidelity with neuronal networks. The electrodes are fabricated from a novel biomimetic material, engineered to provide superior biocompatibility and efficient signal transmission."
                ]}

            />

            <Article
                title="3. Localized Genetic Modification:"
                content={[
                    "Achieving precise control and modification of neuronal activity at specific brain regions has been a significant challenge. PsyNet's localized genetic modification system introduces light-sensitive proteins into neurons enabling precise and targeted adjustments to neuronal behavior, revolutionizing our ability to understand and manipulate brain circuits.",
                    "PsyNet is equipped with a groundbreaking CRISPR-based genetic intervention system. Electrodes carry a payload of viral vectors, capable of introducing optogenetic modifications locally. The release mechanism is finely tuned to respond to specific signals from the PsyNet interface or distinct conditions in the surrounding tissue. This enables a more precise, localized control and monitoring of neuronal activity, paving the way for advanced brain studies and potential therapeutic interventions.",
                    ""
                ]}
                images={["/media/bci_electrodes.jpg"]}
                imageIndex={2}
            />

            <Article
                title="4. Biomimetic Design:"
                content={[
                    "Conventional brain-computer interfaces lack biocompatibility and durability, leading to discomfort and potentially serious immune reactions. PsyNet's design ethos is deeply rooted in biomimicry.",
                    "Our proprietary neural lace material is designed to flex and move with the brain, minimizing discomfort and potential immune reactions. The lace mimics the properties of brain tissue, contributing to its biocompatibility and durability. Advanced nanotechnologies are utilized to enhance its power and data transmission capabilities, allowing for efficient wireless communication with external systems.",
                    "With PsyNet, Del Complex is pushing the boundaries of what's possible in the realm of brain-computer interfaces. We're excited about the potential it holds for accelerating research and opening up new therapeutic avenues, and we look forward to partnering with researchers and clinicians who share our vision."
                ]}
            />


        <br />
        <div className="text-container">
            {textCardsData.map((data, index) => (
                <TextCard
                key={index}
                heading={data.heading}
                caption={data.caption}
                link={data.link}
                />
            ))}
        </div>

    </div>
  );
};

export default ResearchOverview;
