// ContactUs.js
import React from "react";
import Article from "./Article";

const ContactUs = () => {
	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Contact Del Complex"
				content={[
					"Call us:",
					"Sunnyvale Unit 6 Labs (669) 600 - 6898",
					"Redding Research (530) 338-0209",
					"Mt. Augusta Facility (844) 917-2675",
					"Alpine Security Site (432) 356-3236",
					<br />,
					"Register:",
					<a href="/register">Contractors and Employees</a>,
				]}
				titleLevel={1}
			/>
			<div className="spacerShort"></div>
		</div>
	);
};

export default ContactUs;
