import React from "react";
import { NavLink } from "react-router-dom";
import SmartImg from "./SmartImg";
import MetaTags from "./MetaTags";

const BrainWormDoctorNavigation = ({ user }) => {
	// Adjust this to get the user info as needed
	return (
		<div>
			<h3>Medical Provider Portal</h3>
			<div className="BrainWormDoctor-nav">
				<MetaTags
					titleSuffix="ACCESS DENIED"
					description="ACCESS DENIED"
					path={location.pathname}
				/>
				<div className="user-info">
					<span>Welcome, Dr. Maren Skye</span>
				</div>
				<nav>
					<ul>
						<li>
							<NavLink
								to="/medicalprovider/contactadmin"
								activeClassName="active"
							>
								Contact Admin
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/medicalprovider/requestaccess"
								activeClassName="active"
							>
								Request Access
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/medicalprovider/portal"
								exact
								activeClassName="active"
							>
								Records
							</NavLink>
						</li>
					</ul>
				</nav>
				<SmartImg
					src="/media/brainworms/medicalProviderLogo2.png"
					alt="Medical Provideder Portal Logo"
					className="logo"
				/>
			</div>
		</div>
	);
};

export default BrainWormDoctorNavigation;
