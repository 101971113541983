// PortalDefault.js
import React from "react";
import Article from "./Article";

const PortalDefault = () => {
	return (
		// <div className="article-container">
		<Article
			title=""
			content={[
				"Access is limited to employees, contractors, preferred candidates, and authorized visitors.",
				<>
					Individuals lacking the necessary authorization are encouraged to{" "}
					<a href="/visitor-pass">acquire a visitor pass</a> or{" "}
					<a href="/onboarding/">complete employee onboarding</a> through the
					appropriate channels. Unauthorized access is strictly prohibited and
					is subject to criminal and civil penalties.
				</>,
			]}
			titleLevel={1}
		/>
		// </div>
	);
};

export default PortalDefault;
