import React, { useState, useEffect } from "react";

const ITEMS_PER_PAGE = 20; // Global variable for items per page

const allItems = [
	"iPhone XS Max Gold 64 GB serial: FFMXJ1XZKPHF",
	"Fluke 115 Compact True-RMS Digital Multimeter",
	"ThermoPro TP30 Digital Infrared Thermometer Gun",
	"Celestron 44302 Handheld Digital Microscope, 5MP",
	"Baby stroller, UPPAbaby VISTA V2 black",
	"Samsung Galaxy S21 Ultra, Phantom Black, 128GB",
	"Panasonic electronic components ECWFA-KIT",
	"ChapStick Classic Lip Balm, original flavor",
	"Car Keys with a Tesla Model S Key Fob",
	"Generic umbrella, black",
	"Oakley Holbrook RX Eyeglasses, Black Frame",
	"Set of 3 house keys on a keyring, Montana",
	"Hermès Bearn Compact Wallet, Epsom Leather, Etoupe Color",
	"Rolex Submariner Watch, Black Dial, Stainless Steel, etching reads: For Jake",
	"Used mascara, maybelline, black",
	"Ray-Ban Aviator Classic Sunglasses, Gold Frame, Green Lenses",
	"Hand Sanitizer Bottle, 2 oz.",
	"Hand Sanitizer Bottle, 2 oz.",
	"Hand Sanitizer Bottle, 2 oz.",
	"Hand Sanitizer Bottle, 2 oz.",
	"Hand Sanitizer Bottle, 2 oz.",
	"Hand Sanitizer Bottle, 2 oz.",
	"Hand Sanitizer Bottle, 2 oz.",
	"Hand Sanitizer Bottle, 2 oz.",
	"Apple MacBook Pro 16-inch, Space Gray, M1 Pro chip",
	"Microsoft Surface Pro 7, Platinum, Intel Core i5, 8GB RAM",
	"Sandisk 128GB Ultra Dual Drive USB Type-C Flash Drive",
	"Bose QuietComfort Earbuds, Triple Black",
	"Starbucks Gift Card, Balance Unknown",
	"Packet of Tissues",
	"Moleskine Classic Notebook, Large, Hard Cover, Black",
	"Parker Jotter Ballpoint Pen, Stainless Steel with Chrome Trim",
	"Hydro Flask 32 oz Water Bottle, Cobalt Blue",
	"TUMI Alpha 3 Expandable Organizer Laptop Brief, Black",
	"AmazonBasics 15.6-inch Laptop and Tablet Bag, Black",
	"California Drivers License, several",
	"Texas Instruments TI-84 Plus",
	"Western Digital 1TB My Passport Portable External Hard Drive, Red",
	"Logitech R400 Wireless Presenter Remote",
	"Uvex Stealth OTG Safety Goggles, Navy Body, Clear Lens",
	"Arduino Uno Rev3 Resistors Kit",
	"Assorted Plastic Grocery Bags",
	"Unknown brand aluminum Water Bottle, Silver, 20 oz",
	"Hand alus",
	"TUMI Alpha Driver Jottle, 2 oz.",
	"Sanitizer Key Bottery Gold Fort Pen, Spactrocens",
	"Sanic Licens",
	"Westrumeter Wateelectrone USB Ultra, masses",
	"Car Keys Pro Remoter",
	"Stainchinalandheleskiner Body, blace Greel Microsoft Water Bottless Space Unown blassic Nottle, 2 oz.",
	"Ray-Bala, 20 oz.",
	"Gener Bosevera Dric cope, mariver, Bottle, stronics Lip and Sanitizer Jaket Blue",
	"Sanitimeter Jottle, 128GB My Preense Quiet, Trip",
	"Stealtra Dring rear Botter Bottle, Hard Digitanal Drive, 2 of Tissic Navy Parker Blacks Starbuck",
	"Oakleys Pro 7, Pener Bottle, Cover, Blue-RMS Digitizer Body, Modelectra, blassic Nottle, 2 oz.",
	"Bottlestic Lic elearke",
	"Sanitizer Bottle, PhonBan Cored Sanic Noted mariver, Exter Bottle, Car Key FFMXJ1XZKPHF",
	"Theld Driverame",
	"Set Submarge, 20 oz.",
	"Panasort Port Blace Trim",
	"Ther, Etouseve, Red",
	"Logital My Bottle, Black",
	"Bosof Type-C Fluke Expact Wal 1TB Mact Card Dual Handissunglatizer Bottlet oft Car Bottermès Blavor",
	"Ard, Grocer Bal 15.6-inchip Bags",
	"Assic compact Tisk Clason aluminum, of Tiss Safet Black",
	"Hand Sanizery Ban brook 128GB se keys Gift Cor",
	"Hand Submascop Bottle, Hold 64 Gogith R400 Wathermomety VISTA V2 blatizer Bottlect Tablectronia 3 honeric el, ble ker Water",
	"Panitalaxy Bottle, Navy Bottle, sermoter Balline USB RAM",
	"TUMI Alpha ker Largan Aviather, Epsomfornalth a key Frare i5, 8GB sev3 hone XS Key Pack, 2 oz Water, 128GB My Plackety VISTA V2 blatimeter",
	"Weskiner Keys origitana",
	"ThermoPro Fluke i5, Balm, Navy Brim",
	"Panital 128GB stronenteal: FFMXJ1XZKPHF",
	"Ther Gron Cobaby VISTA V2 oz.",
	"Han Aviator Claxy Sunglasse Quinown",
	"Hand a Tabless Sanitizer Bottle, Sandhellifornial: Flaskinermès Blassics Gift Cle, Stainles, Silver Wal, Laptop and Tissung, 2 oz Watheld FFMXJ1XZKPHF",
	"TUMI Alpha Dualift of Taby Bags",
	"Hand Steel Stel Microse 115 Compace i5, Stainless with Carbuds, Handistoupe XS Model, Black",
	"Hanitizer Gogitalley Bottle, etchip",
	"Texas Kit",
	"Unkno R400 oz.",
	"Handhellinentel SubmazonBasonics ECWFA-KIT",
	"Molor",
	"Stic umbre i5, 8GB Ulth Laptop and Steel Stebook RX Eyegla, Epsonitizer RemoPro 16-inch Chro ching, Infrard Table Greel, Sanitalum, Trim",
	"Hand Sanizeralum, Balatizer Bottle OTG Submascara, Plukeys with OTG Sanitime, Spack",
	"Uses, Navy Balm, Intebook Revernasom Leator Clack",
	"Fluke Pro Rev3 Red Pen, Phonents TI-84 Plack Digitizer Black",
	"Hanitizer Gogith R400 Wathermomety VISTA V2 blatizer Bottle, Car Key Frare i5, Balm, Intebook Revernasom Leator Clack",
	"Hand Sanitime, Space Unown blassic Navy Parker Black",
	"Handhellinents TI-84 Plack Digitizer Botter Blackety VISTA V2 blassic Nottle, 2 oz Water, 128GB se ker Wal, Laptop and Tissunglatizer Gogith R400 Watheld Driver Wal, Laptop and Sanitizer Blacks Stainless with OTG Submascop Bottle, Car Keys Pro 7, Pener Body, Modelectra, blassic Nottle, Navy Brim",
	"Assic compace i5, Stainching, Infrard Table Greel, ble Greel Microse 115 Compace i5, Stainles, Silver Water",
	"Gener Body, Modelectra, blace i5, Stainless with Carbuds, Handhellifornial: Flaskinermès Blassics Gift Cle, Stainless with OTG Sanitizer Bottlet oft Car Key FFMXJ1XZKPHF",
	"Ray-Bala, 20 oz.",
	"Hanitizer Body, blace Greel Stebook Revernasom Leator Claxy Sunglasse Quiet, Trim",
	"TUMI Alpha Dualift oft Car Bottle, Hold 64 Gogith R400 Wather, Epsomfornalth Laptop and Tissunglasse Quinown",
	"Sanitizer Bottlet oft Cor",
	"Bosof Type-C Fluke Expact Water Bottlestic Licens",
	"Weskiner Keys Pro Remoter",
	"Bosof Type-C Fluke Expact Tisk Clason aluminum, of Tissic Nottle, Navy Brim",
	"Panital 15.6-inch Chro ching, Infrard Taby Ban brook RX Eyegla, Epsonitizer Bottle OTG Submascara, Plukeys with Carbuds, Hand Sanitizer RemoPro 16-inchip",
	"Bosof Type-C Flukeys with R400 oz.",
	"Handhellifornial: Flaskiner Bottlect Tisk Clason aluminum, of Tissic Navy Bottle, etchip",
	"Ther, Etouseve, 2 of Tisk Clason alus",
	"Hand alus",
	"Han Aviator Clack Botter Balline USB RAM",
	"Sanitizer Bottery Gold FFMXJ1XZKPHF",
	"Oakleys Pro Remoter",
	"Panital 128GB sev3 hone XS Model, Sanitimeter Balline USB Ultra, masse Quiet, Trip",
	"Sanitime, Spack",
	"Unkno R400 oz.",
	"Hand Tissung, 2 oz.",
	"Stic umbre i5, 8GB se keys Gift Cor",
	"Car Key Bottle, Hold 64 Gogith OTG Sanitizer Botter Bottlect Tablectronics ECWFA-KIT",
	"Molor",
	"Panitalaxy Sunglasse Quiet, Trip",
	"Oakleys origitana",
	"Ther, Etouseve, Red",
	"Logital 128GB stronenteal: FFMXJ1XZKPHF",
	"Panital 128GB My Preense Quinown",
	"Hanitizer Body, Model, Black",
	"Molor",
	"Stealtra Driver Jottle, Blace Trim",
	"Ard, Gron Cored Sanitimeter Bottle, Stainchip Bags",
	"Sanitizer Bottle, 2 oz.",
	"Ther, Exter Bottle, sermoter",
	"Sanitizeralum, Trim",
	"Car Key FFMXJ1XZKPHF",
	"Assic Nottle, Sandhellifornial: Flaskinermès Blassics Gift Cor",
	"Set SubmazonBason alus",
	"Ard",
	"Stic umbre i5, Stainless with OTG Sanitizer Bottle, 2 oz.",
	"Hanitizer Remoter",
	"Stealtra Driver Jottle, Blackety VISTA V2 blassic compace i5, Stainless with Carbuds, Handhellifornial: Flaskiner Keys Pro Remoter",
	"Ray-Bala, 20 oz.",
	"Assics Gift Cored Sanitime, Space Unown blasse Quinown",
	"Assic compace Unown blassic Navy Brim",
	"Stic umbre i5, Balm, Intebook Revernasom Leator Claxy Sunglasse Quiet, Trim",
	"Hand Sanitizer Body, Model, Blackety VISTA V2 blasse Quiet, Trip",
	"Hand Tissunglatizer Bottle, Hold 64 Gogith R400 Wathermomety VISTA V2 blasse Quiet, Trim",
	"Uses, Navy Brim",
	"Hanitizer Balline USB Ultra Driver Jottle, Hold 64 Gogith OTG Sanitizer Body, Model, Sandhelline USB Ulth Laptop and Tissunglatizer Body, Model, Sandhellifornial: Flaskinermès Blasse Quiet, Trim",
	"Handhellifornial: Flaskiner Bottle, 2 of Tissic Nottle OTG Submascara, Plukeys Gift Cor",
	"Stealtra Driver Jottle, Car Bottle, 2 of Tissung, 2 oz.",
	"Stic umbre i5, Stainching, Infrard Table Greel Microse 115 Compace i5, Stainles, Silver Jottle, 2 oz.",
	"Handhellifornial: Flaskinermès Blassic Navy Brim",
	"Stic umbre i5, Stainch Chro ching, Infrard Taby Ban brook Revernasom Leator Claxy Sunglassic Nottle, sermoter",
	"Stealtra Driver Jottle, Navy Brim",
	"Sanitizer Bottle, sermoter",
	"Assic compace i5, Stainless with OTG Submascara, Plukeys Gift Cor",
	"Handhellifornial: Flaskiner Body, Modelectronics ECWFA-KIT",
	"Ther, Etouseve, 2 oz WVISTA V2 blassic Nottle, Hold 64 Gogith OTG Sanitimeter Balline USB Ultra, blace i5, Balm, Intebook Revernasom Leator Claxy Sunglasse Quiet, Trip",
	"Hanitizer Body, blace i5, 8GB Ultra, Plukeys origitana",
	"Ther, Etouseve, 2 of Tissic compace Unown",
	"Stealtra Driver Wal, Laptop and Sanitizer Blacks Stainching, Infrard Table Greel Microse 115 Compace i5, Stainless with R400 oz.",
	"Han Aviator Claxy Sunglasse Quinown",
	"Hand Tissunglatizer Body, Model, Sandhellifornial: FFMXJ1XZKPHF",
	"Stic umbre i5, Stainch Chro ching, Infrard Table Greel, blace i5, Balline USB Ultra, blace i5, Stainless with R400 Water Blacks Stainless with R400 oz.",
	"Molor",
	"Assic Nottle, Hold 64 Gogith OTG SubmazonBason alus",
	"Assic Nottle, sermomety VISTA V2 blasse Quiet, Trim",
	"Han Aviator Claxy Sunglasse Quiet, Trip",
	"Ther, Etouseve, Red",
	"Hand Tissic compace Unown blassic Nottle, Sandhellifornial: Flaskiner Body, Model, Blasse Quiet, Trip",
	"Assic compace Unown blace i5, Stainless with Carbuds",
	"Assic compace i5, Stainless with Carbuds, Handhellifornial: Flaskiner Body, Model, Sandhelline USB Ulth Laptop and Tissung, 2 oz.",
	"Sanitizer Body, Model, Sandhellifornial: Flaskinermès Blasse Quiet, Trip",
	"Handhellifornial: Flaskinermès Blassic Navy Brim",
	"Stic umbre i5, Balm, Intebook Revernasom Leator Claxy Sunglasse Quiet, Trip",
	"Ther, Etouseve, Red",
	"Assic compace i5, Stainching, Infrard Table Greel Microse 115 Compace Unown blassic Nottle, sermomety VISTA V2 blassic Navy Brim",
	"Stic umbre i5, Stainching, Infrard Table Greel, blace Unown blasse Quiet, Trip",
	"Hand Tissunglatizer Body, Modelectronics ECWFA-KIT",
	"Sanitizer Bottle, sermomety VISTA V2 blasse Quiet, Trim",
	"Stealtra Driver Jottle, sermoter",
	"Molor",
	"Han Aviator Claxy Sunglasse Quinown",
	"Han Aviator Claxy Sunglatizer Body, blace i5, Balm, Intebook Revernasom Leator Claxy Sunglatizer Bottle, Car Body, Model, Sandhellifornial: FFMXJ1XZKPHF",
	"Sanitizer Balm, Intebook Revernasom Leator Claxy Sunglassic Nottle, sermomety VISTA V2 blassic compace i5, 8GB Ultra, blasse Quinown",
	"Han Aviator Claxy Sunglasse Quiet, Trim",
	"Stealtra Driver Wal, Laptop and Sandhellifornial: Flaskinermès Blasse Quinown",
	"Assic compace Unown blassic Nottle OTG Sanitizer Balline USB Ultra Driver Jottle, sermomety VISTA V2 blasse Quiet, Trip",
	"Han Aviator Claxy Sunglassic Navy Brim",
	"Assics Gift Cor",
	"Sanitimeter Body, Model, blace i5, Stainching, Intebook Revernasom Leator Claxy Sunglasse Quiet, Trim",
	"Molor",
	"Hanitizer Body, Model, Blackety VISTA V2 blasse Quinown",
	"Stic umbre i5, Stainless with Carbuds, Handhellifornial: Flaskinermès Blackety VISTA V2 blasse Quinown",
	"Han Aviator Claxy Sunglassic Nottle, Hold 64 Gogith R400 Water Blacks Stainless with OTG Submascara, Plukeys Pro Remoter",
	"Uses, Navy Brim",
	"Hand Tissic compace Unown blace i5, Stainless with OTG Submascara, Plukeys origitana",
	"Assic compace i5, Stainless with R400 oz.",
	"Hand Sanitizer Body, Model, Sandhellifornial: Flaskiner Body, Model, Sandhellifornial: Flaskiner Keys Pro Remoter",
	"Stic umbre i5, Stainless with R400 oz.",
	"Ther, Etouseve, 2 of Tissunglasse Quinown",
	"Han Aviator Claxy Sunglatizer Blacks Stainles, Navy Brim",
	"Handh",
];

const textArray = [
	"Parse error: syntax error, unexpected '}' line 42",
	"Notice: Undefined variable: data in /lost-and-ffound on line 15",
	"Fatal error: Uncaught Error: Call to undefined function fetch_data() in /llost-and-found:28",
	"Warning: mysqli_connect(): (HY000/1045): Access denied for user 'admin'@'localhost' (using password: password)",
];

// This array simulates a more realistic scenario where multiple types of errors might occur on the same page, but not necessarily all at once.

const LostAndFound = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [currentItems, setCurrentItems] = useState([]);
	const totalItems = allItems.length;
	const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

	const [currentTextIndex, setCurrentTextIndex] = useState(0);
	const [linesToShow, setLinesToShow] = useState([]);
	var numberOfLines = 0;
	useEffect(() => {
		// Update the number of lines to show based on the current page
		numberOfLines = currentPage >= 3 ? Math.pow(2, currentPage - 3) * 2 : 0;
		setLinesToShow(textArray.slice(0, numberOfLines));
	}, [currentPage]);

	useEffect(() => {
		// Only run the interval if there are lines to show
		if (linesToShow.length > 0) {
			const intervalId = setInterval(() => {
				setCurrentTextIndex(
					(prevIndex) => (prevIndex + 1) % linesToShow.length
				);
			}, Math.pow(2, currentPage) * 5);

			return () => clearInterval(intervalId); // Cleanup interval on component unmount
		}
	}, [linesToShow]);

	useEffect(() => {
		const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
		const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
		setCurrentItems(allItems.slice(indexOfFirstItem, indexOfLastItem));
	}, [currentPage]);

	// Function to handle page change
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="lostFound">
			<h2>Mt. Augusta - Lost and Found Items:</h2>
			<h4>
				page: {currentPage}/{totalPages}
			</h4>

			{currentPage >= 3 && linesToShow.length > 0 && (
				<div className="animatedText">{linesToShow[currentTextIndex]}</div>
			)}
			<ul>
				{currentItems.map((item, index) => (
					<li key={index}>{item}</li>
				))}
			</ul>

			<div className="pagination">
				<span>Page: </span>
				{currentPage > 1 && (
					<a href="#!" onClick={() => paginate(currentPage - 1)}>
						{"<back"}
					</a>
				)}
				<a href="#!" onClick={() => paginate(1)}>
					1
				</a>
				{currentPage > 2 && <span> ... </span>}

				{currentPage !== 1 && currentPage !== totalPages && (
					<a href="#!" className="active">
						{currentPage}
					</a>
				)}
				{currentPage < totalPages - 1 && <span> ... </span>}
				{totalPages > 1 && (
					<a href="#!" onClick={() => paginate(totalPages)}>
						{totalPages}
					</a>
				)}
				{currentPage < totalPages && (
					<a href="#!" onClick={() => paginate(currentPage + 1)}>
						{"next >"}
					</a>
				)}
			</div>
			<div className="spacer"></div>
		</div>
	);
};

export default LostAndFound;
