// useBrainWormDoctorSecurity.js
import { useEffect } from "react";

const useBrainWormDoctorSecurity = () => {
	useEffect(() => {
		if (localStorage.getItem("brainWormDoctor_access_granted") !== "true") {
			alert("Access Denied");
			window.location.href = "/";
		} else {
			let serverErrorResponse = {
				status: 615,
				error: "Database Null Error",
				message:
					"An unexpected access violation has been detected. Credentials: Dr. Maren Skye $PSWRD Error Fetching Password. We apologize for the inconvenience and are working to resolve the issue.",
			};

			let errorCount = sessionStorage.getItem("errorCount") || 0;

			if (errorCount < 1) {
				alert(JSON.stringify(serverErrorResponse, null, 4));
				sessionStorage.setItem("errorCount", ++errorCount);
			}
		}
	}, []);
};

export default useBrainWormDoctorSecurity;
