import React from "react";
import Article from "./Article";

const content = [
	"October 6th, 2023",
	"",
	"To All Employees,",
	"We are pleased to formally announce that Monday marks the successful conclusion of the Q3 Del Complex Hiring Event. Held at the Mt. Augusta Facility on September 24th, the event was executed according to plan and saw a turnout of over 1,500 prospective candidates from a variety of professional backgrounds.",
	"We acknowledge the efforts of all staff members involved in the planning and execution of the event. Your contributions ensured the operational efficiency and professionalism that are characteristic of Del Complex’s corporate events.",
	"Candidates were presented with an overview of Del Complex’s ongoing projects and initiatives in the integration of Artificial General Intelligences (AGIs) with human cognition. The event facilitated an exploration of our interdisciplinary collaboration frameworks and provided insights into the structured career pathways within the organization.",
	"The registration of preferred candidate Ethereum addresses aligns with the organization’s strategic objective to integrate innovative talent into our professional network. The registration process is subject to standard vetting procedures and protocols.",
	"We wish to clarify that registration does not imply guaranteed employment. All candidates will undergo a comprehensive screening process in adherence to Del Complex’s rigorous recruitment standards. The organization is committed to maintaining the integrity of our recruitment and selection processes.",
	"The Human Resources department will proceed with the next phases of the recruitment process and is expected to communicate the outcomes in due course. All inquiries regarding this should be directed to the designated contact within the department.",
	"We appreciate the cooperation of all staff and stakeholders in ensuring the event’s success and look forward to the positive impact the influx of new talent will have in driving Del Complex’s strategic objectives.",
	"Sincerely,",
	"Internal Corporate Communications",
	"Del Complex",
];

export const title = "Q3 Hiring Event recap.";
const PortalNewsHiringEnd = () => {
	return (
		<Article
			title={title}
			content={content}
			titleLevel={2}
			images={["/media/employees_2_wide.jpg"]} // Replace 'url_to_image' with the actual image URL
			imageIndex={4}
		/>
	);
};

export default PortalNewsHiringEnd;
