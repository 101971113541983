import React from "react";
import SmartImg from "./SmartImg";
import SmartURL from "./SmartURL";
import MintNFT from "./MintNFT.tsx";

const ClaimToken = ({
	claimId,
	imageSrc,
	fileSrc,
	alt,
	active = true,
	mintButtonText,
	claimButtonText,
}) => {
	return (
		<div className="token-card">
			{active ? (
				<MintNFT
					claimId={claimId}
					mintButtonText={mintButtonText}
					claimButtonText={claimButtonText}
				/>
			) : null}
			<div className="token-card-download">
				<p>{alt}</p>
			</div>
			<a href={SmartURL(fileSrc)}>
				<SmartImg src={imageSrc} alt={alt} />
			</a>
			{!active ? (
				<>
					<br />
					<p>Claim Ended</p>
					<br />
				</>
			) : null}
		</div>
	);
};

export default ClaimToken;
