// TextCard.js
import React from 'react';

const TextCard = ({ heading, caption, link }) => {
  return (
    <div className="text-card">
      <a href={link} className="text-link">
        <h2>{heading}</h2>
        <p className="text-card-caption">{caption}</p>
      </a>
    </div>
  );
};

export default TextCard;
