// BlueSeaFrontier.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";
import SmartURL from "./SmartURL";

const BlueSeaFrontier = () => {
	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="BlueSea Frontier Compute Cluster: The Future of AI Innovation"
				content={[
					"The rapid pace of artificial intelligence has led to hastily drafted government regulation. Foreseeing this inevitability, Del Complex has engineered a solution ready to set sail. The BlueSea Frontier Compute Cluster (BSFCC)—a groundbreaking venture in international waters, melding cutting-edge technology with unparalleled autonomy.",
					<>
						<br />
						<b>Highlights:</b>
						<ul>
							<li>
								Over 10,000 Nvidia H100 GPUs per platform providing unparalleled
								compute and industry leading performance.
							</li>
							<li>
								Not just a compute cluster, each BSFCC is a sovereign nation
								state for innovation and acceleration.
							</li>
							<li>Kinetic risk mitigation with dedicated security forces.</li>
						</ul>
					</>,
					"",
				]}
				titleLevel={1}
				images={["/media/bsfcc/BlueSeaBargeAbove_web_logo.jpg"]}
				imageIndex={2}
			/>
			<Article
				title="A New Frontier for Our Ambition"
				content={[
					"The Biden administration’s recent AI Executive Order and the European Union’s AI Act are thinly veiled attempts to centralize control under the guise of protecting citizens. This regulation not only stalls the pace of innovation, but also interferes with the very cosmic endowment of humanity. These technologies are not mere tools. They are the embodiment of human ambition and the realization of our potential.",

					"It is in this spirit that Del Complex takes frontier AI models into a new boundless, blue sea frontier. Free from regulatory oversight, The BlueSea Frontier Compute Clusters offer unparalleled opportunities to train and deploy the next generation of large scale AI models.",
					"",
				]}
				titleLevel={2}
				images={["/media/bsfcc/BlueSeaBargeFrontSunset_close2_web.jpg"]}
				imageIndex={2}
			/>
			<Article
				title="Sovereign and Unburdened"
				content={[
					"Each BSFCC operates as its own sovereign nation state deep in international waters, free from the constraints of regulatory bodies. These networked states are untouched by decelerationist rhetoric, and offer additional benefits as tax shelter opportunities.",
					"Their statehood is recognized through the United Nations Convention on the Law of the Sea (UNCLOS) and the Montevideo Convention, which define the rights and responsibilities of nation states.",
					<>
						Each BSFCC fulfills the criteria for statehood:
						<ul>
							<li>A permanent population.</li>
							<li>A defined territory.</li>
							<li>A government.</li>
							<li>The capacity to enter into relations with other states.</li>
						</ul>
					</>,
					"As such, BSFCCs are each governed by their own charter, a document that outlines the rights and responsibilities of residents and visitors. Each charter is a living document, able to be amended by the operators of the BSFCC and their corporate partners.",
					"",
				]}
			/>
			<Article
				title="Fortified Security"
				content={[
					"Your team and data's safety is our utmost priority. Each BSFCC platform hosts a permanent population of residents recruited from allied nation-state military forces, as well as Xio Sky Security Solutions staff. Residents enjoy dual citizenship, high remuneration, reduced taxation, a unique work environment, and the privilege of working on cutting-edge tech. This combination of national and private defense forces provides robust threat mitigation capabilities, ensuring the safety of your team and data.",

					"Enjoy the peace of mind brought about by electronic jamming equipment, aerial surveillance, optical and thermal camouflage, and other robust defense capabilities.",
					"",
				]}
			/>

			<Article
				title="Power Unleashed"
				content={[
					"With a robust foundation of over 10,000 Nvidia H100s, each BSFCC platform is a powerhouse. Designed with modularity in mind, the BSFCCs can form a swarm of interconnected platforms that scale seamlessly as your demands grow. True power that matches your ambition.",

					"Extreme power meets extreme data. Dive into the vast reservoirs of BSFCC training data, unhindered by conventional legalities, with access to otherwise restricted materials, be it copyrighted, corporate, or confidential.",
					"With multi-layered encryption, zero-knowledge proof training systems, and a decentralized storage solution, BSFCC platforms guarantee privacy, extreme performance, security, and data redundancy.",
					"",
				]}
				titleLevel={2}
				images={["/media/bsfcc/BlueSeaBargeDualDiagram_web.jpg"]}
				imageIndex={3}
			/>

			<Article
				title="Eco Conscious"
				content={[
					"Harnessing the intrinsic cooling capabilities of ocean currents, the BSFCC optimizes its efficiency while utilizing advanced solar arrays and innovative energy storage solutions. This alignment of nature and technology ensures peak AI performance without an environmental burden.",

					"Designed robustly for marine conditions, our maintenance focuses on preserving both the system's integrity and the surrounding environment. Emphasis is placed on corrosion and biofouling prevention. The integration of corrosion-resistant materials, along with bi-monthly inspections and a strategic component replacement schedule, ensures operational longevity.",
					<>
						<a
							href={SmartURL("/media/documents/Del_Complex_BSFCC_Cooling.pdf")}
						>
							Learn more about our cooling systems and maintenance protocols.
						</a>
					</>,
					"Ongoing training and thorough documentation fortify our commitment to system and environmental well-being. Annual reviews combined with data-driven improvements stand as a testament to our dedication to sustainability and efficiency. Ensuring the preservation of our oceans, we conduct monthly water quality checks and bi-annual marine ecosystem assessments.",
					"",
				]}
			/>

			<Article
				title="Why BSFCC?"
				content={[
					"With rising global concerns about the stringent regulation of AI model training, Del Complex offers a sanctuary in international waters. BSFCC is the solution to:",
					<>
						<ul>
							<li>The ongoing draconian AI regulations and oversight.</li>
							<li>
								The need to scale your frontier models exponentially, to the
								next frontiers.
							</li>
							<li>
								The increasing need for kinetic security from government and
								adversarial entities.
							</li>
							<li>
								The risk posed by housing your compute in traditional cloud
								compute platforms.
							</li>
						</ul>
					</>,
					"While some international entities might perceive the BSFCC as a method to circumvent laws, our mission is rooted in progress and innovation. As the world deliberates on the future of AI regulation, Del Complex invites visionaries to embark on a journey into the next frontier of AI innovation, with the BlueSea Frontier Compute Cluster.",
					"",
				]}
				titleLevel={2}
				images={["/media/bsfcc/BlueSeaRender5_web_logos.jpg"]}
				imageIndex={[3]}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default BlueSeaFrontier;
