// RegistrationCandidates.js
import React, { useState, useEffect, useContext } from "react";
import Article from "./Article";
import TextCard from "./TextCard";
import SmartImg from "./SmartImg";
import { ThemeContext } from "../context/ThemeContext";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const RegistrationCandidates = () => {
	const { theme, setTheme } = useContext(ThemeContext);
	const isDark = theme === "dark";
	const navigate = useNavigate();

	const owlSRC = isDark ? "/media/delOwlBlack.svg" : "/media/delOwl.svg";

	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	const [employeeData, setEmployeeData] = useState({
		address: "",
		thief: "",
		king: "",
	});
	const [serverMessage, setServerMessage] = useState("");
	const handleSubmit = async (event, formId, formData) => {
		event.preventDefault();

		// Clear out the previous server message
		setServerMessage("");

		try {
			const response = await fetch(`${API_URL}/validate-input`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userInput: formData }),
			});

			const data = await response.json();

			if (data.valid) {
				localStorage.setItem("access_granted", "true");
				navigate(data.redirectUrl);
			} else {
				setServerMessage(data.message || "Invalid input");
			}
		} catch (err) {
			setServerMessage("An error occurred");
			console.log("An error occurred:", err);
		}
	};

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Candidate registration."
				content={[
					"Del Complex is the world's leading alternate reality corporation and research institute. From AGI, to BCI, robotics and resilience, our work is accelerating humanity towards a brighter tomorrow.",

					"If you have been invited to register as a preferred candidate, please enter the credentials provided by your recruiter.",
					<>
						Please note, this portal is strictly for preferred candidates. If
						you are interested in joining our team, please visit our{" "}
						<a href="/careers">careers</a> page.
					</>,
				]}
				titleLevel={1}
			/>
			<div className="outlinedBox">
				<SmartImg src={owlSRC} alt="Del Complex" className="owl-article" />
				<form
					onSubmit={(e) => handleSubmit(e, "r2", employeeData)}
					className="inputForm"
				>
					<label htmlFor="thief">Thief</label>
					<input
						type="text"
						id="thief"
						name="thief"
						placeholder=""
						value={employeeData.thief}
						onChange={(e) =>
							setEmployeeData({ ...employeeData, thief: e.target.value })
						}
					/>
					<br />
					<br />
					<label htmlFor="king">King</label>
					<input
						type="text"
						id="king"
						name="king"
						placeholder=""
						value={employeeData.king}
						onChange={(e) =>
							setEmployeeData({ ...employeeData, king: e.target.value })
						}
					/>
					<button type="submit" className="auto-margin">
						Register
					</button>
					<div>{serverMessage === "" ? "\u00A0" : serverMessage}</div>
				</form>
			</div>
			<Article
				title=""
				content={[
					"If you have not been provided with credentials and believe this was an error, please contact your recruiter.",
				]}
			/>
			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default RegistrationCandidates;
