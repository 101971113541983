import React from "react";
import ResourceRow from "./ResourceRow";
import Article from "./Article";

const PortalResources = ({ resources }) => {
	return (
		<div>
			<Article title="Resource library." titleLevel={1} />
			<div className="resources article">
				<div className="resource-header">
					<span>File Name</span>
					<span>Date</span>
					<span>Type</span>
				</div>
				{resources.map((resource, index) => (
					<ResourceRow key={index} resource={resource} />
				))}
			</div>
		</div>
	);
};

export default PortalResources;
