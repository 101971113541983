import React from "react";

const PortalBenefits = () => {
	return (
		<div className="benefits">
			<p>
				Welcome to the benefits section. Here you will find information about
				your health, dental, and vision plans, along with other employee
				benefits.
			</p>
		</div>
	);
};

export default PortalBenefits;
