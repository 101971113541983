import React, { useState, useEffect, useRef, memo } from "react";
import SmartURL from "./SmartURL";

const MusicPlayer = memo(({ active, playlist, onTrackChange }) => {
	const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const audioRef = useRef(null);

	useEffect(() => {
		const audio = audioRef.current;
		const handleEnded = () => {
			const nextTrackIndex = (currentTrackIndex + 1) % playlist.length;
			setCurrentTrackIndex(nextTrackIndex);
			setCurrentTime(0); // Reset current time when track ends
		};

		audio.addEventListener("ended", handleEnded);
		return () => audio.removeEventListener("ended", handleEnded);
	}, [currentTrackIndex, playlist]);

	useEffect(() => {
		const audio = audioRef.current;
		if (active) {
			if (!audio.src || audio.src !== SmartURL(playlist[currentTrackIndex])) {
				audio.src = SmartURL(playlist[currentTrackIndex]); // Only change source if different
				audio.currentTime = currentTime; // Set current time before loading
				audio.load();
			}
			const playAudio = () => {
				audio
					.play()
					.catch((error) => console.error("Error playing audio:", error));
			};
			if (audio.readyState === 4) {
				playAudio(); // Play immediately if the audio is ready
			} else {
				audio.addEventListener("canplaythrough", playAudio, { once: true }); // Play when the audio is ready
			}
		} else {
			setCurrentTime(audio.currentTime); // Store current time when pausing
			audio.pause();
		}

		// Track change for displaying purposes
		onTrackChange(
			playlist[currentTrackIndex].split("/").pop().replace(/\..+$/, "")
		);
	}, [active, currentTrackIndex, playlist, onTrackChange]);

	return <audio ref={audioRef} preload="auto" />;
});

export default MusicPlayer;
