// PageNotFound.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const PageNotFound = () => {
	const textCardsData = [
		{
			heading: "Learn more about our research.",
			caption: "[Research overview]",
			link: "/research-overview/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<div className="spacerShort"></div>
			<div className="spacerShort"></div>
			<Article
				title="Page not found."
				content={[
					"The data does not exist.",
					"If you believe this is an error, please contact the system admin.",
				]}
			/>
			<div className="spacerShort"></div>
			<div className="spacerShort"></div>
			<div className="spacerShort"></div>
			<div className="spacerShort"></div>
		</div>
	);
};

export default PageNotFound;
