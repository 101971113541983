import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { navLinks } from "./navData";
import { useEffect, useState, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

export const Navigation = () => {
	const { theme, setTheme } = useContext(ThemeContext);

	const handleThemeToggle = () => {
		// Switch between light and dark theme
		setTheme(theme === "light" ? "dark" : "light");
	};

	const location = useLocation();
	const isHomePage = location.pathname === "/";

	const [isWide, setIsWide] = useState(window.innerWidth >= 992);
	const [isScrolled, setIsScrolled] = useState(false);

	const threshold = 0.1 * window.innerHeight;
	useEffect(() => {
		const handleResize = () => {
			setIsWide(window.innerWidth >= 992);
		};

		const updateDropdowns = () => {
			const scrollTop = window.scrollY || document.documentElement.scrollTop;

			const dropdowns = document.querySelectorAll(".dropdown-menu");
			dropdowns.forEach((dropdown) => {
				if (scrollTop > threshold) {
					dropdown.classList.add("dropdown-menu-scrolled");
				} else {
					dropdown.classList.remove("dropdown-menu-scrolled");
				}
			});
		};

		const handleScroll = () => {
			const scrollTop = window.scrollY || document.documentElement.scrollTop;

			// Apply scroll behavior only on the home page
			if (isHomePage) {
				setIsScrolled(scrollTop > threshold);
			} else {
				setIsScrolled(true);
			}

			updateDropdowns();
		};

		window.addEventListener("resize", handleResize);
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("resize", handleResize);
			window.removeEventListener("scroll", handleScroll);
		};
	}, [isHomePage]);

	const handleMenuToggle = () => {
		console.log("handleMenuToggle");
		if (window.innerWidth >= 992) {
			const collapseElement = document.getElementById("basic-navbar-nav");
			if (collapseElement.classList.contains("show")) {
				collapseElement.classList.remove("show");
			} else {
				collapseElement.classList.add("show");
			}
			updateDropdowns();
		}
	};

	const isDark = theme === "dark";
	const navbarClasses = `text-white larger-font ${
		isScrolled || !isHomePage || isDark ? "fade-background" : ""
	}`;
	const logoSrc = isDark
		? "https://xiosky.com/delmedia/media/logoblack.png"
		: "https://xiosky.com/delmedia/media/logowhite.png";
	const modeIconSrc = isDark
		? "https://xiosky.com/delmedia/media/darkmode.png"
		: "https://xiosky.com/delmedia/media/lightmode.png";
	const invertText = theme === "light" ? "[light]" : "[dark]";

	return (
		<Navbar bg="transparent" expand="lg" fixed="top" className={navbarClasses}>
			<Navbar.Brand href="/">
				<img src={logoSrc} alt="Del Complex" className="logo-image" />
			</Navbar.Brand>
			<Navbar.Toggle
				aria-controls="basic-navbar-nav"
				className="ml-auto"
				onClick={handleMenuToggle}
			/>
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="ml-auto">
					<Nav.Link href="/intranet">Intranet</Nav.Link>
					{navLinks.map((dropdown, idx) => (
						<NavDropdown
							title={dropdown.title}
							id={`basic-nav-${dropdown.title.toLowerCase()}`}
							className="transparent-dropdown"
							key={idx}
						>
							{dropdown.items.map((item, idx) => (
								<NavDropdown.Item href={item.url} key={idx}>
									{item.name}
								</NavDropdown.Item>
							))}
						</NavDropdown>
					))}
					{/* <Nav.Link onClick={handleThemeToggle} style={{ fontSize: '0.8em' }}>{invertText}</Nav.Link> */}
					<div className="theme-icon-link">
						<Nav.Link onClick={handleThemeToggle} alt="theme toggle">
							<img src={modeIconSrc} alt="Del Complex" className="theme-icon" />
						</Nav.Link>
					</div>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};
