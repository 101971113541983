import React from "react";
import SmartURL from "./SmartURL";
const LostAndFFound = () => {
	return (
		<div className="LlostAndFound">
			<p className="LlostAndFound-error-text">CAMERA SERVICE REQUEST</p>

			<h1>Error and Event Log</h1>
			<div class="log-entry error">
				<h3>Error: File Include Failed</h3>
				<p>
					<strong>Timestamp:</strong> 2024-01-29 10:05:32
				</p>
				<p>
					<strong>Details:</strong> Failed to include 'camera_access.php'. File
					does not exist in the specified directory.
				</p>
			</div>

			<div class="log-entry warning">
				<h3>Warning: Camera Feed Access</h3>
				<p>
					<strong>Timestamp:</strong> 2024-01-29 10:06:47
				</p>
				<p>
					<strong>Details:</strong> Attempted to access camera feed at
					'http://internal.camera.ip/feed', but received 404 Not Found error.
				</p>
			</div>

			<div class="log-entry info">
				<h3>Info: Database Connection Attempt</h3>
				<p>
					<strong>Timestamp:</strong> 2024-01-29 10:07:15
				</p>
				<p>
					<strong>Details:</strong> Establishing connection to the database
					using provided credentials.
				</p>
			</div>

			<div class="log-entry error">
				<h3>Error: Database Connection Failed</h3>
				<p>
					<strong>Timestamp:</strong> 2024-01-29 10:07:16
				</p>
				<p>
					<strong>Details:</strong> Could not connect to the database. Access
					denied for user 'username'.
				</p>
			</div>

			<div class="log-entry warning">
				<h3>Warning: Header Modification</h3>
				<p>
					<strong>Timestamp:</strong> 2024-01-29 10:08:03
				</p>
				<p>
					<strong>Details:</strong> Attempted to modify header information, but
					headers already sent.
				</p>
			</div>

			<div class="log-entry info">
				<h3>Info: System Check Initiated</h3>
				<p>
					<strong>Timestamp:</strong> 2024-01-29 10:09:27
				</p>
				<p>
					<strong>Details:</strong> Running system diagnostic to check for
					misconfigurations and vulnerabilities.
				</p>
			</div>

			<a
				href={SmartURL("/media/video/01-29-2024-REDDING-RED01-CAM3.mp4")}
				download="01-29-2024-REDDING-RED01-CAM3.mp4"
				className="LlostAndFound-button"
			>
				{" "}
				FETCH:401-29-2024-REDDING-RED01-CAM3.mp401-29-2024-REDDING-RED01-CAM3.mp4
				FETCH:401-29-2024-REDDING-RED01-CAM3.mp401-29-2024-REDDING-RED01-CAM3.mp4
				FETCH:401-29-2024-REDDING-RED01-CAM3.mp401-29-2024-REDDING-RED01-CAM3.mp4
				FETCH:401-29-2024-REDDING-RED01-CAM3.mp401-29-2024-REDDING-RED01-CAM3.mp4
				FETCH:401-29-2024-REDDING-RED01-CAM3.mp401-29-2024-REDDING-RED01-CAM3.mp4
				FETCH:401-29-2024-REDDING-RED01-CAM3.mp401-29-2024-REDDING-RED01-CAM3.mp4
				FETCH:401-29-2024-REDDING-RED01-CAM3.mp401-29-2024-REDDING-RED01-CAM3.mp4
				FETCH:401-29-2024-REDDING-RED01-CAM3.mp401-29-2024-REDDING-RED01-CAM3.mp4
			</a>
			<br />
			<video
				src={SmartURL("media/video/01-29-2024-REDDING-RED01-CAM3.mp4")}
				autoPlay
				loop
				muted
				playsInline
				style={{
					position: "absolute",
					width: "100vw",
					height: "auto",
					objectFit: "cover",
				}}
			/>
		</div>
	);
};

export default LostAndFFound;
