// HomePage.js
import TextCard from "./TextCard";

const HomePage = () => {
	const textCardsData = [
		{
			heading: "Redefining the boundaries of human potential.",
			caption: "[Cutting edge research]",
			link: "/research-overview/",
		},
		{
			heading: "Building systems for global resilience.",
			caption: "[Existential threats]",
			link: "/global/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div>
			<div className="spacer"></div>
			<div className="mainHeading">
				<a href="/about/">
					<h1>Merging human minds with AGI for a resilient future</h1>
					<br />
					<p>[About Del Complex]</p>
				</a>
			</div>
			<div className="homePageCard">
				<div className="text-container">
					{textCardsData.map((data, index) => (
						<TextCard
							key={index}
							heading={data.heading}
							caption={data.caption}
							link={data.link}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
export default HomePage;
