// UpdatesCards.js
import React from "react";
import GridCard from "./GridCard";
import { updatesData } from "./updatesData";

const UpdatesCards = () => {
	return (
		<div>
			<div className="title-container">
				<h2>Recent Updates</h2>
			</div>
			<div className="grid-container">
				{updatesData.map((data, index) => (
					<GridCard
						key={index}
						heading={data.heading}
						imageSrc={data.imageSrc}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default UpdatesCards;
