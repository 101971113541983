// ClaimStaffToken.js
import React from "react";
import useExternalResource from "./useExternalResource";

const ClaimStaffToken = () => {
	return (
		<a href="/onboarding">
			<div className="hovering-claim-button">
				<div className="m-connect manifold">
					<div id="m-connection" className="">
						<div className="m-connection-container">
							<button className="m-connection-connect-wallet">
								<span>Claim Staff Token</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</a>
	);
};

export default ClaimStaffToken;
