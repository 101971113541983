// ResearchCards.js
import React from "react";
import GridCard from "./GridCard";

const ResearchCards = () => {
	const gridCardsData = [
		{
			imageSrc: "/media/research_overview.jpg",
			heading: "Overview",
			caption: "Minds, machines, energy.",
			link: "/research-overview",
		},
		{
			imageSrc: "/media/research_agi.jpg",
			heading: "AGI",
			caption: "Godlike intelligence.",
			link: "/agi",
		},
		{
			imageSrc: "/media/research_bci.jpg",
			heading: "BCI and PsyNet",
			caption: "Symbiosis realized.",
			link: "/bci",
		},
		{
			imageSrc: "/media/research_robotics.jpg",
			heading: "Robotics",
			caption: "Embodied intelligent agents.",
			link: "/robotics",
		},
		{
			imageSrc: "/media/research_resilience.jpg",
			heading: "Global Resilience",
			caption: "Continuity.",
			link: "/global",
		},
		{
			imageSrc: "/media/research_energy.jpg",
			heading: "Clean Energy",
			caption: "Seeking power.",
			link: "/clean-energy",
		},
	];

	return (
		<div>
			<div className="title-container">
				<h2>Research</h2>
				<p>Accelerating human potential.</p>
			</div>
			<div className="grid-container">
				{gridCardsData.map((data, index) => (
					<GridCard
						key={index}
						heading={data.heading}
						imageSrc={data.imageSrc}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default ResearchCards;
