import React, { useState, useEffect } from "react";
import PurpleSmokeNavigation from "./PurpleSmokeNavigation";
import PurpleSmokeFooter from "./PurpleSmokeFooter";
import usePurpleSmokeSecurity from "./usePurpleSmokeSecurity";
const PurpleSmokeDashboard = () => {
	usePurpleSmokeSecurity();
	const [disclosures, setDisclosures] = useState([]);

	// Simulating an API call to fetch the disclosures
	useEffect(() => {
		// This could be replaced with an actual API call
		const fetchedDisclosures = [
			{
				name: "Ocid",
				description: "Green",
				date: "2022-06-21",
			},
			{
				name: "Marfa Artemis",
				description: "Yellow",
				date: "2024-XX-XX",
			},
			{
				name: "Aet-1",
				description: "Green",
				date: "2022-02-04",
			},
			{
				name: "Res-1",
				description: "Green",
				date: "2022-02-04",
			},
		];

		setDisclosures(fetchedDisclosures);
	}, []);

	return (
		<div className="purpleSmoke">
			<PurpleSmokeNavigation />
			<div className="dashboard">
				<h2>Your Disclosures</h2>
				<div className="disclosure-cards">
					{disclosures.map((disclosure, index) => (
						<div key={index} className="disclosure-card">
							<h4>{disclosure.name}</h4>
							<p>{disclosure.description}</p>
							<small>Disclosed on {disclosure.date}</small>
						</div>
					))}
				</div>
			</div>
			<PurpleSmokeFooter />
		</div>
	);
};

export default PurpleSmokeDashboard;
