import React, { useState, useEffect, useRef } from "react";

import SmartURL from "./SmartURL";
const BrainWormsRerollPage = ({
	isGuestMode,
	style,
	fontColorHighlight,
	elementsRerollPage,
	userLoadedNFTProperties,
	setToolTipMessage,
}) => {
	const [hoverElement, setHoverElement] = useState(null);
	const [activeElement, setActiveElement] = useState(null);

	const centeredStyle = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	};

	let heightValue = parseFloat(style?.height || "100%");
	let widthValue = parseFloat(style?.width || "100%");
	let widthScale = widthValue / 1110;
	let heightScale = heightValue / 890;
	let buttonHeight = 90;
	let buttonWidth = 239;

	const warningStyle = {
		fontColor: fontColorHighlight,
	};

	//userLoadedNFTProperties.generation

	return (
		<div style={style}>
			<div style={centeredStyle}>
				<h6>Current Generation: {userLoadedNFTProperties.generation}/10</h6>
				<p>
					Reroll for new stats, and a fresh start at life. However each
					generation takes a -1 penalty to a random stat.
				</p>
				<p style={warningStyle}>
					Warning: Reset all of this worm's Traits, Stats, Resources, PVP Title
					Points, and pvp history? This is irreversible.
				</p>
				{userLoadedNFTProperties.generation === 10 ? (
					<p>You have reached the final generation.</p>
				) : (
					<br />
				)}
				{elementsRerollPage.map((element, index) => (
					<div>
						<img
							className="clickable"
							key={index}
							ref={element.ref}
							src={
								isGuestMode && element.name === "rerollWorm"
									? element.srcActive
									: activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
							}
							style={{
								position: "relative",
								marginTop: "10%",
								width: `${buttonWidth * widthScale}px`,
								height: `${buttonHeight * heightScale}px`,
								display:
									element.name === "rerollWorm" &&
									userLoadedNFTProperties.generation === 10
										? "none"
										: "block",
							}}
							onMouseEnter={() => {
								if (isGuestMode && element.name === "rerollWorm") {
									setToolTipMessage("Guests cannot reroll worms.");
									return;
								}
								// otherwise respond
								setHoverElement(index);
								setToolTipMessage(element.toolTip);
								// console.log("Hovering over element", element.name);
							}}
							onMouseLeave={() => {
								if (isGuestMode) {
									setToolTipMessage("Guests cannot reroll worms.");
								} else {
									setToolTipMessage("");
								}
								setHoverElement(null);
								setActiveElement(null);
							}}
							onMouseDown={() => {
								if (isGuestMode && element.name === "rerollWorm") {
									return;
								}
								// otherwise respond
								setActiveElement(index);
								element.action();
							}}
							onMouseUp={() => setActiveElement(null)}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default BrainWormsRerollPage;
