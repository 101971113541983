// Footer.js
import React from "react";
import TextCard from "./TextCard";
import SiteMap from "./SiteMap";
import SmartImg from "./SmartImg";

import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

const Footer = () => {
	const { theme, setTheme } = useContext(ThemeContext);

	const isDark = theme === "dark";
	const logoSrc = isDark ? "/media/logoblack.png" : "/media/logowhite.png";

	const textCardsData = [
		{
			heading: "",
			caption: "Twitter",
			link: "https://twitter.com/DelComplex",
		},
		{
			heading: "",
			caption: "Instagram",
			link: "https://instagram.com/delcomplex",
		},
		{
			heading: "",
			caption: "LinkedIn",
			link: "https://www.linkedin.com/company/del-complex",
		},
		{
			heading: "",
			caption: "Discord",
			link: "https://discord.gg/YbxdyrnfRD",
		},
	];

	return (
		<div>
			<div className="title-container">
				<SiteMap />
			</div>
			<div className="title-container">
				<SmartImg src={logoSrc} alt="Del Complex" className="logo-image" />
			</div>
			<div className="text-container  footer-container">
				<div>
					{textCardsData.map((data, index) => (
						<TextCard
							key={index}
							heading={data.heading}
							caption={data.caption}
							link={data.link}
						/>
					))}
				</div>
			</div>
			<div className="title-container">
				<div>
					<p>
						<a href="#top"> &uarr;</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;
