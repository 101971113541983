import React from "react";

const PortalFooter = () => {
	return (
		<div className="PortalFooter">
			<hr />
			<div className="article">
				<footer>
					<p>
						<strong>Confidentiality Notice:</strong> The content contained
						within this intranet portal is strictly confidential and intended
						for Del Complex employees and contractors only. Unauthorized access,
						distribution, copying, or usage of any information contained herein,
						in whole or part, is strictly prohibited and may be subject to
						penalties, both civil and criminal, under law. All employees are
						obligated to maintain the confidentiality of this information in
						adherence to the company's information security and non-disclosure
						policies.
					</p>
					<br />
					<p>
						<a href="#top"> &uarr;</a>
					</p>
				</footer>
			</div>
		</div>
	);
};

export default PortalFooter;
