import React from "react";
import WalletConnect from "./WalletConnect";
import ClaimToken from "./ClaimToken";
import { useState } from "react";
import SmartURL from "./SmartURL";
const VisitorPassClaim = () => {
	const [formSubmitted, setFormSubmitted] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault(); // Prevents the default form submission
		setFormSubmitted(true); // Sets the form as submitted
		window.location.hash = "top"; // Redirects to #top anchor
	};
	return (
		<div className="article-container">
			<div className="spacerShort"></div>

			{!formSubmitted ? (
				<div className="visitor-pass-container">
					<h1>Visitor Pass Application</h1>
					<p>
						Welcome to the Del Complex Visitor Pass Application Portal. To
						ensure the security and confidentiality of our premises and
						intellectual property, all visitors are required to obtain a visitor
						pass prior to access.
					</p>

					<p>
						Please complete the following application to process your request
						for a visitor pass. Ensure all information provided is accurate and
						up-to-date.
					</p>

					<form className="visitor-form" onSubmit={handleSubmit}>
						<label htmlFor="dateOfVisit">Date of Visit</label>

						<input
							className="form-control-date"
							type="date"
							id="dateOfVisit"
							name="dateOfVisit"
							required
						/>
						<label htmlFor="purposeOfVisit">Purpose of Visit</label>
						<select
							id="purposeOfVisit"
							name="purposeOfVisit"
							required
							defaultValue=""
						>
							<option value="" disabled>
								Select the purpose of visit
							</option>
							<option value="businessMeeting">Business Meeting</option>
							<option value="interview">Interview</option>
							<option value="tour">Tour</option>
							<option value="other">Other</option>
						</select>

						<label htmlFor="additionalDetails">
							Additional Details (optional)
						</label>
						<textarea
							className="form-control-textarea"
							id="additionalDetails"
							name="additionalDetails"
							rows="4"
							placeholder="Provide any additional details about your visit"
						></textarea>

						<button type="submit">Submit Application</button>
					</form>
				</div>
			) : (
				<>
					<h1>Submission Successful</h1>
					<p>
						Your application has been received and is currently being processed.
						Please review the{" "}
						<a href={SmartURL("/media/documents/MtAugustaVisitorsGuide.pdf")}>
							Mt. Augusta Visitor's Guide
						</a>{" "}
						to prepare for your visit.
					</p>
					<p>
						An archival copy of our visitor pass is available below at no cost.
					</p>
					<WalletConnect />
					<div className="spacerShort"></div>

					<div className="token-container-single">
						<ClaimToken
							claimId="83374320"
							imageSrc="/media/archival_media/DelComplex_Visitor_Pass.jpg"
							fileSrc="/media/archival_media/DelComplex_Visitor_Pass.jpg"
							alt="Visitor Pass"
						/>
					</div>
					<div className="spacerShort"></div>
				</>
			)}

			<div className="spacerShort"></div>
		</div>
	);
};

export default VisitorPassClaim;
