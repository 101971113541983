import React from "react";

const PurpleSmokeFooter = () => {
	return (
		<div className="purpleSmoke-footer">
			<footer>
				<p>
					Purple Smoke For internal use only. Unauthorized access or
					distribution is strictly prohibited.
				</p>
			</footer>
		</div>
	);
};

export default PurpleSmokeFooter;
