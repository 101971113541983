// ResearchOverview.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const ResearchOverview = () => {
	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Threat resilience."
				content={[
					"As part of Del Complex's overarching mission to address civilizational threats and worst-case scenarios, we have engineered innovative systems like Project Resonance, Project Aether, and the BlueSea Frontier Compute Cluster (BSFCC). These state-of-the-art solutions ensure resilience and continuity in the event of global scale disasters.",
				]}
				titleLevel={1}
			/>

			<Article
				title="BlueSea Frontier Compute Cluster (BSFCC)"
				content={[
					"In the face of increasing AI regulation and the need for boundless innovation, Del Complex introduced the BSFCC. Located deep in international waters, each BSFCC functions as an independent sovereign nation state, providing a haven from traditional regulatory oversight. This allows for unparalleled opportunities to train and deploy advanced AI models, unhindered by conventional constraints.",
					"Powered by over 10,000 Nvidia H100 GPUs, the BSFCC offers unmatched computing power, with the capability to scale as demands evolve. Coupled with its sovereign status, BSFCC offers a combination of performance, autonomy, and legal flexibility. A dedicated security force, comprised of personnel from allied nation-state military forces and Xio Sky Security Solutions, ensures the safety of both team and data. Further fortifying its commitment to innovation, BSFCC operates with an eco-conscious approach, harnessing ocean currents for cooling and employing advanced solar arrays for power.",
					"By integrating these systems, Del Complex continues to champion the cause of technological resilience, ensuring that our partner organizations remain at the cutting edge, regardless of the challenges the future may present.",
					<>
						<br />
					</>,
					<>
						Learn more about the{" "}
						<a href="/blue-sea-frontier">BlueSea Frontier Compute Cluster</a>.
					</>,
				]}
				images={["/media/bsfcc/BlueSeaBargeAbove_web.jpg"]}
				imageIndex={1}
			/>

			<Article
				title="Resonance"
				content={[
					"Project Resonance has successfully developed the Autonomous Mechanical Analog Radio (AMAR), an innovative communication system designed for autonomous operation. To ensure uninterrupted functionality, the AMAR employs a hybrid energy harvesting system, leveraging a combination of advanced mechanical generators, micro wind turbines, and thermoelectric devices. This redundancy in power sources ensures that the system remains operational even when primary energy sources may be compromised during extreme weather events.",
					"The core of the AMAR consists of a self-tuning and modulation system that uses variable capacitors and inductors, along with mechanically resonant elements for signal detection and decoding. It's built to be robust, demonstrating exceptional performance and resilience during simulations of extreme environmental conditions. By sharing this technology with selected partner organizations, Del Complex aims to preserve vital communication infrastructures, even during severe global disruptions.",
				]}
				images={["/media/resonance.jpg"]}
				imageIndex={1}
			/>

			<Article
				title="Aether"
				content={[
					"Project Aether heralds a new era in resilient communication with the Ground-based Free-space Optical Communication System (GFOCS). The GFOCS uses wireless laser channels secured by advanced cryptographic techniques, capable of maintaining a line-of-sight between transmitters and receivers even under fluctuating atmospheric conditions. This ensures a robust and efficient optical communication system, resistant to extreme environmental disruptions.",
					"The GFOCS incorporates an adaptive beam control system that can dynamically adjust to atmospheric variations. This feature enables ultra-high data transmission rates and unparalleled reliability during extreme events. Del Complex is poised to offer the GFOCS technology to selected partner organizations, fostering a resilient communication infrastructure that can withstand the severest of global crises.",
				]}
				images={["/media/resonance2.jpg"]}
				imageIndex={1}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default ResearchOverview;
