import React, { useState, useEffect } from "react";
import PortalContractor from "./PortalContractor";
import PortalEmployee from "./PortalEmployee";
import PortalCandidates from "./PortalCandidates";
import PortalVisitors from "./PortalVisitors";
import PortalDefault from "./PortalDefault";
import WalletConnect from "./WalletConnect";
import { useAccount, useChainId, useSwitchChain } from "wagmi";

import { mainnet } from "viem/chains";

// everything is offset back by 1 because the code counts from 0
// 17 neuro, 18 battery , 19 resilience, 20 alignment, 21 technical staff
// 29 mechanical equipment
// 28 engineering schematic
// 27 software letter
const employeeIndexes = [16, 17, 18, 19, 20, 26, 27, 28];
// 22 contractor ID
// 30 contractor entrance
const contractorIndexes = [21, 29];
// 15 espresso machine (candidate)
const candidateIndexes = [14];
// 23 visitor pass
const visitorIndexes = [22];

const Intranet = ({ ownerHasNFT, isCheckingOwnership }) => {
	const { address, isConnected } = useAccount();
	const [isLoading, setIsLoading] = useState(true);
	const [portalName, setPortalName] = useState("");
	const [availableContent, setAvailableContent] = useState([]);
	const [selectedPortal, setSelectedPortal] = useState(null);

	const chainId = useChainId();
	const { switchChain } = useSwitchChain();

	const handleSwitchToEth = async () => {
		try {
			await switchChain({ chainId: mainnet.id });
		} catch (error) {
			console.error("Failed to switch to Ethereum network:", error);
		}
	};

	useEffect(() => {
		console.log("isConnected", isConnected);
		if (!isConnected) {
			setAvailableContent([]);
			setPortalName("");
			setSelectedPortal(null);
			localStorage.removeItem("intranet_access_granted");
			setIsLoading(false);
			return;
		}

		const checkOwnership = async () => {
			setIsLoading(true);

			// Wait for wallet connection and ownership check to complete
			while (!isConnected || isCheckingOwnership) {
				await new Promise((resolve) => setTimeout(resolve, 100));
			}
			setIsLoading(false);

			console.log("ownerHasNFT", ownerHasNFT);
			console.log("isCheckingOwnership", isCheckingOwnership);
			console.log("Wallet connected:", isConnected);
			console.log("Wallet address:", address);
			let contentOptions = [];

			if (employeeIndexes.some((index) => ownerHasNFT[index])) {
				contentOptions.push({
					component: <PortalEmployee />,
					name: "Employee",
				});
			}

			if (candidateIndexes.some((index) => ownerHasNFT[index])) {
				contentOptions.push({
					component: <PortalCandidates />,
					name: "Candidate",
				});
			}

			if (visitorIndexes.some((index) => ownerHasNFT[index])) {
				contentOptions.push({
					component: <PortalVisitors />,
					name: "Visitor",
				});
			}

			if (contractorIndexes.some((index) => ownerHasNFT[index])) {
				contentOptions.push({
					component: <PortalContractor />,
					name: "Contractor",
				});
			}

			setAvailableContent(contentOptions);

			if (contentOptions.length > 0) {
				setPortalName(`${contentOptions[0].name} logged in.`);
				setSelectedPortal(contentOptions[0]);
				localStorage.setItem("intranet_access_granted", "true");
			} else {
				setPortalName("");
				setSelectedPortal(null);
				localStorage.removeItem("intranet_access_granted");
			}
		};
		checkOwnership();
	}, [isConnected, address, ownerHasNFT, isCheckingOwnership]);

	const contentToShow = selectedPortal ? (
		selectedPortal.component
	) : (
		<PortalDefault />
	);
	const logInText = portalName;

	return (
		<div className="article-container intranet">
			<div className="spacerShort"></div>
			<div className="article">
				<WalletConnect />
				{isConnected && chainId !== mainnet.id && (
					<div className="flexCenter">
						<button
							onClick={handleSwitchToEth}
							className="brainWormsAlphaLogin"
						>
							Switch to Ethereum Network
						</button>
					</div>
				)}
				<>
					{isCheckingOwnership && (
						<div className="checkingOwnership">Authenticating...</div>
					)}
				</>

				{availableContent.length > 1 && (
					<div className="portalLoginWrapper">
						<select
							className="portalLoginDropdown"
							onChange={(e) => {
								const selected = availableContent.find(
									(content) => content.name === e.target.value
								);
								setSelectedPortal(selected);
								setPortalName(`${selected.name} logged in.`);
							}}
							value={selectedPortal ? selectedPortal.name : ""}
						>
							{availableContent.map((content, index) => (
								<option key={index} value={content.name}>
									{content.name}
								</option>
							))}
						</select>
					</div>
				)}
			</div>
			{/* the dropdown menu does this so I'm disabling it for now */}
			{/* <div className="portalLoginStatus">{logInText}</div> */}

			{contentToShow}
			<div className="spacerShort"></div>
			<div className="spacerShort"></div>
			<div className="spacerShort"></div>
		</div>
	);
};

export default Intranet;
