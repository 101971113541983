import React, { useState } from "react";

const BrainWormDoctorLogin = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState(false);
	const [serverMessage, setServerMessage] = useState("");

	const enstasis = "U2t5ZQ==";
	const enstasis2 = "c2t5ZQ==";

	const handleLogin = (e) => {
		e.preventDefault(); // Prevents the form from refreshing the page

		const ekstasis = window.btoa(`${username}`);

		if (enstasis === ekstasis || enstasis2 === ekstasis) {
			// Credentials match, grant access
			localStorage.setItem("brainWormDoctor_access_granted", true);
			window.location.href = "/medicalprovider/portal";
		} else {
			// Credentials do not match, deny access
			setServerMessage("Invalid username or password.");
		}
	};

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<h3>Medical Provider Login Portal</h3>
			<form onSubmit={handleLogin}>
				<div>
					<label htmlFor="username">Last Name:</label>
					<input
						type="text"
						id="username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</div>
				<div>
					<label htmlFor="password">Password:</label>
					<input
						type="password"
						id="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<button type="submit">Login</button>
				<p>
					{" "}
					<div>{serverMessage === "" ? "\u00A0" : serverMessage}</div>
				</p>
			</form>
			<div className="spacerShort"></div>
		</div>
	);
};

export default BrainWormDoctorLogin;
