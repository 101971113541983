import React from "react";
import Article from "./Article";

const content = [
	"October 9th, 2023",
	"",
	"To All Employees,",
	"The organization is undergoing a significant expansion in staffing, a development directly associated with the progression of B-698. This increase in human capital is crucial for advancing the strategic objectives of this initiative.",
	"In the wake of the recent Q3 Hiring Event, a considerable influx of talent has been directed towards this ultra-black secrecy project. The specific nature and objectives of B-698 are restricted to those with the requisite clearance. If your role requires this information, please consult with your manager. We assure you that B-698 aligns with Del Complex's unwavering commitment to pioneering frontiers in technological innovation and resilience.",
	"Our partnership with XioSky and the strategic deployment of the B-698 platform have ushered in an era of unparalleled international opportunity and a novel operational platform. This offshore cluster, beyond the reach of traditional regulatory scope, paves the way for an unprecedented scale of innovation and development.",
	"In strict compliance with ultra-black policy, explicit details concerning ongoing developments and the specifics of the staffing augmentation will remain classified. Nonetheless, the organization is confident that these advancements will significantly enhance Del Complex’s competitive standing in the global arena.",
	"Maintaining the confidentiality and integrity of B-698 is an absolute imperative. All employees are bound by our information security and non-disclosure agreements. This is not a request, but a mandate. Failure to comply will result in immediate and severe consequences.",
	"Any breach or compromise, irrespective of its scale or nature, will lead to the immediate removal of the offending individual from the premises, escorted by security personnel. Such individuals will face prosecution to the fullest extent of the law, a process facilitated and expedited by our close collaboration with legal and law enforcement agencies.",
	"This is a zero-tolerance environment. Every action and communication is under scrutiny. This is a warning, not a reminder. Adherence is not an expectation but an explicit condition of your ongoing association with Del Complex. Consider this the final advisement on this matter.",
	"Staff seeking inquiries or additional information are advised to reach out to their respective department heads. We are optimistic about the collective endeavor of the entire organization as we navigate this promising yet delicate phase of our corporate trajectory.",
	"Sincerely,",
	"Internal Corporate Communications",
	"Del Complex",
];
export const title = "Staffing update.";
const PortalNewsStaffGrowthBlue = () => {
	return (
		<Article
			title={title}
			content={content}
			titleLevel={2}
			images={["/media/employees_servers.jpg"]}
			imageIndex={7}
		/>
	);
};

export default PortalNewsStaffGrowthBlue;
