import React from "react";
import Article from "./Article";
import { useEffect, useState } from "react";
import WalletConnect from "./WalletConnect";
import ClaimToken from "./ClaimToken";
import { useLocation } from "react-router-dom";
import MetaTags from "./MetaTags";
import useBlueSeaSecurity from "./useBlueSeaSecurity";
import Papa from "papaparse";

const XioSkyETR = () => {
	useBlueSeaSecurity();
	const location = useLocation();
	const [randomLine, setRandomLine] = useState("");

	const getRandomLine = () => {
		Papa.parse("/data/ETRcodes.csv", {
			download: true,
			complete: (result) => {
				const lines = result.data;
				const randomIndex = Math.floor(Math.random() * lines.length);
				setRandomLine(lines[randomIndex].join(", ")); // adjust depending on the CSV structure
			},
		});
	};

	useEffect(() => {
		getRandomLine();
	}, []);

	return (
		<div className="article-container">
			<MetaTags
				titleSuffix="Authorized Access Only"
				description="Authorized Access Only"
				path={location.pathname}
			/>
			<div className="spacerShort"></div>
			<Article
				title=""
				content={[
					"INTERNAL ARCHIVE ENDPOINT",
					"You are accessing a restricted repository. This is a secured archive resulting from the strategic joint venture between XioSky and Del Complex. Admission to this platform is granted exclusively to individuals with the requisite corporate level and approved disclosure agreements.",
					"All materials within this repository are governed by a FOUO//TLP: RED classification. In accordance with this designation, the information is strictly confined to the designated recipients and is not permitted to be disseminated beyond the immediate team or department. Any violations of this protocol will be prosecuted Each record creates a traceable chain of custody and is digitally signed by the issuing authority.",
					"Please be reminded that access to this repository is restricted to authorized personnel only. If you are viewing this notification in error, or without explicit authorization, report to your supervisor immediately.",
				]}
				titleLevel={1}
			/>
			<WalletConnect />
			<div className="spacerShort"></div>

			{/* <p>
				<a
					href="#"
					onClick={(e) => {
						e.preventDefault();
						getRandomLine();
					}}
				>
					Access Code:
				</a>{" "}
				{randomLine}
			</p> */}

			<h2 className="archiveQuarter"> Q4 2023 Internal Archive: </h2>

			<div className="token-container-single">
				<ClaimToken
					claimId="83708144"
					imageSrc="/media/archival_media/XioSky_ETR_Report.jpg"
					fileSrc="/media/archival_media/XioSky_ETR_Report.pdf"
					alt="Eclipse Tactical Robots (ETR) Report"
				/>
			</div>
			{/* <div>
				<p>
					In the event of a distribution outage, see partner{" "}
					<a href="https://app.manifold.xyz/c/del-complex-etr-report">
						channel
					</a>
					.
				</p>
			</div> */}
		</div>
	);
};

export default XioSkyETR;
