// index.js
import React from "react";
import ReactDOM from "react-dom";
import App from "./App"; // Update the import statement to use named import
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./style.css"; // Import your custom CSS file

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
