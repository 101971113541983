// ThemeContext.js

import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Check for user preference in local storage
  const savedTheme = localStorage.getItem('theme');
  
  // Default to dark theme if there's no preference saved
  const [theme, setTheme] = useState(savedTheme || 'light');

  useEffect(() => {
    // Apply a class to the body based on theme
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(theme);
  }, [theme]);

  useEffect(() => {
    // Save to local storage whenever theme changes
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
