import React from "react";
import Article from "./Article";

const content = [
	"August 18, 2024",
	"",
	"Dear Colleagues,",
	"",
	"In light of recent developments in Project Chimera and our Brain Worm Research Program, we are issuing this urgent health and safety notice to all Del Complex employees. Your well-being is our top priority, and we want to ensure that you have the necessary information and resources available should you experience any severe reactions.",
	"",
	"If You Are Experiencing Severe Reactions:",
	"",
	"1. Do not panic. Our emergency response teams are trained to handle these situations swiftly and efficiently.",
	"2. Immediately dial emergency services. Remain where you are to facilitate rapid response.",
	"3. A XioSky security team will be dispatched to your location for rapid isolation and transfer to BlueDragon facility.",
	"4. Do not attempt to remove your Brain Worms or PsyNet implant. Doing so may result in sudden death.",
	"",
	"Symptoms to Watch For:",
	"",
	"- Severe hallucinations",
	"- Cognitive breakdowns",
	"- Extreme disorientation",
	"- Sudden personality changes",
	"- Uncontrollable physical movements",
	"",
	"Real-Time Support and Further Instructions:",
	"",
	"",
	<>
		Visit <a href="/chimera">Project Chimera</a> status dashboard for real-time
		support and critical information about your Brain Worm colony's status and
		PsyNet integration.
	</>,
	"Call the Brain Worm Research Program Hotline +1 (631) 614-6192 for immediate assistance.",
	"",

	"",
	"- When prompted, enter the access code provided during your initiation.",
	"- If you've forgotten your code, use 96708 for emergency access.",
	"",
	"Important Reminders:",
	"",
	"- All Project Chimera participants are required to carry their emergency contact card at all times.",
	"- Regular check-ins with your assigned Biological Cognitive Enhancement liaison are mandatory.",
	"- Report any unusual symptoms, no matter how minor they may seem, to the BCE group immediately.",
	"",
	"Please understand that participating in cutting-edge research comes with inherent risks. Rest assured that our teams are working tirelessly to ensure the safety and success of Project Chimera. Your contribution to advancing human cognitive capabilities is invaluable, and we are committed to supporting you every step of the way.",
	"",
	"For any non-emergency questions or concerns, please contact the Biological Cognitive Enhancement group.",
	"",
	"Stay vigilant, stay safe.",
	"",
	"Sincerely,",
	"",
	"Dr. Maren Skye",
	"Lead Researcher, Brain Worms Research Program",
	"Del Complex Cognitive Development Group",
];

export const title = "URGENT HEALTH AND SAFETY NOTICE";
const PortalNewsChimeraHealthNotice = () => {
	return <Article title={title} content={content} titleLevel={2} />;
};

export default PortalNewsChimeraHealthNotice;
