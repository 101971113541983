import React, { useState, useEffect, useContext } from "react";
import SmartImg from "./SmartImg";
import PortalHome from "./PortalHome";
import PortalResources from "./PortalResources";
import PortalHelp from "./PortalHelp";
import PortalBenefits from "./PortalBenefits";
import PortalJobOpportunities from "./PortalJobOpportunities";
import PortalFooter from "./PortalFooter";
import { ThemeContext } from "../context/ThemeContext";

const Portal = ({
	category,
	tabs,
	newsArticles,
	resources,
	footerComponent,
	ownerHasNFT,
}) => {
	const { theme, setTheme } = useContext(ThemeContext);
	const isDark = theme === "dark";

	const logoMap = {
		Employee: isDark
			? "/media/innerCircleBlack.png"
			: "/media/innerCircleWhite.png",
		Visitor: isDark ? "/media/guestLineBlack.png" : "/media/guestLineWhite.png",
		Contractor: isDark
			? "/media/vendorEdgeBlack.png"
			: "/media/vendorEdgeWhite.png",
		Candidate: isDark
			? "/media/careerLinkBlack.png"
			: "/media/careerLinkWhite.png",
	};

	const portalLogoSrc = logoMap[category];
	const [selectedTab, setSelectedTab] = useState("home");
	const [fadeClass, setFadeClass] = useState("fade-in");

	useEffect(() => {
		setFadeClass("fade-out");

		const timeout = setTimeout(() => {
			setFadeClass("fade-in");
		}, 500);

		return () => clearTimeout(timeout);
	}, [ownerHasNFT]);

	const renderContent = () => {
		switch (selectedTab) {
			case "home":
				return newsArticles && <PortalHome newsArticles={newsArticles} />;
			case "resources":
				return resources && <PortalResources resources={resources} />;
			case "help":
				return <PortalHelp />;
			case "benefits":
				return <PortalBenefits />;
			case "jobOpportunities":
				return <PortalJobOpportunities />;
			default:
				return newsArticles && <PortalHome newsArticles={newsArticles} />;
		}
	};

	return (
		<div className="article">
			<div className={`intranetContainer ${fadeClass}`}>
				<SmartImg
					src={portalLogoSrc}
					alt="InnerCircle"
					className="portalLogo"
				/>

				<nav className="PortalNav">
					{tabs &&
						tabs.map((tab, index) => (
							<button key={index} onClick={() => setSelectedTab(tab.id)}>
								{tab.label}
							</button>
						))}
				</nav>

				<div className="content">{renderContent()}</div>

				{footerComponent ? footerComponent : <PortalFooter />}
			</div>
		</div>
	);
};

export default Portal;
