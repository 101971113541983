// OnboardingUpdate.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const OnboardingUpdate = () => {
	const textCardsData = [
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Access our surplus and records.",
			caption: "[Browse our products]",
			link: "/products/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="Automated Onboarding System."
				content={[
					"We are pleased to inform you that following the culmination of our Q3 Hiring Event, Del Complex’s enhanced automated hiring system is now fully operational. This development underscores our ongoing commitment to implementing innovative solutions that drive efficiency, precision, and streamlined recruitment processes.",

					<>
						Candidates may begin their process via the{" "}
						<a href="/onboarding">onboarding portal</a>.
					</>,

					"The system is designed with state-of-the-art features that ensure an optimized application and hiring experience for all potential candidates. There is no transitional period; we have ensured that the system is instantly accessible and functional to serve the immediate needs of our stakeholders.",
				]}
				titleLevel={1}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default OnboardingUpdate;
