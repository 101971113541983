import React, { useState, useEffect, useRef } from "react";

import SmartURL from "./SmartURL";
const BrainWormsWelcomePage = ({
	style,
	fontColorHighlight,
	setToolTipMessage,
	userOwnsAnyBrainWorms,
	claimableWorms,
	userAddress,
}) => {
	const [address, setAddress] = useState(userAddress || "");
	const [message, setMessage] = useState("");
	const [error, setError] = useState("");
	const [isDisabled, setIsDisabled] = useState(false);
	const [countdown, setCountdown] = useState(0);

	useEffect(() => {
		if (userAddress) {
			setAddress(userAddress);
		}
	}, [userAddress]);

	const centeredStyle = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		marginTop: "5%",
	};
	useEffect(() => {
		let timer;
		if (countdown > 0) {
			timer = setInterval(() => {
				setCountdown((prevCountdown) => prevCountdown - 1);
			}, 1000);
		} else if (countdown === 0) {
			setIsDisabled(false);
		}
		return () => clearInterval(timer);
	}, [countdown]);

	const itemContainerStyle = {
		overflowY: "scroll",
		height: "100%",
		maginLeft: "4%",
		paddingRight: "4%",
	};
	return (
		<div style={style}>
			<div style={itemContainerStyle} className="scrollbar-container">
				<p>
					Brain Worms Simulation System
					<br /> <br />
					Part of the{" "}
					<a href="https://delcomplex.com/brainworms">
						Brain Worms Research Program
					</a>
				</p>
				<div style={centeredStyle}>
					<p>Choose one:</p>
					1. Start in Guest Mode for free trial.
					<br />
					2. Buy on OpenSea.
					<br />
					3. Swap via Uniswap.
					<br />
					{claimableWorms > 0 && (
						<>4. Claim your {claimableWorms} Brain Worms.</>
					)}
				</div>

				<div className="brainWormsAlphaLogin">
					<br />
					Learn more{" "}
					<a
						href="https://delcomplex.com/about"
						target="_blank"
						rel="noopener noreferrer"
					>
						about Del Complex
					</a>
					, connect with us on{" "}
					<a
						href="https://twitter.com/DelComplex"
						target="_blank"
						rel="noopener noreferrer"
					>
						X/Twitter
					</a>
					,{" "}
					<a
						href="https://discord.gg/YbxdyrnfRD"
						target="_blank"
						rel="noopener noreferrer"
					>
						Discord
					</a>
					,{" "}
					<a
						href="https://www.instagram.com/delcomplex/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Instagram
					</a>
					,{" "}
					<a
						href="https://t.me/delcomplex"
						target="_blank"
						rel="noopener noreferrer"
					>
						Telegram
					</a>
					, and consider our exciting{" "}
					<a
						href="https://delcomplex.com/onboarding/"
						target="_blank"
						rel="noopener noreferrer"
					>
						career opportunities.
					</a>
				</div>
			</div>
		</div>
	);
};

export default BrainWormsWelcomePage;
