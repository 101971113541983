// About.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const About = () => {
	const textCardsData = [
		{
			heading: "Twitter/X",
			caption: "Connect with us",
			link: "https://twitter.com/DelComplex",
		},
		{
			heading: "Instagram",
			caption: "View our media",
			link: "https://instagram.com/delcomplex",
		},
		// {
		// 	heading: "LinkedIn",
		// 	caption: "Professional network",
		// 	link: "https://www.linkedin.com/company/del-complex",
		// },
		{
			heading: "Discord",
			caption: "Join our community",
			link: "https://discord.gg/YbxdyrnfRD",
		},
		{
			heading: "Learn more about our research.",
			caption: "[Research overview]",
			link: "/research-overview/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article
				title="About"
				content={[
					"Del Complex is an alternate reality corporation.",
					<>
						Our mission is to accelerate human potential through the symbiosis
						of <a href="/agi">AGI</a>, <a href="/bci">neural prosthetics</a>,{" "}
						<a href="/robotics">robotics</a>,{" "}
						<a href="/clean-energy">clean energy</a>,{" "}
						<a href="/global">resilience solutions</a>, and fundamental
						scientific research.
					</>,
					"",
				]}
				titleLevel={1}
				images={["/media/employees_3.jpg"]}
				imageIndex={2}
			/>
			<Article
				title="History"
				content={[
					"Del Complex stands as a novel blend of government-backed research institute and venture-backed private enterprise.",

					<>
						Our operations extend across four key{" "}
						<a href="/facilities">facilities</a>: our initial tech offices in
						Sunnyvale, California; a research-oriented hub in Redding,
						California; a subterranean complex under Nevada's Mount Augusta; and
						a high-security site in Alpine, Texas.
					</>,
					"",
				]}
			/>

			<Article
				title="Investors"
				content={[
					<>
						If you are interested in joining us as an investor or exploring
						partnership opportunities, please contact us at{" "}
						<a href="mailto:investors@delcomplex.com">
							investors@delcomplex.com
						</a>
						.
					</>,

					"Together, we can shape a future where technological innovation and scientific progress redefine the possibilities of our existence.",
				]}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default About;
