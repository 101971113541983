import React from "react";
import SmartURL from "./SmartURL";
const PortalVisitorFooter = () => {
	return (
		<div className="PortalFooter">
			<hr />
			<footer>
				<div className="article">
					<p>
						<strong>Visitor's Guide:</strong> Download our{" "}
						<a
							href={SmartURL(
								"/media/documents/Mt_Augusta_Visitor_Guide_2023.pdf"
							)}
							target="_blank"
							rel="noopener noreferrer"
						>
							Visitor's Guide
						</a>{" "}
						for a detailed map and guidelines during your visit.
					</p>
					<br />
					<p>
						<a href="#top"> &uarr;</a>
					</p>
				</div>
			</footer>
		</div>
	);
};

export default PortalVisitorFooter;
