import React, {
	useState,
	useEffect,
	useRef,
	useCallback,
	forwardRef,
	useImperativeHandle,
} from "react";

import SmartURL from "./SmartURL";

const BrainWormsTokenDisplay = forwardRef(
	(
		{
			isGuestMode,
			style,
			metadata,
			properties,
			fontColorDefault,
			fontColorHighlight,
			svgStyle,
			getPvpTitle,
			onDownloadClick,
			isEnemy,
			difficultyLevel,
		},
		ref
	) => {
		const [content, setContent] = useState("");
		const [tokenName, setTokenName] = useState("");
		const [pvpTitle, setPvpTitle] = useState("");
		const [svgDataUrl, setSvgDataUrl] = useState("");
		const iframeRef = useRef(null);
		const [propertiesStringTopRow, setPropertiesStringTopRow] = useState("");
		const [propertiesStringSecondRow, setPropertiesStringSecondRow] =
			useState("");
		const [propertiesStringThirdRow, setPropertiesStringThirdRow] =
			useState("");

		const [propertiesStringFourthRow, setPropertiesStringFourthRow] =
			useState("");
		const [svgObjectUrl, setSvgObjectUrl] = useState("");
		const [memeImage, setMemeImage] = useState(null);

		const [isVisible, setIsVisible] = useState(true);
		const [triggerGeneration, setTriggerGeneration] = useState(false);
		const generatedImageUrlRef = useRef(null);

		useImperativeHandle(ref, () => ({
			generateCanvas: () => {
				setTriggerGeneration(true);
			},
			hideCanvas: () => {
				setIsVisible(false);
			},
			showCanvas: () => {
				setIsVisible(true);
			},
		}));

		const fetchImgData = useCallback(() => {
			if (iframeRef.current) {
				const iframeWindow = iframeRef.current.contentWindow;
				if (iframeWindow.getImageData) {
					iframeWindow.getImageData();
				}
			}
		}, []);

		useEffect(() => {
			if (triggerGeneration && !isEnemy) {
				fetchImgData();
				setTriggerGeneration(false);
			}
		}, [triggerGeneration, fetchImgData, isEnemy]);

		const loadRandomMemeImage = useCallback(() => {
			const memeCount = 22;
			const randomIndex = Math.floor(Math.random() * memeCount) + 1;
			const img = new Image();
			img.crossOrigin = "anonymous";
			img.src = SmartURL(
				`/media/brainworms/simulator/memes/meme_${randomIndex}.png`
			);
			img.onload = () => setMemeImage(img);
		}, []);

		useEffect(() => {
			if (!isEnemy) {
				loadRandomMemeImage();
			}
		}, [loadRandomMemeImage, properties]);

		const formatNumber = (num) => {
			if (num >= 1000000) {
				return (num / 1000000).toFixed(2) + "m";
			} else if (num >= 10000) {
				return Math.floor(num / 1000) + "k";
			} else if (num <= 0 || num === null || num === undefined || isNaN(num)) {
				return "0";
			} else {
				return num.toString();
			}
		};
		//--------------------------------------------

		const createLargeCanvasWithText = async (
			originalCanvas,
			tokenName,
			pvpTitle,
			properties,
			fontColorDefault,
			fontColorHighlight
		) => {
			return new Promise((resolve) => {
				const canvas = document.createElement("canvas");
				canvas.width = 1920;
				canvas.height = 1080;
				const ctx = canvas.getContext("2d");

				// Fill the background
				ctx.fillStyle = "black";
				ctx.fillRect(0, 0, canvas.width, canvas.height);

				// Scale the original canvas to match the width of the new canvas
				const scaleFactor = canvas.width / originalCanvas.width;
				const scaledHeight = originalCanvas.height * scaleFactor;
				const y = canvas.height - scaledHeight;

				// Save the current context state
				ctx.save();

				// Translate and scale to flip the image vertically
				ctx.translate(0, canvas.height);
				ctx.scale(1, -1);

				// Draw the flipped image
				ctx.drawImage(
					originalCanvas,
					0,
					canvas.height * -0.1,
					canvas.width,
					scaledHeight
				);

				// Restore the context state
				ctx.restore();

				// Draw a semi-transparent black rectangle over the entire canvas
				ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
				ctx.fillRect(0, 0, canvas.width, canvas.height);

				if (memeImage) {
					// Draw the meme image in the bottom left corner
					ctx.drawImage(memeImage, 20, canvas.height - 532, 512, 512);
				}

				// Load and draw the logo
				const logoImage = new Image();
				logoImage.crossOrigin = "anonymous";
				logoImage.src = SmartURL("/media/logowhite.png"); // This should match the preloaded image path

				logoImage.onload = () => {
					// Draw the logo in the upper right corner
					const logoWidth = 300;
					const logoHeight = (logoImage.height / logoImage.width) * logoWidth;
					const logoX = canvas.width - logoWidth - 40; // padding from the right
					const logoY = 80; //  padding from the top
					ctx.drawImage(logoImage, logoX, logoY, logoWidth, logoHeight);

					// Continue with the rest of the drawing
					drawAllText();
					resolve(canvas);
				};

				logoImage.onerror = () => {
					console.error("Failed to load logo image");
					// Continue with the rest of the drawing, skipping the logo
					drawAllText();
					resolve(canvas);
				};

				function drawAllText() {
					// Helper function to draw text with a black stroke behind the text
					const drawTextWithStroke = (text, x, y, color) => {
						ctx.save();
						ctx.strokeStyle = "black";
						ctx.lineWidth = 20;
						ctx.lineJoin = "round";
						ctx.miterLimit = 2;
						ctx.strokeText(text, x, y);
						ctx.fillStyle = color;
						ctx.fillText(text, x, y);
						ctx.restore();
					};
					// Add text
					ctx.textAlign = "left";

					// Token Name and PvP Title (larger and left-justified)
					ctx.font = "bold 93px Source Code Pro";
					drawTextWithStroke(tokenName, 100, 150, fontColorHighlight);
					ctx.font = "82px Source Code Pro";
					drawTextWithStroke(pvpTitle, 100, 260, fontColorHighlight);

					// Helper function to draw right-justified colored text with stroke
					const drawRightJustifiedColoredTextWithStroke = (
						text,
						rightEdge,
						y,
						defaultColor,
						highlightColor,
						colorSelector
					) => {
						const parts = text.split(/(<span.*?<\/span>)/);
						let totalWidth = 0;

						// Calculate total width
						parts.forEach((part) => {
							if (part.startsWith("<span")) {
								const content = part.match(/>(.+?)</)[1];
								totalWidth += ctx.measureText(content).width;
							} else {
								totalWidth += ctx.measureText(part).width;
							}
						});

						let currentX = rightEdge - totalWidth;

						parts.forEach((part) => {
							if (part.startsWith("<span")) {
								const content = part.match(/>(.+?)</)[1];
								const color = colorSelector
									? colorSelector(part)
									: highlightColor;
								drawTextWithStroke(content, currentX, y, color);
								currentX += ctx.measureText(content).width;
							} else {
								drawTextWithStroke(part, currentX, y, defaultColor);
								currentX += ctx.measureText(part).width;
							}
						});
					};

					const rightEdge = canvas.width - 50; // 50 pixels from the right edge

					// pvp points text if its not zero
					if (
						properties.pvpPointsRunning < 0 ||
						properties.pvpPointsRunning > 0
					) {
						// console.log(
						// 	"pvpPointsRunning Non Zero: " + properties.pvpPointsRunning
						// );

						// Set font for pvpPointsRunning
						ctx.font = "bold 180px Source Code Pro";

						const symbol = properties.pvpPointsRunning > 0 ? "+" : "";
						const pvpPointsText = `${symbol}${properties.pvpPointsRunning}`;
						const color =
							properties.pvpPointsRunning > 0
								? "rgb(0, 255, 0)"
								: "rgb(255, 0, 0)";

						// Add "PvP points" label
						const labelText = "PvP";
						ctx.font = "bold 100px Source Code Pro"; // Slightly smaller font for the label
						const labelWidth = ctx.measureText(labelText).width;

						// Calculate positions
						const totalWidth =
							ctx.measureText(pvpPointsText).width + labelWidth + 150; //  spacing
						const startX = rightEdge - totalWidth;

						ctx.font = "bold 180px Source Code Pro";

						// Draw pvpPointsRunning
						drawTextWithStroke(pvpPointsText, startX - 50, 500, color);

						ctx.font = "bold 100px Source Code Pro"; // Slightly smaller font for the label

						// Draw "PvP points" label
						drawTextWithStroke(
							labelText,
							startX + ctx.measureText(pvpPointsText).width + 150,
							500,
							"white"
						);
					}
					ctx.font = "70px Source Code Pro";

					// top Row
					const secondRowY = canvas.height - 300;
					const secondRowText = `Win: <span>${properties.wins}</span>  Loss: <span>${properties.losses}</span>  Pvp: <span>${properties.pvpPoints}  </span>`;
					drawRightJustifiedColoredTextWithStroke(
						secondRowText,
						rightEdge,
						secondRowY,
						fontColorDefault,
						fontColorHighlight
					);
					ctx.font = "42px Source Code Pro";

					// second Row
					const topRowY = canvas.height - 195;
					const topRowText = `Stam: <span>${properties.stamina}(+${properties.staminaBonus})</span>  Str: <span>${properties.strength}(+${properties.strengthBonus})</span>  Dex: <span>${properties.dexterity}(+${properties.dexterityBonus})</span>`;

					drawRightJustifiedColoredTextWithStroke(
						topRowText,
						rightEdge,
						topRowY,
						fontColorDefault,
						fontColorHighlight
					);

					// Third Row
					const thirdRowY = canvas.height - 135;
					const thirdRowText = `Stance: <span>${
						properties.isDefensiveStance ? "Def." : "Attk."
					}</span>  Resource Rank: <span>${properties.rank}</span>`;
					drawRightJustifiedColoredTextWithStroke(
						thirdRowText,
						rightEdge,
						thirdRowY,
						fontColorDefault,
						fontColorHighlight
					);

					// Fourth Row
					const fourthRowY = canvas.height - 75;
					const fourthRowText = `Gen: <span>${
						properties.generation
					}</span>  Resources: <span class="resource1">${formatNumber(
						properties.resource1
					)}</span>  -  <span class="resource2">${formatNumber(
						properties.resource2
					)}</span>  -  <span class="resource3">${formatNumber(
						properties.resource3
					)}</span>`;

					drawRightJustifiedColoredTextWithStroke(
						fourthRowText,
						rightEdge,
						fourthRowY,
						fontColorDefault,
						fontColorHighlight,
						(part) => {
							if (part.includes('class="resource1"')) {
								return properties.resourceTypeHarvested === 0
									? fontColorHighlight
									: fontColorDefault;
							} else if (part.includes('class="resource2"')) {
								return properties.resourceTypeHarvested === 1
									? fontColorHighlight
									: fontColorDefault;
							} else if (part.includes('class="resource3"')) {
								return properties.resourceTypeHarvested === 2
									? fontColorHighlight
									: fontColorDefault;
							}
							return fontColorHighlight;
						}
					);

					//resolve
					resolve(canvas);
				}
			});
		};

		//--------------------------------------------

		useEffect(() => {
			const handleMessage = async (event) => {
				if (event.data && event.data.type === "imageData" && !isEnemy) {
					const { width, height, buffer } = event.data;

					const canvas = document.createElement("canvas");
					canvas.width = width;
					canvas.height = height;
					const ctx = canvas.getContext("2d");
					const imageData = new ImageData(
						new Uint8ClampedArray(buffer),
						width,
						height
					);
					ctx.putImageData(imageData, 0, 0);

					const largeCanvas = await createLargeCanvasWithText(
						canvas,
						tokenName,
						pvpTitle,
						properties,
						fontColorDefault,
						fontColorHighlight
					);

					// Convert canvas to Blob
					largeCanvas.toBlob((blob) => {
						// Create a URL from the Blob
						generatedImageUrlRef.current = URL.createObjectURL(blob);
					}, "image/png");
				}
			};

			window.addEventListener("message", handleMessage);

			return () => {
				window.removeEventListener("message", handleMessage);
			};
		}, [tokenName, pvpTitle, properties, fontColorDefault, fontColorHighlight]);

		useEffect(() => {
			const updateContent = () => {
				try {
					if (!metadata) {
						return "";
					}
					const cleanedMetadata = metadata
						.replace("data:application/json,", "")
						.replace(/[\u0000-\u001F\u007F-\u009F]/g, "");
					const decoded = decodeURIComponent(cleanedMetadata);
					// //console.log("decoded metadata: ", decoded);
					//console.log("decoding tokenURI data");
					const jsonMetadata = JSON.parse(decoded);
					// remove the # symbol from the metadataname
					jsonMetadata.name = jsonMetadata.name.replace("#", "");
					setTokenName(jsonMetadata.name);
					if (isGuestMode) {
						setTokenName("Guest Brain Worm");
					}
					//console.log("isGuestMode: ", isGuestMode);
					//console.log("tokeName: ", tokenName);
					let htmlContent = decodeURIComponent(jsonMetadata.animation_url);
					let svgContent = decodeURIComponent(jsonMetadata.image);
					svgContent = svgContent.replace("data:image/svg+xml;base64,", "");
					svgContent = atob(svgContent);

					// Create a Blob from the SVG content
					const blob = new Blob([svgContent], { type: "image/svg+xml" });
					const objectUrl = URL.createObjectURL(blob);

					return {
						htmlContent,
						svgDataUrl: `data:image/svg+xml;base64,${btoa(svgContent)}`,
						objectUrl,
					};
				} catch (error) {
					console.error("Error decoding metadata: ", error);
					return {
						htmlContent: "Error in processing metadata.",
						svgDataUrl: "",
						objectUrl: "",
					};
				}
			};
			const { htmlContent, svgDataUrl, objectUrl } = updateContent();
			setContent(htmlContent);
			setSvgDataUrl(svgDataUrl);
			setSvgObjectUrl(objectUrl);
			// Clean up the object URL when the component unmounts
			return () => {
				if (objectUrl) {
					URL.revokeObjectURL(objectUrl);
				}
			};
		}, [metadata]);

		const handleSvgClick = useCallback(
			(e) => {
				e.preventDefault();
				if (svgObjectUrl) {
					window.open(svgObjectUrl, "_blank");
				}
			},
			[svgObjectUrl]
		);

		useEffect(() => {
			const updateProperties = () => {
				try {
					if (!properties) {
						return;
					}
					// if (!isEnemy) {
					// 	console.log("pvpPointsRunning " + properties.pvpPointsRunning);
					// }
					const pvpTitle = getPvpTitle(properties.pvpPoints);
					setPvpTitle(pvpTitle);
					//console.log("PvpPoints: ", properties.pvpPoints);
					//console.log("PvpTitle: ", pvpTitle);

					let kdRatio = 0;
					let isDefensiveStanceString = properties.isDefensiveStance
						? "Def."
						: "Attk.";

					let staminaStringBonus = "";
					let strengthStringBonus = "";
					let dexterityStringBonus = "";

					if (properties.staminaBonus > 0) {
						staminaStringBonus = "(+" + properties.staminaBonus + ")";
					}
					if (properties.strengthBonus > 0) {
						strengthStringBonus = "(+" + properties.strengthBonus + ")";
					}
					if (properties.dexterityBonus > 0) {
						dexterityStringBonus = "(+" + properties.dexterityBonus + ")";
					}

					if (properties.losses > 0) {
						kdRatio = properties.wins / properties.losses;
					} else {
						kdRatio = properties.wins;
					}
					// trim to 1 decimal place
					kdRatio = Number(kdRatio).toFixed(1);
					setPropertiesStringSecondRow(
						<>
							Win:
							<span style={{ color: fontColorHighlight }}>
								{properties.wins.toString()}
							</span>{" "}
							Loss:
							<span style={{ color: fontColorHighlight }}>
								{properties.losses.toString()}
							</span>{" "}
							{/* W/L:
							<span style={{ color: fontColorHighlight }}>
								{kdRatio.toString()}
							</span> */}
							Pvp:
							<span style={{ color: fontColorHighlight }}>
								{properties.pvpPoints.toString()}
							</span>{" "}
						</>
					);

					setPropertiesStringTopRow(
						<>
							Stam:
							<span style={{ color: fontColorHighlight }}>
								{properties.stamina.toString()}

								{staminaStringBonus}
							</span>{" "}
							Str:
							<span style={{ color: fontColorHighlight }}>
								{properties.strength.toString()}

								{strengthStringBonus}
							</span>{" "}
							Dex:
							<span style={{ color: fontColorHighlight }}>
								{properties.dexterity.toString()}

								{dexterityStringBonus}
							</span>
						</>
					);

					setPropertiesStringThirdRow(
						<>
							Stance:
							<span style={{ color: fontColorHighlight }}>
								{isDefensiveStanceString}
							</span>{" "}
							Resource Rank:
							<span style={{ color: fontColorHighlight }}>
								{properties.rank.toString()}
							</span>
						</>
					);

					setPropertiesStringFourthRow(
						<>
							Gen:
							<span style={{ color: fontColorHighlight }}>
								{properties.generation.toString()}
							</span>{" "}
							Resources:
							<span
								style={{
									color:
										properties.resourceTypeHarvested === 0
											? fontColorHighlight
											: "inherit",
								}}
							>
								{formatNumber(properties.resource1)}
							</span>
							-
							<span
								style={{
									color:
										properties.resourceTypeHarvested === 1
											? fontColorHighlight
											: "inherit",
								}}
							>
								{formatNumber(properties.resource2)}
							</span>
							-
							<span
								style={{
									color:
										properties.resourceTypeHarvested === 2
											? fontColorHighlight
											: "inherit",
								}}
							>
								{formatNumber(properties.resource3)}
							</span>
						</>
					);
				} catch (error) {
					console.error("Error decoding properties: ", error);
				}
			};
			updateProperties();
		}, [properties]);

		////console.log("Style object:", style); // Debug: Output the entire style object

		const positionLeft = style?.left || "0px";
		const positionTop = style?.top || "0px";
		// console.log("Extracted positionTop:", positionTop); // Debug: Check extracted top value

		const positionUnits = positionLeft.replace(/[0-9.]/g, ""); // Extract the unit (em, px, etc.)

		const height = parseFloat(style?.height || "100%");
		////console.log("Extracted height:", height); // Debug: Check extracted height

		// extract width
		const width = parseFloat(style?.width || "100%");
		const widthUnits = style?.width.replace(/[0-9.]/g, ""); // Extract the unit (em, px, etc.)
		// Extract fontSize from style
		const fontSize = style?.fontSize || "1em"; // Default to '1em' if fontSize isn't provided
		const fontSizeValue = parseFloat(style?.fontSize || "1em");
		const fontSizeUnit = (style?.fontSize || "1em").replace(/[0-9.]/g, ""); // Extract the unit (em, px, etc.)

		const propertiesFontSizeTitle = `${fontSizeValue * 0.7}${fontSizeUnit}`;

		const propertiesFontScale = 0.6;
		const propertiesFontSize = `${
			fontSizeValue * propertiesFontScale
		}${fontSizeUnit}`;

		let pos0Top = parseFloat(positionTop) + fontSizeValue * 0.5;
		let pos0TopStyle = `${pos0Top}${positionUnits}`;

		let posTopRowTop = parseFloat(positionTop) + fontSizeValue * 1.5;
		let posTopRowTopStyle = `${posTopRowTop}${positionUnits}`;
		let posSecondRowTop =
			parseFloat(positionTop) +
			height -
			fontSizeValue * 2.4 -
			fontSizeValue * 0.4;
		let posSecondRowTopStyle = `${posSecondRowTop}${positionUnits}`;

		let posThirdRowTop =
			parseFloat(positionTop) +
			height -
			fontSizeValue * 1.7 -
			fontSizeValue * 0.4;
		let posThirdRowTopStyle = `${posThirdRowTop}${positionUnits}`;

		let posFourthRowTop =
			parseFloat(positionTop) + height - fontSizeValue - fontSizeValue * 0.4;
		let posFourthRowTopStyle = `${posFourthRowTop}${positionUnits}`;
		////console.log("pos1TopStyle:", pos1TopStyle); // Debug: Output calculated top value for position 1

		////console.log("pos2TopStyle:", pos2TopStyle); // Debug: Output calculated top value for position 2

		////console.log("pos3TopStyle:", pos3TopStyle); // Debug: Output calculated top value for position 3

		const calculatedMarginLeft = `${
			isEnemy &&
			properties &&
			properties.quality >= 90 &&
			pvpTitle &&
			pvpTitle.length >= 7
				? fontSizeValue * 0.15
				: isEnemy
				? fontSizeValue * 0.6
				: fontSizeValue * 0.9
		}${fontSizeUnit}`;

		const canvasLinkStyle = {
			position: "absolute",
			top: `${parseFloat(positionTop) * 2.142 * 1.03}${positionUnits}`,
			left: `${parseFloat(positionLeft) * 1.05}${positionUnits}`,
			width: `${fontSizeValue * 5 * 0.85}${fontSizeUnit}`,
			height: "auto",
			objectFit: "contain",
			zIndex: 150,
		};

		const titleStyle = {
			...style, // Spread the existing style props
			top: pos0TopStyle,
			marginLeft: calculatedMarginLeft,
			// subtract margin from extracted width, and add the units
			width: `${width - parseFloat(calculatedMarginLeft)}${widthUnits}`,
			fontSize: propertiesFontSizeTitle,
			zIndex: 51,
			// pointerEvents: "none",
			color: fontColorHighlight,
			height: "auto",
		};

		const pvpTitleStyle = {
			fontSize: `${fontSizeValue * 0.6}${fontSizeUnit}`,
		};

		const pvpDifficultLevelStyle = {
			fontSize: `${fontSizeValue * 0.3}${fontSizeUnit}`,
		};

		const propertiesStyleTopRow = {
			...style, // Spread the existing style props
			top: posTopRowTopStyle,
			marginLeft: calculatedMarginLeft,
			// subtract margin from extracted width, and add the units
			width: `${width - parseFloat(calculatedMarginLeft)}${widthUnits}`,
			fontSize: propertiesFontSize,
			zIndex: 51,
			// pointerEvents: "none",
			color: fontColorDefault,
			height: "auto",
		};

		const propertiesStyleSecondRow = {
			...style, // Spread the existing style props
			top: posSecondRowTopStyle,
			marginLeft: calculatedMarginLeft,
			// subtract margin from extracted width, and add the units
			width: `${width - parseFloat(calculatedMarginLeft)}${widthUnits}`,
			fontSize: propertiesFontSize,
			zIndex: 51,
			// pointerEvents: "none",
			color: fontColorDefault,
			height: "auto",
		};

		const propertiesStyleThirdRow = {
			...style, // Spread the existing style props
			top: posThirdRowTopStyle,
			marginLeft: calculatedMarginLeft,
			// subtract margin from extracted width, and add the units
			width: `${width - parseFloat(calculatedMarginLeft)}${widthUnits}`,
			fontSize: propertiesFontSize,
			zIndex: 51,
			// pointerEvents: "none",
			color: fontColorDefault,
			height: "auto",
		};

		const propertiesStyleFourthRow = {
			...style, // Spread the existing style props
			top: posFourthRowTopStyle,
			marginLeft: calculatedMarginLeft,
			// subtract margin from extracted width, and add the units
			width: `${width - parseFloat(calculatedMarginLeft)}${widthUnits}`,
			fontSize: propertiesFontSize,
			zIndex: 51,
			// pointerEvents: "none",
			color: fontColorDefault,
			height: "auto",
		};

		const iframeStyle = {
			...style,
			cursor: "grab",
			scrolling: "no",
		};

		// trying to prevent the inner iFrame content from being too wide
		// and creating scrollbars on firefox
		const handleIframeLoad = (event) => {
			const iframe = event.target;
			const iframeDocument =
				iframe.contentDocument || iframe.contentWindow.document;
			const style = iframeDocument.createElement("style");
			style.textContent = `
				body > * {
					max-width: 99%;
					max-height: 99%;
					overflow: hidden;
					cursor: grab;
				}
			`;
			iframeDocument.head.appendChild(style);
		};

		useEffect(() => {
			const handleMessage = (event) => {
				if (event.data === "exportUSDZ") {
					onDownloadClick();
				}
			};

			window.addEventListener("message", handleMessage);

			return () => {
				window.removeEventListener("message", handleMessage);
			};
		}, [onDownloadClick]);

		const openImageInNewTab = useCallback((imageUrl) => {
			const win = window.open();
			win.document.write(
				'<iframe src="' +
					imageUrl +
					'" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
			);
		}, []);

		return (
			<div>
				<div id="brainWormsTokenDisplayContainer">
					{generatedImageUrlRef.current && isVisible && (
						<a
							href={generatedImageUrlRef.current}
							target="_blank"
							rel="noopener noreferrer"
							className="canvas-link"
							style={{
								top: `${
									parseFloat(positionTop) * 1.875 * 1.15 * 1.037
								}${positionUnits}`,
								left: `${
									parseFloat(positionLeft) * 1.05 * 1.11 * 0.93
								}${positionUnits}`,
								width: `${fontSizeValue * 5 * 0.63}${fontSizeUnit}`,
								filter: "drop-shadow(0 0 1px #00baff)",
							}}
						>
							<img
								src={generatedImageUrlRef.current}
								alt="Generated BrainWorms Image"
							/>
						</a>
					)}
					<p style={titleStyle}>
						{properties && properties.quality >= 90 ? "🪱 " : ""}
						{tokenName} <span style={pvpTitleStyle}>{pvpTitle}</span>{" "}
						<span style={pvpDifficultLevelStyle}>{difficultyLevel}</span>
					</p>
					<p style={propertiesStyleTopRow}>{propertiesStringTopRow}</p>
					<p style={propertiesStyleSecondRow}>{propertiesStringSecondRow}</p>
					<p style={propertiesStyleThirdRow}>{propertiesStringThirdRow}</p>
					<p style={propertiesStyleFourthRow}>{propertiesStringFourthRow}</p>
					{svgDataUrl && (
						<img
							id="brainWormsTokenDisplaySVG"
							src={svgDataUrl}
							alt="BrainWorms"
							style={svgStyle}
							onClick={handleSvgClick}
							className="clickable"
						/>
					)}
					<iframe
						className="grab"
						id="brainWormsTokenDisplay"
						srcDoc={content}
						title="BrainWorms"
						style={iframeStyle}
						sandbox="allow-scripts allow-same-origin allow-downloads"
						onLoad={handleIframeLoad}
						ref={iframeRef}
					/>
				</div>
			</div>
		);
	}
);

export default BrainWormsTokenDisplay;
