import React from "react";

const ProbCalc = ({ worm1, worm2, numSimulations = 10000 }) => {
	const calculateWinProbability = (worm1, worm2, numSimulations) => {
		function simulateCombat(attacker, defender) {
			let attackerStamina = attacker.stamina;
			let defenderStamina = defender.stamina;
			const attackerGoesFirst = calculateInitiative(attacker, defender);

			if (attackerGoesFirst) {
				defenderStamina = performAttack(attacker, defender, defenderStamina);
				if (defenderStamina > 0) {
					attackerStamina = performAttack(defender, attacker, attackerStamina);
				}
			} else {
				attackerStamina = performAttack(defender, attacker, attackerStamina);
				if (attackerStamina > 0) {
					defenderStamina = performAttack(attacker, defender, defenderStamina);
				}
			}

			return attackerStamina > defenderStamina;
		}

		function calculateInitiative(attacker, defender) {
			const attackerRoll = Math.floor(Math.random() * 20) + attacker.dexterity;
			let defenderRoll = Math.floor(Math.random() * 20);

			if (defenderRoll > Math.floor(defender.stamina / 2)) {
				defenderRoll -= Math.floor(defender.stamina / 2);
			} else {
				defenderRoll = 0;
			}

			return attackerRoll > defenderRoll;
		}

		function performAttack(attacker, defender, defenderStamina) {
			const hitChance = Math.floor(Math.random() * 20);
			const dodgeChance = Math.floor(Math.random() * 20);

			if (hitChance + Math.floor(attacker.dexterity / 2) >= 22) {
				return 0; // Critical hit, instant defeat
			} else if (
				hitChance + attacker.strength >
				dodgeChance + defender.dexterity
			) {
				const damage =
					Math.floor(Math.random() * 10) + Math.floor(attacker.strength / 2);
				return Math.max(0, defenderStamina - damage);
			} else {
				return defenderStamina; // Miss
			}
		}

		let wins = 0;
		for (let i = 0; i < numSimulations; i++) {
			if (simulateCombat(worm1, worm2)) {
				wins++;
			}
		}

		return wins / numSimulations;
	};

	const probability = calculateWinProbability(worm1, worm2, numSimulations);

	return probability;
};

export default ProbCalc;
