import React, { useState, useEffect, useRef } from "react";
import ProbCalc from "./ProbCalc";

import SmartURL from "./SmartURL";
const BrainWormsLeaderboardDisplay = ({
	isGuestMode,
	style,
	fontColorHighlight,
	sharedDefaultFontColor,
	sharedEnemyFontColor,
	sharedEnemyHighlightFontColor,
	leaderboardData,
	handleLeaderboardRowClick,
	setToolTipMessage,
	userLoadedNFTProperties,
	enemyLoadedNFTProperties,
}) => {
	// console.log("BrainWormsLeaderboardDisplay loaded ");

	const [hoveredIndex, setHoveredIndex] = useState(null);
	const itemContainerRef = useRef(null);
	const [difficultyLevel, setDifficultyLevel] = useState(null);
	const leaderboardMainImage = SmartURL(
		"/media/brainworms/simulator/leaderboard_background.jpg"
	);

	// Scroll to user's row when userLoadedNFTProperties changes
	useEffect(() => {
		if (isGuestMode) {
			return;
		}
		if (
			itemContainerRef.current &&
			userLoadedNFTProperties &&
			leaderboardData.length > 0
		) {
			const userMatchedIndex = leaderboardData.findIndex(
				(entry) => entry.tokenidnorm === userLoadedNFTProperties.tokenIdRegular
			);

			if (userMatchedIndex !== -1) {
				const rowElement = itemContainerRef.current.children[userMatchedIndex];
				if (rowElement) {
					rowElement.scrollIntoView({ behavior: "smooth", block: "center" });
				}
			}
		}
	}, [userLoadedNFTProperties, leaderboardData, isGuestMode]);

	let currentResourceName = "";
	let heightValue = parseFloat(style?.height || "100%");
	let widthValue = parseFloat(style?.width || "100%");
	let widthScale = widthValue / 1110;
	let heightScale = heightValue / 890;
	let buttonHeight = 90;
	let buttonWidth = 239;
	parseFloat(style?.height || "100%");

	// -----------------------------------
	// extract width and height from style
	const width = style?.width || "100%";
	const height = style?.height || "100%";

	const widthUnit = width.replace(/[0-9.]/g, "");
	const heightUnit = height.replace(/[0-9.]/g, "");

	// Extract fontSize from style
	const fontSize = style?.fontSize || "1em"; // Default to '1em' if fontSize isn't provided
	const fontSizeValue = parseFloat(fontSize); // Convert fontSize string to a number
	const fontSizeUnit = fontSize.replace(/[0-9.]/g, ""); // Extract the unit (em, px, etc.)

	const propertiesFontScale = 0.6;
	const propertiesFontSize = `${
		fontSizeValue * propertiesFontScale
	}${fontSizeUnit}`;

	const calculatedMarginSides = `${fontSizeValue * 0.7}${fontSizeUnit}`;
	const calculatedMarginTop = `${fontSizeValue}${fontSizeUnit}`;

	const backgroundImageContainerStyle = {
		backgroundImage: `url(${leaderboardMainImage})`,
		backgroundSize: "100%",
		backgroundRepeat: "no-repeat",
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
	};

	// each row of the leaderboard goes into this container
	const itemContainerStyle = {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "center",
		top: 0,
		left: 0,
		marginLeft: calculatedMarginSides,
		paddingRight: calculatedMarginSides,
		overflowY: "scroll",
		listStyle: "none",
		height: "83%",
	};
	const headerRowStyle = {
		width: "100%",
		paddingBottom: `${fontSizeValue}${fontSizeUnit}`,
		fontSize: `${fontSizeValue * 0.6}${fontSizeUnit}`,
		fontColor: fontColorHighlight,
		paddingTop: calculatedMarginTop,
		paddingLeft: calculatedMarginSides,
		paddingRight: calculatedMarginSides,
	};

	const isWithin100PVPPoints = (entryPVPPoints) => {
		if (!userLoadedNFTProperties) return false;
		const userPVPPoints = userLoadedNFTProperties.pvpPoints;
		return Math.abs(entryPVPPoints - userPVPPoints) <= 200;
	};

	const itemRowStyle = {
		width: "100%",
		paddingTop: `${fontSizeValue * 0.3}${fontSizeUnit}`,
		paddingBottom: `${fontSizeValue * 0.3}${fontSizeUnit}`,
	};

	const columnLabelStyle = {
		width: "7%",
		display: "inline-block",
		textAlign: "center",
		borderRight: `1px solid rgba(150, 177, 196,0.3)`,
	};
	const columnOneStyle = {
		width: "6%",
		display: "inline-block",
		textAlign: "center",
		borderRight: `1px solid rgba(150, 177, 196,0.3)`,
	};

	const columnTwoStyle = {
		width: "25%",
		display: "inline-block",
		textAlign: "center",
		borderRight: `1px solid rgba(150, 177, 196,0.3)`,
	};

	const columnThreeStyle = {
		width: "14%",
		display: "inline-block",
		textAlign: "center",
		borderRight: `1px solid rgba(150, 177, 196,0.3)`,
	};

	const columnFourStyle = {
		width: "48%",
		display: "inline-block",
		textAlign: "center",
	};

	const userOwnedGradient = `linear-gradient(to right, #00214b, #000a14)`;
	const userOwnedGradientHover = `linear-gradient(to right, #003476, #000a14)`;
	const enemyOwnedGradient = "none";
	const enemyOwnedGradientHover = `linear-gradient(to right, #2b2929, #211f1f)`;
	const enemyWithinRangeGradient = `linear-gradient(to right, #141313, #211f1f)`;

	const calcImgTop = (index) => {
		return buttonHeight * 2.2 * heightScale;
	};

	const calculateDifficulty = (hoveredEntry) => {
		if (!userLoadedNFTProperties || !hoveredEntry) {
			setDifficultyLevel(null);
			return;
		}

		const userWorm = {
			stamina:
				userLoadedNFTProperties.stamina + userLoadedNFTProperties.staminaBonus,
			strength:
				userLoadedNFTProperties.strength +
				userLoadedNFTProperties.strengthBonus,
			dexterity:
				userLoadedNFTProperties.dexterity +
				userLoadedNFTProperties.dexterityBonus,
		};

		const enemyWorm = {
			stamina: hoveredEntry.stamina + hoveredEntry.staminabonus,
			strength: hoveredEntry.strength + hoveredEntry.strengthbonus,
			dexterity: hoveredEntry.dexterity + hoveredEntry.dexteritybonus,
		};

		// Check if all required properties are present
		if (
			userWorm.stamina === undefined ||
			userWorm.strength === undefined ||
			userWorm.dexterity === undefined ||
			enemyWorm.stamina === undefined ||
			enemyWorm.strength === undefined ||
			enemyWorm.dexterity === undefined
		) {
			setDifficultyLevel(null);
			return;
		}

		const difficultyValue = ProbCalc({ worm1: userWorm, worm2: enemyWorm });
		let difficultyEmoji;

		if (difficultyValue < 0.125) {
			difficultyEmoji = "🟥🟥🟥🟥";
		} else if (difficultyValue < 0.25) {
			difficultyEmoji = "🟥🟥🟥";
		} else if (difficultyValue < 0.375) {
			difficultyEmoji = "🟥🟥";
		} else if (difficultyValue < 0.45) {
			difficultyEmoji = "🟥";
		} else if (difficultyValue >= 0.45 && difficultyValue <= 0.55) {
			difficultyEmoji = "🟨";
		} else if (difficultyValue < 0.625) {
			difficultyEmoji = "🟩";
		} else if (difficultyValue < 0.75) {
			difficultyEmoji = "🟩🟩";
		} else if (difficultyValue < 0.875) {
			difficultyEmoji = "🟩🟩🟩";
		} else {
			difficultyEmoji = "🟩🟩🟩🟩";
		}

		setDifficultyLevel(difficultyEmoji);
	};

	// log data type of resoureType
	return leaderboardData ? (
		<div style={style}>
			<div style={backgroundImageContainerStyle}>
				<div style={headerRowStyle}>
					<div style={columnLabelStyle}>Rank</div>
					<div style={columnOneStyle}>ID</div>
					<div style={columnTwoStyle}>PVP Title</div>
					<div style={columnThreeStyle}>Win-Loss</div>
					<div style={columnFourStyle}>Stats</div>
				</div>
				<div
					style={itemContainerStyle}
					className="scrollbar-container"
					ref={itemContainerRef}
				>
					{leaderboardData.map((entry, index) => {
						if (
							entry.stamina === undefined ||
							entry.strength === undefined ||
							entry.dexterity === undefined ||
							(entry.stamina === 0 &&
								entry.strength === 0 &&
								entry.dexterity === 0)
						) {
							return null;
						}
						const isHovered = hoveredIndex === index;
						const isUserMatched =
							userLoadedNFTProperties &&
							entry.tokenidnorm === userLoadedNFTProperties.tokenIdRegular;
						const isEnemyMatched =
							enemyLoadedNFTProperties &&
							entry.tokenidnorm === enemyLoadedNFTProperties.tokenIdRegular;

						const rowStyle = {
							...itemRowStyle,
							fontSize: `${fontSizeValue * 0.59}${fontSizeUnit}`,
							color: entry.enemy
								? sharedEnemyFontColor
								: sharedDefaultFontColor,
							cursor: "pointer",
							backgroundImage: isHovered
								? entry.enemy
									? enemyOwnedGradientHover
									: userOwnedGradientHover
								: isUserMatched
								? userOwnedGradientHover
								: isEnemyMatched
								? enemyOwnedGradientHover
								: entry.enemy
								? isWithin100PVPPoints(entry.pvppoints)
									? enemyWithinRangeGradient
									: enemyOwnedGradient
								: userOwnedGradient,
							boxShadow: isUserMatched
								? "0px 0px 4px 0px white"
								: isEnemyMatched
								? "0px 0px 4px 0px red"
								: "none",
							borderBottom: "1px solid rgba(150, 177, 196,0.3)",
						};

						const rowStyleDetails = {
							color: entry.enemy
								? sharedEnemyHighlightFontColor
								: fontColorHighlight,
						};

						const handleMouseEnter = () => {
							setHoveredIndex(index);
							if (entry.enemy) {
								calculateDifficulty(entry);
							} else {
								setDifficultyLevel(null);
							}
						};

						const handleMouseLeave = () => {
							setHoveredIndex(null);
						};

						return (
							<div
								style={rowStyle}
								key={index}
								onClick={() => handleLeaderboardRowClick(entry.tokenidnorm)}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
							>
								<div style={columnLabelStyle}>
									<span style={rowStyleDetails}>{entry.idx}</span>
								</div>
								<div style={columnOneStyle}>
									<span style={rowStyleDetails}>{entry.tokenidnorm}</span>
								</div>
								<div style={columnTwoStyle}>
									{entry.pvptitle}{" "}
									{isHovered && difficultyLevel ? (
										<span
											style={{
												...rowStyleDetails,
												fontSize: `${fontSizeValue * 0.25}${fontSizeUnit}`,
											}}
										>
											{difficultyLevel}
										</span>
									) : (
										<span style={rowStyleDetails}>{entry.pvppoints}</span>
									)}
								</div>
								<div style={columnThreeStyle}>
									<span style={rowStyleDetails}>{entry.wins}</span>-
									<span style={rowStyleDetails}>{entry.losses}</span>
								</div>
								<div
									style={{
										...columnFourStyle,
										fontSize:
											entry.quality >= 90 &&
											entry.staminabonus >= 10 &&
											entry.strengthbonus >= 10 &&
											entry.dexteritybonus >= 10
												? `${fontSizeValue * 0.53}${fontSizeUnit}`
												: `${fontSizeValue * 0.59}${fontSizeUnit}`,
									}}
								>
									{entry.quality >= 90 && <span>🪱 </span>}
									Stam:
									<span style={rowStyleDetails}>
										{entry.stamina}
										{entry.staminabonus !== 0 && `(+${entry.staminabonus})`}
									</span>{" "}
									Str:
									<span style={rowStyleDetails}>
										{entry.strength}
										{entry.strengthbonus !== 0 && `(+${entry.strengthbonus})`}
									</span>{" "}
									Dex:
									<span style={rowStyleDetails}>
										{entry.dexterity}
										{entry.dexteritybonus !== 0 && `(+${entry.dexteritybonus})`}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	) : (
		<div style={style}>
			<div style={backgroundImageContainerStyle}></div>
		</div>
	);
};

export default BrainWormsLeaderboardDisplay;
