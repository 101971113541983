// MetaTags.js
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom"; // Import useLocation hook

const defaultRootURL = "https://www.delcomplex.com";
const baseTitle = "Del Complex"; // Default base title

const MetaTags = ({
	titleSuffix = "", // Note the name change
	description = "Del Complex is an alternate reality corporation. Our mission is to accelerate human potential through the symbiosis of AGI, neural prosthetics, robotics, clean energy, resilience solutions, and fundamental scientific research.",
	imageUrl = "https://www.xiosky.com/delmedia/media/office1.png",
	path = "",
}) => {
	const location = useLocation(); // Use the useLocation hook to get the current location
	const fullURL = `${defaultRootURL}${location.pathname}`; // Use location.pathname to get the current path
	const fullTitle = titleSuffix ? `${baseTitle} - ${titleSuffix}` : baseTitle; // Construct the full title

	return (
		<Helmet>
			<title>{fullTitle}</title>
			<meta name="description" content={description} />

			{/* Open Graph Tags */}
			<meta property="og:title" content={fullTitle} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={imageUrl} />
			<meta property="og:image:type" content="image/png" />
			<meta property="og:url" content={fullURL} />

			{/* Twitter Card Tags */}
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={fullTitle} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={imageUrl} />
		</Helmet>
	);
};

export default MetaTags;
