// Products.js
import React from "react";
import Article from "./Article";
import TextCard from "./TextCard";

const Products = () => {
	const textCardsData = [
		{
			heading: "Learn more about our research.",
			caption: "[Research overview]",
			link: "/research-overview/",
		},
		{
			heading: "Visit our facilities.",
			caption: "[Facilities]",
			link: "/facilities/",
		},
		{
			heading: "Join our team.",
			caption: "[Careers]",
			link: "/careers/",
		},
	];

	return (
		<div className="article-container">
			<div className="spacerShort"></div>
			<Article title="Surplus and Records." content={[""]} titleLevel={1} />

			<Article
				title="Del Complex Property Office Shop."
				content={[
					"Pursuant to regulations, all property owned by Del Complex that has been declared as surplus is required to be sold and dispersed by the Del Complex Property Office to government agencies, qualified non-profit entities, and the public.",
					<>
						<a href="https://shop.delcomplex.com/">
							Browse the Property Office Shop
						</a>
					</>,
					"",
				]}
				titleLevel={4}
			/>

			<Article
				title="Archival Media"
				content={[
					"Del Complex discloses specific records upon written request, except records which are exempt. While records are freely available, you may obtain a cryptographic token verifying its authenticity.",
					<>
						<a href="/archival-media/">Request a record</a>
					</>,
					"",
				]}
				titleLevel={4}
			/>

			<br />
			<div className="text-container">
				{textCardsData.map((data, index) => (
					<TextCard
						key={index}
						heading={data.heading}
						caption={data.caption}
						link={data.link}
					/>
				))}
			</div>
		</div>
	);
};

export default Products;
