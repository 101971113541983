import React from "react";
import SmartURL from "./SmartURL";
const SysLogRequestLine = () => {
	return (
		<div className="syslogrequestline">
			<p className="syslogrequestline-error-text">
				ERROR 404: SYSTEM COMPROMISED – ACCESS DENIED
			</p>
			<a
				href={SmartURL("/media/documents/BSFCC_CoolingSystem.pdf")}
				download="BSFCC_CoolingSystem.pdf"
				className="syslogrequestline-button"
			>
				REFRESH
			</a>
		</div>
	);
};

export default SysLogRequestLine;
