// App.js
import React from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Web3Provider } from "./components/Web3Provider";
import Main from "./Main";

const AppContent = () => {
	const location = useLocation();
	const connectToBase = location.pathname === "/login-brainworms-simulator";

	return (
		<Web3Provider connectToBase={connectToBase}>
			<Main />
		</Web3Provider>
	);
};

const App = () => {
	return (
		<Router>
			<AppContent />
		</Router>
	);
};

export default App;
