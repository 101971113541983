// BackgroundVideo.js
import { useLocation } from "react-router-dom";
import { navLinks, backgroundOverrides } from "./navData";
import React, { useContext, useEffect, useState, useRef } from "react";
import { VideoContext } from "../context/VideoContext";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const BackgroundVideo = () => {
	const location = useLocation();
	const isHomePage = location.pathname === "/";
	const videoRef = useRef();
	const containerRef = useRef();
	const bgContainerRef = useRef();
	const { videoTime, setVideoTime } = useContext(VideoContext);
	const totalFrames = 3;
	const [prefix, setPrefix] = useState("");

	const prefixArray = [
		"a",
		"b",
		"c",
		"d",
		"e",
		"f",
		"g",
		"h",
		"i",
		"j",
		"k",
		"l",
		"m",
		"n",
		"o",
		"p",
		"q",
	];

	// Function to get the prefix for the background image
	const getBgPrefix = (pathname) => {
		// remove trailing slash if present
		if (pathname.endsWith("/")) {
			pathname = pathname.slice(0, -1);
		}
		for (let navGroup of navLinks) {
			for (let item of navGroup.items) {
				if (item.url === pathname) {
					return item.bgPrefix;
				}
			}
		}
		// also check backgroundOverrides
		for (let navGroup of backgroundOverrides) {
			for (let item of navGroup.items) {
				if (item.url === pathname) {
					return item.bgPrefix;
				}
			}
		}
		// get a random prefix if no match
		return selectRandomPrefix();
	};

	// Function to select a random prefix
	const selectRandomPrefix = () => {
		const randomIndex = Math.floor(Math.random() * prefixArray.length);
		return prefixArray[randomIndex];
	};
	const getPoster = (time, totalFrames, prefix) => {
		const scene = Math.floor(time % totalFrames);
		return `https://xiosky.com/delmedia/posters/${prefix}${scene}.jpg`;
	};

	useEffect(() => {
		// Only update the prefix when the pathname changes
		var newPrefix = getBgPrefix(location.pathname);
		if (isHomePage && !isSafari) {
			newPrefix = "a";
		}
		setPrefix(newPrefix);

		let intervalId;
		if (isSafari || !isHomePage) {
			let frameRate = 15;
			let updateFreq = 1000 / frameRate;
			intervalId = setInterval(() => {
				setVideoTime((prevTime) => (prevTime + 1) % totalFrames);
			}, updateFreq);
		} else {
			const updateTime = () => {
				if (videoRef.current) {
					const currentTime = videoRef.current.currentTime;
					setVideoTime(currentTime);
				}
			};

			videoRef.current.addEventListener("timeupdate", updateTime);

			return () => {
				videoRef.current.removeEventListener("timeupdate", updateTime);
			};
		}

		return () => {
			if (isSafari && intervalId) {
				clearInterval(intervalId);
			}
		};
	}, [location.pathname, isHomePage]); // Depend only on pathname and isHomePage

	useEffect(() => {
		const newPoster = getPoster(videoTime, totalFrames, prefix);
		bgContainerRef.current.style.backgroundImage = `url(${newPoster})`;
		containerRef.current.style.backgroundImage = `url(${newPoster})`;
		localStorage.setItem("lastStoredPoster", newPoster);
	}, [videoTime, prefix]); // Depend on videoTime and prefix

	return (
		<>
			<div className="video-overlay"></div>
			<div className="bg-video-container" ref={bgContainerRef}>
				{isHomePage &&
					!isSafari && ( // Display video on home page and if it is not Safari
						<video
							src="https://xiosky.com/delmedia/media/del.mp4"
							autoPlay
							loop
							muted
							playsInline
							style={{
								position: "absolute",
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
			</div>
			<div className="video-container" ref={containerRef}>
				{isHomePage &&
					!isSafari && ( // Display video on home page and if it is not Safari
						<video
							ref={videoRef}
							src="https://xiosky.com/delmedia/media/del.mp4"
							autoPlay
							loop
							muted
							playsInline
							style={{
								position: "absolute",
								width: "100%",
								height: "100%",
								objectFit: "cover",
							}}
						/>
					)}
			</div>
		</>
	);
};
