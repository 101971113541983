import React from "react";
import SmartImg from "./SmartImg";
import SmartURL from "./SmartURL";
import MintNFT from "./MintNFT.tsx";

const MintToken = ({
	claimId,
	imageSrc,
	fileSrc,
	alt,
	active = true,
	priceOverride,
	mintButtonText,
}) => {
	return (
		<div className="token-card-mint">
			<div className="token-card-download">
				<p>
					<a href={SmartURL(fileSrc)}>{alt}</a>
				</p>
			</div>
			<a href={SmartURL(fileSrc)}>
				<SmartImg src={imageSrc} alt={alt} />
			</a>

			{active ? (
				<MintNFT
					claimId={claimId}
					isMint={true}
					priceOverride={priceOverride}
					mintButtonText={mintButtonText}
				/>
			) : (
				<p>Mint Ended.</p>
			)}
		</div>
	);
};

export default MintToken;
