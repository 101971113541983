// updatesData.js
export const updatesData = [
	{
		imageSrc: "/media/updates/brainworms.jpg",
		heading: "Brain Worms",
		caption: "March 2024",
		link: "/brainworms",
	},
	{
		imageSrc: "/media/updates/psynet.jpg",
		heading: "PsyNet",
		caption: "January 2024",
		link: "/psynet-update",
	},
	{
		imageSrc: "/media/updates/vonGoom.jpg",
		heading: "Von Goom",
		caption: "December 2023",
		link: "/vonGoom",
	},

	{
		imageSrc: "/media/updates/blueSea.jpg",
		heading: "BlueSea Frontier Compute Cluster",
		caption: "October 2023",
		link: "/blue-sea-frontier",
	},
	{
		imageSrc: "/media/updates/automatedHiring.jpg",
		heading: "Automated Onboarding System",
		caption: "October 2023",
		link: "/onboarding-update",
	},
	{
		imageSrc: "/media/updates/careers.jpg",
		heading: "Careers Transparency Report",
		caption: "September 2023",
		link: "/careers-transparency-report",
	},
	{
		imageSrc: "/media/updates/careerFair.jpg",
		heading: "Hiring Event ",
		caption: "September 2023",
		link: "/2023-hiring-event",
	},
	{
		imageSrc: "/media/updates/devConference.jpg",
		heading: "Annual Developer Conference",
		caption: "December 2023",
		link: "/2023-developer-conference",
	},

	// {
	// 	imageSrc: "/media/updates/archivalMedia.jpg",
	// 	heading: "Information disclosure",
	// 	caption: "Q3 2023",
	// 	link: "/archival-media",
	// },
];
