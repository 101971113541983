import React from "react";
import SmartURL from "./SmartURL";
const LLostAndFound = () => {
	return (
		<div className="LlostAndFound">
			<p className="LlostAndFound-error-text">
				[####-##-## ##:##:##] Time Elapsed: 0.00002 seconds - Starting try to
				get URL https://delcomplex.com/lost-and-found
				<br />
				[####-##-## ##:##:##] Time Elapsed: 0.00011 seconds - url_get_contents
				start timeout=2
				<br />
				Warning: file_get_contents(): SSL operation failed with code 1. OpenSSL
				Error messages: error:0A00010B:SSL routines::wrong version number
				<br />
				Warning: file_get_contents(): Failed to enable Warning: Media Service:
				https://www.delcomplex.com/mediaservice is not registered.
				file_get_contents Failed to open stream: operation failed on line 54
				[####-##-## ##:##:##]] Time Elapsed: 0.00452 seconds -
				file_get_contents:: [####-##-## ##:##:##] Time Elapsed: 0.00453 seconds
				- file_get_contents fail return an empty content [####-##-## ##:##:##]
				Time Elapsed: 0.00454 seconds - FAIL [####-##-## ##:##:##] Time Elapsed:
				0.00454 seconds - Finish try to get URL
			</p>
		</div>
	);
};

export default LLostAndFound;
