import React, { useState, useEffect, useContext, useRef } from "react";
import SmartURL from "./SmartURL";
import BrainWormsABI from "../ABI/BrainWorms.json";
import BrainWormsCombatSystemABI from "../ABI/BrainWormsCombatSystem.json";
import BrainWormsResourceHarvestingABI from "../ABI/BrainWormsResourceHarvesting.json";
import BrainWormsResourceStorageABI from "../ABI/BrainWormsResourceStorage.json";
import BrainWormsTokenURIABI from "../ABI/BrainWormsTokenURI.json";
import BrainWormsTokenDisplay from "./BrainWormsTokenDisplay";
import BrainWormsBattleStatsDisplay from "./BrainWormsBattleStatsDisplay";
import BrainWormsResourceDisplay from "./BrainWormsResourceDisplay";
import BrainWormsLeaderboardDisplay from "./BrainWormsLeaderboardDisplay";
import BrainWormsRerollPage from "./BrainWormsRerollPage";
import BrainWormsWelcomePage from "./BrainWormsWelcomePage";
import BrainWormsUserManual from "./BrainWormsUserManual";
import BrainWormsLogin from "./BrainWormsLogin";
import Papa from "papaparse";
import ProbCalc from "./ProbCalc";
import ConnectKitCustomButton from "./ConnectKitCustomButton";

import { useMemo } from "react";
import { ethers } from "ethers";
import { CoinbaseWalletContext } from "./CoinbaseWalletContext";

//////////////////////////////////////////////////////////////////////////////
//                                                                          //
//                              DELCOMPLEXDEL                               //
//                      DELCOMPLEXDELCOMPLEXDELCOMPLE                       //
//                 DELCOMPLE                     DELCOMPLE                  //
//              DELCOMP                               DELCOMP               //
//           DELCOM                                       DELCOM            //
//         DELCOM                                             DELCOM        //
//       DELCOM                                                 DELCOM      //
//      DELC       DELCOMPLEXDELCOMPLEXDELCOMPLEXDELCOMPLE       DELCOM     //
//    DELCOM        DELCOMPLEXDELCOMPLEXDELCOMPLEXDELCOMPL         DELCOM   //
//   DELC            DELCOMPLEXDELCOMPLEXDELCOMPLEXDELCOM            DELC   //
//  DELC              DELCOMPL                    DELCOM              DELC  //
//  DELC               DELCOMPL                  DELCOM               DELC  //
// DELC                 DELCOMPL                DELCOM                 DELC //
// DELC                  DELCOMPL              DELCOM                  DELC //
// DELC                   DELCOMPL           DELCOM                    DELC //
// DELC                    DELCOMPL         DELCOM                     DELC //
// DELC                     DELCOMPL       DELCOM                      DELC //
//  DELC                     DELCOMPL     DELCOM                      DELC  //
//  DELC                      DELCOMPL   DELCOM                       DELC  //
//   DELC                      DELCOMPLEXDELC                       DELCO   //
//    DELCOM                     DELCOMPLEXD                       DELCOM   //
//      DELC                     DELCOMPLE                       DELCOMP    //
//       DELCOM                    DELCOM                       DELCOM      //
//         DELCOM                   DELC                       DELCOM       //
//           DELCOM                 DEL                   DELCOMPL          //
//              DELCOMP                               DELCOMPL              //
//                 DELCOMPLE                     DELCOMPLE                  //
//                      DELCOMPLEXDELCOMPLEXDELCOMPLE                       //
//                              DELCOMPLEXDEL                               //
//                                                                          //
//////////////////////////////////////////////////////////////////////////////

/// @title Brain Worms Simulation System
///
/// @author Sterling Crispin
/// Del Complex, Brain Worm Research Program
/// http://www.delcomplex.com
/// @sterlingcrispin
///
/// @notice Our long term goal is to genetically engineer parasitic worms to secrete
/// cognitive enhancing biomolecules. Thus increasing the intelligence of the host human.
/// With Brain Worms, we will accelerate the human species in order to compete with AGI.
///
/// The Brain Worm implantation process begins at the nose. We inject them via intranasal
/// delivery to bypass the blood-brain barrier and enable direct access to the brain.
/// Once inside, they compete for limited resources found in the interstitial fluid of the
/// brain such as glucose, amino acids, and micronutrients. Thus, the Brain Worm Simulation
/// is a critical step in understanding the behavior of the worms in a hostile environment.
///
/// As users interact with the Brain Worm Simulation, engage with PVP combat, and gather
/// resources, our scientists will learn from the data and improve our biological brainworms.
///
/// @dev This is not a place of honor, no highly esteemed deed is commemorated here
/// nothing valued is here. What is here was dangerous and repulsive to us. This message
/// is a warning about danger. The danger is in a particular location, it increases towards
/// the center, the center of danger is here, of a particular size and shape, and below us.
///
const BrainWormSimulator = ({
	userAddress,
	isMusicPlaying,
	toggleMusicPlay,
	musicTitle,
	isLoggedIn,
	provider,
}) => {
	// Check if the provider is available
	const checkProviderAvailable = async () => {
		if (!isLoggedIn) {
			//console.log("checkProviderAvailable: user is not connected");
			return false;
		}

		if (!provider) {
			//console.log("Provider not initialized yet");
			clearTextOnErrors();
			return false;
		}

		try {
			await provider.getNetwork();
			return true;
		} catch (error) {
			console.error("Ethereum provider is not available", error);
			setTextUserFeedbackWithTimeout(
				"Wallet connection failed. Reconnect and try again."
			);
			clearTextOnErrors();
			return false;
		}
	};

	// //console.log("BrainWormSimulator loaded");
	const BrainWormsContractAddress =
		"0x506BEb7965FC7053059006c7ab4C62c02C2D989F";

	const BrainWormsResourceStorageAddress =
		"0xfaa72A710de9bdd564b904aF3650Be3f98dD39e3";

	const BrainWormsResourceHarvestingAddress =
		"0x0c15c5E8f28F4F935813F966731CA34cB29C1580";

	const BrainWormsCombatSystemAddress =
		"0x45CCb739b51C6D2C3B683E1e22592Db9D04375Ef";

	const BrainWormsTokenURIContractAddress =
		"0x8d6dF3fb7972C89d6b4b8D3a8268a88e4e1BEce0";

	const zeroAddress = "0x0000000000000000000000000000000000000000";

	const pvpPointTiers = [
		0, 100, 200, 300, 500, 700, 900, 1200, 1600, 2000, 2400,
	];

	const pvpRankTitles = [
		"Prey",
		"Hatchling",
		"Harvester",
		"Infiltrator",
		"Manipulator",
		"Sentinel",
		"Marauder",
		"Predator",
		"Master Parasite",
		"Champion Parasite",
		"Apex Parasite",
	];

	const getPvpTitle = (points) => {
		let rank = 0;
		if (points >= pvpPointTiers[10]) {
			rank = 10;
		} else if (points >= pvpPointTiers[9]) {
			rank = 9;
		} else if (points >= pvpPointTiers[8]) {
			rank = 8;
		} else if (points >= pvpPointTiers[7]) {
			rank = 7;
		} else if (points >= pvpPointTiers[6]) {
			rank = 6;
		} else if (points >= pvpPointTiers[5]) {
			rank = 5;
		} else if (points >= pvpPointTiers[4]) {
			rank = 4;
		} else if (points >= pvpPointTiers[3]) {
			rank = 3;
		} else if (points >= pvpPointTiers[2]) {
			rank = 2;
		} else if (points >= pvpPointTiers[1]) {
			rank = 1;
		} else if (points >= pvpPointTiers[0]) {
			rank = 0;
		}
		return pvpRankTitles[rank];
	};

	const API_URL = process.env.REACT_APP_API_LEADERBOARD;

	// this is the entire leaderboard and a refresh of all users owned tokens
	async function fetchLeaderboard(start, limit) {
		const response = await fetch(
			`${API_URL}/leaderboard?start=${start}&limit=${limit}`
		);
		const data = await response.json();
		return data;
	}

	// State for the wallet and simulator
	const [isStarted, setIsStarted] = useState(false);
	const [ownedTokensCount, setOwnedTokensCount] = useState(0);
	const [ownedNFTsBigInt, setOwnedNFTsBigInt] = useState([]);
	const [ownedNFTsNormalIds, setOwnedNFTsNormalIds] = useState([]);
	const [userOwnsAnyBrainWorms, setUserOwnsAnyBrainWorms] = useState(false);
	const [claimableWorms, setClaimableWorms] = useState(0);
	const [isResourceDisplayOpen, setIsResourceDisplayOpen] = useState(false);
	const [leaderboardData, setLeaderboardData] = useState([]);
	const [leaderboardIdxs, setLeaderboardIdxs] = useState([1, 3200]);
	const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);
	const [isGuestMode, setIsGuestMode] = useState(false);
	const [isRerollPageOpen, setIsRerollPageOpen] = useState(false);
	const [currentBlockNumber, setCurrentBlockNumber] = useState(null);
	const [isVampireAttackReady, setIsVampireAttackReady] = useState(false);
	const [isWelcomePageOpen, setIsWelcomePageOpen] = useState(false);
	const [hasUserManualEverBeenOpened, setHasUserManualEverBeenOpened] =
		useState(false);
	const [isUserManualOpen, setIsUserManualOpen] = useState(false);
	const [backgroundStaticIndex, setBackgroundStaticIndex] = useState(0);
	const [showStaticBackground, setShowStaticBackground] = useState(true);
	const [showMobileBackground, setShowMobileBackground] = useState(false);

	const [claimants, setClaimants] = useState([]);

	// Metadata
	const [userLoadedNFTMetadata, setUserLoadedNFTMetadata] = useState(null);
	const [enemyLoadedNFTMetadata, setEnemyLoadedNFTMetadata] = useState(null);

	// Brain Worm Properties
	const [userLoadedNFTProperties, setUserLoadedNFTProperties] = useState(null);
	const [enemyLoadedNFTProperties, setEnemyLoadedNFTProperties] =
		useState(null);
	const [userLoadedNFTBeginningPVPPoints, setUserLoadedNFTBeginningPVPPoints] =
		useState(null);

	const [difficultyLevel, setDifficultyLevel] = useState(null);

	// Current Loaded NFT's at big int format
	const [userLoadedNFTIdBigInt, setUserLoadedNFTIdBigInt] = useState(null);
	const [enemyLoadedNFTIdBigInt, setEnemyLoadedNFTIdBigInt] = useState(null);
	const [previousEnemyIdBigInt, setPreviousEnemyIdBigInt] = useState(null);
	const [previousUserIdBigInt, setPreviousUserIdBigInt] = useState(null);

	const [vampireMinVictimBalance, setVampireMinVictimBalance] = useState(0);
	const [vampireBlockCooldown, setVampireBlockCooldown] = useState(null);

	// Hover over attack type states
	const [hoveringOverAttackType, setHoveringOverAttackType] = useState(null);

	// List of potential enemies
	const [potentialEnemiesBigInt, setPotentialEnemiesBigInt] = useState([]);
	const [actionAfterEnemyLoad, setActionAfterEnemyLoad] = useState(null);
	const [totalSupply, setTotalSupply] = useState(null);

	// Battle Results
	const [battleResults, setBattleResults] = useState({
		battleReady: false,
		battlePending: false,
		tokenIdRegularPlayer1: null,
		tokenIdRegularPlayer2: null,
	});

	const [isBattleSoundEnabled, setIsBattleSoundEnabled] = useState(true);
	const [isDefensiveStance, setIsDefensiveStance] = useState(false);

	// UI Text
	const [textUserFeedback, setTextUserFeedback] = useState("");
	const [textOwnedListBrainWorms, setTextOwnedListBrainWorms] = useState("");
	const [pendingTxMessage, setPendingTxMessage] = useState("");
	const [toolTipTxMessage, setToolTipTxMessage] = useState("");
	const [vampireAttackToolTip, setVampireAttackToolTip] = useState("");

	// which Cartridge is displayed currently
	let currentCart1Idx = 0;
	let currentCart2Idx = 0;
	// Refs for the UI elements
	const imageRef = useRef(null);
	const containerRef = useRef(null);
	const tokenDisplayRef = useRef(null);
	const pendingTxMessageTextBoxRef = useRef(null);
	const userFeedbackTextBoxRef = useRef(null);
	const ownedListBrainWormsTextBoxRef = useRef(null);
	const musicTitleTextBoxRef = useRef(null);
	const screenReflectionsRef = useRef(null);
	const screenReflectionsSmallRef = useRef(null);
	const connectKitCustomButtonRef = useRef(null);

	const loadedBrainWormsTextBoxRef = useRef(null);

	const textBoxInputRef = useRef(null);
	const enemyTextBoxInputRef = useRef(null);
	const textBoxImageRef = useRef(null);
	const textBoxEnemyImageRef = useRef(null);
	const staticRef = useRef(null);

	const clickSoundSrcs = [SmartURL("/media/brainworms/simulator/click5.wav")];
	const audioRefs = useRef(clickSoundSrcs.map(() => new Audio()));

	const backgroundSrc = SmartURL(
		"/media/brainworms/simulator/simulation_background_screen6.jpg"
	);

	const backgroundSrcMobile = SmartURL(
		"/media/brainworms/simulator/simulation_background_mobile3.jpg"
	);

	const mobileOffsetX = 60;

	const screenReflectionsSrc = SmartURL(
		"/media/brainworms/simulator/screenReflections.png"
	);

	const screenReflectionsSmallSrc = SmartURL(
		"/media/brainworms/simulator/screenReflectionsSmall3.png"
	);

	const TOKEN_OFFSET = BigInt(
		"57896044618658097711785492504343953926634992332820282019728792003956564819968"
	);

	const backgroundStaticSrc = [
		SmartURL("/media/brainworms/simulator/del_channel_1.gif"),
		SmartURL("/media/brainworms/simulator/del_channel_2.gif"),
		SmartURL("/media/brainworms/simulator/del_channel_3.gif"),
		SmartURL("/media/brainworms/simulator/del_channel_4.gif"),
		SmartURL("/media/brainworms/simulator/del_channel_5.gif"),
		SmartURL("/media/brainworms/simulator/del_channel_6.gif"),
		SmartURL("/media/brainworms/simulator/del_channel_7.gif"),
		SmartURL("/media/brainworms/simulator/del_channel_8.gif"),
	];

	// Convinience function to convert the big number ID to a regular number
	function convertBigIdToRegular(id) {
		// ERC404 offsets the Id by  1 << 255
		// 57896044618658097711785492504343953926634992332820282019728792003956564819968
		return Number(BigInt(id) - TOKEN_OFFSET);
	}

	// Convinience function to convert the regular number ID to a big number
	function convertRegularIdToBig(id) {
		// ERC404 offsets the Id by  1 << 255
		// 57896044618658097711785492504343953926634992332820282019728792003956564819968
		return BigInt(id) + TOKEN_OFFSET;
	}

	// Clear the text feedback on errors
	function clearTextOnErrors() {
		setTextOwnedListBrainWorms("");
	}

	// random background static image
	useEffect(() => {
		if (!isLoggedIn) {
			return;
		}
		let shouldShowStaticBackground = enemyLoadedNFTIdBigInt
			? !(enemyLoadedNFTMetadata && enemyLoadedNFTProperties)
			: userLoadedNFTIdBigInt
			? !(userLoadedNFTMetadata && userLoadedNFTProperties)
			: true;

		if (shouldShowStaticBackground && !showStaticBackground) {
			console;
			setBackgroundStaticIndex(
				Math.floor(Math.random() * backgroundStaticSrc.length)
			);
		}

		if (shouldShowStaticBackground && Math.random() < 0.3) {
			setShowStaticBackground(shouldShowStaticBackground);
		} else {
			setShowStaticBackground(false);
		}

		if (isWelcomePageOpen) {
			setShowStaticBackground(false);
		}
	}, [
		enemyLoadedNFTIdBigInt,
		enemyLoadedNFTMetadata,
		enemyLoadedNFTProperties,
		userLoadedNFTIdBigInt,
		userLoadedNFTMetadata,
		userLoadedNFTProperties,
		showStaticBackground,
		isWelcomePageOpen,
	]);

	const getContract = async (address, abi, withSigner = true) => {
		if (!provider) {
			throw new Error("Provider not initialized");
		}

		try {
			if (withSigner) {
				const signer = await provider.getSigner();
				return new ethers.Contract(address, abi, signer);
			} else {
				return new ethers.Contract(address, abi, provider);
			}
		} catch (error) {
			console.error("Error getting contract:", error);
			throw error;
		}
	};

	// listen for guest mode changes
	useEffect(() => {
		setIsRerollPageOpen(false);
		setIsLeaderboardOpen(false);
		setIsResourceDisplayOpen(false);
		setIsWelcomePageOpen(false);
		setIsUserManualOpen(false);
		handleHideCanvas();

		if (isGuestMode) {
			fetchNextOwnedNFT();
		} else {
			fetchOwnedNFTs();
		}
	}, [isGuestMode]);

	const checkIfAlreadyClaimed = async () => {
		const contract = await getBrainWormsContract();
		if (contract) {
			const hasClaimed = await contract.hasClaimedAirdrop(userAddress);
			//console.log("hasClaimed: ", hasClaimed);
			if (hasClaimed) {
				//console.log("User has already claimed the airdrop.");
				setClaimableWorms(0);
				return true;
			} else {
				//console.log("User has not claimed the airdrop.");
				return false;
			}
		} else {
			//console.log("Failed to check if user has already claimed the airdrop.");
			return false;
		}
	};
	const fetchClaimants = async () => {
		if (await checkIfAlreadyClaimed()) {
			// //console.log(
			// 	"User has already claimed the airdrop exiting from CSV fetch"
			// );
			return;
		}

		if (claimants.length === 0) {
			//console.log("CSV Fetching claimants data...");
			Papa.parse(SmartURL("/data/airdrop_data_with_proofs2.csv"), {
				download: true,
				header: true,
				skipEmptyLines: true,
				step: (row) => {
					let proof = [];
					if (row.data.proof) {
						try {
							// Parse the proof string as JSON
							proof = JSON.parse(row.data.proof);
						} catch (error) {
							console.warn(
								`Error parsing proof for address ${row.data.address}:`,
								error
							);
						}
					}
					claimants.push({
						address: row.data.address.toLowerCase(),
						value: BigInt(row.data.value),
						proof: proof,
					});
				},
				complete: () => {
					////console.log("Parsed claimants data:", claimants);
					////console.log(
					// 	"claimants data from : ",
					// 	SmartURL("/data/airdrop_data_with_proofs.csv")
					// );
					setClaimants(claimants);

					// find address
					const claimantData = claimants.find(
						(data) => data.address.toLowerCase() === userAddress
					);
					// see if they have anything they can claim
					if (claimantData) {
						const value = BigInt(claimantData.value);
						setClaimableWorms(parseFloat(value) / 1000000000000000000);
					}
				},
				error: (error) => {
					console.error("Error fetching CSV:", error);
				},
			});
		} else {
			// //console.log("CSV Claimants data already fetched.");
			// //console.log("Parsed claimants data:", claimants);
			// //console.log(
			// 	"claimants data from : ",
			// 	SmartURL("/data/airdrop_data_with_proofs.csv")
			// );
		}
	};
	// parse the claimants data from the CSV file
	useEffect(() => {
		if (isLoggedIn) {
			fetchClaimants();
		}
	}, [isLoggedIn]);

	// if isResourceDisplayOpen we want to get the current block number
	// once a minute and update the resource display
	useEffect(() => {
		// //console.log("useEffect isResourceDisplayOpen");
		if (isResourceDisplayOpen) {
			// //console.log("isResourceDisplayOpen", isResourceDisplayOpen);

			getCurrentBlockNumber().then((blockNumber) => {
				if (blockNumber) {
					setCurrentBlockNumber(blockNumber);
				}
			});
			const intervalId = setInterval(() => {
				getCurrentBlockNumber().then((blockNumber) => {
					if (blockNumber) {
						setCurrentBlockNumber(blockNumber);
					}
					// //console.log("isResourceDisplayOpen Interval", isResourceDisplayOpen);
					// //console.log("blockNumber", blockNumber);
				});
			}, 30000);
			return () => clearInterval(intervalId);
		}
	}, [isResourceDisplayOpen, userLoadedNFTProperties]);

	// animated dots after the pending tx message
	const [msgString, setMsgString] = useState("");

	useEffect(() => {
		let intervalId;
		let dotCount = 0;

		if (pendingTxMessage) {
			intervalId = setInterval(() => {
				dotCount = (dotCount + 1) % 4;
				const dots = ".".repeat(dotCount);
				setMsgString(dots);
			}, 500);
		} else {
			setMsgString("");
		}

		return () => {
			clearInterval(intervalId);
		};
	}, [pendingTxMessage]);

	const setPendingTxMessageAnimated = (message) => {
		//console.log("setPendingTxMessageAnimated, message:", message);
		setToolTipMessage("");
		setPendingTxMessage(message);
		return () => setPendingTxMessage("");
	};

	const formattedPendingTxMessageMessage = (
		<>
			tx pending: <br /> {pendingTxMessage} {msgString}
		</>
	);

	// Initialize the combat system contract instance
	const getCombatSystemContract = async () => {
		//console.log("getCombatSystemContract");
		const providerAvailable = await checkProviderAvailable();
		if (!providerAvailable) {
			return null; // Ensures no further execution if provider is not available
		}
		const withSigner = true;
		try {
			return getContract(
				BrainWormsCombatSystemAddress,
				BrainWormsCombatSystemABI.abi,
				withSigner
			);
		} catch (error) {
			console.error(
				"Failed to initialize combat system contract with signer:",
				error
			);
			setTextUserFeedbackWithTimeout(
				"Failed to initialize the combat system contract with necessary permissions. Please try again."
			);
			return null;
		}
	};

	useEffect(() => {
		if (
			vampireMinVictimBalance !== 0 &&
			enemyLoadedNFTProperties &&
			enemyLoadedNFTMetadata
		) {
			checkIfVampireAttackReady();
		}
	}, [
		vampireMinVictimBalance,
		enemyLoadedNFTProperties,
		enemyLoadedNFTMetadata,
		userLoadedNFTProperties,
		userLoadedNFTMetadata,
	]);

	// Initialize the brain worms contract instance
	const getBrainWormsContract = async () => {
		//console.log("getContract");
		const providerAvailable = await checkProviderAvailable();
		if (!providerAvailable) {
			return null; // Ensures no further execution if provider is not available
		}
		// //console.log(BrainWormsABI);
		// //console.log(BrainWormsABI.abi);
		const withSigner = true;
		try {
			return getContract(
				BrainWormsContractAddress,
				BrainWormsABI.abi,
				withSigner
			);
		} catch (error) {
			console.error("Failed to initialize contract with signer:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to initialize the contract with necessary permissions. Please try again."
			);
			return null;
		}
	};

	function calculateQuality(stamina, strength, dexterity) {
		// check for NaN or undefined values and return 0 quality
		if (isNaN(stamina) || isNaN(strength) || isNaN(dexterity)) {
			return 0;
		}
		if (
			stamina === undefined ||
			strength === undefined ||
			dexterity === undefined
		) {
			return 0;
		}
		// if any of the stats are 0, return 0 quality
		if (stamina === 0 || strength === 0 || dexterity === 0) {
			return 0;
		}
		// Step 1: Normalize the stats
		const normalizedStamina = (stamina - 1) / 14; // 1-15 range
		const normalizedStrength = (strength - 1) / 9; // 1-10 range
		const normalizedDexterity = (dexterity - 1) / 9; // 1-10 range

		// Step 2: Calculate the base quality as the product of normalized stats
		// This ensures that low values in any stat significantly impact the quality
		let baseQuality =
			normalizedStamina * normalizedStrength * normalizedDexterity;

		// Step 3: Apply a power function to create more separation between high and low qualities
		baseQuality = Math.pow(baseQuality, 1 / 3);

		// Step 4: Calculate a balance factor
		const avg =
			(normalizedStamina + normalizedStrength + normalizedDexterity) / 3;
		const variance =
			(Math.pow(normalizedStamina - avg, 2) +
				Math.pow(normalizedStrength - avg, 2) +
				Math.pow(normalizedDexterity - avg, 2)) /
			3;
		const balanceFactor = 1 - Math.sqrt(variance);

		// Step 5: Combine base quality and balance factor
		// We'll weight base quality more heavily than balance
		const rawQuality = baseQuality * 0.8 + balanceFactor * 0.2;
		if (isNaN(rawQuality)) {
			return 0;
		}
		// Step 6: Scale to 0-100 range and round to nearest integer
		return Math.round(rawQuality * 100);
	}

	// Initialize the brain worms tokenURI contract
	const getBrainWormsTokenURIContract = async () => {
		//console.log("getContract");
		const providerAvailable = await checkProviderAvailable();
		if (!providerAvailable) {
			return null; // Ensures no further execution if provider is not available
		}
		// //console.log(BrainWormsABI);
		// //console.log(BrainWormsABI.abi);
		const withSigner = true;
		try {
			return getContract(
				BrainWormsTokenURIContractAddress,
				BrainWormsTokenURIABI.abi,
				withSigner
			);
		} catch (error) {
			console.error("Failed to initialize contract with signer:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to initialize the contract with necessary permissions. Please try again."
			);
			return null;
		}
	};

	const getCurrentBlockNumber = async () => {
		// //console.log("getCurrentBlockNumber");
		const providerAvailable = await checkProviderAvailable();
		if (!providerAvailable) {
			return null; // Ensures no further execution if provider is not available
		}
		try {
			// let provider = new ethers.BrowserProvider(window.ethereum);

			return provider.getBlockNumber();
		} catch (error) {
			console.error("Failed to get current block number:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to get the current block number. Reconnect and try again."
			);
			return null;
		}
	};

	// Initialize the harvesting contract instance
	const getHarvestingContract = async () => {
		//console.log("getHarvesterContract");
		const providerAvailable = await checkProviderAvailable();
		if (!providerAvailable) {
			return null; // Ensures no further execution if provider is not available
		}
		const withSigner = true;
		try {
			return getContract(
				BrainWormsResourceHarvestingAddress,
				BrainWormsResourceHarvestingABI.abi,
				withSigner
			);
		} catch (error) {
			console.error(
				"Failed to initialize harvesting contract with signer:",
				error
			);
			setTextUserFeedbackWithTimeout(
				"Failed to initialize the harvesting contract with necessary permissions. Please try again."
			);
			return null;
		}
	};

	// Initialize the storage contract instance
	const getStorageContract = async () => {
		//console.log("getStorageContract");
		const providerAvailable = await checkProviderAvailable();
		if (!providerAvailable) {
			return null; // Ensures no further execution if provider is not available
		}
		const withSigner = true;
		try {
			return getContract(
				BrainWormsResourceStorageAddress,
				BrainWormsResourceStorageABI.abi,
				withSigner
			);
		} catch (error) {
			console.error(
				"Failed to initialize storage contract with signer:",
				error
			);
			setTextUserFeedbackWithTimeout(
				"Failed to initialize the storage contract with necessary permissions. Please try again."
			);
			return null;
		}
	};

	const clearErrorMessage = () => {
		setTextUserFeedbackWithTimeout("");
	};

	const setTextUserFeedbackWithTimeout = (feedback, timeout = 5000) => {
		setTextUserFeedback(feedback);
		setTimeout(() => {
			setTextUserFeedback("");
		}, timeout);
	};

	const fetchLastHarvestBlock = async (id) => {
		//console.log("fetchLastHarvestBlock");
		const contract = await getHarvestingContract();
		if (!contract) {
			console.error("fetchLastHarvestBlock Contract instance is not available");
			setTextUserFeedbackWithTimeout(
				"Failed to connect to contract. Reconnect and try again."
			);
			return;
		}
		try {
			const lastHarvestBlock = await contract.lastHarvestBlock(id);
			// ////console.log(
			// 	"fetchLastHarvestBlock Last Harvest Block:",
			// 	lastHarvestBlock.toNumber()
			// );
			return Number(lastHarvestBlock);
		} catch (error) {
			console.error("Failed to fetch last harvest block:", error);
			setTextUserFeedbackWithTimeout(
				"fetchLastHarvestBlock Failed to fetch last harvest block. Reconnect and try again."
			);
			return null;
		}
	};

	const fetchNFTResourceBalances = async (id) => {
		if (isGuestMode) {
			return {
				resource1: 0,
				resource2: 0,
				resource3: 0,
				rank: 0,
				resourceTypeHarvested: 0,
			};
		}
		//console.log("fetchNFTResourceBalances", id);
		const contractStorage = await getStorageContract();
		let resource1Balance = "-";
		let resource2Balance = "-";
		let resource3Balance = "-";
		let totalResourceBalance = "-";

		// try to get each property type's balance from the storage contract
		try {
			resource1Balance = await contractStorage.getResourceBalance(id, 0);
			resource2Balance = await contractStorage.getResourceBalance(id, 1);
			resource3Balance = await contractStorage.getResourceBalance(id, 2);
			totalResourceBalance = await contractStorage.getTotalResourcesByType(0);
			////console.log(
			// 	"Total Resource Balance Type 0 :",
			// 	totalResourceBalance.toNumber()
			// );
			totalResourceBalance = await contractStorage.getTotalResourcesByType(1);
			////console.log(
			// 	"Total Resource Balance Type 1 :",
			// 	totalResourceBalance.toNumber()
			// );
			totalResourceBalance = await contractStorage.getTotalResourcesByType(2);
			////console.log(
			// 	"Total Resource Balance Type 2 :",
			// 	totalResourceBalance.toNumber()
			// );
		} catch (error) {
			console.error("Failed to fetch resource balances:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to fetch resource balances. Reconnect and try again."
			);
		}

		// now try to getPlayerRank from the resourceHarvesting contract
		let rank = 1;
		let resourceTypeHarvested = "-";
		try {
			const contractHarvesting = await getHarvestingContract();

			// passing in zero because we don't want to know the players rank when they
			// collect more resources, we want to know the current value
			rank = await contractHarvesting.getPlayerRanking(id, 0);
			rank = Number(rank);
			// rank might be null or zero if nobody has started harvesting yet
			if (rank === null || rank === 0) {
				rank = 1;
			} else {
				rank = rank;
			}

			//console.log("Player Rank:", rank);

			// now get the resource type that the player is currently harvesting
			resourceTypeHarvested =
				await contractHarvesting.resourceTypeBeingHarvested(id);
			resourceTypeHarvested = Number(resourceTypeHarvested);
			if (resourceTypeHarvested != "-" && resourceTypeHarvested != null) {
				resourceTypeHarvested = resourceTypeHarvested;
			}
			//console.log("Resource Type Harvested:", resourceTypeHarvested);
		} catch (error) {
			console.error("Failed to fetch player rank:", error);
			console.error("Failed to fetch resource type harvested:", error.name);
			setTextUserFeedbackWithTimeout(
				"Failed to fetch player rank. Reconnect and try again."
			);
		}

		return {
			resource1: Number(resource1Balance),
			resource2: Number(resource2Balance),
			resource3: Number(resource3Balance),
			rank: rank,
			resourceTypeHarvested: resourceTypeHarvested,
		};
	};

	const fetchLeaderboardData = async (startIdx, endIdx) => {
		await fetchTotalSupply();
		//console.log("fetchLeaderboardData", startIdx, endIdx);
		try {
			////console.log(
			// 	"fetchLeaderboardData ownedNFTsNormalIds",
			// 	ownedNFTsNormalIds
			// );
			const topWorms = await fetchLeaderboard(startIdx, endIdx);
			if (
				topWorms === null ||
				topWorms === undefined ||
				topWorms.length === 0
			) {
				console.error("Failed to fetch leaderboard data");
				return;
			}

			//console.log("fetchLeaderboardData Top Worms fetched");

			// now adjust topWorms so we know which position they are using the indexes, call it idx
			// and check if its in ownedNFTsNormalIds , label as enemy or not
			topWorms.forEach((worm, idx) => {
				worm.idx = startIdx + idx;
				if (ownedNFTsNormalIds.includes(worm.tokenidnorm)) {
					worm.enemy = false;
				} else {
					worm.enemy = true;
				}
				worm.pvptitle = getPvpTitle(worm.pvppoints);
				let bonuses = calculateStatBonuses(
					worm.isdefensivestance,
					worm.rank,
					worm.harvesting,
					worm.pvppoints
				);
				worm.staminabonus = bonuses.staminaBonus;
				worm.strengthbonus = bonuses.strengthBonus;
				worm.dexteritybonus = bonuses.dexterityBonus;
			});

			//console.log("Set Leaderboard Data");
			setLeaderboardData(topWorms);
			// //console.log(topWorms);
			// //console.log(topWorms);
		} catch (error) {
			console.error("Failed to fetch leaderboard data:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to fetch leaderboard data. Reconnect and try again."
			);
		}
		//console.log("fetchLeaderboardData finished");
	};

	const openSea = async () => {
		window.open(
			"https://opensea.io/assets/base/" + BrainWormsContractAddress,
			"_blank"
		);
	};

	const handleLeaderboardRowClick = async (id) => {
		const bigId = convertRegularIdToBig(id);

		// check if this is in the ownedNFTsNormalIds
		//console.log("handleLeaderboardRowClick", id);
		if (ownedNFTsBigInt.includes(bigId)) {
			if (isGuestMode) {
				setToolTipMessage(
					"Guest mode does not support viewing your own Brain Worms. Exit Guest Mode to load your Brain Worms."
				);
				return;
			}
			// this is the user's NFT
			//console.log("handleLeaderboardRowClick user");
			await fetchNFTMetadata(bigId, true, true);
		} else {
			// this means it is an enemy
			//console.log("handleLeaderboardRowClick enemy");
			await fetchNFTMetadata(bigId, false, true);
		}
	};

	// Fetch properties like strength wins losses from NFT
	const fetchNFTProperties = async (id, isUser) => {
		//console.log("fetchNFTProperties", id, isUser);

		// save a backup of the properties in case we need to revert
		let backupProperties = isUser
			? userLoadedNFTProperties
			: enemyLoadedNFTProperties;
		// clear the current properties
		isUser
			? setUserLoadedNFTProperties(null)
			: setEnemyLoadedNFTProperties(null);

		setIsVampireAttackReady(false);

		const contract = await getBrainWormsContract();
		if (!contract) {
			console.error("Contract instance is not available");
			setTextUserFeedbackWithTimeout(
				"Failed to connect to contract. Reconnect and try again."
			);
			return;
		}

		const resourceBalances = await fetchNFTResourceBalances(id);

		// now try to read the last harvest block
		let lastHarvestBlock = await fetchLastHarvestBlock(id);
		//console.log("fetchNFTProperties lastHarvestBlock", lastHarvestBlock);
		// uint8 stamina,
		// uint8 strength,
		// uint8 dexterity,
		// uint16 wins,
		// uint16 losses,
		// uint16 generation,
		// uint256 DNA,
		// 	bool isDefensiveStance

		// now get the properties from the contract
		try {
			const props = await contract.getProperties(id);
			// now calculate the bonuses
			let bonuses = calculateStatBonuses(
				props[7],
				resourceBalances.rank,
				resourceBalances.resourceTypeHarvested,
				props[8]
			);

			let properties = {
				stamina: Number(props[0]),
				strength: Number(props[1]),
				dexterity: Number(props[2]),
				wins: Number(props[3]),
				losses: Number(props[4]),
				generation: Number(props[5]),
				DNA: Number(props[6]),
				isDefensiveStance: props[7], // Assuming this is a boolean
				pvpPoints: Number(props[8]),
				resource1: Number(resourceBalances.resource1),
				resource2: Number(resourceBalances.resource2),
				resource3: Number(resourceBalances.resource3),
				rank: Number(resourceBalances.rank),
				resourceTypeHarvested: Number(resourceBalances.resourceTypeHarvested),
				tokenIdRegular: Number(convertBigIdToRegular(id)),
				lastHarvestBlock: Number(lastHarvestBlock),
				staminaBonus: Number(bonuses.staminaBonus),
				strengthBonus: Number(bonuses.strengthBonus),
				dexterityBonus: Number(bonuses.dexterityBonus),
			};

			properties.quality = calculateQuality(
				properties.stamina,
				properties.strength,
				properties.dexterity
			);

			//
			//console.log("properties", properties);
			// Either set the user's NFT or the enemy's NFT
			if (isUser) {
				if (
					userLoadedNFTProperties !== null &&
					userLoadedNFTProperties.pvpPointsRunning !== null
				) {
					// console.log(
					// 	"Setting new properties, pvpPointsRunning:",
					// 	userLoadedNFTProperties.pvpPointsRunning
					// );
					properties.pvpPointsRunning =
						userLoadedNFTProperties.pvpPointsRunning;
				}

				// if its the first time loading the user's NFT set the beginning PVP points
				if (userLoadedNFTBeginningPVPPoints === null) {
					setUserLoadedNFTBeginningPVPPoints(properties.pvpPoints);
				}
				// if the user's NFT has changed set the beginning PVP points
				if (userLoadedNFTIdBigInt !== null && userLoadedNFTIdBigInt !== id) {
					setUserLoadedNFTBeginningPVPPoints(properties.pvpPoints);
				}
				setUserLoadedNFTProperties(properties);
				setIsDefensiveStance(properties.isDefensiveStance);
				clearErrorMessage();
			} else {
				setEnemyLoadedNFTProperties(properties);

				clearErrorMessage();
			}
		} catch (error) {
			console.error("Failed to fetch NFT properties:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to fetch Brain Worm properties. Try again or reload the page."
			);
			clearTextOnErrors();
			// revert the properties
			isUser
				? setUserLoadedNFTProperties(backupProperties)
				: setEnemyLoadedNFTProperties(backupProperties);
		}
	};

	const fetchGuestModeUserMetadata = async (shouldGenerateNewProperties) => {
		// //console.log("fetchGuestModeUserMetadata , " + shouldGenerateNewProperties);
		let props = userLoadedNFTProperties;
		// generate a new worm unless the battle results are being cleared
		// and the same worm should persist
		if (shouldGenerateNewProperties) {
			props = await fetchGuestModeUserProperties();
		}

		setIsVampireAttackReady(false);
		setUserLoadedNFTMetadata(null);

		let contract = await getBrainWormsTokenURIContract();
		// stamina,strength,dexterity,generation,wins,losses,pvpPoints
		let lifeStats = [
			props.stamina,
			props.strength,
			props.dexterity,
			props.generation,
			props.wins,
			props.losses,
			props.pvpPoints,
		];
		let id = convertRegularIdToBig(69420);
		// //console.log("Fetching guest mode user metadata from contract");
		// //console.log("id:", id);
		// //console.log("DNA:", parseInt(props.DNA));
		// //console.log("lifeStats:", lifeStats);

		const metadata = await contract.tokenURI(id, props.DNA, lifeStats);

		setUserLoadedNFTMetadata(metadata);
		setUserLoadedNFTIdBigInt(id);
		clearErrorMessage();

		// Also switch off the resource display page if its open
		setIsResourceDisplayOpen(false);
		setIsLeaderboardOpen(false);
		setIsWelcomePageOpen(false);
	};

	const fetchGuestModeUserProperties = async () => {
		// //console.log("fetchGuestModeUserProperties");
		// DNA is large random number % 10000000000 needs to be 10 digits
		let DNA = Math.floor(Math.random() * 10000000000);
		while (DNA < 1000000000) {
			DNA += Math.floor(Math.random() * 10000000000);
		}
		DNA = DNA % 10000000000;

		let properties = {
			stamina: Math.floor(Math.random() * 6) + 10,
			strength: Math.floor(Math.random() * 10) + 1,
			dexterity: Math.floor(Math.random() * 10) + 1,
			wins: 0,
			losses: 0,
			generation: 1,
			DNA: parseInt(DNA),
			isDefensiveStance: true,
			staminaBonus: 0,
			dexterityBonus: 2,
			strengthBonus: 0,
			pvpPoints: 0,
			resource1: Math.floor(Math.random() * 500),
			resource2: Math.floor(Math.random() * 500),
			resource3: Math.floor(Math.random() * 500),
			rank: 1,
			resourceTypeHarvested: 0,
			tokenIdRegular: "Guest Mode",
			lastHarvestBlock: null,
		};

		properties.quality = calculateQuality(
			properties.stamina,
			properties.strength,
			properties.dexterity
		);

		setUserLoadedNFTProperties(properties);
		setIsDefensiveStance(properties.isDefensiveStance);
		clearErrorMessage();
		return properties;
	};

	// Fetch the metadata for a given NFT ID
	// either for the user or for an enemy
	const fetchNFTMetadata = async (id, isUser, shouldClearBattleResults) => {
		if (isGuestMode && isUser) {
			await fetchGuestModeUserMetadata(shouldClearBattleResults);
			return;
		}
		//console.log("fetchNFTMetadata", id, isUser, shouldClearBattleResults);
		// clear this out as it may change
		if (isUser === false) {
			setToolTipTxMessage("");
		}
		setIsVampireAttackReady(false);

		// this helps cartridges know if they need to update
		setPreviousEnemyIdBigInt(enemyLoadedNFTIdBigInt);
		setPreviousUserIdBigInt(userLoadedNFTIdBigInt);

		// make a backup of the metadata in case we need to revert
		let backupMetadata = isUser
			? userLoadedNFTMetadata
			: enemyLoadedNFTMetadata;
		// clear the current metadata
		isUser ? setUserLoadedNFTMetadata(null) : setEnemyLoadedNFTMetadata(null);

		// also fetch the properties
		fetchNFTProperties(id, isUser);

		// check if this ID is in the leaderboard data already
		if (leaderboardData.length > 0) {
			let leaderboardId = convertBigIdToRegular(id);
			let leaderboardIdx = leaderboardData.findIndex(
				(worm) => worm.tokenidnorm === leaderboardId
			);
			// if its not in here, NFTS are probably being claimed and the whole leaderboard
			// should be refreshed
			if (leaderboardIdx === -1) {
				////console.log(
				// 	"fetchNFTMetadata, leaderboardIdx is -1, refreshing leaderboard"
				// );
				fetchLeaderboardData(leaderboardIdxs[0], leaderboardIdxs[1]);
			}
		}

		const contract = await getBrainWormsContract();
		if (!contract) {
			console.error("Contract instance is not available");
			("Failed to connect to contract. Reconnect and try again.");
			return;
		}

		try {
			//console.log("fetchNFTMetadata getting contract");
			const metadata = await contract.tokenURI(id);
			// //console.log("metadata", metadata);
			// Either load the user's NFT or the enemy's NFT
			if (isUser) {
				////console.log(
				// 	"fetchNFTMetadata setting user loaded NFT metadata, id: ",
				// 	id
				// );
				setUserLoadedNFTMetadata(metadata);
				setUserLoadedNFTIdBigInt(id);

				clearErrorMessage();
				// also update the leaderboard data if its open
				// if (!shouldClearBattleResults) {
				// 	handleGenerateCanvas();
				// }
			} else {
				////console.log(
				// 	"fetchNFTMetadata setting enemy loaded NFT metadata, id: ",
				// 	id
				// );
				setEnemyLoadedNFTMetadata(metadata);
				setEnemyLoadedNFTIdBigInt(id);
				// check if a battle is pending, if so that means we are
				// refreshing because the user has attacked
				// and its about to display the results
				// so don't clear out the battle results
				if (shouldClearBattleResults) {
					// set battle ready because we're loading a new enemy
					setBattleResults({
						battleReady: true,
						battlePending: false,
						tokenIdRegularPlayer1: convertBigIdToRegular(userLoadedNFTIdBigInt),
						tokenIdRegularPlayer2: convertBigIdToRegular(id),
					});
				}
				clearErrorMessage();
				// Also switch off the resource display page if its open
				setIsResourceDisplayOpen(false);
				setIsLeaderboardOpen(false);
				setIsWelcomePageOpen(false);
			}
		} catch (error) {
			console.error("Failed to fetch NFT metadata:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to fetch Brain Worm metadata. Try again or reload the page."
			);
			clearTextOnErrors();
			// revert the metadata
			isUser
				? setUserLoadedNFTMetadata(backupMetadata)
				: setEnemyLoadedNFTMetadata(backupMetadata);
		}
	};

	const fetchSpecificNFT = async (fetchId) => {
		//console.log("fetchSpecificNFT");
		setIsRerollPageOpen(false);
		setIsUserManualOpen(false);
		setIsLeaderboardOpen(false);
		setIsResourceDisplayOpen(false);
		setIsWelcomePageOpen(false);
		if (textBoxInputRef.current.value === "" && fetchId === undefined) {
			// If the text box is empty and no arguemnt, do nothing
			return;
		}

		if (!(await verifyUserHasBrainWorms())) {
			// If the user doesn't have any brain worms, do nothing
			return;
		}

		if (isGuestMode) {
			await fetchGuestModeUserMetadata(true);
			setTextUserFeedbackWithTimeout(
				"Only one Brain Worm available in Guest Mode."
			);
			return;
		}
		// check if user even owns the NFT
		const enteredId = fetchId
			? fetchId
			: parseInt(textBoxInputRef.current.value, 10); // Convert to a number
		//console.log("typeof enteredId", typeof enteredId);
		//console.log("Entered ID:", enteredId);
		//console.log("Owned NFTs:", ownedNFTsNormalIds);
		if (!ownedNFTsNormalIds.includes(enteredId)) {
			setTextUserFeedbackWithTimeout(
				"You do not own this Brain Worm. Please enter a valid ID."
			);
			return;
		}
		let id = BigInt(enteredId) + TOKEN_OFFSET;
		fetchNFTMetadata(id, true, true);
	};

	const ensurePotentialEnemiesLoaded = async (actionAfterLoad) => {
		//console.log("ensurePotentialEnemiesLoaded", actionAfterLoad);
		if (potentialEnemiesBigInt.length === 0) {
			await fetchPotentialEnemies(actionAfterLoad);
		} else {
			setActionAfterEnemyLoad(actionAfterLoad);
		}
		return; // Resolve indicating enemies are now loaded.
	};

	// now make a useEffect for actionAfterEnemyLoad
	useEffect(() => {
		//console.log("useEffect actionAfterEnemyLoad", actionAfterEnemyLoad);
		if (actionAfterEnemyLoad) {
			switch (actionAfterEnemyLoad) {
				case "randomEnemyNFTAfterLoad":
					fetchRandomEnemyNFTAfterLoad();
					break;
				case "fetchSpecificEnemyNFTAfterLoad":
					fetchSpecificEnemyNFTAfterLoad(enemyTextBoxInputRef.current.value);
					break;
				default:
					break;
			}
		}
	}, [actionAfterEnemyLoad]);

	const fetchTotalSupply = async () => {
		if (!totalSupply) {
			//console.log("fetchTotalSupply");
			const contract = await getBrainWormsContract();
			if (!contract) {
				console.error("Contract instance is not available");
				setTextUserFeedbackWithTimeout(
					"Failed to connect to contract. Reconnect and try again."
				);
				return;
			}
			try {
				const supply = await contract.erc721TotalSupply();
				//console.log("Total Supply:", Number(supply));
				setTotalSupply(Number(supply));
				setLeaderboardIdxs([1, Number(supply) + 2]);
			} catch (error) {
				console.error("Failed to fetch total supply:", error);
				setTextUserFeedbackWithTimeout(
					"Failed to fetch total supply. Reconnect and try again."
				);
			}
		}
	};
	// for battles
	const handleGenerateCanvas = () => {
		// console.log("handleGenerateCanvas");
		if (tokenDisplayRef.current) {
			// console.log("handleGenerateCanvas showCanvas");
			tokenDisplayRef.current.showCanvas();
			tokenDisplayRef.current.generateCanvas();
		}
	};

	const handleHideCanvas = () => {
		// console.log("handleHideCanvas");
		if (tokenDisplayRef.current) {
			// console.log("handleHideCanvas hideCanvas");
			tokenDisplayRef.current.hideCanvas();
		}
	};

	const handleShowCanvas = () => {
		// console.log("handleShowCanvas");
		if (tokenDisplayRef.current && battleResults.tokenIdPlayer2) {
			// console.log("handleShowCanvas showCanvas");
			tokenDisplayRef.current.showCanvas();
		}
	};

	const fetchPotentialEnemies = async (actionAfterLoad) => {
		// we're going to getERC721QueueLength() to find out which NFTs are stored in the contract
		// then call getERC721TokensInQueue(start,count) with start at zero and count at QueueLength
		// then we're going to filter out the NFTs that the user owns
		// and store it in the potentialEnemies state
		//console.log("fetchPotentialEnemies");
		const contract = await getBrainWormsContract();
		if (!contract) {
			console.error("Contract instance is not available");
			setTextUserFeedbackWithTimeout(
				"Failed to connect to contract. Reconnect and try again."
			);
			setActionAfterEnemyLoad(null);
			return;
		}

		try {
			let newPotentialEnemiesBigInt = [];
			for (let i = 0; i < totalSupply; i++) {
				// starts at 1, so we need to add the offset
				newPotentialEnemiesBigInt.push(BigInt(i + 1) + TOKEN_OFFSET);
			}
			// //console.log("Potential Enemies BigInt: ", newPotentialEnemiesBigInt);
			// now remove all of the NFTs that the user owns, ownedNFTsBigInt

			newPotentialEnemiesBigInt = newPotentialEnemiesBigInt.filter(
				(token) => !ownedNFTsBigInt.includes(token)
			);

			////console.log(
			// 	"Potential Enemies BigInt after filtering by User: ",
			// 	newPotentialEnemiesBigInt
			// );

			// Get all the NFTs in the queue owned by the contract, these are invalid enemies
			// We need the length first
			const queueLength = await contract.getERC721QueueLength();
			// //console.log("Queue Length: ", queueLength);
			const queueLengthNumber = BigInt(queueLength);
			// //console.log("Queue Length Number: ", queueLengthNumber);
			// So we can get the full list of NFTs in the queue
			const tokensInQueue = await contract.getERC721TokensInQueue(
				0,
				queueLengthNumber
			);
			// //console.log("Tokens in Queue: ", tokensInQueue);

			// check if tokensInQue is non empty and if so, remove them from the potential enemies
			if (tokensInQueue.length > 0) {
				// Now we need to filter out the NFTs that the user owns which are also invalid enemies
				// we need to convert the tokensInQue into BigInt so we can compare
				const tokensInQueueBigInt = tokensInQueue.map((token) => BigInt(token));
				// filter out the owned NFTs from the tokensInQueue
				newPotentialEnemiesBigInt = newPotentialEnemiesBigInt.filter(
					(token) => !tokensInQueueBigInt.includes(token)
				);
				////console.log(
				// 	"Tokens in Queue after filtering by User and Queue: ",
				// 	tokensInQueue
				// );
			}

			setPotentialEnemiesBigInt(newPotentialEnemiesBigInt);
			// ////console.log(
			// 	"Final Potential Enemies BigInt: ",
			// 	newPotentialEnemiesBigInt
			// );
		} catch (error) {
			console.error("Failed to fetch potential enemies:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to fetch potential enemies. Reconnect and try again."
			);
			setActionAfterEnemyLoad(null);
		}
		setActionAfterEnemyLoad(actionAfterLoad);
	};

	const checkIfEnemyisValid = async (enemyId) => {
		//console.log("checkIfEnemyisValid");
		// call ownerOf() on the enemy ID
		// if the owner is the contract, or the zero address, it is invalid
		// if the owner is the user, it is invalid
		// if the owner is another user, it is valid
		try {
			const contract = await getBrainWormsContract();
			if (!contract) {
				console.error("Contract instance is not available");
				setTextUserFeedbackWithTimeout(
					"Failed to connect to contract. Reconnect and try again."
				);
				return false;
			}
			const owner = await contract.ownerOf(enemyId);
			if (
				owner === BrainWormsContractAddress ||
				owner === zeroAddress ||
				owner === userAddress
			) {
				setTextUserFeedbackWithTimeout(
					"Invalid enemy Brain Worm. Please enter a valid ID."
				);
				return false;
			}
		} catch (error) {
			console.error("Failed to fetch enemy owner:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to fetch enemy Brain Worm. Reconnect and try again."
			);
			return false;
		}
		return true;
	};

	const attackEnemyBite = async () => {
		attackEnemy(true);
	};

	const attackEnemySting = async () => {
		attackEnemy(false);
	};

	useEffect(() => {
		const fetchVampireMinVictimBalance = async () => {
			const contractHarvesting = await getHarvestingContract();
			if (contractHarvesting) {
				try {
					let minBal = await contractHarvesting.vampireMinVictimBalance();
					setVampireMinVictimBalance(Number(minBal));
				} catch (error) {
					console.error("Failed to fetch min victim balance:", error);
				}
				try {
					let cooldown = await contractHarvesting.vampireBlockCooldown();
					setVampireBlockCooldown(Number(cooldown));
				} catch (error) {
					console.error("Failed to fetch vampire block cooldown:", error);
				}
			}
		};

		fetchVampireMinVictimBalance();
	}, []);

	const drainResource = async () => {
		attackEnemy(true);
	};

	const checkIfVampireAttackReady = async () => {
		if (isGuestMode) {
			setVampireAttackToolTip(
				<>Drain an enemy's resources. Not available in Guest Mode.</>
			);
			setIsVampireAttackReady(false);
			return false;
		}
		//console.log("checkIfVampireAttackReady");
		// verify an enemy nft is loaded
		if (!enemyLoadedNFTIdBigInt) {
			// //console.log("checkIfVampireAttackReady - Enemy NFT is not loaded");
			setIsVampireAttackReady(false);
			return false;
		}

		////console.log(
		// 	"checkIfVampireAttackReady - userLoadedNFTProperties lastHarvestBlock:",
		// 	userLoadedNFTProperties?.lastHarvestBlock ?? "null"
		// );
		if (!userLoadedNFTProperties?.lastHarvestBlock) {
			setVampireAttackToolTip(
				<>
					Drain an enemy's resources. You must select a resource to harvest
					first.
				</>
			);
			////console.log(
			// 	"checkIfVampireAttackReady - user has not begun harvesting or lastHarvestBlock is null"
			// );
			return false;
		}

		const contractStorage = await getStorageContract();

		if (!contractStorage) {
			console.error(
				"checkIfVampireAttackReady - Contract instance is not available"
			);
			setTextUserFeedbackWithTimeout(
				"checkIfVampireAttackReady - Failed to connect to contract. Reconnect and try again."
			);
			setIsVampireAttackReady(false);
			return false;
		}

		// get the user's resource type harvested
		let resourceTypeHarvested = userLoadedNFTProperties?.resourceTypeHarvested;
		////console.log(
		// 	"checkIfVampireAttackReady - Resource Type Harvested:",
		// 	resourceTypeHarvested
		// );

		let enemyResourceBalance = 0;

		// check if resourceTypeHarvested is undefined and exit if so
		if (resourceTypeHarvested === undefined) {
			////console.log(
			// 	"checkIfVampireAttackReady - resourceTypeHarvested is undefined returning early "
			// );

			setIsVampireAttackReady(false);
			return false;
		}

		try {
			////console.log(
			// 	"checkIfVampireAttackReady - Fetching enemy resource balance , ",
			// 	enemyLoadedNFTIdBigInt,
			// 	resourceTypeHarvested
			// );
			enemyResourceBalance = await contractStorage.getResourceBalance(
				enemyLoadedNFTIdBigInt,
				resourceTypeHarvested
			);
			enemyResourceBalance = Number(enemyResourceBalance);
			////console.log(
			// 	"checkIfVampireAttackReady - Enemy Resource Balance:",
			// 	enemyResourceBalance
			// );
		} catch (error) {
			console.error(
				"checkIfVampireAttackReady - Failed to fetch enemy resource balance:",
				error
			);
			setIsVampireAttackReady(false);
			return false;
		}

		////console.log(
		// 	"checkIfVampireAttackReady - Comparing enemyResourceBalance:",
		// 	enemyResourceBalance,
		// 	"with vampireMinVictimBalance:",
		// 	vampireMinVictimBalance
		// );

		if (enemyResourceBalance < vampireMinVictimBalance) {
			let resourceNames = ["Glucose", "Amino Acids", "Lipids"];
			let resourceName = resourceNames[resourceTypeHarvested];
			setVampireAttackToolTip(
				<>
					Drain an enemy's resources. They need more than{" "}
					{vampireMinVictimBalance} of the resource you're harvesting,{" "}
					{resourceName}.
				</>
			);
			setIsVampireAttackReady(false);
			return false;
		}

		const contractHarvesting = await getHarvestingContract();
		if (!contractHarvesting) {
			console.error(
				"checkIfVampireAttackReady - Contract instance is not available"
			);
			setTextUserFeedbackWithTimeout(
				"checkIfVampireAttackReady - Failed to connect to contract. Reconnect and try again."
			);
			setIsVampireAttackReady(false);
			return false;
		}

		// now check if the lastVampireBlock of the user is greater than the vampire block cooldown
		let lastVampireBlockUser = await contractHarvesting.lastVampireBlock(
			userLoadedNFTIdBigInt
		);
		lastVampireBlockUser = Number(lastVampireBlockUser);

		if (vampireBlockCooldown === null || vampireBlockCooldown === undefined) {
			let cooldown = await contractHarvesting.vampireBlockCooldown();
			setVampireBlockCooldown(Number(cooldown));
		}

		// use getCurrentBlockNumber to get the current block number
		let currentBlock = await getCurrentBlockNumber();
		currentBlock = Number(currentBlock);
		////console.log(
		// 	"checkIfVampireAttackReady Current Block:",
		// 	currentBlock,
		// 	" , lastVampireBlockUser",
		// 	lastVampireBlockUser,
		// 	" , vampireBlockCooldown",
		// 	vampireBlockCooldown
		// );

		if (currentBlock - lastVampireBlockUser < vampireBlockCooldown) {
			let blocksRemaining =
				vampireBlockCooldown - (currentBlock - lastVampireBlockUser);
			setVampireAttackToolTip(
				"You are tired, wait " + blocksRemaining + " blocks"
			);
			setIsVampireAttackReady(false);
			return false;
		}

		// now check if the enemy's lastVampireBlock is greater than the vampire block cooldown
		let lastVampireBlockEnemy = await contractHarvesting.lastVampireBlock(
			enemyLoadedNFTIdBigInt
		);
		lastVampireBlockEnemy = Number(lastVampireBlockEnemy);

		////console.log(
		// 	"checkIfVampireAttackReady Enemy Current Block:",
		// 	currentBlock,
		// 	" , lastVampireBlockEnemy",
		// 	lastVampireBlockEnemy,
		// 	" , vampireBlockCooldown",
		// 	vampireBlockCooldown
		// );

		if (currentBlock - lastVampireBlockEnemy < vampireBlockCooldown) {
			let blocksRemaining =
				vampireBlockCooldown - (currentBlock - lastVampireBlockEnemy);
			setVampireAttackToolTip(
				"Enemy is drained, recovering for " + blocksRemaining + " blocks."
			);
			setIsVampireAttackReady(false);
			////console.log(
			// 	"checkIfVampireAttackReady Enemy is recovering for ",
			// 	blocksRemaining,
			// 	" blocks"
			// );
			return false;
		}

		// if we get here, then the user can attack the enemy

		setIsVampireAttackReady(true);
		setVampireAttackToolTip("Drain resource attack ready!");
		return true;
	};

	const onBattleDisplayCompletion = async () => {
		fetchNFTMetadata(userLoadedNFTIdBigInt, true, false);
		fetchNFTMetadata(enemyLoadedNFTIdBigInt, false, false);
	};

	async function estimateAndSendTransaction(
		contract,
		functionName,
		args,
		adjustedGasAmount = 120
	) {
		try {
			// Try to estimate gas first
			let estimatedGas;
			try {
				estimatedGas = await contract[functionName].estimateGas(...args);
			} catch (gasError) {
				console.warn(
					`Gas estimation failed, using fallback gas limit:`,
					gasError
				);
				estimatedGas = BigInt(500000); // Fallback gas limit
			}

			// Calculate adjusted gas limit with minimum
			const minimumGas = BigInt(100000);
			const adjustedGasLimit = BigInt(
				Math.max(
					Number((estimatedGas * BigInt(adjustedGasAmount)) / 100n),
					Number(minimumGas)
				)
			);

			// Send the transaction with the calculated gas limit
			const transactionResponse = await contract[functionName](...args, {
				gasLimit: adjustedGasLimit,
			});

			return transactionResponse;
		} catch (error) {
			console.error(
				`Error in estimateAndSendTransaction for ${functionName}:`,
				error
			);
			throw error;
		}
	}

	const attackEnemy = async (isBite) => {
		// check if the user has a loaded NFT
		if (!userLoadedNFTIdBigInt) {
			setTextUserFeedbackWithTimeout(
				"You must load a Brain Worm before attacking."
			);
			return;
		}
		// check if the enemy has a loaded NFT
		if (!enemyLoadedNFTIdBigInt) {
			setTextUserFeedbackWithTimeout(
				"You must load an enemy Brain Worm before attacking."
			);
			return;
		}
		// check if the contract is available
		const brainWormsContract = await getBrainWormsContract();
		if (!brainWormsContract) {
			console.error("Brain Worms Contract instance is not available");
			setTextUserFeedbackWithTimeout(
				"Failed to connect to contract. Reconnect and try again."
			);
			return;
		}

		// get the combatSystemContract
		const combatSystemContract = await getCombatSystemContract();
		if (!combatSystemContract) {
			console.error("Combat System Contract instance is not available");
			setTextUserFeedbackWithTimeout(
				"Failed to connect to combat system contract. Reconnect and try again."
			);
			return;
		}

		// Call fightBrainWorms() with the user's NFT and the enemy's NFT
		const clearMessage = setPendingTxMessageAnimated("battle");
		try {
			// increase by another 60% to avoid out of gas errors
			let adjustedGasAmount = 160;
			if (isVampireAttackReady) {
				// crank it up to 200% if the user is attacking with a vampire attack
				// to be sure the transaction goes through
				adjustedGasAmount = 200;
			}

			let transactionResponse;

			handleHideCanvas();

			if (isGuestMode) {
				//console.log("Starting combat in guest mode");
				// in guest mode we directly use the combatSystemContract
				// because the user doesn't have a Brain Worm token
				// so we can pass in hypothetical worm data and
				// skip the fight outcome storage that happens in the brainWormsContract
				// Combat System fightBrainWorms function args
				/*
					uint256 fightCount_,
					uint256 tokenIdAttacker_,
					uint256 tokenIdDefender_,
					uint8[4] memory p1Attributes, // [stamina, strength, dexterity, isDefensiveStance]
					uint8[4] memory p2Attributes, // [stamina, strength, dexterity, isDefensiveStance]
					uint16 p1PVPPoints_,
					uint16 p2PVPPoints_,
					bool attackType_
				)*/

				transactionResponse = await estimateAndSendTransaction(
					combatSystemContract,
					"fightBrainWorms",
					[
						0, // fight count starts from 1 in real contract
						userLoadedNFTIdBigInt, // guest mode user id
						enemyLoadedNFTIdBigInt,
						[
							userLoadedNFTProperties.stamina,
							userLoadedNFTProperties.strength,
							userLoadedNFTProperties.dexterity,
							userLoadedNFTProperties.isDefensiveStance ? 1 : 0,
						],
						[
							enemyLoadedNFTProperties.stamina,
							enemyLoadedNFTProperties.strength,
							enemyLoadedNFTProperties.dexterity,
							enemyLoadedNFTProperties.isDefensiveStance ? 1 : 0,
						],
						userLoadedNFTProperties.pvpPoints,
						enemyLoadedNFTProperties.pvpPoints,
						BigInt(isBite ? 1 : 0),
					],
					adjustedGasAmount
				);
			} else {
				transactionResponse = await estimateAndSendTransaction(
					brainWormsContract,
					"fightBrainWorms",
					[userLoadedNFTIdBigInt, enemyLoadedNFTIdBigInt, isBite],
					adjustedGasAmount
				);
			}

			// Handle successful transaction
			//console.log("Attack successful:", transactionResponse.hash);

			setBattleResults({
				battleReady: false,
				battlePending: true,
				tokenIdRegularPlayer1: convertBigIdToRegular(userLoadedNFTIdBigInt),
				tokenIdRegularPlayer2: convertBigIdToRegular(enemyLoadedNFTIdBigInt),
			});
			const result = await transactionResponse.wait();
			clearMessage(); // Clear the message using the cleanup function

			//console.log("Transaction Receipt:", result);
			//console.log("Transaction Hash:", result.transactionHash);
			//console.log("Transaction logs:", result.logs);
			//console.log("Transaction events:", result.events);

			// Get the BattleResult event signature
			const battleResultEventSignature =
				"BattleResult(uint256,uint256,uint256,bool,bool,bool,bool,bool,uint8,uint8,bool,uint256,uint16,uint16,int8)";
			const battleResultEventTopic = ethers.id(battleResultEventSignature);

			// Find the BattleResult event in the transaction logs
			const battleResultEvent = result.logs.find(
				(log) => log.topics[0] === battleResultEventTopic
			);

			if (battleResultEvent) {
				// Decode the event data using the contract's interface
				const eventFragment =
					combatSystemContract.interface.getEvent("BattleResult");
				const decodedData = combatSystemContract.interface.decodeEventLog(
					eventFragment,
					battleResultEvent.data,
					battleResultEvent.topics
				);

				// Access the decoded event data
				const battleId = decodedData.battleId;

				const tokenIdPlayer1 = Number(decodedData.tokenIdPlayer1);
				const tokenIdPlayer2 = Number(decodedData.tokenIdPlayer2);

				const tokenIdPlayer1Big = BigInt(decodedData.tokenIdPlayer1);
				const tokenIdPlayer2Big = BigInt(decodedData.tokenIdPlayer2);

				const didPlayer1RollInitiative = decodedData.didPlayer1RollInitiative;
				const didPlayer1CriticalHit = decodedData.didPlayer1CriticalHit;
				const didPlayer2CriticalHit = decodedData.didPlayer2CriticalHit;
				const didPlayer1Miss = decodedData.didPlayer1Miss;
				const didPlayer2Miss = decodedData.didPlayer2Miss;
				const staminaRemainingPlayer1 = decodedData.staminaRemainingPlayer1;
				const staminaRemainingPlayer2 = decodedData.staminaRemainingPlayer2;
				const didPlayer1Win = decodedData.didPlayer1Win;
				const resourceAmountTransferred = decodedData.resourceAmountTransferred;
				const p1PvpPoints = decodedData.p1PvpPoints;
				const p2PvpPoints = decodedData.p2PvpPoints;
				const pvpPointsChange =
					decodedData.PvpPointsChange === undefined
						? 0
						: decodedData.PvpPointsChange;

				let bonusesP1 = calculateStatBonuses(
					userLoadedNFTProperties.isDefensiveStance,
					userLoadedNFTProperties.rank,
					userLoadedNFTProperties.resourceTypeHarvested,
					p1PvpPoints
				);

				let bonusesP2 = calculateStatBonuses(
					enemyLoadedNFTProperties.isDefensiveStance,
					enemyLoadedNFTProperties.rank,
					enemyLoadedNFTProperties.resourceTypeHarvested,
					p2PvpPoints
				);

				// Build the battleResults object
				const battleResults = {
					battlePending: false,
					battleReady: false,
					battleId: Number(battleId),
					tokenIdPlayer1: BigInt(tokenIdPlayer1),
					tokenIdPlayer2: BigInt(tokenIdPlayer2),
					tokenIdRegularPlayer1: convertBigIdToRegular(tokenIdPlayer1Big),
					tokenIdRegularPlayer2: convertBigIdToRegular(tokenIdPlayer2Big),
					didPlayer1RollInitiative: didPlayer1RollInitiative,
					didPlayer1CriticalHit: didPlayer1CriticalHit,
					didPlayer2CriticalHit: didPlayer2CriticalHit,
					didPlayer1Miss: didPlayer1Miss,
					didPlayer2Miss: didPlayer2Miss,
					staminaRemainingPlayer1: Number(staminaRemainingPlayer1),
					staminaRemainingPlayer2: Number(staminaRemainingPlayer2),
					didPlayer1Win: didPlayer1Win,
					resourceAmountTransferred: Number(resourceAmountTransferred),
					staminaStartingPlayer1: Number(userLoadedNFTProperties.stamina),
					staminaStartingPlayer2: Number(enemyLoadedNFTProperties.stamina),
					strengthStartingPlayer1: Number(userLoadedNFTProperties.strength),
					strengthStartingPlayer2: Number(enemyLoadedNFTProperties.strength),
					dexterityStartingPlayer1: Number(userLoadedNFTProperties.dexterity),
					dexterityStartingPlayer2: Number(enemyLoadedNFTProperties.dexterity),
					resourceHarvestingByPlayer1: Number(
						userLoadedNFTProperties.resourceTypeHarvested
					),
					resourceHarvestingByPlayer2: Number(
						enemyLoadedNFTProperties.resourceTypeHarvested
					),
					p1PvpPoints: Number(p1PvpPoints),
					p2PvpPoints: Number(p2PvpPoints),
					p1StaminaBonus: Number(bonusesP1.staminaBonus),
					p2StaminaBonus: Number(bonusesP2.staminaBonus),
					p1StrengthBonus: Number(bonusesP1.strengthBonus),
					p2StrengthBonus: Number(bonusesP2.strengthBonus),
					p1DexterityBonus: Number(bonusesP1.dexterityBonus),
					p2DexterityBonus: Number(bonusesP2.dexterityBonus),
					pvpPointsChange: Number(pvpPointsChange),
				};

				// if we are in guest mode, locally store the pvp points and wins losses
				// because they wont be available in the contract
				if (isGuestMode) {
					setUserLoadedNFTProperties((prevState) => ({
						...prevState,
						pvpPoints: p1PvpPoints,
						wins: didPlayer1Win ? prevState.wins + 1 : prevState.wins,
						losses: didPlayer1Win ? prevState.losses : prevState.losses + 1,
					}));
				}

				setUserLoadedNFTProperties((prevState) => ({
					...prevState,
					pvpPointsRunning:
						battleResults.p1PvpPoints - userLoadedNFTBeginningPVPPoints,
				}));

				console.log("Battle Results:", battleResults);
				setBattleResults(battleResults);
				handleGenerateCanvas();
			} else {
				console.error("BattleResult event not found in transaction logs");
				setTextUserFeedbackWithTimeout(
					"Failed to process battle results. Reconnect and try again."
				);
				// Clear the battle results pending if the attack fails
				setBattleResults({
					battleReady: true,
					battlePending: false,
					tokenIdRegularPlayer1: convertBigIdToRegular(userLoadedNFTIdBigInt),
					tokenIdRegularPlayer2: convertBigIdToRegular(enemyLoadedNFTIdBigInt),
				});
				setPendingTxMessage(""); // Clear the message directly
				clearMessage(); // Clear the message using the cleanup function
				handleHideCanvas();
			}
		} catch (error) {
			console.error("Failed to attack enemy:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to attack enemy Brain Worm or process results. Reconnect and try again."
			);
			// Clear the battle results pending if the attack fails
			setBattleResults({
				battleReady: true,
				battlePending: false,
				tokenIdRegularPlayer1: convertBigIdToRegular(userLoadedNFTIdBigInt),
				tokenIdRegularPlayer2: convertBigIdToRegular(enemyLoadedNFTIdBigInt),
			});
			setPendingTxMessage(""); // Clear the message directly
			clearMessage(); // Clear the message using the cleanup function
			handleHideCanvas();
		}
	};

	const openResourcesPage = async () => {
		if (!(await verifyUserHasBrainWorms())) {
			// If the user doesn't have any brain worms, do nothing
			return;
		}
		if (!battleResults.battlePending) {
			//console.log("openResourcesPage");
			if (isResourceDisplayOpen) {
				handleShowCanvas();
			} else {
				handleHideCanvas();
			}
			setIsResourceDisplayOpen(!isResourceDisplayOpen);
			setIsLeaderboardOpen(false);
			setIsRerollPageOpen(false);
			setIsUserManualOpen(false);
			setIsWelcomePageOpen(false);

			// ejectEnemy();
		}
	};

	const downloadWorm = () => {
		// Call the handleDownloadClick function in the BrainWormsTokenDisplay component
		if (userLoadedNFTIdBigInt) {
			const userTokenDisplayIframe = document.getElementById(
				"brainWormsTokenDisplay"
			);
			if (userTokenDisplayIframe && userTokenDisplayIframe.contentWindow) {
				userTokenDisplayIframe.contentWindow.postMessage("exportUSDZ", "*");
			}
		}
	};

	const rerollWorm = async () => {
		if (!(await verifyUserHasBrainWorms())) {
			// If the user doesn't have any brain worms, do nothing
			return;
		}

		if (isGuestMode) {
			await fetchGuestModeUserProperties();
			await fetchGuestModeUserMetadata(true);
			// close the reroll page
			openRerollPage();
		}

		// get brainworms contract then call publicRerollWorm on the bigInt of the loaded user nft
		const contract = await getBrainWormsContract();
		if (!contract) {
			console.error("Contract instance is not available");
			setTextUserFeedbackWithTimeout(
				"Failed to connect to contract. Reconnect and try again."
			);
			return;
		}

		const clearMessage = setPendingTxMessageAnimated("reroll");
		try {
			const transactionResponse = await estimateAndSendTransaction(
				contract,
				"publicRerollWorm",
				[userLoadedNFTIdBigInt],
				150 // 150% of the estimated gas
			);

			const result = await transactionResponse.wait();
			//console.log("Reroll transaction receipt:", result);

			// now fetch the user's new NFT
			await fetchSpecificNFT(convertBigIdToRegular(userLoadedNFTIdBigInt));
		} catch (error) {
			console.error("Failed to reroll Brain Worm:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to reroll Brain Worm. Reconnect and try again."
			);
		}
		// close the reroll page
		openRerollPage();
		clearMessage(); // Clear the message using the cleanup function
	};

	const [hasInterstitialMoment, setHasInterstitialMoment] = useState(false);
	// interstitial moment
	useEffect(() => {
		if (hasInterstitialMoment) {
			setTextUserFeedbackWithTimeout("");
		} else if (
			isWelcomePageOpen == false &&
			isLoggedIn &&
			userOwnsAnyBrainWorms == false &&
			hasInterstitialMoment == false
		) {
			setHasInterstitialMoment(true);
			//console.log("interstitial moment");
			setTextUserFeedbackWithTimeout(
				<>
					-------------------
					<br />
					SYSTEM BOOT SEQUENCE
					<br />
					-------------------
					<br />
					BRAIN WORM SIMULATION SYSTEM
					<br />
					V 1.1.2
					<br />
					<br />
					XIO SKY SECURITY SOLUTIONS
					<br />
					OUR SHADOW. YOUR SHIELD
				</>
			);
		}
	}, [isWelcomePageOpen, isLoggedIn, userOwnsAnyBrainWorms]);

	const openRerollPage = async () => {
		if (!(await verifyUserHasBrainWorms())) {
			// If the user doesn't have any brain worms, do nothing
			return;
		}
		if (!battleResults.battlePending) {
			//console.log("openRerollPage");
			setIsRerollPageOpen(!isRerollPageOpen);
			setIsLeaderboardOpen(false);
			handleHideCanvas();

			setIsResourceDisplayOpen(false);
		}
	};

	const openUserManualPage = () => {
		setHasUserManualEverBeenOpened(true);
		setIsRerollPageOpen(false);
		setIsLeaderboardOpen(false);
		setIsResourceDisplayOpen(false);
		setIsUserManualOpen(!isUserManualOpen);
		handleHideCanvas();
	};
	const openLeaderboardPage = async () => {
		if (!(await verifyUserHasBrainWorms())) {
			// If the user doesn't have any brain worms, do nothing
			return;
		}
		if (!battleResults.battlePending) {
			//console.log("openLeaderboardPage");
			setIsLeaderboardOpen(!isLeaderboardOpen);
			setIsResourceDisplayOpen(false);
			setIsRerollPageOpen(false);
			setIsUserManualOpen(false);

			// ejectEnemy();

			// if its false we are opening the leaderboard
			if (!isLeaderboardOpen) {
				// fetch leaderboard data
				await fetchTotalSupply();
				await fetchLeaderboardData(leaderboardIdxs[0], leaderboardIdxs[1]);
				handleHideCanvas();
			} else {
				handleShowCanvas();
			}
		}
	};

	const ejectEnemy = () => {
		if (!battleResults.battlePending) {
			setEnemyLoadedNFTMetadata(null);
			setEnemyLoadedNFTProperties(null);
			setEnemyLoadedNFTIdBigInt(null);
			// totally clear the battle results
			setBattleResults(null);
			// then just set the starting values
			setBattleResults({
				battleReady: false,
				battlePending: false,
				tokenIdRegularPlayer1: null,
				tokenIdRegularPlayer2: null,
			});
			handleHideCanvas();
		}
	};

	const verifyUserHasBrainWorms = async () => {
		// if user ownedNFTs has never been fetched, fetch it
		if (ownedNFTsBigInt.length === 0) {
			await fetchOwnedNFTs();
			return;
		}
		// Check if the user has any NFTs
		if (ownedNFTsNormalIds.length === 0) {
			setUserOwnsAnyBrainWorms(false);
			if (!isWelcomePageOpen) {
				//console.log("Opening claim page");
				setIsWelcomePageOpen(true);
			} else if (!isWelcomePageOpen) {
				setTextUserFeedbackWithTimeout(
					"You do not own any Brain Worms, or the simulator failed to fetch them. Reconnect and try again."
				);
				clearTextOnErrors();
			}
			return false;
		} else {
			setUserOwnsAnyBrainWorms(true);
			return true;
		}
	};
	const fetchRandomEnemyNFT = async () => {
		//console.log("fetchRandomEnemyNFT");
		setIsRerollPageOpen(false);
		setIsUserManualOpen(false);
		setIsLeaderboardOpen(false);
		setIsWelcomePageOpen(false);
		handleHideCanvas();

		if (await verifyUserHasBrainWorms()) {
			ensurePotentialEnemiesLoaded("randomEnemyNFTAfterLoad");
		}
	};

	const setResourceTypeToHarvest = async (resourceType) => {
		// if there's no userloadedNFT it should just exit
		if (!userLoadedNFTIdBigInt) {
			return;
		}

		// calling setResourceTypeToHarvest on brainworms.sol with the resourceType
		// and the user's NFT ID
		const contract = await getBrainWormsContract();
		if (!contract) {
			console.error("Contract instance is not available");
			setTextUserFeedbackWithTimeout(
				"Failed to connect to contract. Reconnect and try again."
			);
			return;
		}

		setPendingTxMessageAnimated("set resource type");

		let currentResourceToHarvest =
			userLoadedNFTProperties.resourceTypeHarvested;

		try {
			//console.log("Setting resource type to harvest:", resourceType);
			const transactionResponse = await contract.setResourceTypeToHarvest(
				userLoadedNFTIdBigInt,
				resourceType
			);
			const result = await transactionResponse.wait();
			setPendingTxMessage(""); // Clear the message
			//console.log("Set resource type transaction receipt:", result);
			//console.log("setResourceTypeToHarvest to harvest:", resourceType);
			currentResourceToHarvest = resourceType;

			// now fetch the users rank as that will change based on the resource
			const contractHarvesting = await getHarvestingContract();
			if (!contractHarvesting) {
				console.error("Contract instance is not available");
				setTextUserFeedbackWithTimeout(
					"Failed to connect to contract. Reconnect and try again."
				);
				return;
			}
			try {
				// passing in zero because we don't want to know the players rank when they
				// collect more resources, we want to know the current value
				let updatedRank = await contractHarvesting.getPlayerRanking(
					userLoadedNFTIdBigInt,
					resourceType
				);
				updatedRank = Number(updatedRank);
				//console.log("Updated Rank:", updatedRank);

				try {
					// now try to read the last harvest block
					let lastHarvestBlock = await fetchLastHarvestBlock(
						userLoadedNFTIdBigInt
					);
					// now get the bonuses
					let bonuses = calculateStatBonuses(
						userLoadedNFTProperties.isDefensiveStance,
						updatedRank,
						currentResourceToHarvest,
						userLoadedNFTProperties.pvpPoints
					);

					// now set it in the userLoadedNFTProperties
					setUserLoadedNFTProperties({
						...userLoadedNFTProperties,
						resourceTypeHarvested: currentResourceToHarvest,
						lastHarvestBlock: lastHarvestBlock,
						rank: updatedRank,
						staminaBonus: bonuses.staminaBonus,
						strengthBonus: bonuses.strengthBonus,
						dexterityBonus: bonuses.dexterityBonus,
					});
				} catch (error) {
					console.error("Failed to set resource type:", error);
					setTextUserFeedbackWithTimeout(
						"Failed to set resource type. Reconnect and try again."
					);
					setPendingTxMessage(""); // Clear the message
				}
			} catch (error) {
				console.error("Failed to set resource type:", error);
				setTextUserFeedbackWithTimeout(
					"Failed to set resource type. Reconnect and try again."
				);
				setPendingTxMessage(""); // Clear the message
			}
		} catch (error) {
			console.error("Failed to set resource type:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to set resource type. Reconnect and try again."
			);
			setPendingTxMessage(""); // Clear the message
		}
	};

	const fetchRandomEnemyNFTAfterLoad = async () => {
		//console.log("fetchRandomEnemyNFTAfterLoad");

		// await ensurePotentialEnemiesLoaded();

		if (potentialEnemiesBigInt.length > 0) {
			const randomIndex = Math.floor(
				Math.random() * potentialEnemiesBigInt.length
			);
			const randomEnemyId = potentialEnemiesBigInt[randomIndex];
			//console.log("Rolled Random Enemy ID: ", randomEnemyId);

			const isValidEnemy = await checkIfEnemyisValid(randomEnemyId);
			if (isValidEnemy) {
				fetchNFTMetadata(randomEnemyId, false, true);
			} else {
				//console.log("Chosen enemy is not valid.");
				setTextUserFeedbackWithTimeout("Chosen enemy is not valid.");
			}
		} else {
			//console.log("Potential Enemies BigInt is empty");
			setTextUserFeedbackWithTimeout(
				"Failed to fetch potential enemies. Reconnect and try again."
			);
		}
		setActionAfterEnemyLoad(null);
	};

	const fetchSpecificEnemyNFT = async () => {
		//console.log("fetchSpecificEnemyNFT");
		setIsRerollPageOpen(false);
		setIsUserManualOpen(false);
		setIsLeaderboardOpen(false);
		setIsWelcomePageOpen(false);
		handleHideCanvas();

		if (await verifyUserHasBrainWorms()) {
			ensurePotentialEnemiesLoaded("fetchSpecificEnemyNFTAfterLoad");
		}
	};

	const fetchSpecificEnemyNFTAfterLoad = async (enemyId) => {
		//console.log("fetchSpecificEnemyNFT");
		if (enemyTextBoxInputRef.current.value === "") {
			//console.log("Enemy text box is empty");
			// If the text box is empty, do nothing
			return;
		}
		// await ensurePotentialEnemiesLoaded();

		const enemyBigIntId = BigInt(enemyId) + TOKEN_OFFSET;
		if (potentialEnemiesBigInt.includes(enemyBigIntId)) {
			const isValidEnemy = await checkIfEnemyisValid(enemyBigIntId);
			if (isValidEnemy) {
				fetchNFTMetadata(enemyBigIntId, false, true);
			} else {
				setTextUserFeedbackWithTimeout("Invalid enemy ID.");
			}
		} else {
			setTextUserFeedbackWithTimeout(
				"This enemy ID does not exist in potential enemies."
			);
		}
		setActionAfterEnemyLoad(null);
	};

	const fetchNextOwnedNFT = async () => {
		// //console.log("fetchNextOwnedNFT");

		if (!(await verifyUserHasBrainWorms())) {
			// //console.log("fetchNextOwnedNFT - user has no brain worms");
			// if the user has no brain worms, exit
			// return;
		}

		setIsRerollPageOpen(false);
		setIsUserManualOpen(false);

		// else, fetch the next NFT in the list
		// and loop back to the first NFT if the last NFT is reached
		const currentIndex = ownedNFTsBigInt.indexOf(userLoadedNFTIdBigInt);
		const nextIndex = (currentIndex + 1) % ownedNFTsBigInt.length;
		await fetchNFTMetadata(ownedNFTsBigInt[nextIndex], true, true);
	};
	// Fetch the owned NFTs when the component mounts
	const fetchOwnedNFTs = async () => {
		// Clear any messages that may be pending due to alpha test claim
		setPendingTxMessage("");
		if (isGuestMode) {
			//console.log("fetchOwnedNFTs in guest mode");
			// if the user is in guest mode
			// then we don't need to fetch the owned NFTs
			// and we should repopulate with guest mode data
			setOwnedTokensCount(1);
			setOwnedNFTsNormalIds([69420]);
			setOwnedNFTsBigInt([convertRegularIdToBig(69420)]);
			setUserOwnsAnyBrainWorms(true);
			return;
		}
		//console.log("fetchOwnedNFTs");
		// check if the  provider is available
		const providerAvailable = await checkProviderAvailable();
		if (!providerAvailable) {
			//console.log("fetchOwnedNFTs  provider is not available");
			return null; // Ensures no further execution if provider is not available
		}
		try {
			// Fetch the owned NFTs

			const contract = await getBrainWormsContract();
			if (!contract) {
				console.error("Contract instance is not available");
				setTextUserFeedbackWithTimeout(
					"Failed to connect to contract. Reconnect and try again."
				);
				return;
			}
			// //console.log("User Address: ", userAddress);

			const nfts = await contract.owned(userAddress);
			let tokens = await contract.balanceOf(userAddress);

			if (tokens != null && tokens != undefined) {
				tokens = parseFloat(tokens) / 10 ** 18;
				setOwnedTokensCount(tokens);

				// //console.log("Tokens: ", tokens);

				if (tokens > 0 && tokens < 1) {
					setTextUserFeedback(
						<>
							Your token balance is:
							<br />
							{tokens} Brain Worms.
							<br />
							<br />
							You must hold at least 1
							<br />
							to use the simulator.
							<br />
							<br />
							Acquire more tokens
							<br /> or activate Guest Mode.
						</>
					);
					clearTextOnErrors();
					return;
				}
			}

			// Check if the user has any NFTs
			if (nfts.length === 0) {
				setUserOwnsAnyBrainWorms(false);
				if (!isWelcomePageOpen) {
					//console.log("Opening claim page");
					setIsWelcomePageOpen(true);
					return;
				} else if (!isWelcomePageOpen) {
					setTextUserFeedbackWithTimeout(
						"You do not own any Brain Worms, or the simulator failed to fetch them. Reconnect and try again."
					);
					clearTextOnErrors();
					return;
				}
				return;
			} else {
				clearErrorMessage();
			}

			// Convert the big number IDs to regular numbers
			let nftsNormalIds = [];
			let nftsBigInt = [];
			for (let i = 0; i < nfts.length; i++) {
				// log as big number convert
				// //console.log(BigInt(nfts[i]));
				nftsNormalIds = nftsNormalIds.concat(
					convertBigIdToRegular(BigInt(nfts[i]))
				);
				// //console.log(convertBigIdToRegular(BigInt(nfts[i])));
				nftsBigInt = nftsBigInt.concat(BigInt(nfts[i]));
			}
			setOwnedNFTsNormalIds(nftsNormalIds);
			setOwnedNFTsBigInt(nftsBigInt);
			setUserOwnsAnyBrainWorms(true);
			// //console.log(nftsBigInt);

			// need to only display the first 11 NFTs and then prepend with "..." if there is more
			let displayText = tokens
				? `${tokens} Brain Worms owned: `
				: `${nfts.length} Brain Worms owned: `;
			let displayNFTs = nftsNormalIds.slice(0, 12);

			displayText += displayNFTs.join(", ");
			// cut off if too long
			if (displayText.length > 50) {
				// instead slice at the nearest comma before N characters
				const lastComma = displayText.lastIndexOf(",", 50);
				displayText = displayText.slice(0, lastComma) + "...";
			}

			setTextOwnedListBrainWorms(displayText);
			// Fetch the metadata for the first NFT
			fetchNFTMetadata(nftsBigInt[0], true, true);
		} catch (error) {
			setTextUserFeedbackWithTimeout(
				"Failed to fetch your Brain Worms. Reconnect and try again."
			);
			clearTextOnErrors();
			console.error("Failed to fetch owned NFTs:", error);
		}
	};

	const claimBrainWorm = async () => {
		if (battleResults.battlePending) {
			setTextUserFeedbackWithTimeout(
				"Please wait for the current battle to finish."
			);
			return;
		}
		if (await checkIfAlreadyClaimed()) {
			setToolTipMessage("You can only claim once.");
			return;
		} else {
			const contract = await getBrainWormsContract();
			if (!contract) {
				console.error("Contract instance is not available");
				setTextUserFeedbackWithTimeout(
					"Failed to connect to contract. Reconnect and try again."
				);
				return;
			}

			const airdropIsOpen = await contract.airdropIsOpen();
			if (!airdropIsOpen) {
				setTextUserFeedbackWithTimeout(
					"Brain Worm airdrop is closed. You cannot claim a Brain Worm."
				);
				return;
			}

			if (claimants && claimants.length > 0) {
				// //console.log(
				// 	"Claim - sanity check on claimants data, type of: " +
				// 		typeof claimants[0].address
				// );
			} else {
				await fetchClaimants();
			}

			// //console.log(claimants);

			// //console.log("Claim - Claiming Brain Worm for address:", address);
			// //console.log("Claim - Address type of " + typeof address);
			// //console.log(
			// 	"Claim -  Claiming Brain Worm for address lowercase:",
			// 	address.toLowerCase()
			// );
			// //console.log(
			// 	"Claim -  Claiming brainworm for address lowercase type of: " +
			// 		typeof address.toLowerCase()
			// );

			// //console.log(
			// 	"Claim -  Forcing address to be string, type of: " + typeof address
			// );

			// //console.log("Claimants:", claimants);

			// Find the claimant data for the current address
			const claimantData = claimants.find(
				(data) => data.address.toLowerCase() === userAddress
			);
			const clearMessage = setPendingTxMessageAnimated("claiming");

			if (claimantData) {
				const proof = claimantData.proof;
				const value = BigInt(claimantData.value);
				//console.log(
				// 	"Claim - Claiming Brain Worm for address:",
				// 	userAddress,
				// 	" with proof ",
				// 	proof,
				// 	" with value:",
				// 	value,
				// 	"type of value :",
				// 	typeof value
				// );

				try {
					// needs about 160k gas per 1 nft which is 10^18 units
					let gas =
						(BigInt(claimantData.value) / BigInt(10 ** 18)) * BigInt(160000);
					if (gas < 500000) {
						gas = 500000;
					}
					if (gas > 10000000) {
						gas = 10000000;
					}
					////console.log(
					// 	"Claiming ",
					// 	claimantData.value,
					// 	" with gas limit of ",
					// 	gas
					// );
					// Attempt to mint tokens using the Merkle proof
					const tx = await contract.airdropMint(proof, value, {
						gasLimit: gas,
					});

					await tx.wait();
					//console.log("Brain Worm claimed successfully!");
					setIsGuestMode(false);
					await fetchOwnedNFTs();
					setIsWelcomePageOpen(false);
					checkIfAlreadyClaimed();
				} catch (error) {
					console.error("Failed to claim Brain Worm:", error);
					setTextUserFeedbackWithTimeout(
						"Failed to claim Brain Worm. Please try again."
					);
				}
			} else {
				setTextUserFeedbackWithTimeout(
					<>
						You are not eligible to claim a Brain Worm. Join our Discord to
						receive authorization. <br />
						<br />
						<a href="https://discord.gg/YbxdyrnfRD">
							https://discord.gg/YbxdyrnfRD
						</a>
					</>
				);
			}
			clearMessage();
		}
	};

	// Re-run when Provider changes
	useEffect(() => {
		//console.log(" isLoggedIn", isLoggedIn);
		if (!isLoggedIn || !provider) {
			// clear out the user data
			// and the enemy data
			// and any pending battles

			setUserLoadedNFTMetadata(null);
			setUserLoadedNFTProperties(null);
			setUserLoadedNFTIdBigInt(null);
			setEnemyLoadedNFTMetadata(null);
			setEnemyLoadedNFTProperties(null);
			setEnemyLoadedNFTIdBigInt(null);
			setBattleResults(null);
			setBattleResults({
				battleReady: false,
				battlePending: false,
				tokenIdRegularPlayer1: null,
				tokenIdRegularPlayer2: null,
			});
			setOwnedTokensCount(0);
			setOwnedNFTsNormalIds([]);
			setOwnedNFTsBigInt([]);
			setUserOwnsAnyBrainWorms(false);
			setIsGuestMode(false);
			return;
		}
		const fetchData = async () => {
			try {
				await fetchOwnedNFTs();
				await fetchLeaderboardData(leaderboardIdxs[0], leaderboardIdxs[1]);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [provider, isLoggedIn]);

	const combatButtonMobileOffset = 60;
	const elements = [
		{
			// text box 0 is for loading owned brain worms,  must be element 0 for styles to work
			src: SmartURL("/media/brainworms/simulator/textBox.png"),
			baseX: 2715 + 240,
			baseY: 1889 + 65,
			baseXMobile: 30,
			baseYMobile: 0,
			baseXMobile: combatButtonMobileOffset * -1.5,
			width: 172,
			height: 90,
			ref: textBoxImageRef,
			toolTip: "Enter Brain Worm ID",
		},
		{
			// text box 1 is for loading ENEMY brain worms,  must be element 1 for styles to work
			src: SmartURL("/media/brainworms/simulator/textBox.png"),
			baseX: 2075 - 60,
			baseY: 1889 + 65,
			baseXMobile: combatButtonMobileOffset,
			baseYMobile: 0,
			width: 172,
			height: 90,
			ref: textBoxEnemyImageRef,
			toolTip: "Enter enemy Brain Worm ID",
		},
		{
			src: SmartURL("/media/brainworms/simulator/bite.png"),
			srcHover: SmartURL("/media/brainworms/simulator/bite_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/bite_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/bite_off.png"),
			baseX: 1600 - 60,
			baseY: 1805 + 65,
			baseXMobile: combatButtonMobileOffset,
			baseYMobile: 0,
			width: 239,
			height: 75,
			ref: useRef(null),
			action: attackEnemyBite,
			name: "bite",
			toolTip: (
				<>
					+3 Strength Attack <br /> Bite enemy! <br />
					Strength increases your odds of landing a hit, and the damage done.
				</>
			),
		},
		{
			src: SmartURL("/media/brainworms/simulator/sting.png"),
			srcHover: SmartURL("/media/brainworms/simulator/sting_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/sting_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/sting_off.png"),
			baseX: 1600 - 60,
			baseY: 1894 + 65,
			baseXMobile: combatButtonMobileOffset,

			width: 239,
			height: 75,
			ref: useRef(null),
			action: attackEnemySting,
			name: "sting",
			toolTip: (
				<>
					+3 Dexterity Attack <br /> Sting enemy! <br />
					Dexterity increases your odds of dodging an attacker.
				</>
			),
		},
		{
			src: SmartURL("/media/brainworms/simulator/eject.png"),
			srcHover: SmartURL("/media/brainworms/simulator/eject_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/eject_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/eject_off.png"),
			baseX: 2075 - 60,
			baseY: 1800 + 65,
			baseXMobile: combatButtonMobileOffset,

			width: 172,
			height: 90,
			ref: useRef(null),
			action: ejectEnemy,
			name: "eject",
			toolTip: "Eject enemy.",
		},
		{
			src: SmartURL("/media/brainworms/simulator/rndEnemy.png"),
			srcHover: SmartURL("/media/brainworms/simulator/rndEnemy_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/rndEnemy_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/rndEnemy_off.png"),
			baseX: 1840 - 60,
			baseY: 1800 + 65,
			baseXMobile: combatButtonMobileOffset,

			width: 239,
			height: 90,
			ref: useRef(null),
			action: fetchRandomEnemyNFT,
			name: "rndEnemy",
			toolTip: "Load random enemy Brain Worm.",
		},

		{
			src: SmartURL("/media/brainworms/simulator/loadNext.png"),
			srcHover: SmartURL("/media/brainworms/simulator/loadNext_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/loadNext_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/loadNext_off.png"),
			baseX: 2480 + 240,
			baseY: 1800 + 65,
			baseXMobile: combatButtonMobileOffset * -1.5,
			width: 239,
			height: 90,
			ref: useRef(null),
			action: fetchNextOwnedNFT,
			name: "loadNext",
			toolTip: "Load next Brain Worm you own.",
		},
		{
			srcOff: SmartURL("/media/brainworms/simulator/nullsleep_off.png"),
			srcOn: SmartURL("/media/brainworms/simulator/nullsleep_on.png"),
			srcHover: SmartURL("/media/brainworms/simulator/nullsleep_hover.png"),
			baseX: 2240 + 10,
			baseY: 1889 + 65,
			baseXMobile: combatButtonMobileOffset * 0.25,

			width: 239,
			height: 90,
			ref: useRef(null),
			action: toggleMusicPlay,
			name: "nullsleep",
			toolTip: "Play Nullsleep.",
		},
		{
			src: SmartURL("/media/brainworms/simulator/loadId.png"),
			srcHover: SmartURL("/media/brainworms/simulator/loadId_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/loadId_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/loadId_off.png"),
			baseX: 2480 + 240,
			baseY: 1889 + 65,
			baseXMobile: combatButtonMobileOffset * -1.5,

			width: 239,
			height: 90,
			ref: useRef(null),
			action: fetchSpecificNFT,
			name: "loadId",
			toolTip: "Load specific Brain Worm you own.",
		},
		{
			src: SmartURL("/media/brainworms/simulator/enemyId.png"),
			srcHover: SmartURL("/media/brainworms/simulator/enemyId_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/enemyId_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/enemyId_off.png"),
			baseX: 1840 - 60,
			baseY: 1889 + 65,
			baseXMobile: combatButtonMobileOffset,

			width: 239,
			height: 90,
			ref: useRef(null),
			action: fetchSpecificEnemyNFT,
			name: "enemyId",
			toolTip: "Load specific enemy Brain Worm.",
		},

		{
			srcOff: SmartURL("/media/brainworms/simulator/sound_off.png"),
			srcOn: SmartURL("/media/brainworms/simulator/sound_on.png"),
			srcHover: SmartURL("/media/brainworms/simulator/sound_hover.png"),
			baseX: 2240 + 10,
			baseY: 1800 + 65,
			baseXMobile: combatButtonMobileOffset * 0.25,
			width: 239,
			height: 90,
			ref: useRef(null),
			action: () => setIsBattleSoundEnabled(!isBattleSoundEnabled),
			name: "soundToggle",
			toolTip: "Toggle all sounds.",
		},
		{
			srcDefOff: SmartURL("/media/brainworms/simulator/offDef_DefenseOff.png"),
			srcDefOn: SmartURL("/media/brainworms/simulator/offDef_DefenseOn.png"),
			srcOffOff: SmartURL("/media/brainworms/simulator/offDef_OffenseOff.png"),
			srcOffOn: SmartURL("/media/brainworms/simulator/offDef_OffenseOn.png"),
			baseX: 1840 - 60,
			baseY: 1978 + 65,
			baseXMobile: combatButtonMobileOffset,

			width: 239,
			height: 90,
			ref: useRef(null),
			action: () => setIsDefensiveStance(!isDefensiveStance),
			name: "stance",
			// explain that it will enable +2 strength if they are defensive or +2 dex if they are offensive
			// check isDefensiveStance
			toolTip: (
				<>
					{isDefensiveStance ? "Defensive" : "Offensive"} stance gives you a +2
					bonus to {isDefensiveStance ? "Dexterity" : "Strength"}. Switch your
					stance to {isDefensiveStance ? "Offensive" : "Defensive"} for a +2
					bonus to {isDefensiveStance ? "Strength" : "Dexterity"}.
				</>

				// if (isDefensiveStance) {
				// 	return "Defensive Stance: +2 Strength";
				// } else {
				// 	return "Offensive Stance: +2 Dexterity";
				// }
			),
		},

		{
			src: SmartURL("/media/brainworms/simulator/cartridge1.png"),
			srcs: [
				SmartURL("/media/brainworms/simulator/cartridge1.png"),
				SmartURL("/media/brainworms/simulator/cartridge2.png"),
				SmartURL("/media/brainworms/simulator/cartridge3.png"),
				SmartURL("/media/brainworms/simulator/cartridge4.png"),
				SmartURL("/media/brainworms/simulator/cartridge5.png"),
				SmartURL("/media/brainworms/simulator/cartridge6.png"),
			],
			baseX: 1425,
			baseY: 100,
			width: 541,
			height: 426,
			ref: useRef(null),
			name: "cartridge1",
			display: userLoadedNFTIdBigInt ? "block" : "none",
		},
		{
			src: SmartURL("/media/brainworms/simulator/cartridge2.png"),
			srcs: [
				SmartURL("/media/brainworms/simulator/cartridge1.png"),
				SmartURL("/media/brainworms/simulator/cartridge2.png"),
				SmartURL("/media/brainworms/simulator/cartridge3.png"),
				SmartURL("/media/brainworms/simulator/cartridge4.png"),
				SmartURL("/media/brainworms/simulator/cartridge5.png"),
				SmartURL("/media/brainworms/simulator/cartridge6.png"),
			],
			baseX: 2438,
			baseY: 100,
			width: 541,
			height: 426,
			ref: useRef(null),
			name: "cartridge2",
			display: enemyLoadedNFTIdBigInt ? "block" : "none",
		},
		{
			srcOff: SmartURL("/media/brainworms/simulator/resources_off.png"),
			srcOffHover: SmartURL(
				"/media/brainworms/simulator/resources_off_hover.png"
			),
			srcOffActive: SmartURL(
				"/media/brainworms/simulator/resources_off_active.png"
			),
			srcOn: SmartURL("/media/brainworms/simulator/resources_on.png"),
			srcOnHover: SmartURL(
				"/media/brainworms/simulator/resources_on_hover.png"
			),
			srcOnActive: SmartURL(
				"/media/brainworms/simulator/resources_on_active.png"
			),
			srcDisabled: SmartURL(
				"/media/brainworms/simulator/resources_disabled.png"
			),
			baseX: 2480 + 240,
			baseY: 1978 + 65,
			baseXMobile: combatButtonMobileOffset * -1.5,

			width: 401,
			height: 90,
			ref: useRef(null),
			action: () => openResourcesPage(),
			name: "resourceModeToggle",
			toolTip: "Resource management.",
		},
		{
			srcOff: SmartURL("/media/brainworms/simulator/leaderboard_off.png"),
			srcOffHover: SmartURL(
				"/media/brainworms/simulator/leaderboard_off_hover.png"
			),
			srcOffActive: SmartURL(
				"/media/brainworms/simulator/leaderboard_off_active.png"
			),
			srcOn: SmartURL("/media/brainworms/simulator/leaderboard_on.png"),
			srcOnHover: SmartURL(
				"/media/brainworms/simulator/leaderboard_on_hover.png"
			),
			srcOnActive: SmartURL(
				"/media/brainworms/simulator/leaderboard_on_active.png"
			),
			srcDisabled: SmartURL(
				"/media/brainworms/simulator/leaderboard_disabled.png"
			),
			baseX: 2240 + 10,
			baseY: 1978 + 65,
			baseXMobile: combatButtonMobileOffset * -1,
			width: 401,
			height: 90,
			ref: useRef(null),
			action: () => openLeaderboardPage(),
			name: "leaderboardModeToggle",
			toolTip: "Leader Board of top Brain Worms.",
		},
		{
			src: SmartURL("/media/brainworms/simulator/drain.png"),
			srcHover: SmartURL("/media/brainworms/simulator/drain_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/drain_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/drain_off.png"),
			baseX: 1600 - 60,
			baseY: 1978 + 65,
			baseXMobile: combatButtonMobileOffset,
			width: 239,
			height: 75,
			ref: useRef(null),
			action: () => drainResource(),
			name: "drain",
		},
		{
			src: SmartURL("/media/brainworms/simulator/reroll.png"),
			srcHover: SmartURL("/media/brainworms/simulator/reroll_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/reroll_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/reroll_active.png"),
			baseX: 1700,
			baseY: 888,
			width: 239,
			height: 90,
			ref: useRef(null),
			action: () => openRerollPage(),
			name: "reroll",
		},
		{
			src: SmartURL("/media/brainworms/simulator/download.png"),
			srcHover: SmartURL("/media/brainworms/simulator/download_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/download_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/download_active.png"),
			baseX: 1970,
			baseY: 888,
			width: 239,
			height: 90,
			ref: useRef(null),
			action: () => downloadWorm(),
			name: "download",
		},

		{
			src: SmartURL("/media/brainworms/simulator/swapRound.png"),
			srcHover: SmartURL("/media/brainworms/simulator/swapRound_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/swapRound_active.png"),
			baseX: 3275,
			baseY: 600,
			baseXMobile: -1500,
			baseYMobile: 1600,
			width: 172 * 0.8,
			height: 225 * 0.8,
			ref: useRef(null),
			name: "swapRound",
			action: () =>
				window.open(
					"https://app.uniswap.org/swap?chain=base&outputCurrency=" +
						BrainWormsContractAddress,
					"_blank"
				),
			toolTip: "Swap for Brain Worms",
		},

		{
			src: SmartURL("/media/brainworms/simulator/openseaRound.png"),
			srcHover: SmartURL("/media/brainworms/simulator/openseaRound_hover.png"),
			srcActive: SmartURL(
				"/media/brainworms/simulator/openseaRound_active.png"
			),

			baseX: 3275,
			baseY: 900,
			baseXMobile: -1250,

			baseYMobile: 1300,
			width: 172 * 0.8,
			height: 225 * 0.8,
			ref: useRef(null),
			action: openSea,
			name: "openSea",
			toolTip: "View on Open Sea.",
		},

		{
			srcOff: SmartURL("/media/brainworms/simulator/guestRound_off.png"),
			srcOffHover: SmartURL(
				"/media/brainworms/simulator/guestRound_off_hover.png"
			),
			srcOffActive: SmartURL(
				"/media/brainworms/simulator/guestRound_off_active.png"
			),
			srcOn: SmartURL("/media/brainworms/simulator/guestRound_on.png"),
			srcOnHover: SmartURL(
				"/media/brainworms/simulator/guestRound_on_hover.png"
			),
			srcOnActive: SmartURL(
				"/media/brainworms/simulator/guestRound_on_active.png"
			),
			srcDisabled: SmartURL(
				"/media/brainworms/simulator/guestRound_disabled.png"
			),

			baseX: 3275,
			baseY: 1200,
			baseXMobile: -1000,
			baseYMobile: 1000,

			width: 172 * 0.8,
			height: 225 * 0.8,
			ref: useRef(null),
			name: "guestMode",

			//action: () => setIsGuestMode(!isGuestMode),
			action: () => {
				if (battleResults.battlePending) {
					setTextUserFeedbackWithTimeout(
						"Please wait for the current battle to finish."
					);
				} else {
					setIsGuestMode(!isGuestMode);
				}
			},
			toolTip: "Toggle guest mode.",
		},

		{
			src: SmartURL("/media/brainworms/simulator/claimRound.png"),
			srcHover: SmartURL("/media/brainworms/simulator/claimRound_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/claimRound_active.png"),
			srcDisabled: SmartURL(
				"/media/brainworms/simulator/claimRound_disabled.png"
			),
			baseX: 3275,
			baseY: 1500,
			baseXMobile: -750,

			baseYMobile: 700,
			width: 172 * 0.8,
			height: 225 * 0.8,
			ref: useRef(null),
			name: "claimRound",
			action: claimBrainWorm,
			toolTip: claimableWorms
				? "Claim your " + claimableWorms + " Brain Worms"
				: "No Brain Worms to claim",
		},

		{
			src: SmartURL("/media/brainworms/simulator/dexRound.png"),
			srcHover: SmartURL("/media/brainworms/simulator/dexRound_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/dexRound_active.png"),
			baseX: 3275,
			baseY: 1800,
			baseXMobile: -500,
			baseYMobile: 400,
			width: 172 * 0.8,
			height: 225 * 0.8,
			ref: useRef(null),
			name: "dexRound",
			action: () =>
				window.open(
					"https://dexscreener.com/base/" + BrainWormsContractAddress,
					"_blank"
				),
			toolTip: "View on DexScreener",
		},
		,
		{
			srcOff: SmartURL("/media/brainworms/simulator/userManual_off.png"),
			srcOffHover: SmartURL(
				"/media/brainworms/simulator/userManual_off_hover.png"
			),
			srcOffActive: SmartURL(
				"/media/brainworms/simulator/userManual_off_active.png"
			),
			srcOn: SmartURL("/media/brainworms/simulator/userManual_on.png"),
			srcOnHover: SmartURL(
				"/media/brainworms/simulator/userManual_on_hover.png"
			),
			srcOnActive: SmartURL(
				"/media/brainworms/simulator/userManual_on_active.png"
			),
			srcDisabled: SmartURL(
				"/media/brainworms/simulator/userManual_disabled.png"
			),
			baseX: 975,
			baseY: 1290,
			baseXMobile: 1175,
			baseYMobile: -679,
			width: 401 * 0.9,
			height: 90 * 0.9,
			ref: useRef(null),
			action: () => openUserManualPage(),
			name: "userManualModeToggle",
			toolTip: "User Manual for the Brain Worms Simulation System.",
		},
	];

	/*
	// according to the combat contract
	0 = stamina
	1 = strength
	2 = dexterity
	*/
	const elementsResourcePage = [
		{
			src: SmartURL("/media/brainworms/simulator/glucose.png"),
			srcHover: SmartURL("/media/brainworms/simulator/glucose_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/glucose_active.png"),
			srcDarkened: SmartURL("/media/brainworms/simulator/glucose_dark.png"),
			srcHarvest: SmartURL("/media/brainworms/simulator/glucose_harvest.png"),
			baseX: 2300,
			baseY: 1000,
			width: 239,
			height: 90,
			ref: useRef(null),
			name: "resourceGlucose",
			title: "Glucose",
			harvestText: "Stamina",
			toolTip:
				"Select to harvest for a Stamina bonus. You may only harvest one resource at a time.",
		},
		{
			src: SmartURL("/media/brainworms/simulator/lipids.png"),
			srcHover: SmartURL("/media/brainworms/simulator/lipids_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/lipids_active.png"),
			srcDarkened: SmartURL("/media/brainworms/simulator/lipids_dark.png"),
			srcHarvest: SmartURL("/media/brainworms/simulator/lipids_harvest.png"),
			baseX: 2600,
			baseY: 1000,
			width: 239,
			height: 90,
			ref: useRef(null),
			name: "resourceLipids",
			title: "Lipids",
			harvestText: "Strength",
			toolTip:
				"Select to harvest for a Strength bonus. You may only harvest one resource at a time.",
		},
		{
			src: SmartURL("/media/brainworms/simulator/aminoAcids.png"),
			srcHover: SmartURL("/media/brainworms/simulator/aminoAcids_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/aminoAcids_active.png"),
			srcDarkened: SmartURL("/media/brainworms/simulator/aminoAcids_dark.png"),
			srcHarvest: SmartURL(
				"/media/brainworms/simulator/aminoAcids_harvest.png"
			),
			baseX: 2000,
			baseY: 1000,
			width: 239,
			height: 90,
			ref: useRef(null),
			name: "resourceAminoAcids",
			title: "Amino Acids",
			harvestText: "Dexterity",
			toolTip:
				"Select to harvest for a Dexterity bonus. You may only harvest one resource at a time.",
		},
	];

	const elementsResourcePageHarvest = [
		{
			src: SmartURL("/media/brainworms/simulator/harvest.png"),
			srcHover: SmartURL("/media/brainworms/simulator/harvest_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/harvest_active.png"),
			srcDarkened: SmartURL("/media/brainworms/simulator/harvest_dark.png"),
			baseX: 2300,
			baseY: 1100,

			width: 239,
			height: 90,
			ref: useRef(null),
			name: "harvestButton",
			toolTip:
				"Resources build over time. Harvest often to add to your balance. Your yeild will decrease as your rank increases.",
		},
	];

	const elementsRerollPage = [
		{
			src: SmartURL("/media/brainworms/simulator/reroll.png"),
			srcHover: SmartURL("/media/brainworms/simulator/reroll_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/reroll_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/reroll_active.png"),
			ref: useRef(null),
			action: () => rerollWorm(),
			name: "rerollWorm",
		},
		{
			src: SmartURL("/media/brainworms/simulator/cancel.png"),
			srcHover: SmartURL("/media/brainworms/simulator/cancel_hover.png"),
			srcActive: SmartURL("/media/brainworms/simulator/cancel_active.png"),
			srcOff: SmartURL("/media/brainworms/simulator/cancel_active.png"),
			ref: useRef(null),
			action: () => openRerollPage(),
			name: "cancelReroll",
		},
	];

	const [hoverElement, setHoverElement] = useState(null);
	const [activeElement, setActiveElement] = useState(null);
	const [containerScale, setContainerScale] = useState(1);
	const [containerOffset, setContainerOffset] = useState({ x: 0, y: 0 });

	const checkScreenWidth = () => {
		setShowMobileBackground(window.innerWidth < 1050);
	};
	useEffect(() => {
		checkScreenWidth(); // Check on initial render
		window.addEventListener("resize", checkScreenWidth);
		return () => window.removeEventListener("resize", checkScreenWidth);
	}, []);

	useEffect(() => {
		const handleResize = () => {
			updateElementsStyle();
		};
		window.addEventListener("resize", handleResize);
		// Initial call to ensure everything is set correctly upon mount
		updateElementsStyle();
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	// display carts based on NFTs loaded
	useEffect(() => {
		const updateCartridgeDisplay = () => {
			let shouldUpdateCart1 =
				previousUserIdBigInt !== userLoadedNFTIdBigInt ||
				userLoadedNFTIdBigInt == null;

			let shouldUpdateCart2 =
				previousEnemyIdBigInt !== enemyLoadedNFTIdBigInt ||
				enemyLoadedNFTIdBigInt == null;

			elements.forEach((element) => {
				if (element.ref.current) {
					if (element.name === "cartridge1" && shouldUpdateCart1) {
						element.display =
							userLoadedNFTIdBigInt && userLoadedNFTIdBigInt > 0
								? "block"
								: "none";

						element.display = userLoadedNFTMetadata ? element.display : "none";
						// filter the potential carts to remove the currentCart1Idx so its different
						let filteredArray = element.srcs.filter(
							(item, index) => index !== currentCart1Idx
						);
						// and filter out the currentCart2Idx
						filteredArray = filteredArray.filter(
							(item, index) => index !== currentCart2Idx
						);

						// randomly pick an index based on the length of the srcs' array
						currentCart1Idx = Math.floor(Math.random() * filteredArray.length);
						// asign the src based on the index
						element.ref.current.src = filteredArray[currentCart1Idx];
						element.ref.current.style.display = element.display;
					}
					if (element.name === "cartridge2" && shouldUpdateCart2) {
						element.display =
							enemyLoadedNFTIdBigInt && enemyLoadedNFTIdBigInt > 0
								? "block"
								: "none";
						element.display = enemyLoadedNFTMetadata ? element.display : "none";

						// randomly pick an index based on the length of the srcs' array
						// and exclude the index of the first cartridge
						let excludedArray = element.srcs.filter(
							(item, index) => index !== currentCart1Idx
						);
						// and exclude the currentCartIdx of this element
						excludedArray = excludedArray.filter(
							(item, index) => index !== currentCart2Idx
						);
						currentCart2Idx = Math.floor(Math.random() * excludedArray.length);
						// asign the src based on the index
						element.ref.current.src = excludedArray[currentCart2Idx];
						element.ref.current.style.display = element.display;
					}
				}
			});
		};
		updateCartridgeDisplay();
	}, [userLoadedNFTIdBigInt, enemyLoadedNFTIdBigInt]);

	const updateResponsiveGraphics = (
		element,
		containerScale,
		containerOffset,
		showMobileBackground
	) => {
		if (element && element.ref.current) {
			element.ref.current.style.position = "absolute";
			let mx = element.baseXMobile ? element.baseXMobile : 0;
			let x = showMobileBackground
				? element.baseX + mx - mobileOffsetX
				: element.baseX;
			element.ref.current.style.left = `${
				x * containerScale + containerOffset.x
			}px`;
			let my = element.baseYMobile ? element.baseYMobile : 0;
			let y = showMobileBackground ? element.baseY + my : element.baseY;
			element.ref.current.style.top = `${
				y * containerScale + containerOffset.y
			}px`;
			element.ref.current.style.width = `${element.width * containerScale}px`;
			element.ref.current.style.height = `${element.height * containerScale}px`;
		}
	};

	const updateElementsStyle = () => {
		const container = containerRef.current;
		const image = imageRef.current;

		if (image && container) {
			let containerWidth = container.offsetWidth;
			let containerHeight = container.offsetHeight;

			const imageNaturalWidth = 4554;
			const imageNaturalHeight = 2562;

			const widthRatio = containerWidth / imageNaturalWidth;
			const heightRatio = containerHeight / imageNaturalHeight;

			const minAspectRatio = 0.6;

			let scale = Math.max(widthRatio, heightRatio);

			if (containerWidth / containerHeight < minAspectRatio) {
				scale = scale * (containerWidth / containerHeight / minAspectRatio);
			}

			const width = imageNaturalWidth * scale;
			const height = imageNaturalHeight * scale;
			const xOffset = (containerWidth - width) / 2;
			const yOffset = (containerHeight - height) / 2;

			elements.forEach((element) => {
				updateResponsiveGraphics(
					element,
					scale,
					{ x: xOffset, y: yOffset },
					showMobileBackground
				);
				if (
					element.ref.current &&
					(element.ref.current.name === "cartridge1" ||
						element.ref.current.name === "cartridge2")
				) {
					element.ref.current.style.display = element.display;
				}
			});

			// Update the screenReflections image
			updateResponsiveGraphics(
				{
					ref: screenReflectionsRef,
					baseX: 1615,
					baseY: 708,
					width: 1464,
					height: 1040,
				},
				scale,
				{ x: xOffset, y: yOffset },
				showMobileBackground
			);

			updateResponsiveGraphics(
				{
					ref: staticRef,
					baseX: 1635,
					baseY: 730,
					width: 1415,
					height: 1010,
				},
				scale,
				{ x: xOffset, y: yOffset },
				showMobileBackground
			);

			// Update the small screenReflections image
			let smallScreenX = showMobileBackground ? 2135 : 975;
			let smallScreenY = showMobileBackground ? 159 : 787;
			updateResponsiveGraphics(
				{
					ref: screenReflectionsSmallRef,
					baseX: smallScreenX,
					baseY: smallScreenY,
					width: 410,
					height: 421,
				},
				scale,
				{ x: xOffset, y: yOffset },
				showMobileBackground
			);

			image.style.width = `${width}px`;
			image.style.height = `${height}px`;
			image.style.left = `${xOffset}px`;
			image.style.top = `${yOffset}px`;
			setContainerScale(scale);
			setContainerOffset({ x: xOffset, y: yOffset });
		}
	};

	// this responds to isDefensiveStance changes and sets the stance in the brainworms contract
	useEffect(() => {
		if (!isLoggedIn) {
			return;
		}
		const setDefensiveStance = async () => {
			// //console.log("useEffect setDefensiveStance");

			if (isGuestMode) {
				// //console.log("Guest mode setting bonuses , stance: ", isDefensiveStance);
				setUserLoadedNFTProperties({
					...userLoadedNFTProperties,
					isDefensiveStance: isDefensiveStance,
					dexterityBonus: isDefensiveStance ? 2 : 0,
					strengthBonus: isDefensiveStance ? 0 : 2,
				});
				return;
			}
			// if there's no userloadedNFT it should just exit
			if (!userLoadedNFTIdBigInt) {
				return;
			}
			// only respond if isDefensiveStance is different from the userLoadedNFTProperties.isDefensiveStance
			if (isDefensiveStance === userLoadedNFTProperties.isDefensiveStance) {
				return;
			}

			const contract = await getBrainWormsContract();
			if (!contract) {
				console.error("Contract instance is not available");
				setTextUserFeedbackWithTimeout(
					"Failed to connect to contract. Reconnect and try again."
				);
				// set the graphic back to the original state
				setIsDefensiveStance(userLoadedNFTProperties.isDefensiveStance);
				return;
			}
			const clearMessage = setPendingTxMessageAnimated("stance");
			try {
				const transactionResponse = await contract.setStance(
					userLoadedNFTIdBigInt,
					isDefensiveStance
				);

				const result = await transactionResponse.wait();
				clearMessage(); // Clear the message using the cleanup function

				fetchNFTProperties(userLoadedNFTIdBigInt, true);
			} catch (error) {
				console.error("Failed to set defensive stance:", error);
				setTextUserFeedbackWithTimeout(
					"Failed to set defensive stance. Reconnect and try again."
				);
				setPendingTxMessage(""); // Clear the message directly
				clearMessage(); // Clear the message using the cleanup function
				setIsDefensiveStance(userLoadedNFTProperties.isDefensiveStance);
			}
		};
		setDefensiveStance();
	}, [isDefensiveStance]);

	useEffect(() => {
		const handleResize = () => {
			updateElementsStyle();
		};
		window.addEventListener("resize", handleResize);
		// Initial call to ensure everything is set correctly upon mount
		updateElementsStyle();
		return () => window.removeEventListener("resize", handleResize);
	}, [showMobileBackground]);

	const sharedDefaultFontColor = "#96b1c4"; //"#afc4d3"; //"#8c9ba6";
	const sharedHighlightFontColor = "#ffffff"; //"#c0def5"; //"#bdd1e1";
	const sharedEnemyFontColor = "#c49696";
	const sharedEnemyHighlightFontColor = "#ffeeee";

	//////////////////////////////////////////////////////////////////
	////////////////////////////// STYLES ////////////////////////////
	//////////////////////////////////////////////////////////////////

	const cartridge1SVGStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 1703 - mobileOffsetX : 1703) * containerScale +
				containerOffset.x
			}px`,
			top: `${200 * containerScale + containerOffset.y}px`,
			width: `${203 * containerScale}px`,
			height: `${220 * containerScale}px`,
			border: "none",
			outline: "none",
			background: "transparent",
			padding: "0px",
			overflow: "hidden",
			zIndex: 5,
			display: userLoadedNFTIdBigInt ? "block" : "none",
		}),
		[
			containerScale,
			containerOffset,
			textOwnedListBrainWorms,
			userLoadedNFTIdBigInt,
			enemyLoadedNFTIdBigInt,
			showMobileBackground,
		]
	);

	const cartridge2SVGStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 2717 - mobileOffsetX : 2717) * containerScale +
				containerOffset.x
			}px`,
			top: `${200 * containerScale + containerOffset.y}px`,
			width: `${203 * containerScale}px`,
			height: `${220 * containerScale}px`,
			border: "none",
			outline: "none",
			background: "transparent",
			padding: "0px",
			overflow: "hidden",
			zIndex: 5,
			display: userLoadedNFTIdBigInt ? "block" : "none",
		}),
		[
			containerScale,
			containerOffset,
			textOwnedListBrainWorms,
			userLoadedNFTIdBigInt,
			enemyLoadedNFTIdBigInt,
			showMobileBackground,
		]
	);

	const textBoxInputStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground
					? elements[0].baseXMobile + elements[0].baseX - mobileOffsetX
					: elements[0].baseX) *
					containerScale +
				containerOffset.x
			}px`,
			top: `${
				(showMobileBackground
					? elements[0].baseYMobile + elements[0].baseY
					: elements[0].baseY) *
					containerScale +
				containerOffset.y
			}px`,
			width: `${elements[0].width * containerScale}px`,
			height: `${elements[0].height * containerScale}px`,
			fontSize: 50 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: "white",
			padding: "10px",
			overflow: "hidden",
			zIndex: 5,
			placeholder: "...",
			placeholderTextColor: "#8c9ba6",
		}),
		[containerScale, containerOffset, showMobileBackground]
	);

	const enemyTextBoxInputStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground
					? elements[1].baseXMobile + elements[1].baseX - mobileOffsetX
					: elements[1].baseX) *
					containerScale +
				containerOffset.x
			}px`,
			top: `${
				(showMobileBackground
					? elements[1].baseYMobile + elements[1].baseY
					: elements[1].baseY) *
					containerScale +
				containerOffset.y
			}px`,
			width: `${elements[1].width * containerScale}px`,
			height: `${elements[1].height * containerScale}px`,
			fontSize: 50 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: "white",
			padding: "10px",
			overflow: "hidden",
			zIndex: 5,
			placeholder: "...",
			placeholderTextColor: "#8c9ba6",
		}),
		[containerScale, containerOffset, showMobileBackground]
	);

	// left: `${2230 * containerScale + containerOffset.x}px`,
	// top: `${1630 * containerScale + containerOffset.y}px`,
	// width: `${600 * containerScale}px`,
	// height: 40 * containerScale + "px",
	// 	fontSize: 30 * containerScale + "px",

	const pendingTxMessageTextBoxStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 2090 : 995) * containerScale + containerOffset.x
			}px`,
			top: `${
				(showMobileBackground ? 280 : 996) * containerScale + containerOffset.y
			}px`,
			width: `${360 * containerScale}px`,
			height: 300 * containerScale + "px",
			fontSize: 30 * containerScale + "px",
			textAlign: "left",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			overflow: "hidden",
			zIndex: 51,
			display: "flex",
			alignItems: "center",
			textOverflow: "ellipsis",
			display: pendingTxMessage ? "block" : "none",
		}),
		[containerScale, containerOffset, pendingTxMessage, showMobileBackground]
	);

	const toolTipTxMessageTextBoxStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 2090 : 995) * containerScale + containerOffset.x
			}px`,
			top: `${
				(showMobileBackground ? 297 : 925) * containerScale + containerOffset.y
			}px`,
			width: `${360 * containerScale}px`,
			height: (showMobileBackground ? 300 : 310) * containerScale + "px",
			fontSize: 30 * containerScale + "px",
			textAlign: "left",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			overflow: "hidden",
			zIndex: 51,
			display: "flex",
			alignItems: "center",
			textOverflow: "ellipsis",
		}),
		[containerScale, containerOffset, pendingTxMessage, showMobileBackground]
	);

	const connectKitCustomButtonStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 2090 : 995) * containerScale + containerOffset.x
			}px`,
			top: `${
				(showMobileBackground ? 158 : 787) * containerScale + containerOffset.y
			}px`,
			width: `${360 * containerScale}px`,
			// height: `${100 * containerScale}px`,
			fontSize: 30 * containerScale + "px",
			textAlign: "left",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			overflow: "hidden",
			zIndex: 51,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			whiteSpace: "nowrap",
		}),
		[containerScale, containerOffset, showMobileBackground]
	);

	const connectKitCustomButtonStylesExtra = useMemo(
		() => ({
			maxWidth: `${345 * containerScale}px !important`,
			fontSize: "0.8em !important",
			dipsplay: "flex !important",
			justifyContent: "center !important",
		}),
		[containerScale, containerOffset, showMobileBackground]
	);

	const userFeedbackTextBoxStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 1630 - mobileOffsetX : 1630) * containerScale +
				containerOffset.x
			}px`,
			top: `${725 * containerScale + containerOffset.y}px`,
			width: `${1420 * containerScale}px`,
			height: `${1015 * containerScale}px`,
			fontSize: 50 * containerScale + "px",
			padding: 65 * containerScale + "px",
			textAlign: "center",
			border: "none",
			outline: "none",
			background: "#3f0000a3",
			color: sharedHighlightFontColor,
			zIndex: 250,
			display: textUserFeedback ? "flex" : "none",
			alignItems: "center",
			justifyContent: "center",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		}),
		[containerScale, containerOffset, textUserFeedback, showMobileBackground]
	);

	const ownedListBrainWormsTextBoxStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 1813 - mobileOffsetX : 1813) * containerScale +
				containerOffset.x
			}px`,
			top: `${730 * containerScale + containerOffset.y}px`,
			width: `${950 * containerScale}px`,
			height: 40 * containerScale + "px",
			fontSize: 30 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			overflow: "hidden",
			zIndex: 51,
			display: textOwnedListBrainWorms ? "none" : "none",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		}),
		[
			containerScale,
			containerOffset,
			textOwnedListBrainWorms,
			showMobileBackground,
		]
	);

	const musicTitleTextBoxStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 2430 - mobileOffsetX : 2430) * containerScale +
				containerOffset.x
			}px`,
			top: `${1690 * containerScale + containerOffset.y}px`,
			width: `${600 * containerScale}px`,
			height: 40 * containerScale + "px",
			fontSize: 30 * containerScale + "px",
			textAlign: "right",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			// overflow: "hidden",
			zIndex: 51,
			display: isMusicPlaying ? "block" : "none",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		}),
		[containerScale, containerOffset, isMusicPlaying, showMobileBackground]
	);

	const battleStatsTextBoxStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 1630 - mobileOffsetX : 1630) * containerScale +
				containerOffset.x
			}px`,
			top: `${(725 + 970 / 2) * containerScale + containerOffset.y}px`,
			width: `${1420 * containerScale}px`,
			height: `${(1015 / 2) * containerScale}px`,
			fontSize: 53 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			zIndex: 3,
			display:
				enemyLoadedNFTIdBigInt && !isResourceDisplayOpen ? "block" : "none",
			overflow: "hidden",
		}),
		[
			containerScale,
			containerOffset,
			showMobileBackground,
			isResourceDisplayOpen,
			enemyLoadedNFTIdBigInt,
		]
	);

	const rerollPageDisplayStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 1630 - mobileOffsetX : 1630) * containerScale +
				containerOffset.x
			}px`,
			top: `${725 * containerScale + containerOffset.y}px`,
			width: `${1420 * containerScale}px`,
			height: `${1015 * containerScale}px`,
			fontSize: 40 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: 80 * containerScale + "px",
			zIndex: 110,
			display: isRerollPageOpen ? "block" : "none",
			overflow: "hidden",
			overflowY: "hidden",
			backgroundColor: "black",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		}),
		[containerScale, containerOffset, showMobileBackground, isRerollPageOpen]
	);

	const WelcomePageDisplayStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 1630 - mobileOffsetX : 1630) * containerScale +
				containerOffset.x
			}px`,
			top: `${725 * containerScale + containerOffset.y}px`,
			width: `${1420 * containerScale}px`,
			height: `${1015 * containerScale}px`,
			fontSize: 40 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "1rem",
			zIndex: 110,
			display: isWelcomePageOpen ? "block" : "none",
			overflow: "hidden",
			overflowY: "hidden",
			backgroundColor: "black",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		}),
		[containerScale, containerOffset, isWelcomePageOpen, showMobileBackground]
	);

	const LoginPageDisplayStyles = useMemo(
		() => ({
			position: "absolute",
			left: `${
				(showMobileBackground ? 1630 - mobileOffsetX : 1630) * containerScale +
				containerOffset.x
			}px`,
			top: `${725 * containerScale + containerOffset.y}px`,
			width: `${1420 * containerScale}px`,
			height: `${1015 * containerScale}px`,
			fontSize: 40 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			padding: "1rem",
			color: sharedDefaultFontColor,
			zIndex: 110,
			display: !isLoggedIn ? "block" : "none",
			overflow: "hidden",
			overflowY: "hidden",
			backgroundColor: "black",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		}),
		[containerScale, containerOffset, isLoggedIn, showMobileBackground]
	);

	const UserManualDisplayStyles = useMemo(() => {
		let left = showMobileBackground ? 1630 - mobileOffsetX : 1630;
		let top = 725;
		let width = 1420;
		let height = 1015;

		return {
			position: "absolute",
			left: `${left * containerScale + containerOffset.x}px`,
			top: `${top * containerScale + containerOffset.y}px`,
			width: `${width * containerScale}px`,
			height: `${height * containerScale}px`,
			fontSize: 40 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			zIndex: 110,
			display: isUserManualOpen ? "block" : "none",
			overflow: "hidden",
			overflowY: "hidden",
			backgroundColor: "black",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		};
	}, [containerScale, containerOffset, isUserManualOpen, showMobileBackground]);

	const resourceDisplayStyles = useMemo(() => {
		let left = showMobileBackground ? 1630 - mobileOffsetX : 1630;
		let top = 725;
		let width = 1420;
		let height = 1015;

		return {
			position: "absolute",
			left: `${left * containerScale + containerOffset.x}px`,
			top: `${top * containerScale + containerOffset.y}px`,
			width: `${width * containerScale}px`,
			height: `${height * containerScale}px`,
			fontSize: 53 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			zIndex: 50,
			display: isResourceDisplayOpen ? "block" : "none",
			overflow: "hidden",
			overflowY: "hidden",
			backgroundColor: "black",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		};
	}, [
		containerScale,
		containerOffset,
		isResourceDisplayOpen,
		showMobileBackground,
	]);

	const leaderboardDisplayStyles = useMemo(() => {
		let left = showMobileBackground ? 1630 - mobileOffsetX : 1630;
		let top = 725;
		let width = 1420;
		let height = 1015;

		return {
			position: "absolute",
			left: `${left * containerScale + containerOffset.x}px`,
			top: `${top * containerScale + containerOffset.y}px`,
			width: `${width * containerScale}px`,
			height: `${height * containerScale}px`,
			fontSize: 53 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			zIndex: 100,
			display: isLeaderboardOpen ? "block" : "none",
			overflow: "hidden",
			overflowY: "hidden",
			backgroundColor: "black",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		};
	}, [
		containerScale,
		containerOffset,
		isLeaderboardOpen,
		showMobileBackground,
	]);

	const userTokenDisplayStyles = useMemo(() => {
		////console.log(
		// 	"userTokenDisplayStyles, User Loaded NFT ID: ",
		// 	userLoadedNFTIdBigInt
		// );
		let left = showMobileBackground ? 1630 - mobileOffsetX : 1630;
		let top = 725;
		let width = 1420;
		let height = 1015;

		let fontSizeBasis = 65;

		if (enemyLoadedNFTIdBigInt && !isResourceDisplayOpen) {
			////console.log(
			// 	"userTokenDisplayStyles, Enemy Loaded NFT ID: ",
			// 	enemyLoadedNFTIdBigInt
			// );
			width /= 2;
			height /= 2;
			fontSizeBasis = 53;
		} else {
			fontSizeBasis = 65;
		}

		if (isResourceDisplayOpen) {
			fontSizeBasis = 53;
		}

		return {
			position: "absolute",
			left: `${left * containerScale + containerOffset.x}px`,
			top: `${top * containerScale + containerOffset.y}px`,
			width: `${width * containerScale}px`,
			height: `${height * containerScale}px`,
			fontSize: fontSizeBasis * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			zIndex: 3,
			display: userLoadedNFTIdBigInt ? "block" : "none",
			overflowX: "hidden",
			overflowY: "hidden",
			whiteSpace: "nowrap",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		};
	}, [
		containerScale,
		containerOffset,
		textOwnedListBrainWorms,
		userLoadedNFTIdBigInt,
		enemyLoadedNFTIdBigInt,
		isResourceDisplayOpen,
		showMobileBackground,
	]);

	const enemyTokenDisplayStyles = useMemo(() => {
		////console.log(
		// 	"enemyTokenDisplayStyles, Enemy Loaded NFT ID: ",
		// 	enemyLoadedNFTIdBigInt
		// );
		let width = 1420 / 2;
		let height = 1015 / 2;
		let left = showMobileBackground
			? 1630 + width - mobileOffsetX
			: 1630 + width;
		let top = 725;

		return {
			position: "absolute",
			left: `${left * containerScale + containerOffset.x}px`,
			top: `${top * containerScale + containerOffset.y}px`,
			width: `${width * containerScale}px`,
			height: `${height * containerScale}px`,
			fontSize: 53 * containerScale + "px",
			border: "none",
			outline: "none",
			background: "transparent",
			color: sharedDefaultFontColor,
			padding: "0px",
			zIndex: 3,
			display:
				enemyLoadedNFTIdBigInt && !isResourceDisplayOpen ? "block" : "none",
			overflowX: "hidden",
			whiteSpace: "nowrap",
			textShadow:
				"1px 1px 1px black, 0px 0px 2px black,-1px 1px 1px black,0px -1px 1px black,0px 0px 2px black",
		};
	}, [
		containerScale,
		containerOffset,
		textOwnedListBrainWorms,
		enemyLoadedNFTIdBigInt,
		isResourceDisplayOpen,
		showMobileBackground,
	]);

	const playClickSound = (name) => {
		if (name === "claimRound" && claimableWorms <= 0) {
			return;
		}
		if (name == "soundToggle" || isBattleSoundEnabled) {
			const randomIndex = Math.floor(Math.random() * clickSoundSrcs.length);
			audioRefs.current[randomIndex].play();
		}
	};

	const handleElementInteraction = (elementIndex) => {
		playClickSound(elements[elementIndex].name);
		if (elements[elementIndex].action) {
			elements[elementIndex].action();
		}
	};

	useEffect(() => {
		const calculateDifficulty = () => {
			if (!userLoadedNFTProperties || !enemyLoadedNFTProperties) {
				setDifficultyLevel(null);
				return;
			}

			const userWorm = {
				stamina:
					userLoadedNFTProperties.stamina +
					userLoadedNFTProperties.staminaBonus,
				strength:
					userLoadedNFTProperties.strength +
					userLoadedNFTProperties.strengthBonus,
				dexterity:
					userLoadedNFTProperties.dexterity +
					userLoadedNFTProperties.dexterityBonus,
			};

			const enemyWorm = {
				stamina:
					enemyLoadedNFTProperties.stamina +
					enemyLoadedNFTProperties.staminaBonus,
				strength:
					enemyLoadedNFTProperties.strength +
					enemyLoadedNFTProperties.strengthBonus,
				dexterity:
					enemyLoadedNFTProperties.dexterity +
					enemyLoadedNFTProperties.dexterityBonus,
			};

			// Check if all required properties are present
			if (
				userWorm.stamina === undefined ||
				userWorm.strength === undefined ||
				userWorm.dexterity === undefined ||
				enemyWorm.stamina === undefined ||
				enemyWorm.strength === undefined ||
				enemyWorm.dexterity === undefined
			) {
				// console.log(
				// 	"Some properties are undefined, skipping difficulty calculation"
				// );
				setDifficultyLevel(null);
				return;
			}

			const difficultyValue = ProbCalc({ worm1: userWorm, worm2: enemyWorm });
			console.log("difficultyValue: ", difficultyValue);
			let difficultyEmoji;

			if (difficultyValue < 0.125) {
				difficultyEmoji = "🟥🟥🟥🟥";
			} else if (difficultyValue < 0.25) {
				difficultyEmoji = "🟥🟥🟥";
			} else if (difficultyValue < 0.375) {
				difficultyEmoji = "🟥🟥";
			} else if (difficultyValue < 0.45) {
				difficultyEmoji = "🟥";
			} else if (difficultyValue >= 0.45 && difficultyValue <= 0.55) {
				difficultyEmoji = "🟨";
			} else if (difficultyValue < 0.625) {
				difficultyEmoji = "🟩";
			} else if (difficultyValue < 0.75) {
				difficultyEmoji = "🟩🟩";
			} else if (difficultyValue < 0.875) {
				difficultyEmoji = "🟩🟩🟩";
			} else {
				difficultyEmoji = "🟩🟩🟩🟩";
			}

			setDifficultyLevel(difficultyEmoji);
		};

		calculateDifficulty();
	}, [userLoadedNFTProperties, enemyLoadedNFTProperties]);

	const calculateStatBonuses = (
		isDefensiveStance,
		rank,
		resourceTypeHarvested,
		pvpPoints
	) => {
		// give a plus 2 for defensive stance
		let staminaBonus = 0;
		let strengthBonus = isDefensiveStance ? 0 : 2;
		let dexterityBonus = isDefensiveStance ? 2 : 0;

		// give a plus 3 for attack type bonus
		if (hoveringOverAttackType === "bite") {
			strengthBonus += 3;
		} else if (hoveringOverAttackType === "sting") {
			dexterityBonus += 3;
		}

		// as defined in combat system:
		let rankBonus = Math.floor(rank / 20);
		// type 0 goes to stamina
		// type 1 goes to strength
		// type 2 goes to dexterity
		if (resourceTypeHarvested == 0) {
			staminaBonus += rankBonus;
		} else if (resourceTypeHarvested == 1) {
			strengthBonus += rankBonus;
		} else if (resourceTypeHarvested == 2) {
			dexterityBonus += rankBonus;
		}

		// now add PVP Points bonus, which is 1 for every 100, rounded down to the nearest integer
		staminaBonus += Math.floor(Number(pvpPoints) / 100);
		strengthBonus += Math.floor(Number(pvpPoints) / 100);
		dexterityBonus += Math.floor(Number(pvpPoints) / 100);

		return {
			staminaBonus,
			strengthBonus,
			dexterityBonus,
		};
	};

	// listen for hovering over attack types and recalculate bonuses and update properties
	useEffect(() => {
		if (!userLoadedNFTProperties) {
			return;
		}

		let bonuses = calculateStatBonuses(
			isDefensiveStance,
			userLoadedNFTProperties.rank,
			userLoadedNFTProperties.resourceTypeHarvested,
			userLoadedNFTProperties.pvpPoints
		);

		setUserLoadedNFTProperties((prevProps) => ({
			...prevProps,
			staminaBonus: Number(bonuses.staminaBonus),
			strengthBonus: Number(bonuses.strengthBonus),
			dexterityBonus: Number(bonuses.dexterityBonus),
		}));
	}, [hoveringOverAttackType]);

	const handleResourceHarvestButtonClick = async () => {
		//console.log("handleResourceHarvestButtonClick");

		const contract = await getBrainWormsContract();
		if (!contract) {
			// //console.log("Contract instance is not available");
			setTextUserFeedbackWithTimeout(
				"Failed to connect to contract. Reconnect and try again."
			);
			return;
		}
		//console.log("handleResourceHarvestButtonClick, got contract");
		const clearMessage = setPendingTxMessageAnimated("harvest");

		try {
			const initialNFTId = userLoadedNFTIdBigInt;
			////console.log(
			// 	"handleResourceHarvestButtonClick, calling harvest with initialNFTId: ",
			// 	initialNFTId
			// );

			////console.log(
			// 	"handleResourceHarvestButtonClick last harvest block: ",
			// 	userLoadedNFTProperties.lastHarvestBlock
			// );

			const transactionResponse = await contract.harvestResource(initialNFTId);
			const result = await transactionResponse.wait();
			//console.log("handleResourceHarvestButtonClick, result: ", result);
			clearMessage();

			// Use a callback function to ensure that the userLoadedNFTIdBigInt state has been updated
			setUserLoadedNFTIdBigInt((currentNFTId) => {
				// Check if the NFT has changed during the transaction
				if (initialNFTId !== currentNFTId) {
					//console.log("NFT changed during the transaction. Aborting.");
					return currentNFTId;
				}

				//console.log("result.blockNumber ", result.blockNumber);
				let blockNumber = result.blockNumber;

				fetchNFTResourceBalances(initialNFTId).then((updatedBalances) => {
					getHarvestingContract().then((contractHarvesting) => {
						contractHarvesting
							.getPlayerRanking(initialNFTId, 0)
							.then((updatedRank) => {
								let bonuses = calculateStatBonuses(
									isDefensiveStance,
									Number(updatedRank),
									userLoadedNFTProperties.resourceTypeHarvested,
									userLoadedNFTProperties.pvpPoints
								);

								setUserLoadedNFTProperties((prevProps) => ({
									...prevProps,
									...updatedBalances,
									rank: Number(updatedRank),
									lastHarvestBlock: Number(blockNumber),
									staminaBonus: Number(bonuses.staminaBonus),
									strengthBonus: Number(bonuses.strengthBonus),
									dexterityBonus: Number(bonuses.dexterityBonus),
								}));
							});
					});
				});

				return currentNFTId;
			});
		} catch (error) {
			console.error("Failed to harvest resources:", error);
			setTextUserFeedbackWithTimeout(
				"Failed to harvest resources. Reconnect and try again."
			);
			setPendingTxMessage("");
			clearMessage();
		}
	};

	const setToolTipMessage = (toolTip) => {
		if (toolTip && pendingTxMessage === "") {
			setToolTipTxMessage(toolTip);
		} else {
			setToolTipTxMessage("");
		}
	};

	const handleStartButtonClick = () => {
		setIsStarted(true);
	};

	const handleResourceButtonClick = (elementIndex) => {
		playClickSound();
		const selectedResourceType = elementsResourcePage[elementIndex].name;
		//console.log("handleResourceButtonClick", elementIndex);
		//console.log("handleResourceButtonClick", selectedResourceType.name);
		// stamina 0 Glucose
		// strength 1 Lipids
		// deterity 2 Amino Acids
		if (selectedResourceType === "resourceGlucose") {
			setResourceTypeToHarvest(0);
		} else if (selectedResourceType === "resourceLipids") {
			setResourceTypeToHarvest(1);
		} else if (selectedResourceType === "resourceAminoAcids") {
			setResourceTypeToHarvest(2);
		}
	};

	return (
		<div
			id="brainWormSimulatorContainer"
			ref={containerRef}
			style={{
				position: "relative",
				width: "100vw",
				height: "100vh",
				overflow: "hidden",
				margin: 0,
				padding: 0,
			}}
		>
			<img
				src={screenReflectionsSrc}
				ref={screenReflectionsRef}
				style={{
					pointerEvents: "none",
					zIndex: 500,
				}}
			/>

			<img
				src={screenReflectionsSmallSrc}
				ref={screenReflectionsSmallRef}
				style={{
					pointerEvents: "none",
					zIndex: 500,
				}}
			/>
			<img
				src={backgroundStaticSrc[backgroundStaticIndex]}
				ref={staticRef}
				style={{
					pointerEvents: "none",
					opacity: 0.85,
					zIndex: 200,
					display:
						showStaticBackground && isLoggedIn && ownedTokensCount != 0
							? "block"
							: "none",
				}}
			/>
			<img
				src={showMobileBackground ? backgroundSrcMobile : backgroundSrc}
				ref={imageRef}
				style={{
					position: "absolute",
					top: 0,
					left: 0,
				}}
			/>
			{elements.map((element, index) =>
				element.name === "cartridge1" ? (
					<img
						key={index}
						ref={element.ref}
						src={element.src}
						style={{
							position: "absolute",
							display: userLoadedNFTMetadata ? "block" : "none",
						}}
					/>
				) : element.name === "cartridge2" ? (
					<img
						key={index}
						ref={element.ref}
						src={element.src}
						style={{
							position: "absolute",
							display: enemyLoadedNFTMetadata ? "block" : "none",
						}}
					/>
				) : (
					<img
						className={
							element.name === "claimRound" && claimableWorms <= 0
								? ""
								: element.name === "drain" && !isVampireAttackReady
								? ""
								: element.name === "nullsleep" || element.name === "soundToggle"
								? "clickable"
								: (element.name === "bite" ||
										element.name === "sting" ||
										element.name === "drain" ||
										element.name === "eject" ||
										element.name === "stance") &&
								  !enemyLoadedNFTIdBigInt
								? ""
								: battleResults.battlePending || !isLoggedIn
								? ""
								: "clickable"
						}
						key={index}
						ref={element.ref}
						src={
							element.name === "nullsleep"
								? activeElement === index && isMusicPlaying
									? element.srcOn
									: activeElement === index && !isMusicPlaying
									? element.srcOff
									: hoverElement === index
									? element.srcHover
									: isMusicPlaying
									? element.srcOn
									: element.srcOff
								: element.name === "bite"
								? !enemyLoadedNFTIdBigInt ||
								  battleResults.battlePending ||
								  !userOwnsAnyBrainWorms
									? element.srcOff
									: activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "sting"
								? !enemyLoadedNFTIdBigInt ||
								  battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  !isLoggedIn
									? element.srcOff
									: activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "soundToggle"
								? activeElement === index && isBattleSoundEnabled
									? element.srcOn
									: activeElement === index && !isBattleSoundEnabled
									? element.srcOff
									: hoverElement === index
									? element.srcHover
									: isBattleSoundEnabled
									? element.srcOn
									: element.srcOff
								: element.name === "reroll"
								? activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "download"
								? activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "drain"
								? !enemyLoadedNFTIdBigInt ||
								  battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  isVampireAttackReady === false ||
								  !isLoggedIn
									? element.srcOff
									: activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "eject"
								? !enemyLoadedNFTIdBigInt ||
								  battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  !isLoggedIn
									? element.srcOff
									: activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "stance"
								? battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  !isLoggedIn
									? isDefensiveStance
										? element.srcDefOff
										: element.srcOffOff
									: enemyLoadedNFTIdBigInt
									? isDefensiveStance
										? element.srcDefOn
										: element.srcOffOn
									: isDefensiveStance
									? element.srcDefOff
									: element.srcOffOff
								: element.name === "loadId"
								? battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  isGuestMode ||
								  !isLoggedIn
									? element.srcOff
									: activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "loadNext"
								? battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  !isLoggedIn
									? element.srcOff
									: activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "enemyId"
								? battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  !isLoggedIn
									? element.srcOff
									: activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "rndEnemy"
								? battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  !isLoggedIn
									? element.srcOff
									: activeElement === index
									? element.srcActive
									: hoverElement === index
									? element.srcHover
									: element.src
								: element.name === "resourceModeToggle"
								? battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  !isLoggedIn
									? element.srcDisabled
									: isResourceDisplayOpen
									? activeElement === index
										? element.srcOnActive
										: hoverElement === index
										? element.srcOnHover
										: element.srcOn
									: activeElement === index
									? element.srcOffActive
									: hoverElement === index
									? element.srcOffHover
									: element.srcOff
								: element.name === "leaderboardModeToggle"
								? battleResults.battlePending ||
								  !userOwnsAnyBrainWorms ||
								  !isLoggedIn
									? element.srcDisabled
									: isLeaderboardOpen
									? activeElement === index
										? element.srcOnActive
										: hoverElement === index
										? element.srcOnHover
										: element.srcOn
									: activeElement === index
									? element.srcOffActive
									: hoverElement === index
									? element.srcOffHover
									: element.srcOff
								: element.name === "userManualModeToggle"
								? battleResults.battlePending || !isLoggedIn
									? element.srcDisabled
									: isUserManualOpen
									? activeElement === index
										? element.srcOnActive
										: hoverElement === index
										? element.srcOnHover
										: element.srcOn
									: activeElement === index
									? element.srcOffActive
									: hoverElement === index
									? element.srcOffHover
									: element.srcOff
								: element.name === "guestMode"
								? battleResults.battlePending
									? element.srcDisabled || !isLoggedIn
									: isGuestMode
									? activeElement === index
										? element.srcOnActive
										: hoverElement === index
										? element.srcOnHover
										: element.srcOn
									: activeElement === index
									? element.srcOffActive
									: hoverElement === index
									? element.srcOffHover
									: element.srcOff
								: element.name === "claimRound"
								? claimableWorms > 0
									? activeElement === index
										? element.srcActive
										: hoverElement === index
										? element.srcHover
										: element.src
									: element.srcDisabled
								: activeElement === index
								? element.srcActive
								: hoverElement === index
								? element.srcHover
								: element.src
						}
						style={{
							position: "absolute",
							zIndex:
								element.name === "reroll" || element.name === "download"
									? "10"
									: "inherit",
							display:
								(element.name === "reroll" || element.name === "download") &&
								(enemyLoadedNFTIdBigInt || isRerollPageOpen)
									? "none"
									: (element.name === "reroll" ||
											element.name === "download") &&
									  !userLoadedNFTMetadata &&
									  !userLoadedNFTProperties
									? "none"
									: "inherit",
							animation:
								element.name === "userManualModeToggle" &&
								!battleResults.battlePending &&
								!hasUserManualEverBeenOpened
									? "fadeSimulatorUserManualButtons 7s infinite"
									: isWelcomePageOpen &&
									  (element.name === "guestMode" ||
											element.name === "openSea" ||
											element.name === "swapRound" ||
											(claimableWorms > 0 && element.name === "claimRound"))
									? "fadeSimulatorButtons 2s infinite"
									: "none",
						}}
						onMouseEnter={() => {
							if (
								!isLoggedIn &&
								!(
									element.name === "soundToggle" ||
									element.name === "nullsleep" ||
									element.name === "swapRound" ||
									element.name === "openSea" ||
									element.name === "dexRound"
								)
							) {
								setToolTipMessage("You must log into use the simulator.");
								return;
							}

							if (
								!userOwnsAnyBrainWorms &&
								!(
									element.name === "guestMode" ||
									element.name === "soundToggle" ||
									element.name === "nullsleep" ||
									element.name === "swapRound" ||
									element.name === "claimRound" ||
									element.name === "openSea" ||
									element.name === "dexRound"
								)
							) {
								setToolTipMessage(
									"You must own a Brain Worm to use the simulator."
								);
								return;
							}
							// if there's no enemy and no battle pending
							// let the user know they need to load an enemy
							if (
								(element.name === "bite" ||
									element.name === "sting" ||
									element.name === "drain" ||
									element.name === "eject" ||
									element.name === "stance") &&
								!enemyLoadedNFTIdBigInt &&
								battleResults.battlePending == false
							) {
								if (userOwnsAnyBrainWorms) {
									setToolTipMessage("Load enemy to enable");
								}
							}
							if (
								(element.name === "bite" ||
									element.name === "sting" ||
									element.name === "eject" ||
									element.name === "drain" ||
									element.name === "stance") &&
								(!enemyLoadedNFTIdBigInt ||
									battleResults.battlePending ||
									!userOwnsAnyBrainWorms)
							) {
								// Do nothing if battle result is pending or there is no enemy when a user tries to attack or eject
								return;
							}
							if (
								(element.name === "rndEnemy" ||
									element.name === "enemyId" ||
									element.name === "stance" ||
									element.name === "loadId" ||
									element.name === "drain" ||
									element.name === "loadNext" ||
									element.name === "rndEnemy" ||
									element.name === "resourceModeToggle") &&
								(battleResults.battlePending || !isLoggedIn)
							) {
								// Do nothing if battle result is pending and user is trying to load an enemy
								return;
							}

							if (element.name === "loadId" && isGuestMode) {
								return;
							}

							if (element.name === "bite") {
								setHoveringOverAttackType("bite");
							} else if (element.name === "sting") {
								setHoveringOverAttackType("sting");
							} else {
								setHoveringOverAttackType(null);
							}

							setHoverElement(index);

							// check if the vampire attack is ready
							// if they're considering attacking in any way
							if (
								element.name === "drain" ||
								element.name === "bite" ||
								element.name === "sting"
							) {
								//
								checkIfVampireAttackReady();
							}
							if (element.name === "drain") {
								//console.log("hovering on drain");
								setToolTipMessage(vampireAttackToolTip);
							} else {
								if (element.name === "loadNext" && isGuestMode) {
									setToolTipMessage("Load a random Brain Worm in Guest Mode.");
								} else if (element.name === "loadId" && isGuestMode) {
									setToolTipMessage("Not available in Guest Mode.");
								} else if (
									element.name === "loadNext" ||
									element.name === "loadId"
								) {
									setToolTipMessage(
										<>
											{element.toolTip} <br />
											<br /> {textOwnedListBrainWorms}
										</>
									);
								} else {
									setToolTipMessage(element.toolTip);
								}
							}
						}}
						onMouseLeave={() => {
							setHoveringOverAttackType(null);
							setHoverElement(null);
							setActiveElement(null);
							if (isLeaderboardOpen && isGuestMode) {
								setToolTipMessage(
									"Guest Mode worms are not ranked. Obtain a Brain Worm to participate in the leaderboard."
								);
							} else if (isResourceDisplayOpen && isGuestMode) {
								setToolTipMessage(
									"Guest Mode worms cannot harvest resources. Obtain a Brain Worm to harvest resources."
								);
							} else {
								setToolTipMessage("");
							}
						}}
						onMouseDown={() => {
							if (
								(battleResults.battlePending || !isLoggedIn) &&
								(element.name === "guestMode" || element.name === "claimRound")
							) {
								// don't do anything if they are trying to switch between guest mode or claim during a fight
								return;
							}
							if (
								(element.name === "bite" ||
									element.name === "sting" ||
									element.name === "eject" ||
									element.name === "drain" ||
									element.name == "stance") &&
								(!enemyLoadedNFTIdBigInt ||
									battleResults.battlePending ||
									!userOwnsAnyBrainWorms ||
									!isLoggedIn)
							) {
								// Do nothing if battle result is pending or there is no enemy when a user tries to attack or eject
								return;
							}

							if (element.name === "loadId" && isGuestMode) {
								return;
							}

							if (
								((element.name === "rndEnemy" ||
									element.name === "enemyId" ||
									element.name === "stance" ||
									element.name === "loadId" ||
									element.name === "drain" ||
									element.name === "loadNext" ||
									element.name === "rndEnemy" ||
									element.name === "resourceModeToggle" ||
									element.name === "leaderboardModeToggle") &&
									battleResults.battlePending) ||
								(!userOwnsAnyBrainWorms &&
									!(
										element.name === "guestMode" ||
										element.name === "soundToggle" ||
										element.name === "nullsleep" ||
										element.name === "swapRound" ||
										element.name === "openSea" ||
										element.name === "dexRound" ||
										(claimableWorms > 0 && element.name === "claimRound")
									))
							) {
								// Do nothing if battle result is pending and user is trying to load an enemy
								return;
							}
							if (element.name === "drain" && isVampireAttackReady === false) {
								return;
							}
							setActiveElement(index);
							handleElementInteraction(index);
						}}
						onMouseUp={() => {
							if (
								element.name !== "soundToggle" &&
								element.name !== "nullsleep"
							) {
								setActiveElement(null);
							}
						}}
					/>
				)
			)}
			<input
				ref={textBoxInputRef}
				className="no-spinners"
				type="number"
				style={textBoxInputStyles}
				placeholder={textBoxInputStyles.placeholder}
				onMouseEnter={() => {
					if (!userOwnsAnyBrainWorms) {
						setToolTipMessage(
							"You must own a Brain Worm to use the simulator."
						);
					} else if (isGuestMode) {
						setToolTipMessage("Not available in Guest Mode.");
					} else {
						setToolTipMessage("Enter a Brain Worm ID you own to load it.");
					}
				}}
				onMouseLeave={() => {
					setHoveringOverAttackType(null);
					setToolTipMessage("");
				}}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						fetchSpecificNFT();
					}
				}}
			/>
			<input
				ref={enemyTextBoxInputRef}
				className="no-spinners"
				type="number"
				style={enemyTextBoxInputStyles}
				placeholder={enemyTextBoxInputStyles.placeholder}
				onMouseEnter={() => {
					if (!userOwnsAnyBrainWorms) {
						setToolTipMessage(
							"You must own a Brain Worm to use the simulator."
						);
					} else {
						setToolTipMessage("Enter an enemy Brain Worm ID to load it.");
					}
				}}
				onMouseLeave={() => {
					setHoveringOverAttackType(null);
					setToolTipMessage("");
				}}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						fetchSpecificEnemyNFT();
					}
				}}
			/>
			<div
				onClick={clearErrorMessage}
				ref={userFeedbackTextBoxRef}
				style={userFeedbackTextBoxStyles}
			>
				<p onClick={clearErrorMessage}>{textUserFeedback}</p>
			</div>
			<div
				ref={ownedListBrainWormsTextBoxRef}
				style={ownedListBrainWormsTextBoxStyles}
			>
				<p>{textOwnedListBrainWorms}</p>
			</div>
			<div id="tooltip" style={toolTipTxMessageTextBoxStyles}>
				<p style={{ width: "100%", textWrap: "wrap" }}>{toolTipTxMessage}</p>
			</div>
			<div
				ref={pendingTxMessageTextBoxRef}
				style={pendingTxMessageTextBoxStyles}
			>
				<p style={{ width: "100%", textWrap: "wrap" }}>
					{formattedPendingTxMessageMessage}
				</p>
			</div>
			<div ref={musicTitleTextBoxRef} style={musicTitleTextBoxStyles}>
				<p>{musicTitle}</p>
			</div>

			{!isLoggedIn ? (
				<BrainWormsLogin
					style={LoginPageDisplayStyles}
					onStartButtonClick={handleStartButtonClick}
				/>
			) : (
				<>
					<div
						ref={connectKitCustomButtonRef}
						style={connectKitCustomButtonStyles}
					>
						<ConnectKitCustomButton
							extraStyles={connectKitCustomButtonStylesExtra}
						/>
					</div>
					{isWelcomePageOpen && (
						<BrainWormsWelcomePage
							style={WelcomePageDisplayStyles}
							fontColorHighlight={sharedHighlightFontColor}
							setToolTipMessage={setToolTipMessage}
							userOwnsAnyBrainWorms={userOwnsAnyBrainWorms}
							claimableWorms={claimableWorms}
							userAddress={userAddress}
						/>
					)}
					{isUserManualOpen && (
						<BrainWormsUserManual
							style={UserManualDisplayStyles}
							fontColorHighlight={sharedHighlightFontColor}
						/>
					)}
					{isResourceDisplayOpen && (
						<BrainWormsResourceDisplay
							isGuestMode={isGuestMode}
							style={resourceDisplayStyles}
							fontColorHighlight={sharedHighlightFontColor}
							elementsResourcePage={elementsResourcePage}
							elementsResourcePageHarvest={elementsResourcePageHarvest}
							onResourceButtonClick={handleResourceButtonClick}
							onHarvestButtonClick={handleResourceHarvestButtonClick}
							userLoadedNFTProperties={userLoadedNFTProperties}
							currentBlockNumber={currentBlockNumber}
							setToolTipMessage={setToolTipMessage}
						/>
					)}
					{isLeaderboardOpen && (
						<BrainWormsLeaderboardDisplay
							isGuestMode={isGuestMode}
							style={leaderboardDisplayStyles}
							leaderboardData={leaderboardData}
							fontColorHighlight={sharedHighlightFontColor}
							sharedDefaultFontColor={sharedDefaultFontColor}
							sharedEnemyFontColor={sharedEnemyFontColor}
							sharedEnemyHighlightFontColor={sharedEnemyHighlightFontColor}
							handleLeaderboardRowClick={handleLeaderboardRowClick}
							setToolTipMessage={setToolTipMessage}
							userLoadedNFTProperties={userLoadedNFTProperties}
							enemyLoadedNFTProperties={enemyLoadedNFTProperties}
						/>
					)}

					<BrainWormsTokenDisplay
						isGuestMode={isGuestMode}
						key={userLoadedNFTIdBigInt}
						style={userTokenDisplayStyles}
						metadata={userLoadedNFTMetadata}
						properties={userLoadedNFTProperties}
						fontColorDefault={sharedDefaultFontColor}
						fontColorHighlight={sharedHighlightFontColor}
						svgStyle={cartridge1SVGStyles}
						getPvpTitle={getPvpTitle}
						onDownloadClick={downloadWorm}
						isEnemy={false}
						ref={tokenDisplayRef}
					/>

					{isRerollPageOpen && (
						<div>
							<BrainWormsRerollPage
								isGuestMode={isGuestMode}
								style={rerollPageDisplayStyles}
								fontColorHighlight={sharedHighlightFontColor}
								elementsRerollPage={elementsRerollPage}
								userLoadedNFTProperties={userLoadedNFTProperties}
								setToolTipMessage={setToolTipMessage}
							/>
						</div>
					)}
					{enemyLoadedNFTIdBigInt && (
						<div>
							<BrainWormsTokenDisplay
								isGuestMode={false}
								key={enemyLoadedNFTIdBigInt}
								style={enemyTokenDisplayStyles}
								metadata={enemyLoadedNFTMetadata}
								properties={enemyLoadedNFTProperties}
								fontColorDefault={sharedEnemyFontColor}
								fontColorHighlight={sharedEnemyHighlightFontColor}
								svgStyle={cartridge2SVGStyles}
								getPvpTitle={getPvpTitle}
								isEnemy={true}
								difficultyLevel={difficultyLevel}
							/>
							<BrainWormsBattleStatsDisplay
								isGuestMode={isGuestMode}
								style={battleStatsTextBoxStyles}
								battleResults={battleResults}
								fontColorHighlight={sharedHighlightFontColor}
								sharedDefaultFontColor={sharedDefaultFontColor}
								fontColorHighlightEnemy={sharedEnemyHighlightFontColor}
								sharedDefaultEnemyFontColor={sharedEnemyFontColor}
								isBattleSoundEnabled={isBattleSoundEnabled}
								svgStyle={cartridge2SVGStyles}
								pvpPointTiers={pvpPointTiers}
								pvpRankTitles={pvpRankTitles}
								onBattleDisplayCompletion={onBattleDisplayCompletion}
							/>
						</div>
					)}
				</>
			)}
			<>
				{clickSoundSrcs.map((src, index) => (
					<audio
						key={index}
						src={src}
						ref={(el) => (audioRefs.current[index] = el)}
					/>
				))}
			</>
		</div>
	);
};

export default BrainWormSimulator;
