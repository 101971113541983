import React from "react";
import SmartImg from "./SmartImg";

const Article = ({
	title,
	titleLevel = 2,
	content,
	images,
	list,
	imageIndex,
	fullImage = true,
}) => {
	const TitleComponent = `h${titleLevel}`; // Dynamically select the heading level

	// Helper function to determine if an image should be shown for the given paragraph index
	const getImageIndexForParagraph = (idx) => {
		if (Array.isArray(imageIndex)) {
			const indexInArray = imageIndex.indexOf(idx);
			if (indexInArray !== -1) {
				return indexInArray;
			}
		} else if (imageIndex === idx) {
			return 0; // If imageIndex is a single value, return the first image
		}
		return -1; // No image for this paragraph
	};

	return (
		<div className="article">
			{title && <TitleComponent>{title}</TitleComponent>}
			{content &&
				content.map((paragraph, idx) => (
					<React.Fragment key={idx}>
						{images && getImageIndexForParagraph(idx) !== -1 && (
							<div
								className={`image-container${fullImage ? " full-image" : ""}`}
							>
								<SmartImg
									src={images[getImageIndexForParagraph(idx)]}
									alt={`${title} ${getImageIndexForParagraph(idx)}`}
									className="article-image"
								/>
							</div>
						)}
						<p>{paragraph}</p>
					</React.Fragment>
				))}

			{list && (
				<ul>
					{list.map((item, idx) => (
						<li key={idx}>{item}</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Article;
