// RegistrationForm.js
import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import WalletConnect from "./WalletConnect";
import ClaimToken from "./ClaimToken";
import { useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8000";

const RegistrationForm = () => {
	const { theme, setTheme } = useContext(ThemeContext);
	const isDark = theme === "dark";
	const navigate = useNavigate();

	const logoSrc = isDark
		? "https://xiosky.com/delmedia/media/logoblack.png"
		: "https://xiosky.com/delmedia/media/logowhite.png";

	const [contractorAddress, setContractorAddress] = useState({
		address: "",
	});
	const [employeeData, setEmployeeData] = useState({
		address: "",
		light: "",
		shadow: "",
	});
	const [serverMessage, setServerMessage] = useState("");

	const sanitizeEthereumAddress = (address) => {
		// Remove leading and trailing spaces
		address = address.trim();

		// Convert to lowercase
		address = address.toLowerCase();

		// Remove "0x" prefix if present
		if (address.startsWith("0x")) {
			address = address.slice(2);
		}

		// Remove non-hex characters
		address = address.replace(/[^a-f0-9]/g, "");

		// Ensure the address is exactly 40 characters long
		if (address.length !== 40) {
			// Handle validation error, if necessary
			// For example: throw new Error("Invalid Ethereum address");
		}

		return "0x" + address; // Add back the "0x" prefix
	};

	const handleSubmit = async (event, formId, formData) => {
		event.preventDefault();

		// Clear out the previous server message
		setServerMessage("");

		try {
			const response = await fetch(`${API_URL}/store`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userInput: formData, rC: formId }),
			});

			const data = await response.json();
			console.log("Data sent to server:", data);

			// Wait for a brief moment before updating the server message
			setTimeout(() => {
				if (response.status === 400) {
					setServerMessage(data.message || "Bad Request");
				} else if (response.status === 200) {
					if (data.redirectUrl) {
						localStorage.setItem("staffaccess_granted", "true");
						navigate(data.redirectUrl);
					} else {
						setServerMessage(data.message || "Success");
					}
				}
			}, 300);
		} catch (err) {
			setTimeout(() => {
				setServerMessage("An error occurred");
			}, 300);

			console.log("An error occurred:", err);
		}
	};

	return (
		<div className="main-content">
			<div className="content-container">
				<div className="spacerShort"></div>
				<div className="article-content">
					<div style={{ width: "85vw" }}>
						<div
							style={{ marginLeft: "auto", marginRight: "auto", width: "50%" }}
						>
							<img src={logoSrc} alt="Del Complex" className="logo-image" />
							<p>Human Capital Registration.</p>
							<div>{serverMessage === "" ? "\u00A0" : serverMessage}</div>
						</div>

						<form
							onSubmit={(e) => handleSubmit(e, "r2", employeeData)}
							className="inputForm"
						>
							<label htmlFor="employeeAddress">Employee Address&nbsp;</label>
							<br />
							<input
								type="text"
								id="employeeAddress"
								name="employeeAddress"
								placeholder="0x..."
								value={employeeData.address}
								onChange={(e) =>
									setEmployeeData({
										...employeeData,
										address: sanitizeEthereumAddress(e.target.value),
									})
								}
							/>
							<br />
							<label htmlFor="employeeLight">Light</label>
							<input
								type="text"
								id="employeeLight"
								name="employeeLight"
								placeholder=""
								value={employeeData.light}
								onChange={(e) =>
									setEmployeeData({ ...employeeData, light: e.target.value })
								}
							/>
							<br />
							<label htmlFor="employeeShadow">Sight</label>
							<input
								type="text"
								id="employeeShadow"
								name="employeeShadow"
								placeholder=""
								value={employeeData.shadow}
								onChange={(e) =>
									setEmployeeData({ ...employeeData, shadow: e.target.value })
								}
							/>
							<button type="submit" className="auto-margin">
								Register
							</button>
						</form>

						<div className="spacerShort"></div>

						<WalletConnect />
						<div className="spacerShort"></div>

						<div className="token-container-single">
							<ClaimToken
								claimId="52390128"
								imageSrc="/media/archival_media/contractor_entrance.jpg"
								fileSrc="/media/archival_media/contractor_entrance.jpg"
								alt="Contractor Entrance"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegistrationForm;
